import React from 'react';

import { showFormInput, showSearchInput } from '../native/V2_Input';
import { showButton } from '../native/V2_Button';
import { showError } from '../native/V2_Error';

var request = require('../../utilities/NetworkRequest');
var cache_util = require('../../utilities/CacheUtil');

function updateText(context, TAG, text) {
  context.setState({ [TAG] : text });
}

function onCloseDialog(context) {
  context.setState({
    d_show_catalog : false
  });
}

export function showCatalogDialog(context) {
  if (context.state.d_show_catalog === undefined) { context.state.d_show_catalog = false; }
  if (context.state.d_prod_head_bot === undefined) { context.state.d_prod_head_bot = ''; }
  if (context.state.d_prod_head_top === undefined) { context.state.d_prod_head_top = ''; }
  if (context.state.d_prod_comparison === undefined) { context.state.d_prod_comparison = ''; }
  if (context.state.d_prod_price === undefined) { context.state.d_prod_price = ''; }
  if (context.state.d_prod_pcode === undefined) { context.state.d_prod_pcode = ''; }
  if (context.state.d_big_catalog === undefined) { context.state.d_big_catalog = true; }
  if (context.state.d_show_catalog) {
    return (
      <div onClick={() => onCloseDialog(context)} style={m_style.root_dialog}>
        <div onClick={(e) => e.stopPropagation()} style={m_style.container}>
          <div style={m_style.wrapper_profile}>
            <div className='v2_text_a v2_text_bold'>Nama Produk</div>
            { showSearchInput(context, 'd_prod_name', 'form_dialog_a', true, null, 'Cth. Mie Gelas', 'text', 255, {}, (data) => context.setState({ d_prod_price : data.price, d_prod_pcode : data.pcode })) }
          </div>

          <div style={{...m_style.wrapper_profile, ...{paddingTop : 10}}}>
            <div className='v2_text_a v2_text_bold'>Harga Produk</div>
            { showFormInput('form_dialog_a', false, context.state.d_prod_price, null, 'Cth. 10000', 'text', 255, {}, (text) => updateText(context, 'd_prod_price', text)) }
          </div>

          <div style={{...m_style.wrapper_profile, ...{paddingTop : 10}}}>
            <div className='v2_text_a v2_text_bold'>Harga Toko Sebelah</div>
            { showFormInput('form_dialog_a', true, context.state.d_prod_comparison, null, 'Cth. 12000', 'text', 255, {}, (text) => updateText(context, 'd_prod_comparison', text)) }
          </div>

          <div style={{...m_style.wrapper_profile, ...{paddingTop : 10}}}>
            <div className='v2_text_a v2_text_bold'>Heading Top</div>
            { showFormInput('form_dialog_a', true, context.state.d_prod_head_top, null, 'Cth. CARI YANG MURAH? DI RUMAH AJA...', 'text', 255, {}, (text) => updateText(context, 'd_prod_head_top', text)) }
          </div>

          <div style={{...m_style.wrapper_profile, ...{paddingTop : 10}}}>
            <div className='v2_text_a v2_text_bold'>Heading Bottom</div>
            { showFormInput('form_dialog_a', true, context.state.d_prod_head_bot, null, 'Cth. HARGA GROSIR BARANG DIANTAR', 'text', 255, {}, (text) => updateText(context, 'd_prod_head_bot', text)) }
          </div>

          <div style={{display : 'flex', flexDirection : 'row', paddingTop : 20}}>
            { showButton('catalog_button', 'BATAL', 'v2_text_f', {borderRadius : 5, flex : 1}, () => onCloseDialog(context)) }
            <div style={{width : 10}}/>
            { showButton('catalog_button', 'SIMPAN', 'v2_text_f', {borderRadius : 5, flex : 1, backgroundColor : '#194E84'}, () => onSaveData(context)) }
          </div>
        </div>
      </div>
    );
  } else { return null; }
}

function onSaveData(context) {
  try {
    if (context.state.user_information === null) { throw Error('Mohon login kembali!'); }
    if (context.state.d_prod_pcode.length === 0) { throw Error('Belum ada Produk yang dipilih!'); }
    if (context.state.d_prod_comparison.length === 0) { throw Error('Harga perbandingan toko sebelah tidak boleh kosong!'); }
    context.setState({ loading_dialog : true }, () => {
      let body = {
        wholesaler_id : context.state.user_information.wholesaler_id,
        pcode : context.state.d_prod_pcode
      }
      request.onRequestData(1, '/show/catalog_product', body, res_data => {
        try {
          if (res_data.code === 0) { throw Error(res_data.message); }

          let new_catalog = {
            name : res_data.result.pcode_name,
            image : res_data.result.imgbig_url,
            uom : res_data.result.uom,
            price : res_data.result.price,
            price_comparison : context.state.d_prod_comparison
          }
          if (context.state.d_big_catalog) {
            new_catalog.h_value = 3;
            new_catalog.p_type = 'banner';
            new_catalog.header_1 = context.state.d_prod_head_top;
            new_catalog.header_2 = context.state.d_prod_head_bot;
          } else {
            new_catalog.h_value = 1;
            new_catalog.p_type = 'product';
          }

          let i, j, cursor, root_banner = context.state.p_setting;
          let c_banner = root_banner.page.data[root_banner.page.selected].catalog
          for (i = 0; i < Object.keys(c_banner).length; i++) {
            cursor = 0;
            for (j = 0; j < Object.keys(c_banner[i]).length; j++) {
              cursor += c_banner[i][j].h_value;
            }
            if ((cursor+new_catalog.h_value) <= root_banner.paper.selected.style_value.h_max) {
              c_banner[i].push(new_catalog);
              cache_util.onSaveCache(cache_util.CACHE_CATALOG_EDITOR, JSON.stringify(root_banner));
              break;
            } else if (cursor < root_banner.paper.selected.style_value.h_max) {
              throw Error('Baris banner yang tersisa tidak mampu menampung produk lainnya! Silahkan hapus salah satu banner terlebih dahulu!');
            }
          }

          context.setState({ loading_dialog : false, p_setting : root_banner, d_show_catalog : false });
        } catch (e) {
          context.setState({ loading_dialog : false, error_dialog : showError({ message : e.message, result : null }) });
        }
      });
    });
  } catch (e) {
    context.setState({ error_dialog : showError({ message : e.message, result : null }) })
  }
}

const m_style = {
  root_dialog : {
    zIndex : 1,
    width : '100%',
    height : '100%',
    display : 'flex',
    position : 'absolute',
    justifyContent : 'center',
    alignItems : 'center',
    backgroundColor: 'rgba(0,0,0,0.6)'
  },
  container : {
    display : 'flex',
    flexDirection : 'column',
    width : '80%',
    borderRadius : 5,
    padding : 10,
    backgroundColor : 'white'
  },
  wrapper_profile : {
    display : 'flex',
    flexDirection : 'column'
  }
}
