import React, { Component } from 'react';

import gif_img from '../resources/loading.gif';

export default class LoadingDialog extends Component {

  render() {
    if (this.props.showDialog) {
      return (
        <div className='process_dialog_root layer_b'>
          <img className='big_image' src={gif_img} alt='' />
        </div>
      );
    } else {
      return null;
    }
  }

}
