import React, { Component } from 'react';

import Dropdown from '../native/Dropdown';
import ErrorDialog from './ErrorDialog';

export default class AdminDialog extends Component {

  constructor(props) {
    super(props);
    let m_body = JSON.parse(this.props.data);
    if (m_body.super_admin === undefined) { m_body.super_admin = false; }
    this.state = {
      body_error : {
        is_show : false,
        message : ''
      },
      body : m_body
    }
  }

  onEditTextChanged(event, action) {
    let temp_data = this.state.body;
    temp_data[action] = event.target.value;
    this.setState({ body : temp_data });
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  onSaveClicked() {
    try {
      if (this.state.body.super_admin && this.state.body.wholesaler_id.length === 0) { throw Error('Kode grosir tidak boleh kosong!'); }
      if (this.state.body.admin_role.selected.key === null) {
        throw Error('Silahkan pilih peran user terlebih dahulu..!!');
      }
      if (this.state.body.phone.length === 0) {
        throw Error('Field nomor tokopintar tidak boleh kosong..!!');
      }
      if (this.state.body.access_code.length === 0) {
        throw Error('Field kode akses grosir tidak boleh kosong..!!');
      }
      this.props.onCloseListener('next', this.state.body);
    } catch (e) {
      this.setState({
        body_error : {
          is_show : true,
          message : e.message
        }
      });
    }
  }

  getUpdateClass() {
    if (this.state.body.is_update) {
      return 'normal_margin_top default_text_normal_gray non_transparent_input_inactive';
    } else {
      return 'normal_margin_top default_text_normal_gray non_transparent_input';
    }
  }

  getWholesalerForm() {
    if (this.state.body.super_admin) {
      return (
        <div style={{marginBottom : 10}}>
          <div className='default_text_normal_gray bold_text'>Kode Grosir</div>
          <input onChange={ (event) => this.onEditTextChanged(event, 'wholesaler_id') } readOnly={this.state.body.is_update} value={this.state.body.wholesaler_id} style={{width:250}} spellCheck='false' className={this.getUpdateClass()} type='text' />
        </div>
      );
    } else { return null; }
  }

  render() {
    return (
      <div onClick={(e) => this.props.onCloseListener('close', null)} className='process_dialog_root layer_a'>
        <div onClick={(e) => e.stopPropagation()} className='add_retail_dialog_container'>
          {this.showErrorDialog(this.state.body_error)}
          {this.getWholesalerForm()}
          <div className='normal_margin_bottom_b default_text_normal_gray bold_text'>Peran User</div>
          <Dropdown data={this.state.body.admin_role}/>
          <div style={{width:262}} className='normal_margin_top_b default_text_normal_red'>*Anda hanya boleh memilih peran yang setara atau dibawah hak akses anda.</div>
          <div className='normal_margin_top_e default_text_normal_gray bold_text'>Nomor Tokopintar</div>
          <input onChange={ (event) => this.onEditTextChanged(event, 'phone') } readOnly={this.state.body.is_update} value={this.state.body.phone} style={{width:250}} spellCheck='false' className={this.getUpdateClass()} type='text' />
          <div className='normal_margin_top_e default_text_normal_gray bold_text'>Kode Akses Grosir</div>
          <input onChange={ (event) => this.onEditTextChanged(event, 'access_code') } readOnly={this.state.body.is_update} value={this.state.body.access_code} style={{width:250}} spellCheck='false' className={this.getUpdateClass()} type='text' />
          <div className='button_process_wrapper_b'>
            <div onClick={() => this.props.onCloseListener('close', null)} className='default_text_normal_white_b red_background process_button_C'>Batal</div>
            <div style={{width : 20}}/>
            <div onClick={this.onSaveClicked.bind(this)} className='default_text_normal_white_b green_background process_button_C'>Simpan</div>
          </div>
        </div>
      </div>
    );
  }

}
