import React, { Component } from 'react';

import ErrorDialog from './ErrorDialog';

var view_util = require('../../utilities/ViewUtil');

export default class AddressDeliveryDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      body_error : {
        is_show : false,
        message : ''
      },
      body : JSON.parse(this.props.data)
    }
  }

  onUpdateClicked() {
    try {
      if (this.state.body.longitude.length === 0) {
        throw Error('Data longitude tidak boleh kosong saat proses pembaharuan ini.');
      }
      if (this.state.body.latitude.length === 0) {
        throw Error('Data latitude tidak boleh kosong saat proses pembaharuan ini.');
      }
      if (this.state.body.address.length === 0) {
        throw Error('Data alamat tidak boleh kosong saat proses pembaharuan ini.');
      }
      this.props.onCloseListener('next', this.state.body);
    } catch (e) {
      this.setState({
        body_error : {
          is_show : true,
          message : e.message
        }
      });
    }
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  render() {
    return (
      <div onClick={(e) => this.props.onCloseListener('close', null)} className='process_dialog_root layer_a'>
        <div onClick={(e) => e.stopPropagation()} className='default_dialog_container'>
          {this.showErrorDialog(this.state.body_error)}
          {this.props.user_information.wholesaler_id !== 'TP9999' ? null : view_util.getInputViewMinimal(this, 'wholesaler_id', 'Kode Grosir', 'Masukkan kode grosir...', true)}
          {this.props.user_information.wholesaler_id !== 'TP9999' ? null : view_util.getInputViewMinimal(this, 'change_wholesaler_id', 'Kode Grosir Pengganti', 'Masukkan kode grosir pengganti...', false)}
          {view_util.getInputViewMinimal(this, 'longitude', 'Longitude')}
          {view_util.getInputViewMinimal(this, 'latitude', 'Latitude')}
          {view_util.getInputAreaViewMinimal(this, 'address', 'Alamat')}
          <div className='button_process_wrapper_b'>
            <div onClick={() => this.props.onCloseListener('close', null)} className='default_text_normal_white_b red_background process_button_C'>Batal</div>
            <div style={{width : 20}}/>
            <div onClick={this.onUpdateClicked.bind(this)} className='default_text_normal_white_b green_background process_button_C'>Ubah</div>
          </div>
        </div>
      </div>
    );
  }

}
