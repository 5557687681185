import React, { Component } from 'react';

import LoadingDialog from './LoadingDialog';
import ErrorDialog from './ErrorDialog';

import gif_img from '../resources/loading.gif';

var img_utils = require('../../utilities/ImageUtil');
var request = require('../../utilities/NetworkRequest');

export default class BannerDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_loading : false,
      body_error : {
        is_show : false,
        message : ''
      },
      data_products : {
        is_show : false,
        is_loading : false,
        arrays : []
      },
      body : JSON.parse(this.props.data)
    }
  }

  getSuggestions() {
    let temp_suggestions = this.state.data_products;
    let temp_body = this.state.body;
    if (temp_body.pcode_name.length > 0) {
      temp_suggestions.is_show = true;
      temp_suggestions.is_loading = true;
      let temp_str = temp_body.pcode_name;
      let self = this;
      let timer = setInterval(function() {
        if (temp_str === temp_body.pcode_name) {
          self.requestSuggestions();
        }
        clearInterval(timer);
      }, 2000);
    } else {
      temp_suggestions.is_show = false;
    }
    this.setState({ data_products : temp_suggestions });
  }

  requestSuggestions() {
    request.onRequestData(1, '/show/suggestions', this.state.body, res_data => {
      let temp_suggestions = this.state.data_products;
      temp_suggestions.is_loading = false;
      if (res_data.code === 1) {
        if (Object.keys(res_data.result).length > 0) {
          temp_suggestions.arrays = res_data.result;
        } else {
          temp_suggestions.is_show = false;
        }
      }
      this.setState({ data_products : temp_suggestions });
    });
  }

  onEditTextChanged(event, action) {
    let temp_data = this.state.body;
    temp_data[action] = event.target.value;
    this.setState({
      body : temp_data
    }, () => {
      if (action === 'pcode_name') {
        this.getSuggestions();
      }
    });
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  onSelectedImage(event) {
    let temp_data = this.state.body;
    temp_data.image = event.target.files[0];
    this.setState({ body : temp_data });
  }

  getPostBody() {
    let data_form = new FormData();
    data_form.append('wholesaler_id', this.state.body.wholesaler_id);
    data_form.append('pcode', this.state.body.pcode);
    data_form.append('description', this.state.body.description);
    if (this.state.body.img_banner !== undefined) { data_form.append('img_banner', this.state.body.img_banner); }
    if (this.state.body.idx !== undefined) { data_form.append('idx', this.state.body.idx); }
    if (this.state.body.image !== null) {
      data_form.append('image_type', this.state.body.image.type.split('/')[1]);
      data_form.append('image', this.state.body.image);
    }
    return data_form;
  }

  postBanner(body) {
    request.onRequestForm(1, '/post/banner', body, res_data => {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false,
          is_error : false
        }, () => {
          this.props.onCloseListener('next');
        });
      } else {
        this.setState({
          is_loading : false,
          is_error : true,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    });
  }

  onSaveClicked() {
    this.setState({
      is_loading : true
    }, () => {
      try {
        if (this.state.body.pcode.length === 0 && this.state.body.description.length === 0) {
          throw Error('Anda harus memilih produk yang akan disangkutkan dengan banner jika anda ingin mengosongkan deskripsi anda.');
        }
        if (this.state.body.image === null && !this.state.body.is_update) {
          throw Error('Anda belum memasukkan gambar banner anda.');
        }
        if (this.state.body.image !== null) {
          if (!img_utils.accepted_file.includes(this.state.body.image.type)) {
            throw Error('Data gambar yang anda inputkan bukan berjenis jpeg/png, cobalah periksa kembali.');
          }
        }
        let temp_body = this.getPostBody();
        this.postBanner(temp_body);
      } catch (e) {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : e.message
          }
        });
      }
    });
  }

  getDropdownLoading() {
    return <div key='loading-dropdown' className='vertical_padding_d dropdown_loading'><img className='mini_image_c' src={gif_img} alt=''/></div>
  }

  onDropdownSelected(data) {
    let temp_body = this.state.body;
    let temp_dropdown = this.state.data_products;
    temp_body.pcode = data.pcode;
    temp_dropdown.is_show = false;
    this.setState({
      data_products : temp_dropdown,
      body : temp_body
    })
  }

  getDropdownData(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <div onClick={this.onDropdownSelected.bind(this, data[i])} key={i} className='side_padding vertical_padding_c default_text_normal_gray'>{data[i].text}</div>
      );
    }
    return view;
  }

  showDropdownData(data) {
    if (data.is_show) {
      return (
        <div className='dropdown_wrapper layer_a normal_margin_top_f'>
          <div className='arrow-up'/>
          <div className='dropdown_content_c white_background'>
            {data.is_loading ? this.getDropdownLoading() : this.getDropdownData(data.arrays)}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div onClick={(e) => this.props.onCloseListener('close')} className='process_dialog_root layer_a'>
        <div onClick={(e) => e.stopPropagation()} className='add_retail_dialog_container'>
          <LoadingDialog showDialog={this.state.is_loading}/>
          {this.showErrorDialog(this.state.body_error)}
          <div className='default_text_normal_gray bold_text'>Cari Produk</div>
          <div className='wrapper_dropdown_search'>
            <input onChange={ (event) => this.onEditTextChanged(event, 'pcode_name') } value={this.state.body.pcode_name} style={{width:250}} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input' type='text' />
            {this.showDropdownData(this.state.data_products)}
          </div>
          <div className='normal_margin_top_e default_text_normal_gray bold_text'>Produk ID</div>
          <input readOnly='readonly' value={this.state.body.pcode} style={{width:250}} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input_inactive' type='text' />
          <div style={{width:262}} className='normal_margin_top_b default_text_normal_red'>*Produk ID akan terisi otomatis berdasarkan pencarian form nama produk di atas.</div>
          <div className='normal_margin_top_e default_text_normal_gray bold_text'>Deskripsi Banner</div>
          <input onChange={ (event) => this.onEditTextChanged(event, 'description') } value={this.state.body.description} style={{width:250}} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input' type='text' />
          <div style={{width:262}} className='normal_margin_top_b default_text_normal_red'>*Deskripsi boleh kosong apabila pada form banner ini di-ikutsertakan dengan produk yang bersangkutan.</div>
          <div className='normal_margin_top_e default_text_normal_gray bold_text'>Upload Gambar Banner</div>
          <input className='normal_margin_top default_text_normal_gray' accept='image/x-png,image/jpeg' onChange={(event) => this.onSelectedImage(event) } type='file'/>
          <div style={{width:262}} className='normal_margin_top_b default_text_normal_red'>*Saat proses unggah gambar, gambar akan dioptimalkan dengan skala ukuran tinggi 600 pixel apabila melebihi ukuran optimal.</div>
          <div className='button_process_wrapper_b'>
            <div onClick={() => this.props.onCloseListener('close')} className='default_text_normal_white_b red_background process_button_C'>Batal</div>
            <div style={{width : 20}}/>
            <div onClick={this.onSaveClicked.bind(this)} className='default_text_normal_white_b green_background process_button_C'>Simpan</div>
          </div>
        </div>
      </div>
    );
  }

}
