import React, { Component } from 'react';

import LoadingDialog from './LoadingDialog';
import ErrorDialog from './ErrorDialog';

import icon_upload from '../resources/upload_white.png';

var request = require('../../utilities/NetworkRequest');

export default class UploadDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_loading : false,
      body_error : {
        is_show : false,
        message : ''
      },
      additional : {
        wholesaler_id : ''
      },
      data : JSON.parse(this.props.data)
    }
  }

  onUploadClicked(position) {
    let data = this.state.data[position], temp_error;
    if (data.upload_data === null) {
      temp_error = this.state.body_error;
      temp_error.is_show = !temp_error.is_show;
      temp_error.message = 'Anda belum memilih file yang akan diunggah!';
      this.setState({ body_error : temp_error });
    } else {
      switch (data.action) {
        case 'admin_product' :
          if (this.state.additional.wholesaler_id.length === 0) {
            temp_error = this.state.body_error;
            temp_error.is_show = !temp_error.is_show;
            temp_error.message = 'Form kode grosir tidak boleh kosong!';
            this.setState({ body_error : temp_error });
          } else { this.postBatchUpdate(position, data); }
          break;
        case 'update_product':
          this.postBatchUpdate(position, data);
          break;
        default :
          alert('Menu ini masih dalam tahap pengembangan.');
          break;
      }
    }
  }

  postBatchUpdate(position, data) {
    this.setState({
      is_loading : !this.state.is_loading
    }, () => {
      let data_form = new FormData();
      data_form.append('excel_data', data.upload_data);
      data_form.append('action', data.action);
      data_form.append('wholesaler_id', this.props.body.wholesaler_id === 'TP9999' ? this.state.additional.wholesaler_id : this.props.body.wholesaler_id );
      data_form.append('additional', JSON.stringify(data.additional));
      request.onRequestForm(1, '/upload/master_product_v2', data_form, function(res_data) {
        if (res_data.code === 1) {
          this.setState({ is_loading : false });
          this.props.onCloseListener('next');
        } else {
          let temp_data = this.state.data;
          temp_data[position].key = new Date().getTime();
          temp_data[position].upload_data = null;
          let temp_error = this.state.body_error;
          temp_error.is_show = !temp_error.is_show;
          temp_error.message = res_data.message;
          this.setState({
            data : temp_data,
            body_error : temp_error,
            is_loading : false
          });
        }
      }.bind(this));
    });
  }

  onSelectedFile(position, event) {
    let temp_data = this.state.data;
    temp_data[position].upload_data = event.target.files[0];
    this.setState({ data : temp_data });
  }

  onCheckboxClicked(position, status) {
    let data = this.state.data;
    if (data[position].additional.mark_price.selected === status) { status = 0; }
    data[position].additional.mark_price.selected = status;
    this.setState({ data : data });
  }

  getMarkPriceView(position) {
    let view = [], temp_data = this.state.data[position], additional;
    if (temp_data.additional.mark_price !== undefined) {
      for (let i = 0; i < Object.keys(temp_data.additional.mark_price.data).length; i++) {
        additional = temp_data.additional.mark_price.data[i];
        view.push(
          <div key={additional.key} className='upload_content on_center'>
            <input onChange={this.onCheckboxClicked.bind(this, position, additional.mark_status)} type="checkbox" checked={additional.mark_status === temp_data.additional.mark_price.selected} />
            <div className='normal_margin_left default_text_normal_gray'>{additional.description}</div>
          </div>
        );
      }
    }
    return view;
  }

  initViewUpload(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <div key={i} className='upload_root'>
          {this.getMarkPriceView(i)}
          <div className='upload_content normal_padding_top'>
            <div className='upload_input_wrapper'>
              <div className='normal_padding_bottom default_text_normal_gray_b bold_text'><u>{data[i].title_view}</u></div>
              <input key={data[i].action + '_' + data[i].key} className='default_text_normal_gray' onChange={(event) => this.onSelectedFile(i, event)} type='file'/>
            </div>
            <div onClick={this.onUploadClicked.bind(this, i)} className='default_text_normal_white_b orange_background upload_button'>
              <img className='mini_image_c' src={icon_upload} alt=''/>
            </div>
          </div>
        </div>
      );
    }
    return view;
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  onChangeValueInput(e, TAG) {
    let temp_data = this.state.additional;
    temp_data[TAG] = e.target.value;
    this.setState({ additional : temp_data });
  }

  getAdditionalInput() {
    if (this.props.body.wholesaler_id === 'TP9999') {
      return (
        <div className='upload_root'>
          <div className='default_text_normal_gray'>Kode Grosir</div>
          <input onChange={(e) => this.onChangeValueInput(e, 'wholesaler_id')} placeholder='ex. G00001' value={this.state.wholesaler_id} spellCheck='false' className='default_text_normal_gray transparent_input_c normal_border_bottom' type='text' />
        </div>
      );
    } else { return null; }
  }

  render() {
    return (
      <div onClick={(e) => this.props.onCloseListener('close')} className='process_dialog_root layer_a'>
        <div onClick={(e) => e.stopPropagation()} className='process_dialog_container_b'>
          <LoadingDialog showDialog={this.state.is_loading}/>
          {this.showErrorDialog(this.state.body_error)}
          <div className='default_text_medium_gray bold_text'><u>UPLOAD MASTER DATA</u></div>
          { this.getAdditionalInput() }
          { this.initViewUpload(this.state.data) }
        </div>
      </div>
    );
  }

}
