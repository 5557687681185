import React, { Component } from 'react';

import img_expand_more from '../resources/baseline_expand_more.png';

export default class DropdownStatic extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data : this.props.data
    }
  }

  onDropdownSelected(position) {
    this.props.onSelectedListener(this.props.body, this.state.data.data[position]);
  }

  populateDropdownItem() {
    let temp = this.state.data.data;
    let view = [];
    for (let i = 0; i < Object.keys(temp).length; i++) {
      view.push(
        <div onClick={this.onDropdownSelected.bind(this, i)} key={temp[i].key} className='side_padding vertical_padding_b default_text_normal_gray'>{temp[i].label}</div>
      );
    }
    return view;
  }

  showChooserDropdown() {
    if (this.state.data.is_expand) {
      return(
        <div className='dropdown_wrapper layer_a normal_margin_top_b'>
          <div className='arrow-up'/>
          <div className='dropdown_content_d white_background'>
            {this.populateDropdownItem()}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  onDropdownClicked(data) {
    data.is_expand = !data.is_expand;
    this.setState({ data : data });
  }

  render() {
    return (
      <div onClick={this.onDropdownClicked.bind(this, this.state.data)} className='dropdown_container_static blue_background'>
        <div className='dropdown_chooser_static'>
          <div className='full_flex default_text_normal_white'>{this.state.data.selected.label}</div>
          <img className='mini_image_b' src={img_expand_more} alt=''/>
        </div>
        { this.state.data.is_expand ? <div className='dropdown_mask' /> : null }
        {this.showChooserDropdown()}
      </div>
    );
  }

}
