// PROJECT DISCONTINUE

import React, { Component } from 'react';

import Dropdown from '../native/Dropdown';
import ErrorDialog from './ErrorDialog';

export default class BundleDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      body_error : {
        is_show : false,
        message : ''
      },
      status_bundle : {
        selected : {
          key : true,
          label : 'Aktif'
        },
        is_expand : false,
        data : [
          { key : true, label : 'Aktif' }, { key : false, label : 'Non-Aktif' }
        ]
      },
      body : JSON.parse(this.props.data)
    }
  }

  onEditTextChanged(event, action) {
    let temp_data = this.state.body;
    temp_data[action] = event.target.value;
    this.setState({ body : temp_data });
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  onSelectedImage(event) {
    let temp_data = this.state.body;
    temp_data.image = event.target.files[0];
    this.setState({ body : temp_data });
  }

  onSaveClicked() {
    try {
      let temp_data = this.state.body;
      if (temp_data.bundle_name.length === 0) { throw Error('Kolom Nama Bundle tidak boleh kosong..!!'); }
      if (!temp_data.is_update && temp_data.image === null) { throw Error('Belum ada gambar yang akan anda unggah!'); }
      temp_data.is_active = this.state.status_bundle.selected.key;
      this.props.onCloseListener('next', temp_data);
    } catch (e) {
      this.setState({
        body_error : {
          is_show : true,
          message : e.message
        }
      });
    }
  }

  render() {
    return (
      <div onClick={(e) => this.props.onCloseListener('close', null)} className='process_dialog_root layer_a'>
        <div onClick={(e) => e.stopPropagation()} className='add_retail_dialog_container'>
          {this.showErrorDialog(this.state.body_error)}
          <div className='default_text_normal_gray bold_text'>Nama Bundle</div>
          <input onChange={ (event) => this.onEditTextChanged(event, 'bundle_name') } value={this.state.body.bundle_name} style={{width:250}} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input' type='text' />

          <div className='normal_margin_bottom_b normal_margin_top_e default_text_normal_gray bold_text'>Status Bundle</div>
          <Dropdown data={this.state.status_bundle}/>

          <div className='normal_margin_top_e default_text_normal_gray bold_text'>Upload Gambar</div>
          <input className='normal_margin_top default_text_normal_gray' accept='image/x-png,image/jpeg' onChange={(event) => this.onSelectedImage(event) } type='file'/>
          <div className='button_process_wrapper_b'>
            <div onClick={() => this.props.onCloseListener('close', null)} className='default_text_normal_white_b red_background process_button_C'>Batal</div>
            <div style={{width : 20}}/>
            <div onClick={this.onSaveClicked.bind(this)} className='default_text_normal_white_b green_background process_button_C'>Simpan</div>
          </div>
        </div>
      </div>
    );
  }

}
