import React, { Component } from 'react';
import QueryString from 'query-string';

import gif_img from './resources/loading.gif';
import img_error from './resources/error.png';
import img_setting from './resources/settings.png';
import img_back from './resources/back_a.png';

import PageNotFound from './PageNotFound';
import LoadingDialog from './dialog/LoadingDialog';
import ErrorDialog from './dialog/ErrorDialog';

var request = require('../utilities/NetworkRequest');
var cache_util = require('../utilities/CacheUtil');

var route_list = [
  'login',
  'gphome',
  'print',
  'print_mini',
  'print_mini_old',
  'print_delivery',
  'products_catalog',
  'print_product',
  'catalog_editor',

  'v2_wholesaler'
];

export default class Wholesaler extends Component {

  constructor(props) {
    super(props);
    this.m_filter = null;
    this.state = {
      is_main_loading : true,
      is_loading : false,
      is_error : false,
      error_message : '',
      is_filter : false,
      body_error : {
        is_show : false,
        message : ''
      },
      user_id : null,
      list_wholesaler : [],
      pagination : null,
      filter : [
        {
          type : 'input_free_text',
          key : 'a.wholesaler_name',
          value : ''
        }
      ]
    }
  }

  requestData() {
    try {
      let body = {}, temp_session;
      if (this.props.match.params.session === 'wholesaler') {
        temp_session = cache_util.onGetCacheString(cache_util.CACHE_SESSION);
        if (temp_session !== null) {
          body.session = temp_session;
        } else {
          throw Error('Belum ada session, silahkan login di https://www.tokopintar.co.id/');
        }
      } else {
        temp_session = QueryString.parse(this.props.location.search);
        body.session = temp_session.session;
      }
      request.onRequestData(request.OPTION_API_MAIN, '/show/wholesaler', body, res_data => {
        if (res_data.code === 1) {
          if (this.props.match.params.session !== 'wholesaler') {
            cache_util.onSaveCache(cache_util.CACHE_SESSION, body.session);
          }
          // CHECK IF RESULT IS ONLY ONE BY IT'S VERSION
          if (res_data.result.hasOwnProperty('wholesaler')) {
            this.setState({
              is_main_loading : false,
              user_id : res_data.result.user_id,
              list_wholesaler : res_data.result.wholesaler,
              pagination : res_data.result.pagination
            });
          } else {
            cache_util.onSaveCache(cache_util.CACHE_INFO, JSON.stringify(res_data));
            window.open(request.IP_PATH + '/gphome/dashboard?dt=' + new Date().getTime(), '_self');
          }
        } else {
          this.setState({
            is_main_loading : false,
            is_error : true,
            error_message : res_data.message
          });
        }
      });
    } catch (e) {
      this.setState({
        is_main_loading : false,
        is_error : true,
        error_message : e.message
      });
    }
  }

  requestHome(data) {
    this.setState({ is_loading : true }, () => {
      let body = {
        wholesaler_id : data.wholesaler_id,
        user_id : this.state.user_id === null ? undefined : this.state.user_id
      };
      let m_info = { result : { user_information : { wholesaler_id : data.wholesaler_id } } }
      cache_util.onSaveCache(cache_util.CACHE_INFO, JSON.stringify(m_info));
      request.onRequestData(request.OPTION_API_MAIN, '/show/home', body, res_data => {
        if (res_data.code === 1) {
          cache_util.onSaveCache(cache_util.CACHE_INFO, JSON.stringify(res_data));
          window.open(request.IP_PATH + '/gphome/dashboard?dt=' + new Date().getTime(), '_self');
        } else {
          this.setState({
            is_loading : false,
            body_error : {
              is_show : true,
              message : res_data.message
            }
          });
        }
      });
    });
  }

  getAdminStyle(data) {
    if (data.wholesaler_id !== 'TP9999') {
      return 'wholesaler_card custom_cursor_a';
    } else {
      return 'wholesaler_card custom_cursor_a green_linear_bg';
    }
  }

  getTitleStyle(data) {
    if (data.wholesaler_id !== 'TP9999') {
      return 'default_text_normal_gray_b bold_text';
    } else {
      return 'default_text_normal_white_b bold_text';
    }
  }

  getLabelStyle(data) {
    if (data.wholesaler_id !== 'TP9999') {
      return 'normal_margin_top_e default_text_normal_gray';
    } else {
      return 'normal_margin_top_e default_text_normal_white';
    }
  }

  getViewWholesaler(data) {
    let view = [];
    if (Object.keys(data).length === 0) {
      view.push(
        <div className='display_flex_center' style={{height : 100}}>
          <div className='default_text_medium_white'>Tidak ada grosir yang ditemukan!</div>
        </div>
      );
    } else {
      for (let i = 0; i < Object.keys(data).length; i++) {
        view.push(
          <div onClick={this.requestHome.bind(this, data[i])} key={i} className={this.getAdminStyle(data[i])}>
            <div style={{padding:20}} className='display_flex_center'>
              <img className='circle_background_g' src={data[i].profile_img_url} alt='' />
            </div>
            <div className='wholesaler_info'>
              <div className={this.getTitleStyle(data[i])}>{data[i].wholesaler_name}</div>
              <div className={this.getLabelStyle(data[i])}>{data[i].address}</div>
            </div>
          </div>
        )
      }
    }
    view.push(<div id='bottom_nav' key='bottom_nav' style={{height : 30, flexShrink : 0}}/>);
    return view;
  }

  trackScrolling(el) {
    let bottom_el = document.getElementById('bottom_nav');
    if (bottom_el.getBoundingClientRect().bottom <= window.innerHeight) {
      if (!this.state.is_loading && this.state.pagination.is_more) {
        this.requestMoreWholesaler(this.m_filter, true);
      }
    }
  }

  requestMoreWholesaler(filter, next) {
    this.setState({ is_loading : true }, () => {
      let body = {
        user_id : this.state.user_id,
        idx : this.state.pagination.last
      }
      if (filter !== null) { body.filter = filter; }
      if (!next) { delete body.idx; }
      request.onRequestData(request.OPTION_API_MAIN, '/show/wholesaler', body, res_data => {
        if (res_data.code === 1) {
          let temp_wholesaler = this.state.list_wholesaler;
          if (next) {
            temp_wholesaler = temp_wholesaler.concat(res_data.result.wholesaler);
          } else {
            temp_wholesaler = res_data.result.wholesaler;
          }
          this.setState({
            is_loading : false,
            list_wholesaler : temp_wholesaler,
            pagination : res_data.result.pagination
          });
        } else {
          this.setState({
            is_loading : false,
            body_error : {
              is_show : true,
              message : res_data.message
            }
          });
        }
      });
    })
  }

  onFilterClicked() {
    this.setState({is_filter : true}, () => {
      this.m_filter = this.state.filter;
      this.requestMoreWholesaler(this.m_filter, false);
    });
  }

  onEditTextChanged(value) {
    let temp = this.state.filter;
    temp[0].value = value;
    this.setState({ filter : temp });
  }

  getResetFilter() {
    if (this.state.is_filter) {
      return (
        <div onClick={() => this.onResetFilter()} className='display_flex_center' style={{cursor : 'pointer', width : 50}}>
          <img src={img_back} alt=''/>
        </div>
      );
    } else { return null; }
  }

  onResetFilter() {
    let temp = this.state.filter;
    temp[0].value = '';
    this.m_filter = null;
    this.setState({
      is_filter : false,
      filter : temp
    }, () => {
      this.requestMoreWholesaler(this.m_filter, false);
    });
  }

  getRootView() {
    if (this.state.is_main_loading) {
      this.requestData();
      return (
        <div className='loading_container_b'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return (
          <div className='root_login'>
            <LoadingDialog showDialog={this.state.is_loading}/>
            {this.showErrorDialog(this.state.body_error)}
            <div style={{width : 440, maxHeight : '80%', display : 'flex', flexDirection : 'column', backgroundImage : 'linear-gradient(-40deg, #f2ab1d, #ee3c32)'}}>
              <div style={{margin : 20, height : 40, background : 'white', flexShrink : 0, borderRadius : 5}}>
                <div style={{width : '100%', height : '100%', display : 'flex', flexDirection : 'row'}}>
                  { this.getResetFilter() }
                  <input onChange={(event) => this.onEditTextChanged(event.target.value)} placeholder='Ketik nama grosir disini...' value={this.state.filter[0].value} className='transparent_input' style={{margin : '0 10px', flex : 1, width : 'auto'}} spellCheck='false' type='text' />
                  <div onClick={() => this.onFilterClicked()} className='display_flex_center' style={{cursor : 'pointer', width : 50}}>
                    <img src={img_setting} alt=''/>
                  </div>
                </div>
              </div>

              <div onScroll={this.trackScrolling.bind(this)} className='wholesaler_container'>
                {this.getViewWholesaler(this.state.list_wholesaler)}
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className='loading_container_b'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  render() {
    if (this.props.match.params.session === undefined) {
      // SHOW PAGE NOT FOUND
      return <PageNotFound />;
    } else {
      if (route_list.includes(this.props.match.params.session)) {
        let temp_session = cache_util.onGetCacheString(cache_util.CACHE_SESSION);
        if (temp_session === null) {
          return <PageNotFound />;
        } else {
          return null;
        }
      } else {
        return this.getRootView();
      }
    }
  }

}
