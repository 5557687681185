import React from 'react';

import { showButton } from './V2_Button';

import ic_error from '../resources_v2/error_a.png';

export function showError(res_fail) {
  return {
    is_show : res_fail === null ? false : true,
    message : res_fail === null ? null : res_fail.message,
    result : res_fail === null ? null : res_fail.result
  }
}

function getImageError(TAG, context) {
  if (context.state[TAG].result !== null) {
    switch (context.state[TAG].result.action) {
      default:
        return ic_error;
    }
  } else { return ic_error; }
}

function getTitleError(TAG, context) {
  if (context.state[TAG].result !== null) {
    switch (context.state[TAG].result.action) {
      default:
        return 'Oops!';
    }
  } else { return 'Oops!'; }
}

async function getErrorAction(TAG, context, listener) {
  switch (context.state[TAG].result.action) {
    default:
      if (listener !== null) {
        listener();
      } else {
        context.setState({ [TAG] : showError(null) });
      }
      break;
  }
}

export function showErrorDialog(context) {
  if (context.state.error_dialog === undefined) { context.state.error_dialog = showError(null); }
  if (context.state.error_dialog.is_show) {
    return (
      <div onClick={() => context.setState({ error_dialog : showError(null) })} style={m_style.root_dialog} >
        <div onClick={(e) => e.stopPropagation()} style={m_style.dialog_container}>
          <div style={{flex : 1, flexDirection : 'column', padding : 10, overflow : 'auto'}}>
            <div style={{display : 'flex', justifyContent : 'center'}}><img alt='' style={{width : 100, height : 100}} src={getImageError('error_dialog', context)}/></div>
            <div className='v2_text_c v2_text_bold' style={{textAlign : 'center', marginTop : 10}}>{getTitleError('error_dialog', context)}</div>
            <div className='v2_text_a' style={{textAlign : 'center'}}>{context.state.error_dialog.message}</div>
          </div>
          { showButton('error_action', (context.state.error_dialog.result === null ? 'Tutup' : context.state.error_dialog.result.message), 'v2_text_f', {marginTop : 20, borderRadius : 0, flexShrink : 0}, () => { (context.state.error_dialog.result === null) ? context.setState({ error_dialog : showError(null) }) : getErrorAction('error_dialog', context, null) }) }
        </div>
      </div>
    );
  } else { return null; }
}

export function showErrorPage(context, main_view, listener) {
  if (context.state.error_page === undefined) { context.state.error_page = showError(null); }
  if (context.state.error_page.is_show) {
    return (
      <div style={m_style.root_error}>
        <div style={{justifyContent : 'center', alignItems : 'center', flexDirection : 'column', display : 'flex', maxWidth : 300, maxHeight : 300}}>
          <img style={m_style.error_image} alt='' src={getImageError('error_page', context)}/>
          <div className='v2_text_c v2_text_bold' style={{marginTop : 10}}>{getTitleError('error_page', context)}</div>
          <div className='v2_text_a' style={{textAlign : 'center'}}>{context.state.error_page.message}</div>
          { context.state.error_page.result === null ? null : showButton('error_action', context.state.error_page.result.message, 'v2_text_f', {width : '100%', marginTop : 20}, () => getErrorAction('error_page', context, listener)) }
        </div>
      </div>
    );
  } else {
    return main_view;
  }
}

const m_style = {
  dialog_container : {
    display : 'flex',
    flexDirection : 'column',
    width : '80%',
    maxHeight : '80%',
    maxWidth : 300,
    backgroundColor : 'white'
  },
  root_dialog : {
    display : 'flex',
    width : '100%',
    height : '100%',
    position : 'absolute',
    backgroundColor : 'rgba(0, 0, 0, 0.8)',
    justifyContent : 'center',
    alignItems : 'center',
    zIndex : 3
  },
  root_error : {
    width : '100%',
    height : '100vh',
    display : 'flex',
    backgroundColor : 'white',
    justifyContent : 'center',
    alignItems : 'center'
  },
  error_image : {
    width : 150,
    height : 150
  }
}
