import React, { Component } from 'react';

import LoadingDialog from './LoadingDialog';
import ErrorDialog from './ErrorDialog';

import icon_font from '../resources/font.png';
import icon_barcode from '../resources/barcode.png';
import icon_category from '../resources/category.png';
import icon_measurement from '../resources/measurement.png';
import icon_calculator from '../resources/calculator.png';
import icon_lightbulb from '../resources/lightbulb.png';
import icon_delete from '../resources/baseline_delete.png';
import icon_media from '../resources/media.png';
import icon_percent_up from '../resources/percent_up.png';
import icon_percent_down from '../resources/percent_down.png';
import img_edit from '../resources/baseline_edit.png';
import img_edit_black from '../resources/baseline_edit_black.png';
import img_add_white from '../resources/baseline_add_white.png';
import percent from '../resources/percent.png';
import gif_img from '../resources/loading.gif';
import img_error from '../resources/error.png';

var request = require('../../utilities/NetworkRequest');
var img_utils = require('../../utilities/ImageUtil');
var view_util = require('../../utilities/ViewUtil');
var str_util = require('../../utilities/StringUtil');

export default class ProductDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : true,
      is_loading : false,
      is_error : false,
      error_message : '',
      body_error : {
        is_show : false,
        message : ''
      },
      category : {},
      principals : {},
      subtag : {},
      measurement_a : {},
      measurement_b : {},
      calculator : {},
      price : [],
      image : null,
      information : {},
      data_wholesaler : {},
      logs_product : [],
      price_input : this.resetInput()
    }
  }

  componentDidMount() {
    request.onRequestData(request.OPTION_API_MAIN, '/show/detail_product', this.props.body, res_data => {
      if (res_data.code === 1) {
        this.setState({
          information : res_data.result.information,
          data_wholesaler : res_data.result.data_wholesaler,
          price : res_data.result.price,
          category : res_data.result.category,
          principals : res_data.result.principals,
          subtag : res_data.result.subtag,
          measurement_a : res_data.result.measurement_a,
          measurement_b : res_data.result.measurement_b,
          calculator : res_data.result.calculator,
          logs_product : res_data.result.logs_product,
          is_main_loading : false,
          is_error : false
        });
      } else {
        this.setState({
          is_main_loading : false,
          is_error : true,
          error_message : res_data.message
        });
      }
    });
  }

  onSelectedImage(event) {
    this.setState({ image : event.target.files[0] });
  }

  getDePercetage(data) {
    if (data.discount_rate !== undefined) {
      return Math.ceil(data.price - (data.price * data.discount_rate / 100));
    } else {
      return data.discount;
    }
  }

  getPercentage(data) {
    if (data.discount_rate === undefined) {
      return Math.ceil((data.price - data.discount) / data.price * 100);
    } else {
      return data.discount_rate;
    }
  }

  onEditClicked(position) {
    let temp_data = this.state.price;
    temp_data[position].is_edit = !temp_data[position].is_edit;
    temp_data[position].discount_rate = this.getPercentage(temp_data[position]);
    this.setState({ price : temp_data });
  }

  getMarkPriceAction(is_up, position) {
    if (this.state.data_wholesaler.fee_status === 2) {
      if (is_up) {
        return (
          <div onClick={this.onMarkPrice.bind(this, is_up, position)} className='normal_margin_left custom_padding_b label_search_container green_background'>
            <img className='mini_image_d' src={icon_percent_up} alt=''/>
          </div>
        );
      } else {
        return (
          <div onClick={this.onMarkPrice.bind(this, is_up, position)} className='action_button normal_margin_left custom_padding_b label_search_container orange_background'>
            <img className='mini_image_d' src={icon_percent_down} alt=''/>
          </div>
        );
      }
    } else { return null; }
  }

  show_data_table(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      if (!data[i].is_edit) {
        view.push(
          <tr key={i}>
            <td className='whole_padding default_text_normal_gray'>{data[i].quantity}</td>
            <td className='whole_padding default_text_normal_gray'>{str_util.formatSeparator(data[i].price.toString())}</td>
            <td className='whole_padding default_text_normal_gray'>{this.getPercentage(data[i]) + ' % (' + str_util.formatSeparator(this.getDePercetage(data[i]).toString()) + ')'}</td>
            <td>
              <div className='wrapper_product_action'>
                <div onClick={this.onEditClicked.bind(this, i)} className='custom_padding_b label_search_container blue_background'>
                  <img className='mini_image_d' src={img_edit} alt=''/>
                  <div className='normal_padding_left_c default_text_normal_white'>Ubah</div>
                </div>
                {this.getMarkPriceAction(true, i)}
                {this.getMarkPriceAction(false, i)}
                <div onClick={this.onDeleteClicked.bind(this, i)} className='normal_margin_left custom_padding_b label_search_container red_background'>
                  <img className='mini_image_d' src={icon_delete} alt=''/>
                </div>
              </div>
            </td>
          </tr>
        );
      } else {
        view.push(
          <tr key={i}>
            <td>
              <div className='quantity_edit_wrapper'>
                <input onChange={ (event) => this.onEditTextChanged(event, 'quantity', data[i], i) } value={data[i].quantity} spellCheck='false' className='default_text_normal_gray transparent_input_d normal_border_bottom' type='number' />
                <img className='normal_padding_left_c mini_image_d' src={img_edit_black} alt=''/>
              </div>
            </td>
            <td>
              <div className='quantity_edit_wrapper'>
                <input onChange={ (event) => this.onEditTextChanged(event, 'price', data[i], i) } value={data[i].price} spellCheck='false' className='default_text_normal_gray transparent_input_d normal_border_bottom' type='number' />
                <img className='normal_padding_left_c mini_image_d' src={img_edit_black} alt=''/>
              </div>
            </td>
            <td>
              <div className='quantity_edit_wrapper'>
                <input step='.01' onChange={ (event) => this.onEditTextChanged(event, 'discount_rate', data[i], i) } value={data[i].discount_rate} spellCheck='false' className='default_text_normal_gray transparent_input_d normal_border_bottom' type='number' />
                <img className='normal_padding_left_c mini_image_d' src={percent} alt=''/>
              </div>
            </td>
            <td>
              <div className='wrapper_product_action'>
                <div onClick={this.onEditClicked.bind(this, i)} className='custom_padding_b label_search_container blue_background'>
                  <img className='mini_image_d' src={img_edit} alt=''/>
                  <div className='normal_padding_left_c default_text_normal_white'>Simpan</div>
                </div>
                {this.getMarkPriceAction(true, i)}
                {this.getMarkPriceAction(false, i)}
                <div onClick={this.onDeleteClicked.bind(this, i)} className='normal_margin_left custom_padding_b label_search_container red_background'>
                  <img className='mini_image_d' src={icon_delete} alt=''/>
                </div>
              </div>
            </td>
          </tr>
        );
      }
    }
    return view;
  }

  onMarkPrice(is_up, position) {
    if (this.state.category.selected.key !== null) {
      let temp_data = this.state.price;
      temp_data[position].discount_rate = this.getPercentage(temp_data[position]);
      temp_data[position].price = Number(temp_data[position].price);
      if (is_up) {
        temp_data[position].price = Math.ceil(temp_data[position].price + (temp_data[position].price * this.getMarkCategory(is_up)));
      } else {
        temp_data[position].price = Math.floor(temp_data[position].price / this.getMarkCategory(is_up));
      }
      this.setState({ price : temp_data });
    } else {
      this.setState({
        body_error : {
          is_show : true,
          message : 'Silahkan pilih kategori produk anda terlebih dahulu!'
        }
      });
    }
  }

  getMarkCategory(is_up) {
    if (is_up && this.state.category.selected.key === 'CA0013') {
      return 0.001;
    } else if (is_up && this.state.category.selected.key !== 'CA0013') {
      return 0.005;
    } else if (!is_up && this.state.category.selected.key === 'CA0013') {
      return 1.001;
    } else if (!is_up && this.state.category.selected.key !== 'CA0013') {
      return 1.005;
    }
  }

  onDeleteClicked(position) {
    let temp_data = [];
    for (let i = 0; i < Object.keys(this.state.price).length; i++) {
      if (i !== position) {
        temp_data.push(this.state.price[i]);
      }
    }
    this.setState({ price : temp_data });
  }

  onEditTextChanged(event, action, data, position) {
    if (event.target.value.length === 0 || event.target.value < 0) {
      event.target.value = 0;
    }
    if (action === 'discount_rate') {
      if (event.target.value > 100) {
        event.target.value = 100;
      }
    }
    data[action] = Number(event.target.value);
    if (position === -1) {
      this.setState({ price_input : data });
    } else {
      let temp_data = this.state.price;
      temp_data[position] = data;
      this.setState({ price : temp_data });
    }
  }

  onAddClicked() {
    if (this.state.price_input.quantity !== 0 && this.state.price_input.price !== 0) {
      let temp_data = this.state.price;
      temp_data.push({
        quantity : this.state.price_input.quantity,
        price : this.state.price_input.price,
        discount : 0,
        is_edit : false,
        discount_rate : this.state.price_input.discount_rate
      });
      temp_data.sort(function(a, b) {
        return a.quantity - b.quantity;
      });
      temp_data.sort();
      this.setState({
        price : temp_data,
        price_input : this.resetInput()
      })
    }
  }

  resetInput() {
    return {
      quantity : 0,
      price : 0,
      discount_rate : 0
    }
  }

  show_add_editor(data) {
    return(
      <tr>
        <td>
          <div className='quantity_edit_wrapper'>
            <input onChange={ (event) => this.onEditTextChanged(event, 'quantity', data, -1) } value={data.quantity} spellCheck='false' className='default_text_normal_gray transparent_input_d normal_border_bottom' type='number' />
            <img className='normal_padding_left_c mini_image_d' src={img_edit_black} alt=''/>
          </div>
        </td>
        <td>
          <div className='quantity_edit_wrapper'>
            <input onChange={ (event) => this.onEditTextChanged(event, 'price', data, -1) } value={data.price} spellCheck='false' className='default_text_normal_gray transparent_input_d normal_border_bottom' type='number' />
            <img className='normal_padding_left_c mini_image_d' src={img_edit_black} alt=''/>
          </div>
        </td>
        <td>
          <div className='quantity_edit_wrapper'>
            <input step='.01' onChange={ (event) => this.onEditTextChanged(event, 'discount_rate', data, -1) } value={data.discount_rate} spellCheck='false' className='default_text_normal_gray transparent_input_d normal_border_bottom' type='number' />
            <img className='normal_padding_left_c mini_image_d' src={percent} alt=''/>
          </div>
        </td>
        <td>
          <div onClick={this.onAddClicked.bind(this)} className='custom_padding_b label_search_container blue_background'>
            <img className='mini_image_d' src={img_add_white} alt=''/>
            <div className='normal_padding_left_c default_text_normal_white'>Tambah</div>
          </div>
        </td>
      </tr>
    );
  }

  getImageChooser() {
    if (this.props.body.pcode === undefined || this.props.body.is_admin) {
      return (
        <div className='wrapper_form_product normal_border_bottom normal_padding_bottom'>
          <div className='wrapper_left_form_product'>
            <img className='normal_margin_top_d mini_image_d' src={icon_media} alt=''/>
            <div className='normal_margin_left default_text_normal_gray'>Gambar Produk</div>
          </div>
          <div className='wrapper_right_form_product'>
            <input className='default_text_normal_gray' accept='image/x-png,image/jpeg' onChange={(event) => this.onSelectedImage(event) } type='file'/>
          </div>
        </div>
      );
    } else { return null; }
  }

  getPriceTableView() {
    if (this.props.body.pcode !== undefined && !this.props.body.is_admin) {
      return (
        <div className='wrapper_form_product center_content_b normal_border_bottom normal_padding_bottom'>
          <table className='table_data_price'>
            <thead className='gray_background'>
              <tr>
                <th className='whole_padding default_text_normal_gray'>Kuantitas</th>
                <th className='whole_padding default_text_normal_gray'>Harga</th>
                <th className='whole_padding default_text_normal_gray'>Diskon</th>
                <th className='whole_padding default_text_normal_gray'>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.show_add_editor(this.state.price_input)}
              {this.show_data_table(this.state.price)}
            </tbody>
          </table>
        </div>
      );
    } else { return null; }
  }

  getAdditionalView() {
    if (this.props.body.pcode !== undefined && !this.props.body.is_admin) {
      return (
        <div className='wrapper_form_product normal_border_bottom'>
          <div className='wrapper_left_form_product'>
            <img className='normal_margin_top_d mini_image_d' src={icon_lightbulb} alt=''/>
            <div className='normal_margin_left default_text_normal_gray'>Status Produk</div>
          </div>
          <div className='wrapper_right_form_product'>
            {view_util.getSwitchView(this, 'flag_new_item', 'Barang Baru', '#ee3c32')}
            {view_util.getSwitchView(this, 'flag_promo', 'Barang Promo', '#f2ab1d')}
            {view_util.getSwitchView(this, 'is_active', 'Tampilkan Produk', '#43987b')}
          </div>
        </div>
      );
    } else { return null; }
  }

  getLogsProducts() {
    if (this.state.logs_product.length > 0) {
      let view = [];
      for (let i = 0; i < this.state.logs_product.length; i++) {
        view.push(
          <div key={this.state.logs_product[i].log_id} style={{padding : '10px 10px 0', display : 'flex', flexDirection : 'row', alignItems : 'center'}}>
            <div className='blue_background' style={{width : 5, height : 5, borderRadius : '50%', marginRight : 10}}/>
            <div className='default_text_normal_gray'>{this.state.logs_product[i].message}</div>
          </div>
        );
      }
      return (
        <div className='wrapper_form_product normal_border_bottom' style={{flexDirection : 'column', paddingBottom : 20}}>
          <div className='default_text_normal_gray_b'>Log Produk</div>
          { view }
        </div>
      );
    } else {
      return null;
    }
  }

  onChangeCategory(data) {
    let m_subtag = this.state.subtag;
    m_subtag.selected = {
      key : null,
      label : 'Pilih Sub Kategori Produk'
    }
    m_subtag.data = [];
    this.setState({ subtag : m_subtag, is_loading : true }, () => {
      let body = { cat_a : data.key }
      request.onRequestData(request.OPTION_API_MAIN, '/get/sub_category', body, res_data => {
        m_subtag.data = res_data.result.data;
        if (res_data.code === 1) {
          this.setState({
            is_loading : false,
            subtag : m_subtag
          });
        } else {
          this.setState({
            is_loading : false,
            body_error : {
              is_show : true,
              message : res_data.message
            }
          });
        }
      });
    });
  }

  initMainView() {
    if (this.state.is_main_loading) {
      return (
        <div onClick={(e) => e.stopPropagation()} className='pop_up_dialog_container'>
          <img className='big_image' src={gif_img} alt='' />
          <div className='custom_padding_c default_text_big_gray'>Memuat Data</div>
        </div>
      );
    } else {
      if (this.state.is_error) {
        return (
          <div onClick={(e) => e.stopPropagation()} className='pop_up_dialog_container'>
            <img className='normal_margin_top_c big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_medium_gray'>{this.state.error_message}</div>
          </div>
        );
      } else {
        return (
          <div onClick={(e) => e.stopPropagation()} className='product_dialog_container'>
            <div className='wrapper_form_product normal_padding_bottom default_text_medium_gray bold_text normal_border_bottom'>PRODUK EDITOR</div>
            {view_util.getDropdownView(this, 'category', icon_category, 'Kategori', false, (this.props.body.is_admin || this.props.body.pcode === undefined), true, (data) => this.onChangeCategory(data))}
            {view_util.getDropdownView(this, 'subtag', icon_category, 'Sub Kategori', false, (this.props.body.is_admin || this.props.body.pcode === undefined), true)}
            {view_util.getDropdownView(this, 'principals', icon_category, 'Produsen', true, (this.props.body.is_admin || this.props.body.pcode === undefined), true)}
            {view_util.getInputView(this, 'pcode_name', icon_font, 'Nama produk', 'ex. PERMEN RASA DURIAN 30 GRAM', (this.props.body.pcode !== undefined && !this.props.body.is_admin), true, false)}
            {view_util.getInputView(this, 'pcode_suggested', icon_barcode, 'ID Produk Saran', 'ex. 100099', false, (this.props.body.is_admin && this.props.body.wholesaler_id !== 'TP9999'), false)}
            {view_util.getInputView(this, 'pcode', icon_barcode, 'ID Produk', 'ex. 100099', true, (this.props.body.pcode !== undefined), false)}
            {view_util.getInputView(this, 'oddo_ref', icon_barcode, 'Referensi ODDO', 'ex. REFERENSI PRODUK ODDO', false, true, false)}
            {view_util.getInputView(this, 'barcode', icon_barcode, 'Barcode Produk', 'ex. 8997032680329', (this.props.body.pcode !== undefined && !this.props.body.is_admin), true, (this.props.body.pcode !== undefined && !this.props.body.is_admin))}
            {this.getImageChooser()}
            {view_util.getDropdownView(this, 'measurement_a', icon_measurement, 'Satuan Ukur Besar', false, true, (this.props.body.pcode !== undefined && !this.props.body.is_admin))}
            {view_util.getDropdownView(this, 'measurement_b', icon_measurement, 'Satuan Ukur Kecil', false, true, (this.props.body.pcode !== undefined && !this.props.body.is_admin))}
            {view_util.getDropdownView(this, 'calculator', icon_calculator, 'Jenis Perhitungan', true, true, (this.props.body.pcode !== undefined && !this.props.body.is_admin))}
            {this.getPriceTableView()}
            {view_util.getInputView(this, 'conv', icon_calculator, 'Konversi Produk', 'ex. 12', false, (this.props.body.pcode !== undefined && !this.props.body.is_admin), false)}
            {view_util.getInputView(this, 'order_number', icon_calculator, 'Urutan Produk', 'ex. 12', false, (this.props.body.pcode !== undefined && !this.props.body.is_admin), false)}
            {this.getAdditionalView()}
            {this.getLogsProducts()}
            {this.getProductAction()}
          </div>
        );
      }
    }
  }

  getProductAction() {
    if (this.props.body.is_admin && this.props.body.wholesaler_id !== 'TP9999') {
      return (
        <div style={{paddingTop : 50}} className='wrapper_form_product'>
          <div onClick={this.onPostAction.bind(this, 2)} className='default_text_normal_white_b green_background process_button_b'>Tambahkan</div>
          <div style={{width : 20}}/>
          <div onClick={this.onPostAction.bind(this, 1)} className='default_text_normal_white_b red_background process_button_b'>Alihkan Sesuai Saran</div>
        </div>
      );
    } else {
      return (
        <div style={{paddingTop : 50}} className='wrapper_form_product'>
          <div onClick={this.onPostAction.bind(this, 2)} className='default_text_normal_white_b green_background process_button_b'>Simpan</div>
          <div style={{width : 20}}/>
          <div onClick={this.onCloseDialog.bind(this, false)} className='default_text_normal_white_b red_background process_button_b'>Tutup</div>
        </div>
      );
    }
  }

  onPostAction(req_status) {
    this.setState({
      is_loading : true
    }, () => {
      let body;
      try {
        if (this.state.information.pcode_name.length === 0) {
          throw Error('Form nama produk masih kosong');
        }
        if (this.state.information.barcode.length === 0) {
          throw Error('Form barcode produk masih kosong, jika tidak diketahui bisa diisikan dengan asal.');
        }
        if (this.state.category.selected.key === null) {
          throw Error('Form kategori masih kosong');
        }
        if (this.props.body.pcode === undefined || this.props.body.is_admin) {
          body = this.getPostForm(req_status);
          // CHECK IMAGE
          if (!this.props.body.is_admin) {
            if (this.state.image === null) { throw Error('Anda belum mengupload gambar !!'); }
            if (!img_utils.accepted_file.includes(this.state.image.type)) {
              throw Error('Data gambar yang anda inputkan bukan berjenis jpeg/png, cobalah periksa kembali.');
            }
            this.postProduct(body);
          } else {
            this.postMasterProduct(body);
          }
        } else {
          if (this.state.information.pcode.length === 0) {
            throw Error('Form produk ID masih kosong');
          }
          if (this.state.measurement_a.selected.key === null) {
            throw Error('Form satuan ukur besar masih kosong');
          }
          if (this.state.measurement_b.selected.key === null) {
            throw Error('Form satuan ukur kecil masih kosong');
          }
          if (this.state.information.order_number.length === 0 || Number.isNaN(Number(this.state.information.order_number))) {
            throw Error('Nomor urut tidak boleh kosong dan harus berupa angka');
          }
          if (this.state.information.conv.length === 0 || Number.isNaN(Number(this.state.information.conv))) {
            throw Error('Nilai konversi tidak boleh kosong dan harus berupa angka');
          } else if (Number(this.state.information.conv) <= 0) {
            throw Error('Nilai konversi harus bernilai lebih dari 1');
          }
          if (this.state.calculator.selected.key === null) {
            throw Error('Form jenis perhitungan harga masih kosong');
          }
          // CHECK CONVERSION VALUE
          if (this.state.measurement_a.selected.key === this.state.measurement_b.selected.key && Number(this.state.information.conv) !== 1) {
            throw Error('Jika satuan besar dan kecil sama, maka nilai konversi haruslah 1');
          }
          let c_found, c_found_one = 0, temp_price = [];
          if (Object.keys(this.state.price).length > 0) {
            for (let i = 0; i < Object.keys(this.state.price).length; i++) {
              if (Number(this.state.price[i].quantity) === 1) { c_found_one++; } // Value 1 is exist
              c_found = 0;
              for (let j = 0; j < Object.keys(this.state.price).length; j++) {
                if (this.state.price[i].quantity === this.state.price[j].quantity) {
                  c_found++;
                }
              }
              temp_price[i] = this.state.price[i];
              temp_price[i].discount = this.getDePercetage(temp_price[i]);
              if (c_found > 1) {
                throw Error('Terdapat harga dengan kuantitas yang sama, cobalah untuk memeriksanya kembali.');
              }
            }
            if (c_found_one === 0) { throw Error('Tidak ada harga dengan kuantitas 1. Hal ini dapat menyebabkan kesalahan kalkulasi harga!'); }
          } else {
            throw Error('Belum ada harga pada produk, silahkan tambahkan terlebih dahulu minimal satu detail harga.');
          }
          body = this.getPostBody(temp_price);
          this.postUpdateProduct(body);
        }
      } catch (e) {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : e.message
          }
        });
      }
    });
  }

  postMasterProduct(body) {
    request.onRequestForm(1, '/post/master_product', body, res_data => {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => {
          this.onCloseDialog(true);
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    });
  }

  getPostBody(temp_price) {
    return {
      wholesaler_id : this.props.body.wholesaler_id,
      pcode : this.state.information.pcode,
      oddo_ref : this.state.information.oddo_ref,
      pcode_name : this.state.information.pcode_name,
      barcode : this.state.information.barcode,
      conv : this.state.information.conv,
      order_number : this.state.information.order_number,
      uom_a : this.state.measurement_a.selected.key,
      uom_b : this.state.measurement_b.selected.key,
      cat_a : this.state.category.selected.key,
      cat_b : this.state.principals.selected.key,
      subcat_c : this.state.subtag.selected.key,
      flag_new_item : this.state.information.flag_new_item,
      flag_promo : this.state.information.flag_promo,
      is_active : this.state.information.is_active,
      calculation_type : this.state.calculator.selected.key,
      img_path : this.state.information.img_path,
      price : JSON.stringify(temp_price)
    }
  }

  getPostForm(req_status) {
    let data_form = new FormData();
    data_form.append('wholesaler_id', this.props.body.wholesaler_id);
    data_form.append('pcode_name', this.state.information.pcode_name);
    data_form.append('pcode', this.state.information.pcode);
    data_form.append('oddo_ref', this.state.information.oddo_ref);
    if (this.state.information.pcode_suggested !== undefined) { data_form.append('pcode_suggested', this.state.information.pcode_suggested); }
    data_form.append('barcode', this.state.information.barcode);
    data_form.append('cat_a', this.state.category.selected.key);
    data_form.append('cat_b', this.state.principals.selected.key);
    data_form.append('subcat_c', this.state.subtag.selected.key);
    data_form.append('req_status', req_status);
    data_form.append('img_path', this.state.information.img_path);
    if (this.state.image !== null) {
      data_form.append('image_type', this.state.image.type.split('/')[1]);
      data_form.append('image', this.state.image);
    }
    return data_form;
  }

  postUpdateProduct(body) {
    request.onRequestData(1, '/post/root_product', body, res_data => {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => {
          this.onCloseDialog(true);
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    });
  }

  postProduct(body) {
    request.onRequestForm(1, '/post/product', body, function(res_data) {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => {
          this.onCloseDialog(true);
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    }.bind(this));
  }

  onActionSwitched(checked, action) {
    let temp_data = this.state.information;
    temp_data[action] = checked;
    this.setState({ information : temp_data });
  }

  onCloseDialog(is_updated) {
    if (!this.state.is_loading && !this.state.body_error.is_show) {
      this.props.onCloseListener(is_updated);
    }
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  render() {
    return(
      <div onClick={this.onCloseDialog.bind(this, false)} className='process_dialog_root layer_a'>
        <LoadingDialog showDialog={this.state.is_loading}/>
        {this.showErrorDialog(this.state.body_error)}
        {this.initMainView()}
      </div>
    );
  }

}

function onCloseUpdateProduct(context, listener, is_updated) {
  context.setState({
    show_product_dialog : false,
    body_product : null
  }, () => {
    listener(is_updated);
  });
}

export function showProductDialog(context, listener) {
  if (context.state.show_product_dialog) {
    return <ProductDialog body={context.state.body_product === null ? context.state.user_information : context.state.body_product} onCloseListener={(is_updated) => onCloseUpdateProduct(context, listener, is_updated)}/>;
  } else {
    return null;
  }
}
