import React, { Component } from 'react';

import img_left_chevron from '../resources/baseline_chevron_left_2x.png';
import img_right_chevron from '../resources/baseline_chevron_right_2x.png';

export default class Pagination extends Component {

  onPreviousClicked() {
    if (Object.keys(this.props.pagination_array).length > 1) {
      // THAT MEAN PAGINATION ARRAY HAVE MORE THAN 1 DATA
      let temp_pagination_array = [], index = 0;
      while (index < Object.keys(this.props.pagination_array).length - 1) {
        temp_pagination_array.push(this.props.pagination_array[index]);
        index++;
      }
      index--;
      this.props.onChangePage(index, temp_pagination_array);
    }
  }

  onNextClicked() {
    if (this.props.pagination.is_more) {
      this.props.onChangePage(9999, null);
    }
  }

  getClassPagination(is_left) {
    if (is_left) {
      if (Object.keys(this.props.pagination_array).length === 1) {
        return 'btn_previous_dead gray_background'
      } else {
        return 'btn_previous pink_background'
      }
    } else {
      if (this.props.pagination.is_more) {
        return 'btn_next pink_background';
      } else {
        return 'btn_next_dead gray_background';
      }
    }
  }

  render() {
    return (
      <div className='pagination_root'>
        <div onClick={this.onPreviousClicked.bind(this)} className={this.getClassPagination(true)}>
          <img className='mini_image_c' src={img_left_chevron} alt=''/>
        </div>
        <div style={{backgroundColor:'white', width:1, height:30}}/>
        <div onClick={this.onNextClicked.bind(this)} className={this.getClassPagination(false)}>
          <img className='mini_image_c' src={img_right_chevron} alt=''/>
        </div>
      </div>
    );
  }

}
