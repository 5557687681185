import React, { Component } from 'react';

import LoadingDialog from '../dialog/LoadingDialog';
import AdvSearchDialog from '../dialog/AdvSearchDialog';
import ErrorDialog from '../dialog/ErrorDialog';
import TableSearch from '../native/TableSearch';
import Pagination from '../native/Pagination';

import gif_img from '../resources/loading.gif';
import img_error from '../resources/error.png';
import img_setting from '../resources/settings.png';

var request = require('../../utilities/NetworkRequest');
var str_util = require('../../utilities/StringUtil');
var cache_util = require('../../utilities/CacheUtil');
var view_util = require('../../utilities/ViewUtil');

export default class Minimal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : false,
      is_loading : false,
      is_error : false,
      error_message : '',
      body_error : {
        is_show : false,
        message : ''
      },
      dialog_advance : {
        show : false,
        body : null
      },
      user_information : null,
      header_left : [],
      header_search : {},
      table_header : {},
      table_data : [],
      pagination : {},
      pagination_array : []
    };
  }

  componentDidMount() {
    this.refreshData(false, false);
  }

  refreshData(table_only, is_filter) {
    this.setState({
      is_main_loading : true
    }, () => {
      try {
        let decoded_input = this.state.user_information;
        if (decoded_input === null) {
          decoded_input = cache_util.onGetCacheData(cache_util.CACHE_INFO).result.user_information;
          decoded_input.length_data = 50;
        }
        decoded_input.idx = 0;
        if (!is_filter) {
          if (decoded_input.hasOwnProperty('filter')) {
            delete decoded_input.filter;
          }
        }
        request.onRequestData(1, '/show/retail', decoded_input, res_data => {
          let temp_pagination_array = [];
          if (res_data.code === 1) {
            temp_pagination_array.push(res_data.result.res_data.pagination);
            if (table_only) {
              this.setState({
                is_main_loading : false,
                is_error : false,
                table_data : res_data.result.res_data.arrays,
                pagination : res_data.result.res_data.pagination,
                pagination_array : temp_pagination_array
              });
            } else {
              this.setState({
                is_main_loading : false,
                is_error : false,
                dialog_advance : {
                  show : false,
                  body : res_data.result.filterV2
                },
                user_information : decoded_input,
                header_left : res_data.result.header.left,
                header_search : res_data.result.header.search,
                table_header : res_data.result.res_data.header,
                table_data : res_data.result.res_data.arrays,
                pagination : res_data.result.res_data.pagination,
                pagination_array : temp_pagination_array
              });
            }
          } else {
            this.setState({
              is_main_loading : false,
              is_error : true,
              error_message : res_data.message
            });
          }
        });
      } catch (e) {
        this.setState({
          is_main_loading : false,
          is_error : true,
          error_message : e.message
        });
      }
    })
  }

  onLeftHeaderActionClicked(position) {
    switch (this.state.header_left[position].key) {
      case 'refresh' :
        this.refreshData(true, false);
        break;
      default :
        break;
    }
  }

  initLeftHeader(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <div onClick={this.onLeftHeaderActionClicked.bind(this, i)} key={data[i].key} className='container_image'>
          <img className='mini_image_b' src={require(data[i].image + '')} alt=''/>
        </div>
      );
    }
    return view;
  }

  onCheckboxClicked(is_header, position) {
    let temp_table_header = this.state.table_header;
    let temp_table_data = this.state.table_data;
    if (is_header) {
      temp_table_header.is_selected = !temp_table_header.is_selected;
      for (let i = 0; i < Object.keys(temp_table_data).length; i++) {
        temp_table_data[i].is_selected = temp_table_header.is_selected;
      }
      this.setState({
        table_header : temp_table_header,
        table_data : temp_table_data
      });
    } else {
      temp_table_data[position].is_selected = !temp_table_data[position].is_selected;
      if (!temp_table_data[position].is_selected && temp_table_header.is_selected) {
        temp_table_header.is_selected = !temp_table_header.is_selected;
        this.setState({
          table_header : temp_table_header,
          table_data : temp_table_data
        });
      } else {
        this.setState({
          table_data : temp_table_data
        });
      }
    }
  }

  initDataTable(data) {
    let view = [], i, i_found = 0;
    for (i = 0; i < Object.keys(data).length; i++) {
      if (data[i].show_data) {
        i_found++;
        view.push(
          <tr key={i}>
            <td className='whole_padding'><input type="checkbox" checked={data[i].is_selected} onChange={this.onCheckboxClicked.bind(this,false,i)} name={i} value={data[i].order_no}/></td>
            {view_util.getAdminInformation(this, data[i])}
            <td className='whole_padding default_text_normal_gray'>{view_util.limitValue(20, data[i].retail_name)}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].retail_id}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].join_at}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].owner}</td>
            <td className='whole_padding default_text_normal_gray'>{str_util.formatSeparatorHandphone(data[i].phone)}</td>
          </tr>
        );
      }
    }
    if (i_found < 1) {
      view.push(view_util.getZeroRow(9));
    }
    return view;
  }

  initTableView(data) {
    return (
      <table className='table_container_retail'>
        <thead>
          <tr>
            <th className='whole_padding'><input type="checkbox" checked={data.is_selected} onChange={this.onCheckboxClicked.bind(this,true,0)} name="select_all" value="all"/></th>
            {view_util.getAdminHeader(this, data)}
            <th className='whole_padding default_text_normal_gray'>{data.retail_name}</th>
            <th className='whole_padding default_text_normal_gray'>{data.retail_id}</th>
            <th className='whole_padding default_text_normal_gray'>{data.join_at}</th>
            <th className='whole_padding default_text_normal_gray'>{data.owner}</th>
            <th className='whole_padding default_text_normal_gray'>{data.phone}</th>
            <th className='whole_padding default_text_normal_gray'></th>
          </tr>
        </thead>
        <tbody>
          {this.initDataTable(this.state.table_data)}
        </tbody>
      </table>
    );
  }

  requestRetail(body, temp_pagination_array) {
    this.setState({
      is_loading : true
    }, () => {
      request.onRequestData(1, '/show/retail', body, res_data => {
        if (res_data.code === 1) {
          if (temp_pagination_array == null) {
            temp_pagination_array = this.state.pagination_array;
            temp_pagination_array.push(res_data.result.res_data.pagination);
          }
          this.setState({
            is_loading : false,
            table_data : res_data.result.res_data.arrays,
            pagination : res_data.result.res_data.pagination,
            pagination_array : temp_pagination_array
          });
        } else {
          this.setState({
            is_loading : false,
            body_error : {
              is_show : true,
              message : res_data.message
            }
          });
        }
      });
    });
  }

  initSearchHeader() {
    return (
      <div className='search_root_container'>
        <TableSearch header={this.state.header_search} table={this.state.table_data} onTableUpdate={(data_table) => this.setState({ table_data : data_table })} />
        <div onClick={() => this.showDialogAdvance('open', this.state.dialog_advance.body)} className='container_image_b'>
          <img className='mini_image_b' src={img_setting} alt=''/>
        </div>
      </div>
    );
  }

  showDialogAdvance(action, body) {
    let temp = this.state.dialog_advance;
    temp.show = !temp.show;
    switch (action) {
      case 'next' :
        let temp_info = this.state.user_information;
        temp_info.filter = JSON.stringify(body);
        this.setState({
          user_information : temp_info,
          dialog_advance : temp
        }, () => { this.refreshData(true, true); });
        break;
      default :
        this.setState({ dialog_advance : temp });
        break;
    }
  }

  onPaginationClicked(index, data) {
    let temp_body = this.state.user_information;
    if (index === 9999 && data === null) {
      temp_body.idx = this.state.pagination.index;
    } else {
      temp_body.idx = this.state.pagination_array[index].index_before;
    }
    this.requestRetail(temp_body, data);
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className='loading_container'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return (
          <div className='delivery_root'>
            <LoadingDialog showDialog={this.state.is_loading} />
            { this.state.dialog_advance.show ? <AdvSearchDialog body={this.state.dialog_advance.body} onCloseListener={(action, body) => this.showDialogAdvance(action, body)}/> : null }
            { this.showErrorDialog(this.state.body_error) }
            <div className='header_container'>
              <div className='flex_left'>
                {this.initLeftHeader(this.state.header_left)}
              </div>
              <div className='flex_right'>
                { this.initSearchHeader() }
              </div>
            </div>
            {this.initTableView(this.state.table_header)}
            <Pagination pagination={this.state.pagination} pagination_array={this.state.pagination_array} onChangePage={(index, page_array) => this.onPaginationClicked(index, page_array)}/>
          </div>
        );
      } else {
        return (
          <div className='loading_container'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

}
