import React, { Component } from 'react';

import gif_img from './resources/loading.gif';
import img_error from './resources/error.png';

var str_util = require('../utilities/StringUtil');
var cache_util = require('../utilities/CacheUtil');
var request = require('../utilities/NetworkRequest');

export default class ProductsCatalog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_null_view : false,
      is_main_loading : true,
      is_loading : false,
      is_error : false,
      error_message : '',
      user_information : null,
      products : []
    };
  }

  componentDidMount() {
    this.requestProductsCatalog(false);
  }

  requestProductsCatalog(isLoad) {
    try {
      let cache_data = this.state.user_information;
      if (cache_data === null) {
        let temp_cache = cache_util.onGetCacheData(cache_util.CACHE_INFO);
        if (temp_cache !== null) {
          cache_data = temp_cache.result.user_information;
        } else {
          throw Error('Session expired');
        }
        cache_data.page = 0;
        cache_data.size = 100;
        cache_data.isMore = false;
      }
      request.onRequestData(1, '/product/catalog', cache_data, res_data => {
        if (res_data.code === 1) {
          cache_data.isMore = res_data.result.isMore;
          cache_data.page = res_data.result.page;
          let temp_products = null;
          if (isLoad) {
            let current_products = this.state.products;
            for (let i = 0; i < Object.keys(res_data.result.product).length; i++) {
              current_products.push(res_data.result.product[i]);
            }
            temp_products = current_products;
          } else {
            temp_products = res_data.result.product;
          }
          this.setState({
            is_main_loading : false,
            is_loading : false,
            user_information : cache_data,
            products : temp_products
          });
        } else {
          this.setState({
            is_main_loading : false,
            is_loading : false,
            is_error : true,
            error_message : res_data.message
          });
        }
      });
    } catch (e) {
      this.setState({ is_null_view : true });
    }
  }

  getProductPrices(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <tr key={i}>
          <td className='default_text_normal_gray'>Qty</td>
          <td className='side_padding default_text_normal_gray bold_text'>{data[i].quantity}</td>
          <td className='normal_padding_right_b default_text_normal_gray'>x</td>
          <td className='default_text_normal_gray bold_text'>{str_util.formatSeparator(data[i].price)}</td>
          <td className='side_padding default_text_normal_gray bold_text'>~</td>
          <td className='default_text_normal_gray bold_text'>{str_util.formatSeparator(this.calculatePrice(data[i]))}</td>
        </tr>
      );
    }
    return view;
  }

  calculatePrice(data) {
    let result = Number(data.quantity) * Number(data.price);
    return result + '';
  }

  getProductsView(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <div key={i} className='box_product'>
          <div className='box_product_info'>
            <div className='default_text_normal_gray'>Kode Produk : <b><u>{data[i].product_id}</u></b></div>
            <div className='normal_margin_top default_text_normal_gray'>Nama Produk :</div>
            <div className='default_text_normal_gray bold_text'>{data[i].name}</div>
            <div className='normal_margin_top default_text_normal_gray'>Kategori :</div>
            <div className='default_text_normal_gray bold_text'>{data[i].category_name}</div>
            <div className='normal_margin_top default_text_normal_gray'>Satuan Ukur Konversi :</div>
            <table className='box_product_table'>
              <tbody>
                {this.getProductMeasurements(data[i].unit_level)}
              </tbody>
            </table>
            <div className='normal_margin_top default_text_normal_gray'>Harga Produk Per Kuantitas :</div>
            <table className='box_product_table'>
              <tbody>
                {this.getProductPrices(data[i].price_level)}
              </tbody>
            </table>
          </div>
          <div className='box_product_image'>
            <img className='big_image_c' src={data[i].img_big} alt=''/>
          </div>
        </div>
      );
    }
    view.push( <div key='clear_products' style={{clear:'both'}}></div> );
    return view;
  }

  getProductMeasurements(data) {
    let view = [];
    switch (Object.keys(data).length) {
      case 1:
        view.push(
          <tr key='measurement_a'>
            <td className='default_text_normal_gray'>Satuan Kecil</td>
            <td className='side_padding default_text_normal_gray'>:</td>
            <td className='default_text_normal_gray bold_text'>{data[0].text}</td>
          </tr>
        )
        break;
      case 2:
        view.push(
          <tr key='measurement_a'>
            <td className='default_text_normal_gray'>Satuan Kecil</td>
            <td className='side_padding default_text_normal_gray'>:</td>
            <td className='default_text_normal_gray bold_text'>{data[0].text}</td>
          </tr>
        )
        view.push(
          <tr key='measurement_b'>
            <td className='default_text_normal_gray'>Satuan Besar</td>
            <td className='side_padding default_text_normal_gray'>:</td>
            <td className='default_text_normal_gray bold_text'>{data[1].text}</td>
          </tr>
        )
        break;
      default:
        // DO NOTHING
        break;
    }
    return view;
  }

  onShowMoreClicked() {
    this.setState({
      is_loading : true
    }, () => {
      this.requestProductsCatalog(true);
    });
  }

  showMore(data) {
    if (this.state.is_loading) {
      return <div className='loading_container_c'><img className='big_image' src={gif_img} alt='' /></div>
    } else {
      if (data !== null) {
        if (data.isMore) {
          return <div onClick={this.onShowMoreClicked.bind(this)} className='bold_text whole_padding catalog_header_container'>TAMPILKAN LEBIH BANYAK</div>;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  }

  render() {
    if (this.state.is_null_view) {
      return null;
    } else {
      if (this.state.is_main_loading) {
        return (
          <div className='loading_container_b'><img className='big_image' src={gif_img} alt='' /></div>
        );
      } else {
        if (!this.state.is_error) {
          return(
            <div className='product_catalog_root'>
              <div className='catalog_products'>{this.getProductsView(this.state.products)}</div>
              {this.showMore(this.state.user_information)}
            </div>
          );
        } else {
          return (
            <div className='loading_container_b'>
              <img className='big_image_b' src={img_error} alt='' />
              <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
            </div>
          );
        }
      }
    }
  }

}
