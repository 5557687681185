import React, { Component } from 'react';

import { showFormInput } from '../native/V2_Input';
import { showError, showErrorPage } from '../native/V2_Error';
import { showLoadingPage } from '../native/V2_Loading';

var cache_util = require('../../utilities/CacheUtil');
var request = require('../../utilities/NetworkRequest');

var ic_search = require('../resources_v2/magnifying_a.png');

export default class V2_Wholesaler extends Component {

  constructor(props) {
    super(props);
    this.params = new URLSearchParams(this.props.location.search);
    this.state = { main_data : null };
  }

  componentDidMount() { this.onRefresh(); }

  onRefresh() {
    try {
      let body = {};
      if (this.params.get('session') !== null) {
        body.session = this.params.get('session');
      } else {
        let temp_session = cache_util.onGetCacheString(cache_util.CACHE_SESSION);
        if (temp_session !== null) {
          body.session = temp_session;
        } else { throw Error('Tidak ada session yang ditemukan! Silahkan login kembali di halaman www.tokopintar.co.id'); }
      }
      request.onRequestData(request.OPTION_API_MAIN, '/show/wholesaler', body, res_data => {
        if (res_data.code === 1) {
          console.log(res_data);
          this.setState({
            loading_page : false,
            main_data : res_data.result
          });
        } else {
          this.setState({
            loading_page : false,
            error_page : showError(res_data)
          });
        }
      });
    } catch (e) {
      this.setState({ loading_page : false, error_page : showError({ message : e.message, result : null }) });
    }
  }

  getWholesalerItem() {
    let view = [], m_wholesaler = this.state.main_data.wholesaler;
    for (let i = 0; i < Object.keys(m_wholesaler).length; i++) {
      view.push(
        <div id='wholesaler_item' key={i} className='v2_cursor_pointer'>
          <img alt='' style={{width : 40, height : 40, top : 25, left : 10, position : 'absolute', borderRadius : 5}} src={m_wholesaler[i].profile_img_url}/>
          <div style={{height : 50, background : '#ff8080', borderTopLeftRadius : 5, borderTopRightRadius : 5}}/>
          <div style={{lineHeight : '1em', flex : 1, display : 'flex', flexDirection : 'column', justifyContent : 'center', padding : 10}}>
            <div className='v2_text_a'>{m_wholesaler[i].wholesaler_name}</div>
            <div style={{height : 30, overflow : 'hidden'}} className='v2_text_a'>{m_wholesaler[i].address}</div>
          </div>
        </div>
      );
    }
    return view;
  }

  showRootView() {
    return (
      <div className='v2_app'>
        <div style={m_style.root_header}>
          { showFormInput('header_input', true, '',ic_search, 'Pencarian ...', 'text', 255, {borderRadius : 5, borderBottom : 0, height : 40, background : 'white', flex : 1}, (text) => console.log(text)) }
          <div className='v2_text_f' style={{textOverflow: 'ellipsis', paddingLeft : 10, maxWidth : 'calc(200px - 10px)', overflow : 'hidden', whiteSpace : 'nowrap'}}>PUTU GITA ANDIKA</div>
        </div>
        <div style={m_style.root}>
          { this.getWholesalerItem() }
        </div>
      </div>
    );
  }

  showMainView() {
    return showErrorPage(this, this.showRootView(), () => this.onRefresh());
  }

  render() { return showLoadingPage(this); }

}

const m_style = {
  root_header : {
    width : 'calc(100% - 20px)',
    height : 50,
    display : 'flex',
    flexDirection : 'row',
    alignItems : 'center',
    background : 'red',
    padding : '0 10px 0 10px'
  },
  root : {
    width : '100%',
    height : '100%',
    overflow : 'auto',
    display : 'block'
  }
}
