import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import './App.css';
import './V2_App.css';
import Login from './component/Login';
import Home from './component/Home';
import PrintOrder from './component/PrintOrder';
// import PrintMiniOrder from './component/PrintMiniOrder';
import PrintMiniOrderV2 from './component/PrintMiniOrderV2';
import PrintMiniOrderOld from './component/PrintMiniOrderOld';
import PrintDelivery from './component/PrintDelivery';
import ProductsCatalog from './component/ProductsCatalog';
import Wholesaler from './component/Wholesaler';
import PageNotFound from './component/PageNotFound';
import PrintProduct from './component/PrintProduct';
import CatalogEditor from './component/CatalogEditor';

import V2_Wholesaler from './component/page/V2_Wholesaler';

export default class App extends Component {

  render() {
    return (
      <Router>
        <div>
          <Route path="/v2_wholesaler/" component={V2_Wholesaler} />

          <Route exact path="/login/" component={Login} />
          <Route exact path="/catalog_editor/" component={CatalogEditor} />
          <Route exact path="/login/:random" component={PageNotFound} />
          <Route exact path="/gphome/:page_id" component={Home} />
          <Route exact path="/gphome/" component={Home} />
          <Route exact path="/print_mini/:data" component={PrintMiniOrderV2}/>
          <Route exact path="/print_mini/" component={PageNotFound}/>

          <Route exact path="/print_mini_old/:data" component={PrintMiniOrderOld}/>
          <Route exact path="/print_mini_old/" component={PageNotFound}/>
          
          <Route exact path="/print/:data" component={PrintOrder}/>
          <Route exact path="/print/" component={PageNotFound}/>
          <Route exact path="/print_delivery/:data" component={PrintDelivery}/>
          <Route exact path="/print_delivery/" component={PageNotFound}/>
          <Route exact path="/print_product/:data" component={PrintProduct}/>
          <Route exact path="/print_product/" component={PageNotFound}/>
          <Route exact path="/products_catalog/" component={ProductsCatalog}/>
          <Route exact path="/products_catalog/:random" render={() => <PageNotFound is_null={true} />}/>
          <Route path="/:session" component={Wholesaler}/>
          <Route exact path="/" component={Wholesaler}/>
        </div>
      </Router>
    );
  }
}
