var CACHE_INFO = 'tp_wholesaler_cache';
var CACHE_SESSION = 'tp_session_cache';
var CACHE_PRINT_SETTING = 'tp_print_cache';
var CACHE_CATALOG_EDITOR = 'tp_catalog_cache';

function onGetCacheData(cache_key) {
  var cache_data = localStorage.getItem(cache_key);
  if (cache_data === null) {
    return null;
  } else {
    // WHEN CACHE IS EXIST THEN RETURN JSON DATA
    return JSON.parse(cache_data);
  }
}

function onGetCacheString(cache_key) {
  var cache_data = localStorage.getItem(cache_key);
  if (cache_data === null) {
    return null;
  } else {
    // WHEN CACHE IS EXIST THEN RETURN STRING DATA
    return cache_data;
  }
}

function onRemoveCache(cache_key) {
  localStorage.removeItem(cache_key);
}

function onSaveCache(cache_key, data) {
  if (data === null) {
    return false;
  } else {
    localStorage.setItem(cache_key, data);
    return true;
  }
}

module.exports = {
  CACHE_INFO : CACHE_INFO,
  CACHE_SESSION : CACHE_SESSION,
  CACHE_PRINT_SETTING : CACHE_PRINT_SETTING,
  CACHE_CATALOG_EDITOR : CACHE_CATALOG_EDITOR,
  onGetCacheData : onGetCacheData,
  onGetCacheString : onGetCacheString,
  onSaveCache : onSaveCache,
  onRemoveCache : onRemoveCache
}
