import React, { Component } from 'react';
import Switch from "react-switch";

import AddRetailDialog from './dialog/AddRetailDialog';
import CustomerMigrationDialog from './dialog/CustomerMigrationDialog';
import { showMirrorRetailDialog } from './dialog/MirrorRetailDialog';
import AddressDeliveryDialog from './dialog/AddressDeliveryDialog';
import ConfirmationDialog from './dialog/ConfirmationDialog';
import LoadingDialog from './dialog/LoadingDialog';
import NotificationDialog from './dialog/NotificationDialog';
import AdvSearchDialog from './dialog/AdvSearchDialog';
import ErrorDialog from './dialog/ErrorDialog';
import TableSearch from './native/TableSearch';
import Pagination from './native/Pagination';

import gif_img from './resources/loading.gif';
import img_error from './resources/error.png';
import img_setting from './resources/settings.png';
import menu_edit from './resources/menu_edit.png';

var request = require('../utilities/NetworkRequest');
var str_util = require('../utilities/StringUtil');
var cache_util = require('../utilities/CacheUtil');
var view_util = require('../utilities/ViewUtil');

export default class Delivery extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : false,
      is_loading : false,
      is_error : false,
      error_message : '',
      body_error : {
        is_show : false,
        message : ''
      },
      show_msg_dialog : {
        show : false,
        body : null
      },
      show_add_dialog : {
        show : false
      },
      show_delivery_dialog : {
        show : false,
        body : null
      },
      dialog_advance : {
        show : false,
        body : null
      },
      show_migration_dialog : { show : false },
      user_information : null,
      show_confirm_confirmation : {
        show : false,
        question : '',
        body : null,
        yes_answer : 'Hapus',
        no_answer : 'Batal'
      },
      header_left : [],
      header_search : {},
      table_header : {},
      table_data : [],
      pagination : {},
      pagination_array : []
    };
  }

  componentDidMount() {
    this.refreshData(false, false);
  }

  refreshData(table_only, is_filter) {
    this.setState({
      is_main_loading : true
    }, () => {
      try {
        let decoded_input = this.state.user_information;
        if (decoded_input === null) {
          decoded_input = cache_util.onGetCacheData(cache_util.CACHE_INFO).result.user_information;
          decoded_input.length_data = 50;
        }
        decoded_input.idx = 0;
        if (!is_filter) {
          if (decoded_input.hasOwnProperty('filter')) {
            delete decoded_input.filter;
          }
        }
        request.onRequestData(request.OPTION_API_MAIN, '/show/retail', decoded_input, res_data => {
          let temp_pagination_array = [];
          if (res_data.code === 1) {
            temp_pagination_array.push(res_data.result.res_data.pagination);
            if (table_only) {
              this.setState({
                is_main_loading : false,
                is_error : false,
                table_data : res_data.result.res_data.arrays,
                pagination : res_data.result.res_data.pagination,
                pagination_array : temp_pagination_array
              });
            } else {
              this.setState({
                is_main_loading : false,
                is_error : false,
                dialog_advance : {
                  show : false,
                  body : res_data.result.filterV2
                },
                user_information : decoded_input,
                header_left : res_data.result.header.left,
                header_search : res_data.result.header.search,
                table_header : res_data.result.res_data.header,
                table_data : res_data.result.res_data.arrays,
                pagination : res_data.result.res_data.pagination,
                pagination_array : temp_pagination_array
              });
            }
          } else {
            this.setState({
              is_main_loading : false,
              is_error : true,
              error_message : res_data.message
            });
          }
        });
      } catch (e) {
        this.setState({
          is_main_loading : false,
          is_error : true,
          error_message : e.message
        });
      }
    })
  }

  onLeftHeaderActionClicked(position) {
    switch (this.state.header_left[position].key) {
      case 'delete':
        let temp_table_data = this.state.table_data, temp_selected = [];
        for (let i = 0; i < Object.keys(temp_table_data).length; i++) {
          if (temp_table_data[i].is_selected) {
            temp_selected.push({
              retail_id : temp_table_data[i].retail_id,
              wholesaler_id : temp_table_data[i].wholesaler_id
            });
          }
        }
        if (Object.keys(temp_selected).length > 0) {
          let temp_confirmation = this.state.show_confirm_confirmation;
          temp_confirmation.show = !temp_confirmation.show;
          temp_confirmation.question = 'Apakah anda yakin ingin menghapus ' + Object.keys(temp_selected).length + ' ritel ini dari daftar pelangganan anda?';
          temp_confirmation.body = temp_selected;
          this.setState({ show_confirm_confirmation : temp_confirmation });
        } else {
          alert('Pilih terlebih dahulu retail yang ingin dihapus.');
        }
        break;
      case 'notification':
        let temp_table_data_b = this.state.table_data, temp_selected_b = [];
        for (let i = 0; i < Object.keys(temp_table_data_b).length; i++) {
          if (temp_table_data_b[i].is_selected) {
            temp_selected_b.push(temp_table_data_b[i].retail_id);
          }
        }
        let temp_msg_dialog = this.state.show_msg_dialog;
        temp_msg_dialog.show = !temp_msg_dialog.show;
        temp_msg_dialog.body = {
          wholesaler_id : this.state.user_information.wholesaler_id,
          list_retail : temp_selected_b
        };
        this.setState({ show_msg_dialog : temp_msg_dialog });
        break;
      case 'refresh' :
        this.refreshData(true, false);
        break;
      case 'add':
        let temp_add_dialog = this.state.show_add_dialog;
        temp_add_dialog.show = !temp_add_dialog.show;
        this.setState({ show_add_dialog : temp_add_dialog });
        break;
      case 'mirror_cb':
        this.setState({ show_mirror_dialog : true });
        break;
      case 'migration_cb':
        this.setState({ show_migration_dialog : {show : true} });
        break;
      default :
        break;
    }
  }

  initLeftHeader(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <div onClick={this.onLeftHeaderActionClicked.bind(this, i)} key={data[i].key} className='container_image'>
          <img className='mini_image_b' src={require(data[i].image + '')} alt=''/>
        </div>
      );
    }
    return view;
  }

  onCheckboxClicked(is_header, position) {
    let temp_table_header = this.state.table_header;
    let temp_table_data = this.state.table_data;
    if (is_header) {
      temp_table_header.is_selected = !temp_table_header.is_selected;
      for (let i = 0; i < Object.keys(temp_table_data).length; i++) {
        temp_table_data[i].is_selected = temp_table_header.is_selected;
      }
      this.setState({
        table_header : temp_table_header,
        table_data : temp_table_data
      });
    } else {
      temp_table_data[position].is_selected = !temp_table_data[position].is_selected;
      if (!temp_table_data[position].is_selected && temp_table_header.is_selected) {
        temp_table_header.is_selected = !temp_table_header.is_selected;
        this.setState({
          table_header : temp_table_header,
          table_data : temp_table_data
        });
      } else {
        this.setState({
          table_data : temp_table_data
        });
      }
    }
  }

  requestBlocked(position, checked) {
    let temp_data = this.state.table_data;
    temp_data[position].is_blocked = checked;
    this.setState({ table_data : temp_data, is_loading : true }, () => {
      let body = {
        is_blocked : temp_data[position].is_blocked,
        retail_id : temp_data[position].retail_id,
        wholesaler_id : temp_data[position].wholesaler_id
      }
      request.onRequestData(request.OPTION_API_MAIN, '/update/retail_blocked', body, res_data => {
        if (res_data.code === 0) {
          // HANDLE WHEN ERROR ONLY HERE
          temp_data[position].is_blocked = !temp_data[position].is_blocked;
        }
        this.setState({
          is_loading : false,
          table_data : temp_data
        });
      });
    });
  }

  initDataTable(data) {
    let view = [], i, i_found = 0;
    for (i = 0; i < Object.keys(data).length; i++) {
      if (data[i].show_data) {
        i_found++;
        let position = i;
        view.push(
          <tr key={i}>
            <td className='whole_padding'><input type="checkbox" checked={data[i].is_selected} onChange={this.onCheckboxClicked.bind(this,false,i)} name={i} value={data[i].order_no}/></td>
            <td className='whole_padding'>
              <div style={{display:'flex', justifyContent:'center'}}>
                <Switch
                  onChange={(checked) => this.requestBlocked(position, checked)}
                  checked={data[i].is_blocked}
                  className="react-switch"
                  height={20}
                  width={40}
                  onColor='#ee3c32'
                />
              </div>
            </td>
            {view_util.getAdminInformation(this, data[i])}
            <td className='whole_padding default_text_normal_gray'>{view_util.limitValue(20, data[i].retail_name)}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].retail_id}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].join_at}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].owner}</td>
            <td className='whole_padding default_text_normal_gray'>{str_util.formatSeparatorHandphone(data[i].phone)}</td>
            <td className='whole_padding default_text_normal_gray'>{view_util.limitValue(80, data[i].address)}<img onClick={this.onShowDeliveryDialog.bind(this, data[i])} style={{cursor:'pointer'}} title='Edit Alamat' className='normal_margin_left mini_image_e' src={menu_edit} alt=''/></td>
            <td className='whole_padding'>{this.getActiveStatus(data[i])}</td>
          </tr>
        );
      }
    }
    if (i_found < 1) {
      view.push(view_util.getZeroRow(9));
    }
    return view;
  }

  onShowDeliveryDialog(data) {
    let temp_delivery_dialog = this.state.show_delivery_dialog;
    temp_delivery_dialog.show = !temp_delivery_dialog.show;
    temp_delivery_dialog.body = JSON.stringify(data);
    this.setState({ show_delivery_dialog : temp_delivery_dialog });
  }

  getActiveStatus(data) {
    if (data.time_diff >= 30) {
      return <div style={{display:'flex', justifyContent:'center'}}><div className='circle_background_e red_background'/></div>;
    } else if (30 > data.time_diff && data.time_diff >= 15) {
      return <div style={{display:'flex', justifyContent:'center'}}><div className='circle_background_e yellow_background'/></div>;
    } else if (15 > data.time_diff) {
      return <div style={{display:'flex', justifyContent:'center'}}><div className='circle_background_e green_background_2'/></div>;
    }
  }

  initTableView(data) {
    return (
      <table className='table_container_retail'>
        <thead>
          <tr>
            <th className='whole_padding'><input type="checkbox" checked={data.is_selected} onChange={this.onCheckboxClicked.bind(this,true,0)} name="select_all" value="all"/></th>
            <th className='whole_padding default_text_normal_gray'>{data.is_blocked}</th>
            {view_util.getAdminHeader(this, data)}
            <th className='whole_padding default_text_normal_gray'>{data.retail_name}</th>
            <th className='whole_padding default_text_normal_gray'>{data.retail_id}</th>
            <th className='whole_padding default_text_normal_gray'>{data.join_at}</th>
            <th className='whole_padding default_text_normal_gray'>{data.owner}</th>
            <th className='whole_padding default_text_normal_gray'>{data.phone}</th>
            <th className='main_column whole_padding default_text_normal_gray'>{data.address}</th>
            <th className='whole_padding default_text_normal_gray'></th>
          </tr>
        </thead>
        <tbody>
          {this.initDataTable(this.state.table_data)}
        </tbody>
      </table>
    );
  }

  requestRetail(body, temp_pagination_array) {
    this.setState({
      is_loading : true
    }, () => {
      request.onRequestData(request.OPTION_API_MAIN, '/show/retail', body, res_data => {
        if (res_data.code === 1) {
          if (temp_pagination_array == null) {
            temp_pagination_array = this.state.pagination_array;
            temp_pagination_array.push(res_data.result.res_data.pagination);
          }
          this.setState({
            is_loading : false,
            table_data : res_data.result.res_data.arrays,
            pagination : res_data.result.res_data.pagination,
            pagination_array : temp_pagination_array
          });
        } else {
          this.setState({
            is_loading : false,
            body_error : {
              is_show : true,
              message : res_data.message
            }
          });
        }
      });
    });
  }

  onCloseMigration(action, data) {
    let temp_migration_dialog = this.state.show_migration_dialog;
    temp_migration_dialog.show = !temp_migration_dialog.show;
    if (action === 'next') {
      this.setState({
        is_loading : true,
        show_migration_dialog : temp_migration_dialog
      }, () => { this.postCustomerMigration(data); });
    } else {
      this.setState({ show_migration_dialog : temp_migration_dialog });
    }
  }

  postCustomerMigration(data) {
    data.wholesaler_id = data.old_wholesaler;
    data.privilege_id = 3;
    data.user_id = this.state.user_information.user_id;
    request.onRequestData(request.OPTION_API_MAIN, '/post/migration_customer', data, function(res_data) {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => { this.refreshData(true, true); });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    }.bind(this));
  }

  onCloseProcess(action, data) {
    let temp_add_dialog = this.state.show_add_dialog;
    temp_add_dialog.show = !temp_add_dialog.show;
    if (action === 'next') {
      this.setState({
        is_loading : true,
        show_add_dialog : temp_add_dialog
      }, () => {
        this.postAddRetail(data);
      });
    } else {
      this.setState({ show_add_dialog : temp_add_dialog });
    }
  }

  postAddRetail(body) {
    body.privilege_id = this.state.user_information.privilege_id;
    body.phone = str_util.parseHandphone(body.phone);
    body.is_admin = (this.state.user_information.wholesaler_id === 'TP9999' ? true : false);
    request.onRequestData(request.OPTION_API_MAIN, '/add/retail', body, res_data => {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => {
          this.refreshData(true, true);
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    });
  }

  onCloseDeleteConfirmation(action) {
    let temp_confirmation = this.state.show_confirm_confirmation;
    temp_confirmation.show = !temp_confirmation.show;
    if (action === 'next') {
      this.setState({
        is_loading : true,
        show_confirm_confirmation : temp_confirmation
      }, () => {
        this.postDeleteRetail();
      });
    } else {
      this.setState({ show_confirm_confirmation : temp_confirmation });
    }
  }

  postDeleteRetail() {
    let body = { list_retail : JSON.stringify(this.state.show_confirm_confirmation.body) }
    request.onRequestData(request.OPTION_API_MAIN, '/delete/retail', body, res_data => {
      let temp_confirmation = this.state.show_confirm_confirmation;
      temp_confirmation.question = '';
      temp_confirmation.body = null;
      if (res_data.code === 1) {
        this.setState({
          is_loading : false,
          show_confirm_confirmation : temp_confirmation
        }, () => {
          this.refreshData(true, true);
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          },
          show_confirm_confirmation : temp_confirmation
        });
      }
    });
  }

  onCloseDelivery(action, data) {
    let temp_delivery_dialog = this.state.show_delivery_dialog;
    temp_delivery_dialog.show = !temp_delivery_dialog.show;
    temp_delivery_dialog.body = null;
    if (action === 'next') {
      this.setState({
        is_loading : true,
        show_delivery_dialog : temp_delivery_dialog
      }, () => {
        this.updateDeliveryAddress(data);
      });
    } else {
      this.setState({
        show_delivery_dialog : temp_delivery_dialog
      });
    }
  }

  updateDeliveryAddress(data) {
    request.onRequestData(request.OPTION_API_MAIN, '/update/retail', data, res_data => {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => {
          this.refreshData(true, true);
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    });
  }

  onShowNotification(action, data) {
    let temp_dialog = this.state.show_msg_dialog;
    temp_dialog.show = !temp_dialog.show;
    if (action === 'close') {
      temp_dialog.body = null;
    }
    this.setState({
      is_loading : action === 'filter' || action === 'all' ? true : false,
      show_msg_dialog : temp_dialog
    }, () => {
      if (action === 'filter') {
        data.is_all = '0';
        data.list_retail = JSON.stringify(temp_dialog.body.list_retail);
        this.postNotification(data);
      } else if (action === 'all') {
        data.is_all = '1';
        this.postNotification(data);
      }
    });
  }

  postNotification(body) {
    let temp_dialog = this.state.show_msg_dialog;
    temp_dialog.body = null;
    this.setState({
      show_msg_dialog : temp_dialog
    }, () => {
      request.onRequestData(request.OPTION_API_MAIN, '/message/retail', body, res_data => {
        if (res_data.code === 1) {
          this.setState({
            is_loading : false
          });
        } else {
          this.setState({
            is_loading : false,
            body_error : {
              is_show : true,
              message : res_data.message
            }
          });
        }
      });
    });
  }

  initSearchHeader() {
    return (
      <div className='search_root_container'>
        {/* <TableSearch header={this.state.header_search} table={this.state.table_data} onTableUpdate={(data_table) => this.setState({ table_data : data_table })} /> */}
        <div onClick={() => this.showDialogAdvance('open', this.state.dialog_advance.body)} className='container_image_b'>
          <img className='mini_image_b' src={img_setting} alt=''/>
        </div>
      </div>
    );
  }

  showDialogAdvance(action, body) {
    let temp = this.state.dialog_advance;
    temp.show = !temp.show;
    switch (action) {
      case 'next' :
        let temp_info = this.state.user_information;
        temp_info.filter = JSON.stringify(body);
        this.setState({
          user_information : temp_info,
          dialog_advance : temp
        }, () => { this.refreshData(true, true); });
        break;
      default :
        this.setState({ dialog_advance : temp });
        break;
    }
  }

  onPaginationClicked(index, data) {
    let temp_body = this.state.user_information;
    if (index === 9999 && data === null) {
      temp_body.idx = this.state.pagination.index;
    } else {
      temp_body.idx = this.state.pagination_array[index].index_before;
    }
    this.requestRetail(temp_body, data);
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className='loading_container'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return (
          <div className='delivery_root'>
            { this.state.dialog_advance.show ? <AdvSearchDialog body={this.state.dialog_advance.body} onCloseListener={(action, body) => this.showDialogAdvance(action, body)}/> : null }
            { this.state.show_msg_dialog.show ? <NotificationDialog body={this.state.show_msg_dialog.body} onCloseListener={(action, data) => this.onShowNotification(action, data)} /> : null }
            { this.state.show_add_dialog.show ? <AddRetailDialog onCloseListener={(action, data) => this.onCloseProcess(action, data)} /> : null }
            { this.state.show_delivery_dialog.show ? <AddressDeliveryDialog data={this.state.show_delivery_dialog.body} user_information={this.state.user_information} onCloseListener={(action, data) => this.onCloseDelivery(action, data)} /> : null }
            <ConfirmationDialog data={this.state.show_confirm_confirmation} onCloseDialog={(action) => this.onCloseDeleteConfirmation(action)} />
            <div className='header_container'>
              <div className='flex_left'>
                {this.initLeftHeader(this.state.header_left)}
              </div>
              <div className='flex_right'>
                { this.initSearchHeader() }
              </div>
            </div>
            {this.initTableView(this.state.table_header)}
            <Pagination pagination={this.state.pagination} pagination_array={this.state.pagination_array} onChangePage={(index, page_array) => this.onPaginationClicked(index, page_array)}/>
            { this.state.show_migration_dialog.show ? <CustomerMigrationDialog is_admin={true} onCloseListener={(action, data) => this.onCloseMigration(action, data)} /> : null }
            { showMirrorRetailDialog(this) }
            { this.showErrorDialog(this.state.body_error) }
            <LoadingDialog showDialog={this.state.is_loading} />
          </div>
        );
      } else {
        return (
          <div className='loading_container'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

}
