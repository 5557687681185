import React, { Component } from 'react';

import LoadingDialog from './LoadingDialog';
import { showProductDialog } from './ProductDialog';
import { showErrorDialog } from './ErrorDialog';

import img_edit from '../resources/baseline_edit.png';
import img_back from '../resources/back_a.png';

var request = require('../../utilities/NetworkRequest');
var view_util = require('../../utilities/ViewUtil');

class VoucherTPDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : true,
      is_loading : false,
      is_error : false,
      error_message : '',
      body_error : {
        is_show : false,
        message : ''
      },
      show_product_dialog : false,
      body_product : null,
      body : null
    }
  }

  componentDidMount() { this.refreshData(); }

  refreshData() {
    request.onRequestData(1, '/show/promo_detail', this.props.data, res_data => {
      if (res_data.code === 1) {
        this.setState({
          is_main_loading : false,
          is_error : false,
          body : res_data.result
        });
      } else {
        this.setState({
          is_main_loading : false,
          is_error : true,
          error_message : res_data.message
        });
      }
    });
  }

  initTableHeader() {
    return (
      <tr>
        <th className='main_column whole_padding default_text_normal_gray'>Nama Produk</th>
        <th className='whole_padding default_text_normal_gray'>Satuan Promo Berlaku</th>
        <th className='whole_padding default_text_normal_gray'>Satuan Produk Grosir</th>
        <th className='whole_padding default_text_normal_gray'>Status Produk</th>
        <th className='whole_padding default_text_normal_gray'>Action</th>
      </tr>
    );
  }

  onAdjust(data) {
    if (data.pcode !== 'ALL_PRODUCT') {
      data.is_admin = false;
      data.is_root = true;
      this.setState({
        show_product_dialog : true,
        body_product : data
      });
    }
  }

  initTableBody(pos, data) {
    let button_style = ' default_button green_background';
    if (data.pcode === 'ALL_PRODUCT') {
      button_style = ' default_button_inactive gray_background';
    }
    return (
      <tr key={pos}>
        <td className='whole_padding default_text_normal_gray'>{data.name}</td>
        <td className='whole_padding default_text_normal_gray'>{data.valid_uom}</td>
        <td className='whole_padding default_text_normal_gray'>{data.current_uom}</td>
        <td className='whole_padding default_text_normal_gray'>{data.status_product}</td>
        <td>
          <div onClick={this.onAdjust.bind(this, data)} className={'label_search_container' + button_style}>
            <img className='mini_image_d' src={img_edit} alt=''/>
            <div className='normal_padding_left_c default_text_normal_white'>Sesuaikan Produk</div>
          </div>
        </td>
      </tr>
    );
  }

  initMainView() {
    if (this.state.body !== null) {
      return (
        <div style={{position : 'relative'}} onClick={(e) => e.stopPropagation()} className='product_dialog_container'>
          <div onClick={this.onCloseDialog.bind(this, false)} className='custom_cursor_a display_flex_center' style={{height : 40, width : 40, right : 0, top : 0, position : 'absolute'}}>
            <img alt='' src={img_back} style={{width : 16, height : 16}}/>
          </div>
          <div className='wrapper_form_product normal_padding_bottom default_text_medium_gray bold_text normal_border_bottom'>EDITOR VOUCHER TOKOPINTAR</div>
          <div style={{paddingLeft : 20, paddingRight : 20, paddingTop : 20, flexDirection : 'column', display : 'flex'}}>
            <div style={{flexDirection : 'row', display : 'flex'}}>
              <div style={{flex : 1, flexDirection : 'column', display : 'flex'}}>
                <div className='default_text_normal_gray bold_text'>Nama Voucher</div>
                <input readOnly={true} onChange={ (event) => view_util.onEditTextChanged(this, event, 'description') } value={this.state.body.description} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input_inactive_b' type='text' />
              </div>
              <div style={{width : 20}}/>
              <div style={{flex : 1, flexDirection : 'column', display : 'flex'}}>
                <div className='default_text_normal_gray bold_text'>Tipe Pemakaian</div>
                <input readOnly={true} onChange={ (event) => view_util.onEditTextChanged(this, event, 'type_desc') } value={this.state.body.type_desc} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input_inactive_b' type='text' />
              </div>
            </div>

            <div style={{flexDirection : 'row', display : 'flex', paddingTop : 10}}>
              <div style={{flex : 1, flexDirection : 'column', display : 'flex'}}>
                <div className='default_text_normal_gray bold_text'>Tanggal Berlaku</div>
                <input readOnly={true} onChange={ (event) => view_util.onEditTextChanged(this, event, 'time_range') } value={this.state.body.time_range} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input_inactive_b' type='text' />
              </div>
              <div style={{width : 20}}/>
              <div style={{flex : 1, flexDirection : 'column', display : 'flex'}}>
                <div className='default_text_normal_gray bold_text'>Nilai Potongan Promo</div>
                <input readOnly={true} onChange={ (event) => view_util.onEditTextChanged(this, event, 'deduction') } value={this.state.body.deduction} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input_inactive_b' type='text' />
              </div>
            </div>

            <div style={{flexDirection : 'row', display : 'flex', paddingTop : 10}}>
              <div style={{flex : 1, flexDirection : 'column', display : 'flex'}}>
                <div className='default_text_normal_gray bold_text'>Batas Penggunaan Harian Pelanggan</div>
                <input readOnly={true} onChange={ (event) => view_util.onEditTextChanged(this, event, 'daily_limit') } value={this.state.body.daily_limit} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input_inactive_b' type='text' />
              </div>
              <div style={{width : 20}}/>
              <div style={{flex : 1, flexDirection : 'column', display : 'flex'}}>
                <div className='default_text_normal_gray bold_text'>Batas Penggunaan Mingguan Pelanggan</div>
                <input readOnly={true} onChange={ (event) => view_util.onEditTextChanged(this, event, 'weekly_limit') } value={this.state.body.weekly_limit} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input_inactive_b' type='text' />
              </div>
            </div>

            <div style={{flexDirection : 'row', display : 'flex', paddingTop : 10}}>
              <div style={{flex : 1, flexDirection : 'column', display : 'flex'}}>
                <div className='default_text_normal_gray bold_text'>Batas Penggunaan Setiap Pelanggan</div>
                <input readOnly={true} onChange={ (event) => view_util.onEditTextChanged(this, event, 'purchase_limit') } value={this.state.body.purchase_limit} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input_inactive_b' type='text' />
              </div>
              <div style={{width : 20}}/>
              <div style={{flex : 1, flexDirection : 'column', display : 'flex'}}>
                <div className='default_text_normal_gray bold_text'>Jumlah Produk Grosir Yang Sesuai</div>
                <input readOnly={true} onChange={ (event) => view_util.onEditTextChanged(this, event, 'n_valid') } value={this.state.body.n_valid} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input_inactive_b' type='text' />
              </div>
            </div>

            <div style={{flexDirection : 'row', display : 'flex', paddingTop : 10}}>
              <div style={{flex : 1, flexDirection : 'column', display : 'flex'}}>
                <div className='default_text_normal_gray bold_text'>Minimum Pembelian</div>
                <input readOnly={true} onChange={ (event) => view_util.onEditTextChanged(this, event, 'minimum_limit') } value={this.state.body.minimum_limit} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input_inactive_b' type='text' />
              </div>
              <div style={{width : 20}}/>
              <div style={{flex : 1}}></div>
            </div>

            <div style={{paddingTop : 20}} className='default_text_medium_gray bold_text'>BERLAKU PADA PRODUK</div>

            { view_util.initViewTable(this.state.body.products, this.initTableHeader(), (pos, data) => this.initTableBody(pos, data), false) }

          </div>
        </div>
      );
    } else { return null; }
  }

  onCloseDialog(is_updated) {
    if (!this.state.show_product_dialog && !this.state.is_loading && !this.state.body_error.is_show) {
      this.props.onCloseListener(is_updated);
    }
  }

  render() {
    return (
      <div onClick={this.onCloseDialog.bind(this, false)} className='process_dialog_root layer_a'>
        <LoadingDialog showDialog={this.state.is_loading}/>
        { showProductDialog(this, (is_updated) => { if (is_updated) { this.onCloseDialog(is_updated) } } ) }
        { showErrorDialog(this) }
        { view_util.getMainViewDialog(this, this.initMainView()) }
      </div>
    );
  }

}

function closeVoucherTP(context, listener, is_updated) {
  let content_tag = context.state.voucher_tp.content;
  context.setState({
    voucher_tp : { is_show : false, data : null }
  }, () => {
    listener(is_updated, content_tag);
  });
}

export function onShowVoucherTP(context, listener) {
  if (context.state.voucher_tp.is_show) {
    return <VoucherTPDialog onCloseListener={(is_updated) => closeVoucherTP(context, listener, is_updated)} data={context.state.voucher_tp.data} />
  } else {
    return null;
  }
}
