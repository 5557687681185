import React, { Component } from 'react';

import LoadingDialog from '../../dialog/LoadingDialog';
import ErrorDialog from '../../dialog/ErrorDialog';

import icon_coins from '../resources/coins.png';
import icon_wheeler from '../resources/wheeler.png';
import icon_address from '../resources/address.png';
import icon_phone from '../resources/phone.png';
import icon_barcode from '../../resources/barcode.png';
import icon_media from '../../resources/media.png';
import icon_category from '../../resources/category.png';
import icon_font from '../../resources/font.png';
import icon_lightbulb from '../../resources/lightbulb.png';
import gif_img from '../../resources/loading.gif';
import img_error from '../../resources/error.png';

var request = require('../../../utilities/NetworkRequest');
var view_util = require('../../../utilities/ViewUtil');
var str_util = require('../../../utilities/StringUtil');
var img_utils = require('../../../utilities/ImageUtil');

export default class WholesalerDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : true,
      is_loading : false,
      is_error : false,
      error_message : '',
      body_error : {
        is_show : false,
        message : ''
      },
      wholesaler_type : {},
      status_wholesaler : {},
      payment : [],
      information : {},
      image : null
    }
  }

  componentDidMount() {
    let body = this.props.body;
    if (body === null) { body = {}; }
    request.onRequestData(1, '/show/detail_wholesaler', body, res_data => {
      if (res_data.code === 1) {
        this.setState({
          information : res_data.result.information,
          payment : res_data.result.payment,
          wholesaler_type : res_data.result.wholesaler_type,
          status_wholesaler : res_data.result.status_wholesaler,
          is_main_loading : false,
          is_error : false
        });
      } else {
        this.setState({
          is_main_loading : false,
          is_error : true,
          error_message : res_data.message
        });
      }
    });
  }

  getStatusLoyalty() {
    return (
      <div className='wrapper_right_form_product normal_border_bottom'>
        {view_util.getSwitchView(this, 'open_loyalty_mayora', 'Point Loyalty Mayora', '#43987b')}
      </div>
    );
  }

  getStatusWholesaler() {
    return (
      <div className='wrapper_right_form_product normal_border_bottom'>
        {view_util.getSwitchView(this, 'is_active', 'Tampilkan Grosir', '#43987b')}
        {view_util.getSwitchView(this, 'is_open', 'Izinkan Penambahan CB', '#43987b')}
      </div>
    );
  }

  getStatusRetrieval() {
    return (
      <div className='wrapper_right_form_product normal_border_bottom'>
        {view_util.getSwitchView(this, 'is_delivery', 'Kirim Ke Toko', '#43987b')}
        {view_util.getSwitchView(this, 'is_take_away', 'Bawa Pulang', '#43987b')}
      </div>
    );
  }

  onSelectedImage(event) {
    this.setState({ image : event.target.files[0] });
  }

  initMainView() {
    if (this.state.is_main_loading) {
      return (
        <div onClick={(e) => e.stopPropagation()} className='pop_up_dialog_container'>
          <img className='big_image' src={gif_img} alt='' />
          <div className='custom_padding_c default_text_big_gray'>Memuat Data</div>
        </div>
      );
    } else {
      if (this.state.is_error) {
        return (
          <div onClick={(e) => e.stopPropagation()} className='pop_up_dialog_container'>
            <img className='normal_margin_top_c big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_medium_gray'>{this.state.error_message}</div>
          </div>
        );
      } else {
        return (
          <div onClick={(e) => e.stopPropagation()} className='product_dialog_container'>
            <LoadingDialog showDialog={this.state.is_loading}/>
            {this.showErrorDialog(this.state.body_error)}
            <div className='wrapper_form_product normal_padding_bottom default_text_medium_gray bold_text normal_border_bottom'>Manajemen Grosir</div>
            {view_util.getInputView(this, 'wholesaler_id', icon_barcode, 'Kode Grosir', 'ex. G00001', true, (this.state.information.wholesaler_id !== ''), false)}
            {view_util.getInputView(this, 'reference_code', icon_barcode, 'Kode Referensi', 'ex. 123456 (Max. 6 Karakter)', false, true, false)}
            {view_util.getInputView(this, 'wholesaler_name', icon_font, 'Nama Grosir', 'ex. Grosir Daan Mogot', false, true, false)}
            {view_util.getInputView(this, 'address', icon_address, 'Alamat Grosir', 'ex. Jl. Daan Mogot KM. 18', false, true, false)}
            {view_util.getInputView(this, 'sub_district_name', icon_address, 'Area Kecamatan', 'Boleh dikosongkan', true, (this.state.information.wholesaler_id !== ''), false)}
            {view_util.getInputView(this, 'phone', icon_phone, 'Nomor Kontak', 'ex. 0812345689', false, true, false)}
            <div className='wrapper_form_product normal_border_bottom normal_padding_bottom'>
              <div className='wrapper_left_form_product'>
                <img className='normal_margin_top_d mini_image_d' src={icon_media} alt=''/>
                <div className='normal_margin_left default_text_normal_gray'>Gambar Grosir</div>
              </div>
              <div className='wrapper_right_form_product'>
                <input className='default_text_normal_gray' accept='image/x-png,image/jpeg' onChange={(event) => this.onSelectedImage(event) } type='file'/>
              </div>
            </div>
            {view_util.getDropdownView(this, 'wholesaler_type', icon_category, 'Jenis Grosir', false, true, true)}
            {view_util.getDropdownView(this, 'status_wholesaler', icon_category, 'Status Grosir', true, true, true)}
            {view_util.getInputView(this, 'delivery_price', icon_coins, 'Biaya Layanan Aplikasi', 'ex. 10000', false, true, false)}
            {view_util.getInputView(this, 'delivery_platform', icon_coins, 'Biaya Dibebankan', 'ex. 10000', false, true, false)}
            {view_util.getInputView(this, 'is_wheeler_exist', icon_wheeler, 'Jumlah Three Wheeler', 'Min. 0', false, true, true)}
            {view_util.getSwitchWrapper(icon_lightbulb, 'Status Grosir', false, this.getStatusWholesaler())}
            {view_util.getSwitchWrapper(icon_lightbulb, 'Point Loyalty', false, this.getStatusLoyalty())}
            {view_util.getSwitchWrapper(icon_lightbulb, 'Metode Pengambilan', false, this.getStatusRetrieval())}
            {view_util.getSwitchBundle(this, 'payment', icon_coins, 'Metode Pembayaran', true)}
            <div style={{paddingTop : 50}} className='wrapper_form_product'>
              <div onClick={this.onPostAction.bind(this)} className='default_text_normal_white_b green_background process_button_b'>Simpan</div>
              <div style={{width : 20}}/>
              <div onClick={this.props.onCloseListener.bind(this)} className='default_text_normal_white_b red_background process_button_b'>Tutup</div>
            </div>
          </div>
        );
      }
    }
  }

  onPostAction() {
    this.setState({
      is_loading : true
    }, () => {
      try {
        if (this.state.information.wholesaler_name.length === 0) {
          throw Error('Form nama grosir masih kosong');
        }
        // if (this.state.information.reference_code.length !== 6 || Number.isNaN(Number(this.state.information.reference_code))) {
        //   throw Error('Form kode referensi grosir harus memuat 6 karakter angka');
        // }
        if (this.state.information.address.length === 0) {
          throw Error('Form alamat grosir masih kosong');
        }
        if (this.state.information.phone.length === 0) {
          throw Error('Form nomor kontak grosir masih kosong');
        }
        if (this.state.wholesaler_type.selected.key === null) {
          throw Error('Anda belum memilih jenis grosir manapun');
        }
        if (this.state.status_wholesaler.selected.key === null) {
          throw Error('Anda belum memilih status grosir manapun');
        }
        if (this.state.information.delivery_price.length === 0 || Number.isNaN(Number(this.state.information.delivery_price))) {
          throw Error('Form Biaya Layanan Aplikasi masih kosong dan harus berupa angka');
        } else if (Number(this.state.information.delivery_price) < 0) {
          throw Error('Form Biaya Layanan Aplikasi tidak boleh minus');
        }
        if (this.state.information.delivery_platform.length === 0 || Number.isNaN(Number(this.state.information.delivery_platform))) {
          throw Error('Form biaya dibebankan masih kosong dan harus berupa angka');
        } else if (Number(this.state.information.delivery_platform) < 0) {
          throw Error('Form biaya dibebankan tidak boleh minus');
        }
        if (this.state.information.is_wheeler_exist.length === 0 || Number.isNaN(Number(this.state.information.is_wheeler_exist))) {
          throw Error('Form jumlah three wheeler masih kosong dan harus berupa angka');
        } else if (Number(this.state.information.is_wheeler_exist) < 0) {
          throw Error('Form jumlah three wheeler tidak boleh minus');
        }
        if (this.state.image === null && this.state.information.wholesaler_id.length === 0) {
          throw Error('Anda belum mengupload gambar !!');
        } else if (this.state.image !== null) {
          if (!img_utils.accepted_file.includes(this.state.image.type)) {
            throw Error('Data gambar yang anda inputkan bukan berjenis jpeg/png, cobalah periksa kembali.');
          }
        }
        // CREATE FORM
        let data_form = new FormData();
        if (this.state.information.wholesaler_id !== '') {
          data_form.append('wholesaler_id', this.state.information.wholesaler_id);
        }
        if (this.state.information.img_path !== '') {
          data_form.append('img_path', this.state.information.img_path);
        }
        data_form.append('wholesaler_name', this.state.information.wholesaler_name);
        data_form.append('reference_code', this.state.information.reference_code);
        data_form.append('address', this.state.information.address);
        data_form.append('phone', str_util.parseHandphone(this.state.information.phone));
        data_form.append('delivery_price', this.state.information.delivery_price);
        data_form.append('delivery_platform', this.state.information.delivery_platform);
        data_form.append('is_open', this.state.information.is_open);
        data_form.append('open_loyalty_mayora', this.state.information.open_loyalty_mayora);
        data_form.append('is_active', this.state.information.is_active);
        data_form.append('is_delivery', (this.state.information.is_delivery ? '1' : ''));
        data_form.append('is_take_away', (this.state.information.is_take_away ? '2' : ''));
        data_form.append('payment', JSON.stringify(this.state.payment));
        data_form.append('is_wheeler_exist', this.state.information.is_wheeler_exist);
        data_form.append('synergy_status', this.state.wholesaler_type.selected.key);
        data_form.append('status_go', this.state.status_wholesaler.selected.key);
        if (this.state.image !== null) {
          data_form.append('image_type', this.state.image.type.split('/')[1]);
          data_form.append('image', this.state.image);
        }
        this.postWholesaler(data_form);
      } catch (e) {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : e.message
          }
        });
      }
    });
  }

  postWholesaler(body) {
    request.onRequestForm(1, '/post/wholesaler', body, function(res_data) {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => {
          this.props.onCloseListener();
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    }.bind(this));
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else { return null; }
  }

  render() {
    return(
      <div onClick={this.props.onCloseListener.bind(this)} className='process_dialog_root layer_a'>
        {this.initMainView()}
      </div>
    );
  }

}
