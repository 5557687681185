function formatSeparator(text) {
  text = reverse(text);
  let result = [], i, len;
  for (i = 0, len = text.length; i < len; i += 3) {
    result.push(text.substr(i, 3));
  }
  return reverse(result.join(','));
}

function formatSeparatorHandphone(text) {
  if (text.substring(0,2) === '62') {
    text = '0' + text.slice(2);
  }
  let result = [], i, len;
  for (i = 0, len = text.length; i < len; i += 4) {
    result.push(text.substr(i, 4));
  }
  return result.join('-');
}

function parseHandphone(text) {
  if (text.substring(0,1) === '0') {
    text = '62' + text.slice(1);
  }
  return text;
}

function reverse(text) {
  let reverse_text = text.split('');
  reverse_text = reverse_text.reverse();
  return reverse_text.join('');
}

function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

module.exports = {
  formatSeparator : formatSeparator,
  formatSeparatorHandphone : formatSeparatorHandphone,
  parseHandphone : parseHandphone,
  isNumeric : isNumeric
}
