import React, { Component } from 'react';

import ic_cancel from '../resources/cancel_circle.png';
import gif_img from '../resources/loading.gif';
import img_error from '../resources/error.png';

var request = require('../../utilities/NetworkRequest');

export default class DetailProductDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : true,
      is_error : false,
      error_message : '',
      header : {},
      data_table : []
    }
  }

  componentDidMount() {
    request.onRequestData(request.OPTION_API_MAIN, '/show/detail_order', this.props.body, res_data => {
      if (res_data.code === 1) {
        this.setState({
          is_main_loading : false,
          is_error : false,
          header : res_data.result.information,
          data_table : res_data.result.data
        });
      } else {
        this.setState({
          is_main_loading : false,
          is_error : true,
          error_message : res_data.message
        });
      }
    });
  }

  initHeaderView(data) {
    return (
      <table className='table_header_process_container'>
        <tbody>
          <tr>
            <td className='whole_padding default_text_normal_gray'>ID Ritel</td>
            <td className='whole_padding default_text_normal_gray'>:</td>
            <td className='whole_padding default_text_normal_gray bold_text'>{data.retail_id}</td>
          </tr>
          <tr>
            <td className='whole_padding default_text_normal_gray'>Nama Ritel</td>
            <td className='whole_padding default_text_normal_gray'>:</td>
            <td className='whole_padding default_text_normal_gray bold_text'>{data.retail_name}</td>
          </tr>
          <tr>
            <td className='whole_padding default_text_normal_gray'>Alamat</td>
            <td className='whole_padding default_text_normal_gray'>:</td>
            <td className='whole_padding default_text_normal_gray bold_text'>{data.address}</td>
          </tr>
          <tr>
            <td className='whole_padding default_text_normal_gray'>Catatan</td>
            <td className='whole_padding default_text_normal_gray'>:</td>
            <td className='whole_padding default_text_normal_gray'>{data.note}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  initViewFlashsale(data) {
    if (data.fs_code === null) {
      return <td className='whole_padding default_text_normal_gray'>{data.pcode_name}</td>;
    } else {
      return(
        <td>
          <div className='product_wrapper_dialog'>
            <div className='full_flex whole_padding default_text_normal_gray'>{data.pcode_name}</div>
            <div className='tag_flashsale blue_background default_text_normal_white'>Flashsale</div>
          </div>
        </td>
      );
    }
  }

  initItemProductView(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <tr key={i}>
          {this.initViewFlashsale(data[i])}
          <td className='whole_padding default_text_normal_gray'>{data[i].qty_real_a_processed}</td>
          <td className='whole_padding default_text_normal_gray'>{data[i].qty_real_b_processed}</td>
        </tr>
      );
    }
    return view;
  }

  initTableView() {
    return (
      <table className='table_data_process_container'>
        <thead>
          <tr>
            <th className='whole_padding default_text_normal_gray'>Nama Barang</th>
            <th className='whole_padding default_text_normal_gray'>Jumlah Besar</th>
            <th className='whole_padding default_text_normal_gray'>Jumlah Kecil</th>
          </tr>
        </thead>
        <tbody>
          {this.initItemProductView(this.state.data_table)}
        </tbody>
      </table>
    );
  }

  initMainView() {
    if (this.state.is_main_loading) {
      return (
        <div onClick={(e) => e.stopPropagation()} className='pop_up_dialog_container'>
          <img className='big_image' src={gif_img} alt='' />
          <div className='custom_padding_c default_text_big_gray'>Memuat Data</div>
        </div>
      );
    } else {
      if (this.state.is_error) {
        return (
          <div onClick={(e) => e.stopPropagation()} className='pop_up_dialog_container'>
            <img className='normal_margin_top_c big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_medium_gray'>{this.state.error_message}</div>
          </div>
        );
      } else {
        return (
          <div onClick={(e) => e.stopPropagation()} className='process_dialog_container'>
            <div className='title_search_container orange_background'>
              <div className='full_flex default_text_medium_white bold_text'>DETAIL PRODUK</div>
              <img onClick={(e) => this.props.onCloseDialog()} className='custom_cursor_a mini_image_c' src={ic_cancel} alt='' />
            </div>
            <div className='process_dialog_page'>
              {this.initHeaderView(this.state.header)}
              {this.initTableView()}
            </div>
            <div className='product_detail_footer'/>
          </div>
        );
      }
    }
  }

  render() {
    return(
      <div onClick={(e) => this.props.onCloseDialog()} className='process_dialog_root layer_a'>
        {this.initMainView()}
      </div>
    );
  }

}
