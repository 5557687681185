import React, { Component } from 'react';

import AdvSearchDialog from './dialog/AdvSearchDialog';
import LoadingDialog from './dialog/LoadingDialog';
import ProductDialog from './dialog/ProductDialog';
import ErrorDialog from './dialog/ErrorDialog';

import gif_img from './resources/loading.gif';
import img_error from './resources/error.png';
import img_add_white from './resources/baseline_add_white.png';

var view_util = require('../utilities/ViewUtil');
var cache_util = require('../utilities/CacheUtil');
var request = require('../utilities/NetworkRequest');

export default class RootProduct extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : false,
      is_loading : false,
      is_error : false,
      error_message : '',
      show_product_dialog : false,
      user_information : null,
      dialog_advance : {
        show : false,
        body : null
      },
      body_error : {
        is_show : false,
        message : ''
      },
      body_product : null,
      search_header : {},
      toolbar : {},
      products : [],
      product_header : {},
      pagination : {},
      pagination_array : []
    }
  }

  componentDidMount() { this.requestDataProducts(false, false); }

  requestDataProducts(table_only, is_filter) {
    this.setState({
      is_main_loading : true
    }, () => {
      try {
        let decoded_input = this.state.user_information;
        if (decoded_input === null) {
          decoded_input = cache_util.onGetCacheData(cache_util.CACHE_INFO).result.user_information;
          decoded_input.length_data = 50;
        }
        decoded_input.idx = 1;
        if (!is_filter) {
          if (decoded_input.hasOwnProperty('filter')) {
            delete decoded_input.filter;
          }
        }
        request.onRequestData(1, '/show/root_products', decoded_input, res_data => {
          if (res_data.code === 1) {
            let temp_pagination_array = [];
            temp_pagination_array.push(res_data.result.product_data.pagination);
            if (table_only) {
              this.setState({
                is_main_loading : false,
                is_error : false,
                products : res_data.result.product_data.data,
                pagination : res_data.result.product_data.pagination,
                pagination_array : temp_pagination_array
              });
            } else {
              this.setState({
                is_main_loading : false,
                is_error : false,
                user_information : decoded_input,
                show_upload : {
                  show : false,
                  data : res_data.result.upload_data
                },
                dialog_advance : {
                  show : false,
                  body : res_data.result.filterV2
                },
                search_header : res_data.result.search_header,
                toolbar : res_data.result.toolbar,
                products : res_data.result.product_data.data,
                product_header : res_data.result.product_data.header,
                pagination : res_data.result.product_data.pagination,
                pagination_array : temp_pagination_array
              });
            }
          } else {
            this.setState({
              is_main_loading : false,
              is_error : true,
              error_message : res_data.message
            });
          }
        });
      } catch (e) {
        this.setState({
          is_main_loading : false,
          is_error : true,
          error_message : e.message
        });
      }
    });
  }

  requestProduct(body, temp_pagination_array) {
    this.setState({
      is_loading : true
    }, () => {
      if (temp_pagination_array === null) { body.idx += 1; } // WE USE LIMIT OFFSET NOW
      request.onRequestData(1, '/show/root_products', body, res_data => {
        if (res_data.code === 1) {
          if (temp_pagination_array === null) {
            temp_pagination_array = this.state.pagination_array;
            temp_pagination_array.push(res_data.result.product_data.pagination);
          }
          this.setState({
            is_loading : false,
            is_error : false,
            pagination : res_data.result.product_data.pagination,
            products : res_data.result.product_data.data,
            pagination_array : temp_pagination_array
          });
        } else {
          this.setState({
            is_loading : false,
            is_error : true,
            error_message : res_data.message
          });
        }
      });
    });
  }

  requestTemplateMaster() {
    this.setState({
      is_loading : true
    }, () => {
      let body = this.state.user_information;
      request.onRequestData(1, '/get/root_products_template', body, res_data => {
        if (res_data.code === 1) {
          this.setState({ is_loading : false }, () => {
            window.open(res_data.result, '_self');
          });
        } else {
          this.setState({
            is_loading : false,
            body_error : {
              is_show : true,
              message : res_data.message
            }
          });
        }
      });
    })
  }

  onLeftToolbarClicked(data) {
    switch (data.key) {
      case 'refresh':
        this.requestDataProducts(true, false);
        break;
      case 'download_price':
        this.requestTemplateMaster();
        break;
      default:
        alert('Menu ini masih dalam tahap pengembangan.');
        break;
    }
  }

  onCloseUpdateProduct(is_updated) {
    this.setState({
      show_product_dialog : false,
      body_product : null
    }, () => {
      if (is_updated) {
        this.requestDataProducts(true, true);
      }
    });
  }

  showProductDialog(is_show) {
    if (is_show) {
      return(
        <ProductDialog user_information={this.state.user_information} body={this.state.body_product === null ? this.state.user_information : this.state.body_product} onCloseListener={(is_updated) => this.onCloseUpdateProduct(is_updated)}/>
      );
    } else {
      return null;
    }
  }

  showInfoAction(data) {
    if (data.is_exist || this.state.user_information.wholesaler_id === 'TP9999') {
      return <div className='normal_padding_left_c default_text_normal_white'>Edit</div>;
    } else {
      return <div className='normal_padding_left_c default_text_normal_white'>Tambahkan</div>;
    }
  }

  showActionStyle(data) {
    if (data.is_exist || this.state.user_information.wholesaler_id === 'TP9999') {
      return 'full_flex custom_padding_e label_search_container green_background';
    } else {
      return 'full_flex custom_padding_e label_search_container blue_background';
    }
  }

  onAddClicked(data) {
    data.is_admin = this.state.user_information.wholesaler_id === 'TP9999';
    data.is_root = true;
    this.setState({
      show_product_dialog : true,
      body_product : data
    });
  }

  initTableHeader(data) {
    return (
      <tr>
        <th className='main_column whole_padding default_text_normal_gray'>{data.name}</th>
        <th className='whole_padding default_text_normal_gray'>{data.pcode}</th>
        <th className='whole_padding default_text_normal_gray'>{data.barcode}</th>
        <th className='whole_padding default_text_normal_gray'>{data.cat_a_desc}</th>
        <th className='whole_padding default_text_normal_gray'>{data.cat_b_desc}</th>
        <th className='whole_padding default_text_normal_gray'>Action</th>
      </tr>
    );
  }

  initTableBody(pos, data) {
    return (
      <tr key={pos}>
        <td className='whole_padding'>
          <div className='product_name_wrapper'>
            <img key={data.imgtiny_url} className='circle_background' src={data.imgtiny_url} alt=''/>
            <div className='normal_padding_left_d default_text_normal_gray'>{data.name}</div>
          </div>
        </td>
        <td className='whole_padding default_text_normal_gray'>{data.pcode}</td>
        <td className='whole_padding default_text_normal_gray'>{data.barcode}</td>
        <td className='whole_padding default_text_normal_gray'>{data.cat_a_desc}</td>
        <td className='whole_padding default_text_normal_gray'>{data.cat_b_desc}</td>
        <td>
          <div className='wrapper_product_action'>
            <div onClick={this.onAddClicked.bind(this, data)} className={this.showActionStyle(data)}>
              <img className='mini_image_d' src={img_add_white} alt=''/>
              {this.showInfoAction(data)}
            </div>
          </div>
        </td>
      </tr>
    );
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className='loading_container_b'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return (
          <div className='product_root'>
            <LoadingDialog showDialog={this.state.is_loading} />
            {
              this.state.dialog_advance.show ? <AdvSearchDialog
                body={this.state.dialog_advance.body}
                onCloseListener={ (action, body) => view_util.showDialogAdvance(this, action, () => this.requestDataProducts(true, true)) }
              /> : null
            }
            { this.showErrorDialog(this.state.body_error) }
            { this.showProductDialog(this.state.show_product_dialog) }
            <div className='product_wrapper'>
              { view_util.initViewToolbar(
                this, 'products',
                (data) => this.onLeftToolbarClicked(data),
                () => this.requestDataProducts(true, true)
              ) }
              { view_util.initViewTable(this.state.products,
                this.initTableHeader(this.state.product_header),
                (pos, data) => this.initTableBody(pos, data)
              ) }
              { view_util.initPaginationTable(
                this.state,
                (body, pagination) => this.requestProduct(body, pagination),
                (body) => this.requestProduct(body, null)
              ) }
            </div>
          </div>
        );
      } else {
        return (
          <div className='loading_container_b'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

}
