import React, { Component } from 'react';

import LoadingDialog from './dialog/LoadingDialog';
import ConfirmationDialog from './dialog/ConfirmationDialog';
import VoucherDialog from './dialog/VoucherDialog';
import ErrorDialog from './dialog/ErrorDialog';

import gif_img from './resources/loading.gif';
import img_error from './resources/error.png';
import img_edit from './resources/baseline_edit.png';
import icon_delete from './resources/baseline_delete.png';

var voucher_tp = require('./dialog/VoucherTPDialog');
var request = require('../utilities/NetworkRequest');
var cache_util = require('../utilities/CacheUtil');
var view_util = require('../utilities/ViewUtil');
var str_util = require('../utilities/StringUtil');

export default class Voucher extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : false,
      is_loading : false,
      is_error : false,
      error_message : '',
      user_information : {},
      body_error : {
        is_show : false,
        message : ''
      },
      voucher_tp : {
        is_show : false,
        data : null
      },
      show_voucher_dialog : {
        show : false,
        body : null
      },
      show_delete_confirmation : {
        show : false,
        question : '',
        body : null,
        yes_answer : 'Lanjutkan',
        no_answer : 'Batal'
      },
      tab_view : [],
      tab_content : []
    };
  }

  componentDidMount() {
    this.refreshData('voucher');
  }

  refreshData(selected_tab) {
    this.setState({
      is_main_loading : true
    }, () => {
      try {
        let decoded_input = cache_util.onGetCacheData(cache_util.CACHE_INFO).result.user_information;
        let body = {
          wholesaler_id : decoded_input.wholesaler_id,
          selected_tab : selected_tab
        }
        request.onRequestData(1, '/show/voucher_mgmt', body, res_data => {
          if (res_data.code === 1) {
            this.setState({
              is_main_loading : false,
              is_error : false,
              user_information : decoded_input,
              tab_view : res_data.result.tab_view,
              tab_content : res_data.result.tab_content
            });
          } else {
            this.setState({
              is_main_loading : false,
              is_error : true,
              error_message : res_data.message
            });
          }
        });
      } catch (e) {
        this.setState({
          is_main_loading : false,
          is_error : true,
          error_message : e.message
        });
      }
    });
  }

  onLeftHeaderActionClicked(pos_content) {
    switch (this.state.tab_content[pos_content].tag) {
      case 'voucher':
        this.showVoucherDialog(this.state.tab_content[pos_content], null);
        break;
      default:
        break;
    }
  }

  showVoucherDialog(data, body_dialog) {
    let temp_voucher_dialog = this.state.show_voucher_dialog;
    temp_voucher_dialog.show = !temp_voucher_dialog.show;
    // RESET VALUES
    data.type_voucher.selected = {
      key : null,
      label : 'Pilih Jenis Voucher'
    }
    data.list_ws_loyalty.selected = {
      key : null,
      label : 'Pilih Loyalty Grosir'
    }
    // RESET VALUES
    if (body_dialog === null) {
      body_dialog = {
        wholesaler_id : this.state.user_information.wholesaler_id,
        description : '',
        discount : '',
        minimum_purchase : '',
        max_claim : '',
        claim_value : '',
        is_update : false,
        image : null
      }
    } else {
      for (let i = 0; i < Object.keys(data.type_voucher.data).length; i++) {
        if (data.type_voucher.data[i].key === body_dialog.identity_code) {
          data.type_voucher.selected = data.type_voucher.data[i];
          break;
        }
      }
      for (let i = 0; i < Object.keys(data.list_ws_loyalty.data).length; i++) {
        if (data.list_ws_loyalty.data[i].key === body_dialog.identity_data) {
          data.list_ws_loyalty.selected = data.list_ws_loyalty.data[i];
          break;
        }
      }
    }
    body_dialog.type_action = data.tag;
    body_dialog.type_voucher = data.type_voucher;
    body_dialog.list_ws_loyalty = data.list_ws_loyalty;
    temp_voucher_dialog.body = JSON.stringify(body_dialog);
    this.setState({ show_voucher_dialog : temp_voucher_dialog });
  }

  changeVoucherStatus(pos_content, data) {
    let temp_dialog = this.state.show_delete_confirmation;
    temp_dialog.show = !temp_dialog.show;
    temp_dialog.question = data.label_action;
    data.privilege_id = this.state.user_information.privilege_id;
    temp_dialog.body = data;
    temp_dialog.body.type_action = this.state.tab_content[pos_content].tag;
    this.setState({ show_delete_confirmation : temp_dialog });
  }

  getVoucherHeader(data) {
    return (
      <tr>
        <th className='whole_padding default_text_normal_gray main_column'>{data.header.description}</th>
        <th className='whole_padding default_text_normal_gray'>{data.header.id}</th>
        <th className='whole_padding default_text_normal_gray'>{data.header.type}</th>
        <th className='whole_padding default_text_normal_gray'>{data.header.discount}</th>
        <th className='whole_padding default_text_normal_gray'>{data.header.status}</th>
        <th className='whole_padding default_text_normal_gray'>Action</th>
      </tr>
    );
  }

  getVoucherTable(pos_content, position, data) {
    return (
      <tr key={position}>
        <td className='whole_padding default_text_normal_gray'>{data.description}</td>
        <td className='whole_padding default_text_normal_gray'>{data.id}</td>
        <td className='whole_padding default_text_normal_gray'>{data.type}</td>
        <td className='whole_padding default_text_normal_gray'>{str_util.formatSeparator(data.discount)}</td>
        <td className='whole_padding default_text_normal_gray'>{data.status}</td>
        <td className='whole_padding_d'>
          <div className='wrapper_product_action'>
            <div onClick={this.showVoucherDialog.bind(this, this.state.tab_content[pos_content], data)} style={{width:80}} className='custom_padding_e label_search_container blue_background'>
              <img className='mini_image_d' src={img_edit} alt=''/>
              <div className='normal_padding_left_c default_text_normal_white'>Edit</div>
            </div>
            <div onClick={this.changeVoucherStatus.bind(this, pos_content, data)} style={{width:80}} className='normal_margin_left custom_padding_e label_search_container red_background'>
              <img className='mini_image_d' src={icon_delete} alt=''/>
              <div className='normal_padding_left_c default_text_normal_white'>{data.action}</div>
            </div>
          </div>
        </td>
      </tr>
    );
  }

  getPromoHeader(data) {
    return (
      <tr>
        <th className='whole_padding default_text_normal_gray main_column'>{data.header.description}</th>
        <th className='whole_padding default_text_normal_gray'>{data.header.type}</th>
        <th className='whole_padding default_text_normal_gray'>{data.header.deduction}</th>
        <th className='whole_padding default_text_normal_gray'>{data.header.daily_limit}</th>
        <th className='whole_padding default_text_normal_gray'>{data.header.weekly_limit}</th>
        <th className='whole_padding default_text_normal_gray'>{data.header.wholesaler_reward}</th>
        <th className='whole_padding default_text_normal_gray'>{data.header.purchase_limit}</th>
        <th className='whole_padding default_text_normal_gray'>{data.header.quota}</th>
        <th className='whole_padding default_text_normal_gray'>{data.header.status}</th>
        <th className='whole_padding default_text_normal_gray'>{data.header.wholesaler_status}</th>
        <th className='whole_padding default_text_normal_gray'>Action</th>
      </tr>
    );
  }

  getPromoTable(pos_content, position, data) {
    return (
      <tr key={position}>
        <td className='whole_padding default_text_normal_gray'>{data.description}</td>
        <td className='whole_padding default_text_normal_gray'>{data.type_desc}</td>
        <td className='whole_padding default_text_normal_gray'>{str_util.formatSeparator(data.deduction)}</td>
        <td className='whole_padding default_text_normal_gray'>{str_util.formatSeparator(data.daily_limit)}</td>
        <td className='whole_padding default_text_normal_gray'>{str_util.formatSeparator(data.weekly_limit)}</td>
        <td className='whole_padding default_text_normal_gray'>{str_util.formatSeparator(data.wholesaler_reward)}</td>
        <td className='whole_padding default_text_normal_gray'>{data.purchase_limit}</td>
        <td className='whole_padding default_text_normal_gray'>{str_util.formatSeparator(data.quota)}</td>
        <td className='whole_padding default_text_normal_gray'>{data.product_status}</td>
        <td className='whole_padding default_text_normal_gray'>{data.is_active}</td>
        <td className='whole_padding_d'>
          <div className='wrapper_product_action'>
            <div onClick={() => this.setState({ voucher_tp : { is_show : true, content : this.state.tab_content[pos_content].tag, data : data } })} style={{width:80}} className='custom_padding_e label_search_container blue_background'>
              <img className='mini_image_d' src={img_edit} alt=''/>
              <div className='normal_padding_left_c default_text_normal_white'>Cek Data</div>
            </div>
          </div>
        </td>
      </tr>
    );
  }

  initTabContent(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      if (data[i].is_show) {
        switch (data[i].tag) {
          case 'voucher':
            view.push(view_util.initTableContent(i, data[i], this.getVoucherHeader(data[i]), (pos_content, position, data) => this.getVoucherTable(pos_content, position, data) ));
            break;
          case 'promo_tp':
            view.push(view_util.initTableContent(i, data[i], this.getPromoHeader(data[i]), (pos_content, position, data) => this.getPromoTable(pos_content, position, data) ));
            break;
          default:
            break;
        }
        break;
      }
    }
    return view;
  }

  onCloseDeleteConfirmation(action) {
    let temp_dialog = this.state.show_delete_confirmation;
    temp_dialog.show = !temp_dialog.show;
    temp_dialog.question = '';
    if (action !== 'next') {
      temp_dialog.body = null;
    }
    this.setState({
      is_loading : action === 'next' ? true : false,
      show_delete_confirmation : temp_dialog
    }, () => {
      if (action === 'next') {
        switch (temp_dialog.body.type_action) {
          case 'voucher':
            this.postChangeVoucherStatus();
            break;
          default:
            break;
        }
      }
    });
  }

  postChangeVoucherStatus() {
    let temp_dialog = this.state.show_delete_confirmation;
    temp_dialog.body.is_active = !temp_dialog.body.is_active;
    request.onRequestData(1, '/post/voucher_status', temp_dialog.body, res_data => {
      let selected_tab = temp_dialog.body.type_action;
      temp_dialog.body = null;
      if (res_data.code === 1) {
        this.setState({
          is_loading : false,
          show_delete_confirmation : temp_dialog
        }, () => {
          this.refreshData(selected_tab);
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          },
          show_delete_confirmation : temp_dialog
        });
      }
    });
  }

  onCloseVoucher(action, data) {
    let temp_dialog = this.state.show_voucher_dialog;
    temp_dialog.show = !temp_dialog.show;
    temp_dialog.body = null;
    this.setState({
      is_loading : action === 'next' ? true : false,
      show_voucher_dialog : temp_dialog
    }, () => {
      if (action === 'next') { this.postVoucher(data); }
    });
  }

  postVoucher(body) {
    let data_form = new FormData();
    data_form.append('wholesaler_id', body.wholesaler_id);
    data_form.append('description', body.description);
    data_form.append('discount', body.discount);
    data_form.append('max_claim', body.max_claim);

    data_form.append('claim_value', body.claim_value);
    data_form.append('identity_data', body.list_ws_loyalty.selected.key);

    data_form.append('minimum_purchase', body.minimum_purchase);
    data_form.append('identity_code', body.type_voucher.selected.key);
    data_form.append('is_update', body.is_update);
    if (body.id !== undefined) {
      data_form.append('image_path', body.image_path);
      data_form.append('voucher_id', body.id);
    }
    if (body.image !== null) {
      data_form.append('image_type', body.image.type.split('/')[1]);
      data_form.append('image', body.image);
    }
    request.onRequestForm(1, '/post/voucher_wholesaler', data_form, function(res_data) {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => {
          this.refreshData(body.type_action);
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    }.bind(this));
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className='loading_container'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return(
          <div className='root_category'>
            <ConfirmationDialog data={this.state.show_delete_confirmation} onCloseDialog={(action) => this.onCloseDeleteConfirmation(action)} />
            <LoadingDialog showDialog={this.state.is_loading} />
            { voucher_tp.onShowVoucherTP(this, (is_updated, content_tag) => { if (is_updated) { this.refreshData(content_tag) } }) }
            { this.showErrorDialog(this.state.body_error) }
            { this.state.show_voucher_dialog.show ? <VoucherDialog data={this.state.show_voucher_dialog.body} onCloseListener={(action, data) => this.onCloseVoucher(action, data)} /> : null }
            { view_util.initTabView(this, (pos_content) => this.onLeftHeaderActionClicked(pos_content)) }
            { this.initTabContent(this.state.tab_content) }
          </div>
        );
      } else {
        return (
          <div className='loading_container'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

}
