import React, { Component } from 'react';

import LoadingDialog from './dialog/LoadingDialog';
import DeliveryDialog from './dialog/DeliveryDialog';
import EditTicketDialog from './dialog/EditTicketDialog';
import ConfirmationDialog from './dialog/ConfirmationDialog';
import ErrorDialog from './dialog/ErrorDialog';
import AdvSearchDialog from './dialog/AdvSearchDialog';
import TableSearch from './native/TableSearch';
import DropdownStatic from './native/DropdownStatic';

import gif_img from './resources/loading.gif';
import img_error from './resources/error.png';
import img_setting from './resources/settings.png';
import img_edit from './resources/baseline_edit.png';
import icon_delete from './resources/baseline_delete.png';

var request = require('../utilities/NetworkRequest');
var base64 = require('base-64');
var view_util = require('../utilities/ViewUtil');
var cache_util = require('../utilities/CacheUtil');

var static_dropdown = {
  selected : {
    key : 'selected',
    label : 'Cetak',
  },
  is_expand : false,
  data: [
  {
    key: 'order',
    label: 'Nomor Pesanan'
  },
  {
    key : 'product',
    label : 'Produk'
  }
  ]
}

export default class DeliveryMonitoring extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : false,
      is_loading : false,
      is_error : false,
      error_message : '',
      body_error : {
        is_show : false,
        message : ''
      },
      dialog_advance : {
        show : false,
        body : null
      },
      user_information : null,
      show_dialog : false,
      show_editor_dialog : {
        is_show : false,
        root_data : null,
        body : null
      },
      show_inactive_confirmation : {
        show : false,
        question : '',
        body : null,
        yes_answer : 'Non-Aktifkan',
        no_answer : 'Batal'
      },
      body_information : {},
      header_left : [],
      header_search : {},
      table_header : {},
      table_data : []
    }
  }

  componentDidMount() {
    this.refreshData(false, false);
  }

  refreshData(table_only, is_filter) {
    this.setState({ is_main_loading : true }, () => {
      try {
        let decoded_input = this.state.user_information;
        if (decoded_input === null) {
          decoded_input = cache_util.onGetCacheData(cache_util.CACHE_INFO).result.user_information;
          decoded_input.status = 'active';
        }
        if (!is_filter) {
          if (decoded_input.hasOwnProperty('filter')) {
            delete decoded_input.filter;
          }
        }
        request.onRequestData(request.OPTION_API_MAIN, '/show/delivery_ticket', decoded_input, res_data => {
          if (res_data.code === 1) {
            if (table_only) {
              this.setState({
                is_main_loading : false,
                is_error : false,
                table_data : res_data.result.res_data.arrays
              });
            } else {
              this.setState({
                is_main_loading : false,
                is_error : false,
                body_information : {
                  driver : res_data.result.driver,
                  body_request : {
                    view_type : 2,
                    wholesaler_id : decoded_input.wholesaler_id,
                  }
                },
                dialog_advance : {
                  show : false,
                  body : res_data.result.filterV2
                },
                user_information : decoded_input,
                header_left : res_data.result.header.left,
                header_search : res_data.result.header.search,
                table_header : res_data.result.res_data.header,
                table_data : res_data.result.res_data.arrays
              });
            }
          } else {
            this.setState({
              is_main_loading : false,
              is_error : true,
              error_message : res_data.message
            });
          }
        });
      } catch (e) {
        this.setState({
          is_main_loading : false,
          is_error : true,
          error_message : e.message
        });
      }
    });
  }

  onLeftHeaderActionClicked(position) {
    let temp_header_left = this.state.header_left;
    switch (temp_header_left[position].key) {
      case 'refresh' :
        this.refreshData(true, false);
        break;
      case 'add':
        this.setState({ show_dialog : true });
        break;
      default :
        break;
    }
  }

  initLeftHeader(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <div onClick={this.onLeftHeaderActionClicked.bind(this, i)} key={data[i].key} className='container_image'>
          <img className='mini_image_b' src={require(data[i].image + '')} alt=''/>
        </div>
      );
    }
    return view;
  }

  showDialogAdvance(action, body) {
    let temp = this.state.dialog_advance;
    temp.show = !temp.show;
    switch (action) {
      case 'next' :
        let temp_info = this.state.user_information;
        temp_info.filter = JSON.stringify(body);
        this.setState({
          user_information : temp_info,
          dialog_advance : temp
        }, () => { this.refreshData(true, true); });
        break;
      default :
        this.setState({ dialog_advance : temp });
        break;
    }
  }

  initSearchHeader() {
    return (
      <div className='search_root_container'>
        <TableSearch header={this.state.header_search} table={this.state.table_data} onTableUpdate={(data_table) => this.setState({ table_data : data_table })} />
        <div onClick={() => this.showDialogAdvance('open', this.state.dialog_advance.body)} className='container_image_b'>
          <img className='mini_image_b' src={img_setting} alt=''/>
        </div>
      </div>
    );
  }

  getStatusTicket(data) {
    if (data.is_active) {
      return <div style={{display:'flex', flexFlow:'row', alignItems:'center'}}><div className='normal_margin_right circle_background_e green_background'/>{data.doc_delivery}</div>;
    } else {
      return <div style={{display:'flex', flexFlow:'row', alignItems:'center'}}><div className='normal_margin_right circle_background_e red_background'/>{data.doc_delivery}</div>;
    }
  }

  onEditClicked(data) {
    this.setState({ is_loading : true }, () => {
      request.onRequestData(request.OPTION_API_MAIN, '/show/ticket_editor', data, res_data => {
        if (res_data.code === 1) {
          this.setState({
            is_loading : false,
            show_editor_dialog : {
              is_show : true,
              root_data : data,
              body : res_data.result
            }
          })
        } else {
          this.setState({
            is_loading : false,
            body_error : {
              is_show : true,
              message : res_data.message
            }
          });
        }
      });
    });
  }

  // <div onClick={this.onPrintClicked.bind(this, data[i])} style={{width:50}} className='custom_padding_d label_search_container blue_background'>
  //   <img className='mini_image_d' src={img_baseline_search} alt=''/>
  //   <div className='normal_padding_left_c default_text_normal_white'>Cetak</div>
  // </div>

  onStaticSelected(data, static_data) {
    let url_body = {
      wholesaler_id : data.wholesaler_id,
      doc_delivery : data.doc_delivery,
      driver_name : data.driver_name
    }
    switch (static_data.key) {
      case 'order':
        window.open(request.IP_PATH + '/print_delivery/' + base64.encode(JSON.stringify(url_body)), '_blank');
        break;
      case 'product':
        window.open(request.IP_PATH + '/print_product/' + base64.encode(JSON.stringify(url_body)), '_blank');
        break;
      default:
        // DO NOTHING
        break;
    }
  }

  initDataTable(data) {
    let view = [], i, i_found = 0;
    for (i = 0; i < Object.keys(data).length; i++) {
      if (data[i].show_data) {
        i_found++;
        view.push(
          <tr key={i}>
            <td className='whole_padding default_text_normal_gray'>{this.getStatusTicket(data[i])}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].driver_name}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].c_order}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].start_date_time}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].stop_date_time}</td>
            <td>
              <div className='whole_padding_d wrapper_product_action'>
                <DropdownStatic body={data[i]} data={static_dropdown} onSelectedListener={(body, static_data) => this.onStaticSelected(body, static_data)} />
                <div onClick={this.onEditClicked.bind(this, data[i])} style={{width:50}} className='normal_margin_left custom_padding_d label_search_container green_background'>
                  <img className='mini_image_d' src={img_edit} alt=''/>
                  <div className='normal_padding_left_c default_text_normal_white'>Edit</div>
                </div>
                {this.getInactiveStatusAction(data[i])}
              </div>
            </td>
          </tr>
        );
      }
    }
    if (i_found < 1) {
      view.push(view_util.getZeroRow(6));
    }
    return view;
  }

  onInactiveClicked(data) {
    let temp_dialog = this.state.show_inactive_confirmation;
    temp_dialog.show = true;
    temp_dialog.question = 'Apakah anda yakin ingin meng-nonaktifkan dokumen pengiriman ' + data.doc_delivery + ' ini?';
    temp_dialog.body = data;
    this.setState({ show_inactive_confirmation : temp_dialog })
  }

  getInactiveStatusAction(data) {
    if (data.is_active) {
      return (
        <div onClick={this.onInactiveClicked.bind(this, data)} style={{width:80}} className='normal_margin_left custom_padding_d label_search_container red_background'>
          <img className='mini_image_d' src={icon_delete} alt=''/>
          <div className='normal_padding_left_c default_text_normal_white'>Non-Aktifkan</div>
        </div>
      );
    } else {
      return (
        <div style={{width:80}} className='normal_margin_left custom_padding_d label_search_container gray_background'>
          <img className='mini_image_d' src={icon_delete} alt=''/>
          <div className='normal_padding_left_c default_text_normal_white'>Non-Aktifkan</div>
        </div>
      );
    }
  }

  initTableView(data) {
    return (
      <table className='table_container_doc_delivery'>
        <thead>
          <tr>
            <th className='main_column whole_padding default_text_normal_gray'>{data.doc_delivery}</th>
            <th className='whole_padding default_text_normal_gray'>{data.driver_name}</th>
            <th className='whole_padding default_text_normal_gray'>{data.c_order}</th>
            <th className='whole_padding default_text_normal_gray'>{data.start_date_time}</th>
            <th className='whole_padding default_text_normal_gray'>{data.stop_date_time}</th>
            <th className='whole_padding default_text_normal_gray'>{data.action}</th>
          </tr>
        </thead>
        <tbody>
          {this.initDataTable(this.state.table_data)}
        </tbody>
      </table>
    );
  }

  onCloseProcess(is_updated) {
    this.setState({ show_dialog : false }, () => {
      if (is_updated) {
        this.refreshData(true, true);
      }
    });
  }

  onEditorClosed(is_updated) {
    let temp_data = this.state.show_editor_dialog;
    temp_data.is_show = !temp_data.is_show;
    temp_data.body = null;
    temp_data.root_data = null;
    this.setState({ show_editor_dialog : temp_data }, () => {
      if (is_updated) {
        this.refreshData(true, true);
      }
    });
  }

  showEditorDialog(data) {
    if (data.is_show) {
      return (
        <EditTicketDialog root_data={data.root_data} body={data.body} onCloseListener={(is_updated) => this.onEditorClosed(is_updated)}/>
      );
    } else {
      return null;
    }
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  onCloseConfirmation(action) {
    let temp_dialog = this.state.show_inactive_confirmation;
    temp_dialog.show = false;
    temp_dialog.question = '';
    if (action !== 'next') {
      temp_dialog.body = null;
    }
    this.setState({
      is_loading : action === 'next' ? true : false,
      show_inactive_confirmation : temp_dialog
    }, () => {
      if (action === 'next') {
        this.onRequestInactive();
      }
    });
  }

  onRequestInactive() {
    let temp_dialog = this.state.show_inactive_confirmation;
    request.onRequestData(request.OPTION_API_MAIN, '/post/stop_document', temp_dialog.body, res_data => {
      temp_dialog.body = null;
      if (res_data.code === 1) {
        this.setState({
          is_loading : false,
          show_inactive_confirmation : temp_dialog
        }, () => {
          this.refreshData(true, true);
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          },
          show_inactive_confirmation : temp_dialog
        });
      }
    });
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className='loading_container'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return (
          <div className='root_delivery_monitoring'>
            <LoadingDialog showDialog={this.state.is_loading} />
            <ConfirmationDialog data={this.state.show_inactive_confirmation} onCloseDialog={(action) => this.onCloseConfirmation(action)} />
            { this.state.dialog_advance.show ? <AdvSearchDialog body={this.state.dialog_advance.body} onCloseListener={(action, body) => this.showDialogAdvance(action, body)}/> : null }
            { this.showErrorDialog(this.state.body_error) }
            { this.state.show_dialog ? <DeliveryDialog body={this.state.body_information} onCloseListener={(is_updated) => this.onCloseProcess(is_updated)} /> : null }
            { this.showEditorDialog(this.state.show_editor_dialog) }
            <div className='header_container'>
              <div className='flex_left'>
                {this.initLeftHeader(this.state.header_left)}
              </div>
              <div className='flex_right'>
                {this.initSearchHeader()}
              </div>
            </div>
            {this.initTableView(this.state.table_header)}
          </div>
        );
      } else {
        return (
          <div className='loading_container'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

}
