import React, { Component } from 'react';
import Rating from 'react-rating';

import LoadingDialog from './dialog/LoadingDialog';
import ErrorDialog from './dialog/ErrorDialog';
import AdvSearchDialog from './dialog/AdvSearchDialog';
import TableSearch from './native/TableSearch';
import Pagination from './native/Pagination';

import gif_img from './resources/loading.gif';
import img_error from './resources/error.png';
import img_full_star from './resources/star_fill.png';
import img_empty_star from './resources/star_empty.png';
import img_setting from './resources/settings.png';

var base64 = require('base-64');

var request = require('../utilities/NetworkRequest');
var str_util = require('../utilities/StringUtil');
var cache_util = require('../utilities/CacheUtil');
var view_util = require('../utilities/ViewUtil');

var init_filter = [
  {
    type : 'dropdown',
    key : 'a.status',
    selected : {
      key : 'a.status',
      label : 'Semua'
    }
  }
]

export default class MonitoringOrder extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : false,
      is_loading : false,
      is_error : false,
      error_message : '',
      body_error : {
        is_show : false,
        message : ''
      },
      dialog_advance : {
        show : false,
        body : null
      },
      user_information : null,
      header_left : [],
      header_search : {},
      table_header : {},
      table_data : [],
      pagination : {},
      pagination_array : []
    };
  }

  componentDidMount() {
    this.refreshData('is_main_loading', false, false, init_filter);
  }

  setErrorTag(TAG, error_message) {
    let temp_state = this.state;
    if (TAG === 'is_main_loading') {
      temp_state.is_error = true;
      temp_state.error_message = error_message;
    } else {
      temp_state.body_error = {
        is_show : true,
        message : error_message
      };
    }
  }

  refreshData(TAG_LOADING, table_only, is_filter, initFilter) {
    this.setState({
      [TAG_LOADING] : true
    }, () => {
      try {
        let decoded_input = this.state.user_information;
        if (decoded_input === null) {
          decoded_input = cache_util.onGetCacheData(cache_util.CACHE_INFO).result.user_information;
          decoded_input.length_data = 50;
        }
        if (decoded_input.hasOwnProperty('last_time_stamp')) {
          delete decoded_input.last_time_stamp;
        }
        if (!is_filter) {
          if (decoded_input.hasOwnProperty('filter')) {
            delete decoded_input.filter;
          }
        }
        if (initFilter !== null) {
          decoded_input.filter = initFilter;
        }
        request.onRequestData(request.OPTION_API_MAIN, '/show/all_order_old', decoded_input, res_data => {
          if (res_data.code === 1) {
            let temp_pagination_array = [];
            temp_pagination_array.push(res_data.result.res_data.pagination);
            if (table_only) {
              this.setState({
                [TAG_LOADING] : false,
                is_error : false,
                table_data : res_data.result.res_data.arrays,
                pagination : res_data.result.res_data.pagination,
                pagination_array : temp_pagination_array
              });
            } else {
              this.setState({
                [TAG_LOADING] : false,
                is_error : false,
                dialog_advance : {
                  show : false,
                  body : res_data.result.filterV2
                },
                user_information : decoded_input,
                header_left : res_data.result.header.left,
                header_search : res_data.result.header.search,
                table_header : res_data.result.res_data.header,
                table_data : res_data.result.res_data.arrays,
                pagination : res_data.result.res_data.pagination,
                pagination_array : temp_pagination_array
              });
            }
          } else {
            this.setErrorTag(TAG_LOADING, res_data.message);
            this.setState({ [TAG_LOADING] : false });
          }
        });
      } catch (e) {
        this.setErrorTag(TAG_LOADING, e.message);
        this.setState({ [TAG_LOADING] : false });
      }
    });
  }

  onPrintOrder(TAG, data) {
    let print_params = {
      user_information : this.state.user_information,
      set_id : data.order_no,
      view_id : 3
    }
    if (TAG === 'print_old') {
      window.open(request.IP_PATH + '/print_old/' + base64.encode(JSON.stringify(print_params)), '_blank');
    } else {
      window.open(request.IP_PATH + '/print_mini_old/' + base64.encode(JSON.stringify(print_params)), '_blank');
    }
  }

  onLeftHeaderActionClicked(position) {
    let temp_header_left = this.state.header_left;
    switch (temp_header_left[position].key) {
      case 'refresh' :
        this.refreshData('is_loading', true, false, init_filter);
        break;
      default :
        break;
    }
  }

  initLeftHeader(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <div onClick={this.onLeftHeaderActionClicked.bind(this, i)} key={data[i].key} className='container_image'>
          <img className='mini_image_b' src={require(data[i].image + '')} alt=''/>
        </div>
      );
    }
    return view;
  }

  initSearchHeader() {
    return (
      <div className='search_root_container'>
        {/* <TableSearch header={this.state.header_search} table={this.state.table_data} onTableUpdate={(data_table) => this.setState({ table_data : data_table })} /> */}
        <div onClick={() => this.showDialogAdvance('open', this.state.dialog_advance.body)} className='container_image_b'>
          <img className='mini_image_b' src={img_setting} alt=''/>
        </div>
      </div>
    );
  }

  showDialogAdvance(action, body) {
    let temp = this.state.dialog_advance;
    temp.show = !temp.show;
    switch (action) {
      case 'next' :
        let temp_info = this.state.user_information;
        temp_info.filter = JSON.stringify(body);
        this.setState({
          user_information : temp_info,
          dialog_advance : temp
        }, () => { this.refreshData('is_loading', true, true, null) });
        break;
      default :
        this.setState({ dialog_advance : temp });
        break;
    }
  }

  initDataTable(data) {
    let view = [], i, i_found = 0;
    for (i = 0; i < Object.keys(data).length; i++) {
      if (data[i].show_data) {
        i_found++;
        view.push(
          <tr key={i}>
            {view_util.getAdminInformation(this, data[i])}
            <td className='whole_padding default_text_normal_gray'>{this.getPrintStatus(data[i], 2)}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].retail_id}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].retail_name}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].status_desc}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].order_date_formatted}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].total_items}</td>
            <td className='whole_padding default_text_normal_gray'>{str_util.formatSeparator(data[i].amount)}</td>
            <td className='whole_padding'>
              <Rating
                stop={5}
                fullSymbol={<img src={img_full_star} alt='' />}
                emptySymbol={<img src={img_empty_star} alt='' />}
                initialRating={data[i].star}
                readonly
              />
            </td>
            <td className='side_padding'>{view_util.getPaymentType(data[i].payment_method)}</td>
            <td>
              <div className='wrapper_product_action'>
                <div onClick={this.onPrintOrder.bind(this, 'print_mini_old', data[i])} className='custom_padding_d label_search_container green_background'>
                  <div className='normal_padding_left_c default_text_normal_white'>Print Order</div>
                </div>
              </div>
            </td>
          </tr>
        );
      }
    }
    if (i_found < 1) {
      view.push(view_util.getZeroRow(11));
    }
    return view;
  }

  getDeliveryStatus(data) {
    let view = [];
    if (data.app_platform === 'klikasik') {
      view.push(
        <div key='platform' className='tag_flashsale red_linear_bg default_text_normal_white'>KLIK ASIK</div>
      );
    }
    if (data.app_platform === 'klikasweb') {
      view.push(
        <div key='platform' className='tag_flashsale green_linear_bg default_text_normal_white'>GROSIRKU</div>
      );
    }
    if (data.app_platform === 'tpaspks') {
      view.push(
        <div key='platform_pks' className='tag_flashsale red_linear_bg default_text_normal_white'>Push Order</div>
      );
    }
    if (data.id_bundle !== null) {
      view.push(
        <div key='bundle' className='tag_flashsale orange_background default_text_normal_white'>Bundle</div>
      );
    }
    if (!data.is_delivery) {
      view.push(
        <div key='take_home' className='tag_flashsale green_background_2 default_text_normal_white'>Bawa Pulang</div>
      );
    }
    return view;
  }

  getPrintStatus(data, condition) {
    if (data.print_status > condition) {
      return <div className='print_status'>{data.order_no}<div title='Sudah Dicetak' className='side_margin_b circle_background_f green_background_2'/> {this.getDeliveryStatus(data)}</div>
    } else {
      return <div className='print_status'>{data.order_no}<div title='Belum Dicetak' className='side_margin_b circle_background_f red_background'/> {this.getDeliveryStatus(data)}</div>
    }
  }

  initTableView(data) {
    return (
      <table className='table_container_delivery'>
        <thead>
          <tr>
            {view_util.getAdminHeader(this, data)}
            <th className='main_column whole_padding default_text_normal_gray'>{data.order_no}</th>
            <th className='whole_padding default_text_normal_gray'>{data.retail_id}</th>
            <th className='whole_padding default_text_normal_gray'>{data.retail_name}</th>
            <th className='whole_padding default_text_normal_gray'>{data.status_desc}</th>
            <th className='whole_padding default_text_normal_gray'>{data.order_date_formatted}</th>
            <th className='whole_padding default_text_normal_gray'>{data.total_items}</th>
            <th className='whole_padding default_text_normal_gray'>{data.amount}</th>
            <th className='whole_padding default_text_normal_gray'>{data.star}</th>
            <th className='whole_padding default_text_normal_gray'>{data.payment_method}</th>
            <th className='whole_padding default_text_normal_gray'>{data.action}</th>
          </tr>
        </thead>
        <tbody>
          {this.initDataTable(this.state.table_data)}
        </tbody>
      </table>
    );
  }

  onPaginationClicked(index, data) {
    let temp_body = this.state.user_information;
    if (index === 9999 && data === null) {
      temp_body.last_time_stamp = this.state.pagination.time_stamp;
    } else {
      temp_body.last_time_stamp = this.state.pagination_array[index].time_stamp_before;
    }
    this.requestOrder(temp_body, data);
  }

  requestOrder(body, temp_pagination_array) {
    this.setState({
      is_loading : true
    }, () => {
      request.onRequestData(request.OPTION_API_MAIN, '/show/all_order_old', body, res_data => {
        if (res_data.code === 1) {
          if (temp_pagination_array === null) {
            temp_pagination_array = this.state.pagination_array;
            temp_pagination_array.push(res_data.result.res_data.pagination);
          }
          this.setState({
            is_loading : false,
            is_error : false,
            table_data : res_data.result.res_data.arrays,
            pagination : res_data.result.res_data.pagination,
            pagination_array : temp_pagination_array
          });
        } else {
          this.setState({
            is_loading : false,
            is_error : true,
            error_message : res_data.message
          });
        }
      });
    });
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className='loading_container'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return (
          <div className='delivery_root'>
            {this.state.dialog_advance.show ? <AdvSearchDialog body={this.state.dialog_advance.body} onCloseListener={(action, body) => this.showDialogAdvance(action, body)}/> : null}
            <div className='header_container'>
              <div className='flex_left'>
                {this.initLeftHeader(this.state.header_left)}
              </div>
              <div className='flex_right'>
                {this.initSearchHeader()}
              </div>
            </div>
            {this.initTableView(this.state.table_header)}
            <Pagination pagination={this.state.pagination} pagination_array={this.state.pagination_array} onChangePage={(index, page_array) => this.onPaginationClicked(index, page_array)}/>
            { this.showErrorDialog(this.state.body_error) }
            <LoadingDialog showDialog={this.state.is_loading}/>
          </div>
        );
      } else {
        return (
          <div className='loading_container'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

}
