import React, { Component } from 'react';

import LoadingDialog from './LoadingDialog';
import ErrorDialog from './ErrorDialog';

import icon_download from '../resources/downloads_a.png';

var request = require('../../utilities/NetworkRequest');

export default class PriceDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_loading : false,
      body_error : {
        is_show : false,
        message : ''
      },
      wholesaler_id : ''
    }
  }

  downloadPrice() {
    this.setState({ is_loading : true }, () => {
      let body = { wholesaler_id : this.state.wholesaler_id };
      request.onRequestData(1, '/get/products_template', body, res_data => {
        if (res_data.code === 1) {
          this.setState({ is_loading : false }, () => {
            window.open(res_data.result, '_self');
          });
        } else {
          this.setState({
            is_loading : false,
            body_error : {
              is_show : true,
              message : res_data.message
            }
          });
        }
      });
    });
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  render() {
    return (
      <div onClick={(e) => this.props.onCloseDialog()} className='process_dialog_root layer_a'>
        <div onClick={(e) => e.stopPropagation()} className='process_dialog_container_b'>
          <LoadingDialog showDialog={this.state.is_loading}/>
          {this.showErrorDialog(this.state.body_error)}
          <div className='default_text_medium_gray bold_text'><u>TEMPLATE PRODUK</u></div>
          <div className='upload_root'>
            <div className='default_text_normal_gray'>Kode Grosir</div>
            <input onChange={(e) => this.setState({ wholesaler_id : e.target.value })} placeholder='ex. G00001' value={this.state.wholesaler_id} spellCheck='false' className='default_text_normal_gray transparent_input_c normal_border_bottom' type='text' />
          </div>

          <div className='upload_root'>
            <div className='upload_content normal_padding_top'>
              <div className='upload_input_wrapper'>
                <div className='normal_padding_bottom default_text_normal_gray_b bold_text'><u>Download Template</u></div>
                <div className='normal_padding_bottom default_text_normal_gray'>Isi kode grosir, kemudian tekan tombol download.</div>
              </div>
              <div onClick={() => this.downloadPrice()} className='default_text_normal_white_b orange_background upload_button'>
                <img className='mini_image_c' src={icon_download} alt=''/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}
