import React, { Component } from 'react';

import ErrorDialog from './ErrorDialog';

var view_util = require('../../utilities/ViewUtil');

export default class NotificationDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      body_error : {
        is_show : false,
        message : ''
      },
      body : {
        wholesaler_id : this.props.body.wholesaler_id,
        message : ''
      }
    }
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  onSendClicked(action) {
    if (this.state.body.message.length === 0) {
      this.setState({
        is_loading : false,
        body_error : {
          is_show : true,
          message : 'Pesan yang ingin dikirimkan tidak boleh kosong.'
        }
      });
    } else {
      this.props.onCloseListener(action, this.state.body);
    }
  }

  render() {
    return (
      <div onClick={(e) => this.props.onCloseListener('close', null)} className='process_dialog_root layer_a'>
        <div onClick={(e) => e.stopPropagation()} className='default_dialog_container'>
          {this.showErrorDialog(this.state.body_error)}
          {this.props.body.wholesaler_id !== 'TP9999' ? null : view_util.getInputViewMinimal(this, 'wholesaler_id', 'Kode Grosir')}
          {view_util.getInputAreaViewMinimal(this, 'message', 'Pesan Notifikasi')}
          <div className='button_process_wrapper_b'>
            <div onClick={() => this.props.onCloseListener('close', null)} className='default_text_normal_white_b red_background process_button_e'>Batal</div>
            <div style={{width : 20}}/>
            <div onClick={this.onSendClicked.bind(this, 'filter')} className='default_text_normal_white_b green_background process_button_e'>Kirim Terpilih</div>
            <div style={{width : 20}}/>
            <div onClick={this.onSendClicked.bind(this, 'all')} className='default_text_normal_white_b blue_background process_button_e'>Kirim Semua</div>
          </div>
        </div>
      </div>
    );
  }

}
