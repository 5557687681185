// PROJECT DISCONTINUE

import React, { Component } from 'react';

import LoadingDialog from './dialog/LoadingDialog';
import AdvSearchDialog from './dialog/AdvSearchDialog';
import BundleDialog from './dialog/BundleDialog';
import BundleProductDialog from './dialog/BundleProductDialog';
import ErrorDialog from './dialog/ErrorDialog';

import gif_img from './resources/loading.gif';
import img_error from './resources/error.png';
import img_edit from './resources/baseline_edit.png';

var request = require('../utilities/NetworkRequest');
var cache_util = require('../utilities/CacheUtil');
var view_util = require('../utilities/ViewUtil');

export default class BundleWholesaler extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : false,
      is_loading : false,
      is_error : false,
      error_message : '',
      user_information : null,
      body_error : {
        is_show : false,
        message : ''
      },
      dialog_advance : {
        show : false,
        body : null
      },
      show_bundle_dialog : {
        is_show : false,
        data : null
      },
      show_bundle_product : {
        is_show : false,
        data : null
      },
      search_header : {},
      toolbar : {},
      data_table : [],
      header_table : {},
      pagination : {},
      pagination_array : []
    }
  }

  componentDidMount() { this.requestData(false, false); }

  requestData(table_only, is_filter) {
    this.setState({ is_main_loading : true }, () => {
      try {
        let decoded_input = this.state.user_information;
        if (decoded_input === null) {
          decoded_input = cache_util.onGetCacheData(cache_util.CACHE_INFO).result.user_information;
          decoded_input.length_data = 50;
        }
        if (!is_filter) {
          if (decoded_input.hasOwnProperty('filter')) {
            delete decoded_input.filter;
          }
        }
        request.onRequestData(1, '/show/list_ws_bundle', decoded_input, res_data => {
          let temp_pagination_array = [];
          if (res_data.code === 1) {
            temp_pagination_array.push(res_data.result.res_data.pagination);
            if (table_only) {
              this.setState({
                is_main_loading : false,
                is_error : false,
                data_table : res_data.result.res_data.arrays,
                pagination : res_data.result.res_data.pagination,
                pagination_array : temp_pagination_array
              });
            } else {
              this.setState({
                is_main_loading : false,
                is_error : false,
                dialog_advance : {
                  show : false,
                  body : res_data.result.filterV2
                },
                user_information : decoded_input,
                search_header : res_data.result.search_header,
                toolbar : res_data.result.toolbar,
                data_table : res_data.result.res_data.arrays,
                header_table : res_data.result.res_data.header,
                pagination : res_data.result.res_data.pagination,
                pagination_array : temp_pagination_array
              });
            }
          } else {
            this.setState({
              is_main_loading : false,
              is_error : true,
              error_message : res_data.message
            });
          }
        });
      } catch (e) {
        this.setState({
          is_main_loading : false,
          is_error : true,
          error_message : e.message
        });
      }
    });
  }

  onLeftToolbarClicked(data) {
    switch (data.key) {
      case 'refresh':
        this.requestData(true, false);
        break;
      case 'add':
        this.showBundleDialog(null)
        break;
      default:
        alert('Menu ini masih dalam tahap pengembangan.');
        break;
    }
  }

  showBundleDialog(body_dialog) {
    let temp_bundle_dialog = this.state.show_bundle_dialog;
    temp_bundle_dialog.show = !temp_bundle_dialog.show;
    if (body_dialog === null) {
      body_dialog = {
        wholesaler_id : this.state.user_information.wholesaler_id,
        is_update : false,
        bundle_name : '',
        image : null
      }
    }
    temp_bundle_dialog.body = JSON.stringify(body_dialog);
    this.setState({ show_bundle_dialog : temp_bundle_dialog });
  }

  showBundleProductDialog(data) {
    let temp_bundle_dialog_product = this.state.show_bundle_product;
    temp_bundle_dialog_product.show = !temp_bundle_dialog_product.show;
    temp_bundle_dialog_product.body = JSON.stringify(data);
    this.setState({ show_bundle_product : temp_bundle_dialog_product });
  }

  initTableHeader(data) {
    return (
      <tr>
        <th className='main_column whole_padding default_text_normal_gray'>{data.bundle_name}</th>
        <th className='whole_padding default_text_normal_gray'>{data.id_bundle}</th>
        <th className='whole_padding default_text_normal_gray'>{data.c_data}</th>
        <th className='whole_padding default_text_normal_gray'>{data.is_limited}</th>
        <th className='whole_padding default_text_normal_gray'>{data.is_active}</th>
        <th className='whole_padding default_text_normal_gray'>Action</th>
      </tr>
    );
  }

  initTableBody(pos, data) {
    return (
      <tr key={pos}>
        <td className='whole_padding default_text_normal_gray'>{data.bundle_name}</td>
        <td className='whole_padding default_text_normal_gray'>{data.id_bundle}</td>
        <td className='whole_padding default_text_normal_gray'>{data.c_data}</td>
        <td className='whole_padding default_text_normal_gray'>{data.is_limited_desc}</td>
        <td className='whole_padding default_text_normal_gray'>{data.is_active_desc}</td>
        <td className='custom_padding_h'>
          <div className='wrapper_product_action'>
            <div onClick={this.showBundleDialog.bind(this, data)} style={{width:80}} className='custom_padding_e label_search_container blue_background'>
              <img className='mini_image_d' src={img_edit} alt=''/>
              <div className='normal_padding_left_c default_text_normal_white'>Edit</div>
            </div>
            <div onClick={this.showBundleProductDialog.bind(this, data)} style={{width:80}} className='normal_margin_left custom_padding_e label_search_container green_background'>
              <img className='mini_image_d' src={img_edit} alt=''/>
              <div className='normal_padding_left_c default_text_normal_white'>Setting Produk</div>
            </div>
          </div>
        </td>
      </tr>
    );
  }

  onCloseBundle(action, data) {
    let temp_dialog = this.state.show_bundle_dialog;
    temp_dialog.show = !temp_dialog.show;
    temp_dialog.body = null;
    this.setState({
      is_loading : action === 'next' ? true : false,
      show_bundle_dialog : temp_dialog
    }, () => {
      if (action === 'next') { this.postBundle(data); }
    });
  }

  onCloseBundleProduct(action) {
    let temp_dialog = this.state.show_bundle_product;
    temp_dialog.show = !temp_dialog.show;
    temp_dialog.body = null;
    this.setState({ show_bundle_product : temp_dialog }, () => {
      if (action === 'next') { console.log('NEXT'); }
    });
  }

  postBundle(body) {
    let data_form = new FormData();
    data_form.append('wholesaler_id', body.wholesaler_id);
    data_form.append('bundle_name', body.bundle_name);
    data_form.append('is_active', body.is_active);

    if (body.image !== null) {
      data_form.append('image_type', body.image.type.split('/')[1]);
      data_form.append('image', body.image);
    }
    request.onRequestForm(1, '/post/ws_bundle_wholesaler', data_form, function(res_data) {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => {
          this.requestData(true, false);
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    }.bind(this));
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className='loading_container_b'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return (
          <div className='product_root'>
            {this.showErrorDialog(this.state.body_error)}
            <LoadingDialog showDialog={this.state.is_loading} />
            {
              this.state.dialog_advance.show ? <AdvSearchDialog
                body={this.state.dialog_advance.body}
                onCloseListener={ (action, body) => view_util.showDialogAdvance(this, action, () => this.requestData(true, true)) }
              /> : null
            }
            { this.state.show_bundle_dialog.show ? <BundleDialog data={this.state.show_bundle_dialog.body} onCloseListener={(action, data) => this.onCloseBundle(action, data)} /> : null }
            { this.state.show_bundle_product.show ? <BundleProductDialog data={this.state.show_bundle_product.body} onCloseListener={(action) => this.onCloseBundleProduct(action)} /> : null }
            <div className='product_wrapper'>
              { view_util.initViewToolbar(
                this, 'data_table',
                (data) => this.onLeftToolbarClicked(data),
                () => this.requestData(true, true)
              ) }
              { view_util.initViewTable(this.state.data_table,
                this.initTableHeader(this.state.header_table),
                (pos, data) => this.initTableBody(pos, data)
              ) }
              { view_util.initPaginationTable(
                this.state,
                (body, pagination) => this.requestNextData(body, pagination),
                (body) => this.requestNextData(body, null)
              ) }
            </div>
          </div>
        );
      } else {
        return (
          <div className='loading_container_b'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

}
