import React, { Component } from 'react';

import img_expand_more from '../resources/baseline_expand_more.png';

export default class TableSearch extends Component {

  constructor(props) {
    super(props);
    this.state = { header_search : this.props.header };
  }

  onChangeText(event) {
    let temp_search_header = this.state.header_search;
    let temp_data = this.props.table;
    temp_search_header.text = event.target.value;
    for (let i = 0; i < Object.keys(temp_data).length; i++) {
      if (temp_data[i][temp_search_header.active.key].toLowerCase().indexOf(event.target.value) !== -1) {
        temp_data[i].show_data = true;
      } else {
        temp_data[i].show_data = false;
      }
    }
    this.setState({ header_search : temp_search_header }, () => {
      this.props.onTableUpdate(temp_data);
    });
  }

  onSearchFilterClicked() {
    let temp_header_search = this.state.header_search;
    temp_header_search.show = !temp_header_search.show;
    this.setState({ header_search : temp_header_search });
  }

  showDropdown(data) {
    if (data.show) {
      return (
        <div className='dropdown_wrapper layer_b normal_margin_top_b normal_position_right_b'>
          <div className='arrow-up'/>
          <div className='dropdown_content gray_background'>{this.initDropdownSearch(data.data)}</div>
        </div>
      );
    }
  }

  onSearchFilterSelected(data) {
    let temp_search_header = this.state.header_search;
    temp_search_header.active = data;
    this.setState({ header_search : temp_search_header });
  }

  initDropdownSearch(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <div onClick={this.onSearchFilterSelected.bind(this, data[i])} key={data[i].key} className='side_padding vertical_padding_b default_text_normal_gray'>{data[i].label}</div>
      );
    }
    return view;
  }

  render() {
    if (this.state.header_search.hasOwnProperty('active')) {
      return (
        <div className='search_container'>
          <input onChange={(event) => this.onChangeText(event)} value={this.state.header_search.text} spellCheck='false' className='transparent_input' type='text' />
          <div onClick={this.onSearchFilterClicked.bind(this)} className='dropdown_display side_padding'>
            <div className='vertical_padding normal_padding_left_b label_search_container blue_background'>
              <div className='default_text_normal_white'>{this.state.header_search.active.label}</div>
              <img className='mini_image_b side_padding_b' src={img_expand_more} alt=''/>
            </div>
            { this.state.header_search.show ? <div className='dropdown_mask' /> : null }
            {this.showDropdown(this.state.header_search)}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

}
