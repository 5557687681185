import React, { Component } from 'react';
import Switch from "react-switch";

import LoadingDialog from './dialog/LoadingDialog';
import ProductDialog from './dialog/ProductDialog';
import ProductOldDialog from './dialog/ProductOldDialog';
import ConfirmationDialog from './dialog/ConfirmationDialog';
import UploadDialog from './dialog/UploadDialog';
import PriceDialog from './dialog/PriceDialog';
import ErrorDialog from './dialog/ErrorDialog';
import AdvSearchDialog from './dialog/AdvSearchDialog';

import gif_img from './resources/loading.gif';
import img_error from './resources/error.png';
import img_edit from './resources/baseline_edit.png';
import icon_delete from './resources/baseline_delete.png';

var request = require('../utilities/NetworkRequest');
var cache_util = require('../utilities/CacheUtil');
var view_util = require('../utilities/ViewUtil');

export default class Product extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : false,
      is_loading : false,
      is_error : false,
      error_message : '',
      body_product : {
        is_show : false,
        body : null
      },
      body_product_old : {
        is_show : false,
        body : null
      },
      body_tprice : { is_show : false, body : null },
      user_information : null,
      show_confirm_confirmation : {
        show : false,
        question : '',
        body : null,
        yes_answer : 'Hapus',
        no_answer : 'Batal'
      },
      body_error : {
        is_show : false,
        message : ''
      },
      show_upload : {
        show : false,
        data : []
      },
      dialog_advance : {
        show : false,
        body : null
      },
      search_header : {},
      toolbar : {},
      quick_button : [
        {key : 'add_list', description : 'Master Produk', background : '#43987b'},
        {key : 'download_price', description : 'Template Harga', background : '#f3823b'},
        {key : 'upload', description : 'Update Harga', background : '#194E84'}
      ],
      products : [],
      product_header : {},
      pagination : {},
      pagination_array : []
    }
  }

  componentDidMount() {
    this.requestDataProducts(false, false);
  }

  requestDataProducts(table_only, is_filter) {
    this.setState({
      is_main_loading : true
    }, () => {
      try {
        let decoded_input = this.state.user_information;
        if (decoded_input === null) {
          decoded_input = cache_util.onGetCacheData(cache_util.CACHE_INFO).result.user_information;
          decoded_input.length_data = 50;
        }
        if (!is_filter) {
          if (decoded_input.hasOwnProperty('filter')) { delete decoded_input.filter; }
        }
        request.onRequestData(request.OPTION_API_MAIN, '/show/products', decoded_input, res_data => {
          if (res_data.code === 1) {
            let temp_pagination_array = [];
            temp_pagination_array.push(res_data.result.product_data.pagination);
            if (table_only) {
              this.setState({
                is_main_loading : false,
                is_error : false,
                products : res_data.result.product_data.data,
                pagination : res_data.result.product_data.pagination,
                pagination_array : temp_pagination_array
              });
            } else {
              this.setState({
                is_main_loading : false,
                is_error : false,
                user_information : decoded_input,
                show_upload : {
                  show : false,
                  data : res_data.result.upload_data
                },
                dialog_advance : {
                  show : false,
                  body : res_data.result.filterV2
                },
                search_header : res_data.result.search_header,
                toolbar : res_data.result.toolbar,
                products : res_data.result.product_data.data,
                product_header : res_data.result.product_data.header,
                pagination : res_data.result.product_data.pagination,
                pagination_array : temp_pagination_array
              });
            }
          } else {
            this.setState({
              is_main_loading : false,
              is_error : true,
              error_message : res_data.message
            });
          }
        });
      } catch (e) {
        this.setState({
          is_main_loading : false,
          is_error : true,
          error_message : e.message
        });
      }
    });
  }

  onLeftToolbarClicked(data) {
    switch (data.key) {
      case 'refresh':
        this.requestDataProducts(true, false);
        break;
      case 'upload':
        let temp_upload = this.state.show_upload;
        temp_upload.show = !temp_upload.show;
        this.setState({
          show_upload : temp_upload
        });
        break;
      case 'download_product':
        window.open(request.IP_PATH + '/products_catalog/', '_blank');
        break;
      case 'download_price':
        if (this.state.user_information.wholesaler_id === 'TP9999') {
          let temp_price = this.state.body_tprice;
          temp_price.is_show = true;
          temp_price.body = this.state.user_information;
          this.setState({ body_tprice : temp_price });
        } else {
          this.requestTemplateMaster();
        }
        break;
      case 'catalog_editor':
        window.open(request.IP_PATH + '/catalog_editor', '_blank');
        break;
      case 'add_list':
        window.open(request.IP_PATH + '/gphome/root_product/', '_self');
        break;
      case 'add':
        this.setState({
          body_product_old : {
            is_show : true,
            body : {
              wholesaler_id : this.state.user_information.wholesaler_id
            }
          }
        });
        break;
      default:
        alert('Menu ini masih dalam tahap pengembangan.');
        break;
    }
  }

  requestTemplateMaster() {
    this.setState({
      is_loading : true
    }, () => {
      let body = this.state.user_information;
      request.onRequestData(1, '/get/products_template', body, res_data => {
        if (res_data.code === 1) {
          this.setState({ is_loading : false }, () => {
            window.open(res_data.result, '_self');
          });
        } else {
          this.setState({
            is_loading : false,
            is_error : true,
            error_message : res_data.message
          });
        }
      });
    })
  }

  onProductSwitched(TAG, checked, position, FLAG) {
    let temp_products = this.state.products;
    temp_products[position][TAG] = checked;
    let body = {
      wholesaler_id : temp_products[position].wholesaler_id,
      product_id : temp_products[position].pcode,
      update : checked ? '1' : '0',
      action_update : FLAG
    }
    this.setState({
      is_loading : true,
      products : temp_products
    }, () => {
      request.onRequestData(1, '/update/products', body, res_data => {
        if (res_data.code === 0) {
          // HANDLE WHEN ERROR ONLY HERE
          temp_products[position][TAG] = !temp_products[position][TAG];
        }
        this.setState({
          is_loading : false,
          products : temp_products
        });
      });
    });
  }

  onDeleteClicked(data) {
    let temp_confirmation = this.state.show_confirm_confirmation;
    temp_confirmation.body = data;
    temp_confirmation.question = 'Apakah anda yakin ingin menghapus produk "' + data.pcode_name + '" ini dari daftar produk anda?';
    temp_confirmation.show = !temp_confirmation.show;
    this.setState({ show_confirm_confirmation : temp_confirmation });
  }

  onUpdateClicked(data) {
    data.is_admin = false;
    data.is_root = false;
    this.setState({
      body_product : {
        is_show : true,
        body : data
      }
    });
  }

  requestProduct(body, temp_pagination_array) {
    this.setState({
      is_loading : true
    }, () => {
      request.onRequestData(request.OPTION_API_MAIN, '/show/products', body, res_data => {
        if (res_data.code === 1) {
          if (temp_pagination_array == null) {
            temp_pagination_array = this.state.pagination_array;
            temp_pagination_array.push(res_data.result.product_data.pagination);
          }
          this.setState({
            is_loading : false,
            is_error : false,
            pagination : res_data.result.product_data.pagination,
            products : res_data.result.product_data.data,
            pagination_array : temp_pagination_array
          });
        } else {
          this.setState({
            is_loading : false,
            is_error : true,
            error_message : res_data.message
          });
        }
      });
    });
  }

  onCloseUpdateProduct(is_updated) {
    this.setState({
      body_product : {
        is_show : false,
        body : null
      },
      body_product_old : {
        is_show : false,
        body : null
      }
    }, () => {
      if (is_updated) {
        this.requestDataProducts(true, true);
      }
    });
  }

  onCloseDeleteConfirmation(action) {
    let temp_confirmation = this.state.show_confirm_confirmation;
    temp_confirmation.show = !temp_confirmation.show;
    if (action === 'next') {
      this.setState({
        is_loading : true,
        show_confirm_confirmation : temp_confirmation
      }, () => {
        this.postDeleteProduct();
      });
    } else {
      this.setState({ show_confirm_confirmation : temp_confirmation });
    }
  }

  postDeleteProduct() {
    request.onRequestData(1, '/delete/product', this.state.show_confirm_confirmation.body, res_data => {
      let temp_confirmation = this.state.show_confirm_confirmation;
      temp_confirmation.question = '';
      temp_confirmation.body = null;
      if (res_data.code === 1) {
        this.setState({
          is_loading : false,
          show_confirm_confirmation : temp_confirmation
        }, () => {
          this.requestDataProducts(false, true);
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          },
          show_confirm_confirmation : temp_confirmation
        });
      }
    });
  }

  onCloseUpload(action) {
    let temp_upload = this.state.show_upload;
    temp_upload.show = !temp_upload.show;
    this.setState({
      show_upload : temp_upload
    }, () => {
      if (action === 'next') {
        this.requestDataProducts(true, true);
      }
    });
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  initTableHeader(data) {
    return (
      <tr>
        <th className='main_column whole_padding default_text_normal_gray'>{data.pcode_name}</th>
        <th className='whole_padding default_text_normal_gray'>{data.pcode}</th>
        {view_util.getAdminHeader(this, data)}
        <th className='whole_padding default_text_normal_gray'>{data.uom_a}</th>
        <th className='whole_padding default_text_normal_gray'>{data.uom_b}</th>
        <th className='whole_padding default_text_normal_gray'>{data.cat_a}</th>
        <th className='whole_padding default_text_normal_gray'>{data.flag_new_item}</th>
        <th className='whole_padding default_text_normal_gray'>{data.flag_promo}</th>
        <th className='whole_padding default_text_normal_gray'>{data.is_active}</th>
        <th className='whole_padding default_text_normal_gray'>Action</th>
      </tr>
    );
  }

  initTableBody(pos, data) {
    return (
      <tr key={pos}>
        <td className='whole_padding'>
          <div className='product_name_wrapper'>
            <img key={data.imgtiny_url} className='circle_background' src={data.imgtiny_url} alt=''/>
            <div className='normal_padding_left_d default_text_normal_gray'>{data.pcode_name}</div>
          </div>
        </td>
        <td className='whole_padding default_text_normal_gray'>{data.pcode}</td>
        {view_util.getAdminInformation(this, data)}
        <td className='whole_padding default_text_normal_gray'>{data.uom_a}</td>
        <td className='whole_padding default_text_normal_gray'>{data.uom_b}</td>
        <td className='whole_padding default_text_normal_gray'>{data.cat_a}</td>
        <td className='whole_padding'>
          <div style={{display:'flex', justifyContent:'center'}}>
            <Switch
              onChange={(checked) => { this.onProductSwitched('flag_new_item', checked, pos, '1') }}
              checked={data.flag_new_item}
              className="react-switch"
              height={20}
              width={40}
              onColor='#ee3c32'
            />
          </div>
        </td>
        <td className='whole_padding'>
          <div style={{display:'flex', justifyContent:'center'}}>
            <Switch
              onChange={(checked) => { this.onProductSwitched('flag_promo', checked, pos, '2') }}
              checked={data.flag_promo}
              className="react-switch"
              height={20}
              width={40}
              onColor='#f2ab1d'
            />
          </div>
        </td>
        <td className='whole_padding'>
          <div style={{display:'flex', justifyContent:'center'}}>
            <Switch
              onChange={(checked) => { this.onProductSwitched('is_active', checked, pos, '3') }}
              checked={data.is_active}
              className="react-switch"
              height={20}
              width={40}
              onColor='#43987b'
            />
          </div>
        </td>
        <td>
          <div className='wrapper_product_action'>
            <div onClick={this.onUpdateClicked.bind(this, data)} style={{width:80}} className='custom_padding_e label_search_container blue_background'>
              <img className='mini_image_d' src={img_edit} alt=''/>
              <div className='normal_padding_left_c default_text_normal_white'>Edit</div>
            </div>
            <div onClick={this.onDeleteClicked.bind(this, data)} style={{width:80}} className='normal_margin_left custom_padding_e label_search_container red_background'>
              <img className='mini_image_d' src={icon_delete} alt=''/>
              <div className='normal_padding_left_c default_text_normal_white'>Hapus</div>
            </div>
          </div>
        </td>
      </tr>
    );
  }

  onClosePriceDialog() {
    let temp = this.state.body_tprice;
    temp.is_show = false;
    temp.body = null;
    this.setState({ body_tprice : temp });
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className='loading_container_b'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return (
          <div className='product_root'>
            <LoadingDialog showDialog={this.state.is_loading} />
            <ConfirmationDialog data={this.state.show_confirm_confirmation} onCloseDialog={(action) => this.onCloseDeleteConfirmation(action)} />
            {
              this.state.dialog_advance.show ? <AdvSearchDialog
                body={this.state.dialog_advance.body}
                onCloseListener={ (action, body) => view_util.showDialogAdvance(this, action, () => this.requestDataProducts(true, true)) }
              /> : null
            }
            { this.state.show_upload.show ? <UploadDialog body={this.state.user_information} data={JSON.stringify(this.state.show_upload.data)} onCloseListener={(action) => this.onCloseUpload(action)}/> : null }
            { this.state.body_tprice.is_show ? <PriceDialog data={this.state.body_tprice} onCloseDialog={this.onClosePriceDialog.bind(this)}/> : null }
            { this.showErrorDialog(this.state.body_error) }
            { this.state.body_product.is_show ? <ProductDialog user_information={this.state.user_information} body={this.state.body_product.body === null ? this.state.user_information : this.state.body_product.body} onCloseListener={(is_updated) => this.onCloseUpdateProduct(is_updated)}/> : null }
            { this.state.body_product_old.is_show ? <ProductOldDialog user_information={this.state.user_information} body={this.state.body_product_old.body === null ? this.state.user_information : this.state.body_product_old.body} onCloseListener={(is_updated) => this.onCloseUpdateProduct(is_updated)}/> : null }
            <div className='product_wrapper'>
              { view_util.initViewToolbar(
                this, 'products',
                (data) => this.onLeftToolbarClicked(data),
                () => console.log('WAIT')
              ) }
              { view_util.viewQuickButton(this, (data) => this.onLeftToolbarClicked(data)) }
              { view_util.initViewTable(this.state.products,
                this.initTableHeader(this.state.product_header),
                (pos, data) => this.initTableBody(pos, data)
              ) }
              { view_util.initPaginationTable(
                this.state,
                (body, pagination) => this.requestProduct(body, pagination),
                (body) => this.requestProduct(body, null)
              ) }
            </div>
          </div>
        );
      } else {
        return (
          <div className='loading_container_b'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

}
