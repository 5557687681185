import React, { Component } from 'react';

import LoadingDialog from './dialog/LoadingDialog';
import ConfirmationDialog from './dialog/ConfirmationDialog';
import DriverDialog from './dialog/DriverDialog';
import AdminDialog from './dialog/AdminDialog';
import ErrorDialog from './dialog/ErrorDialog';

import gif_img from './resources/loading.gif';
import img_error from './resources/error.png';
import img_edit from './resources/baseline_edit.png';
import icon_delete from './resources/baseline_delete.png';

var request = require('../utilities/NetworkRequest');
var cache_util = require('../utilities/CacheUtil');
var view_util = require('../utilities/ViewUtil');
var str_util = require('../utilities/StringUtil');

export default class Utility extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : false,
      is_loading : false,
      is_error : false,
      error_message : '',
      user_information : {},
      body_error : {
        is_show : false,
        message : ''
      },
      show_driver_dialog : {
        show : false,
        body : null
      },
      show_admin_dialog : {
        show : false,
        body : null
      },
      show_delete_confirmation : {
        show : false,
        question : '',
        body : null,
        yes_answer : 'Hapus',
        no_answer : 'Batal'
      },
      tab_view : [],
      tab_content : []
    };
  }

  componentDidMount() {
    this.refreshData('cat_a');
  }

  refreshData(selected_tab) {
    this.setState({
      is_main_loading : true
    }, () => {
      try {
        let decoded_input = cache_util.onGetCacheData(cache_util.CACHE_INFO).result.user_information;
        let body = {
          wholesaler_id : decoded_input.wholesaler_id,
          selected_tab : selected_tab
        }
        request.onRequestData(1, '/show/utility', body, res_data => {
          if (res_data.code === 1) {
            this.setState({
              is_main_loading : false,
              is_error : false,
              user_information : decoded_input,
              tab_view : res_data.result.tab_view,
              tab_content : res_data.result.tab_content
            });
          } else {
            this.setState({
              is_main_loading : false,
              is_error : true,
              error_message : res_data.message
            });
          }
        });
      } catch (e) {
        this.setState({
          is_main_loading : false,
          is_error : true,
          error_message : e.message
        });
      }
    });
  }

  onLeftHeaderActionClicked(pos_content) {
    switch (this.state.tab_content[pos_content].tag) {
      case 'driver':
        this.showDriverDialog(this.state.tab_content[pos_content].tag, null);
        break;
      case 'admin':
        this.showAdminDialog(this.state.tab_content[pos_content], null);
        break;
      default:
        break;
    }
  }

  showAdminDialog(data, body_dialog) {
    let temp_admin_dialog = this.state.show_admin_dialog;
    temp_admin_dialog.show = !temp_admin_dialog.show;
    if (body_dialog === null) {
      body_dialog = {
        wholesaler_id : this.state.user_information.wholesaler_id,
        phone : '',
        access_code : '',
        is_update : false
      }
    } else {
      for (let i = 0; i < Object.keys(data.admin_role.data).length; i++) {
        if (data.admin_role.data[i].key === body_dialog.privilege_id) {
          data.admin_role.selected = data.admin_role.data[i];
          break;
        }
      }
      body_dialog.is_update = true;
    }
    body_dialog.type_action = data.tag;
    body_dialog.admin_role = data.admin_role;
    temp_admin_dialog.body = JSON.stringify(body_dialog);
    this.setState({ show_admin_dialog : temp_admin_dialog });
  }

  showDriverDialog(tag, body_dialog) {
    let temp_drv_dialog = this.state.show_driver_dialog;
    temp_drv_dialog.show = !temp_drv_dialog.show;
    if (body_dialog === null) {
      body_dialog = {
        wholesaler_id : this.state.user_information.wholesaler_id,
        driver_code : '',
        driver_name : '',
        driver_phone : '',
        is_update : false,
        type_action : tag
      }
    } else {
      body_dialog.is_update = true;
      body_dialog.type_action = tag;
    }
    temp_drv_dialog.body = JSON.stringify(body_dialog);
    this.setState({ show_driver_dialog : temp_drv_dialog });
  }

  showDeleteAdmin(pos_content, data) {
    let temp_dialog = this.state.show_delete_confirmation;
    temp_dialog.show = !temp_dialog.show;
    temp_dialog.question = 'Apakah anda yakin ingin menghapus "' + data.owner + '" dari daftar admin?';
    data.privilege_id = this.state.user_information.privilege_id;
    temp_dialog.body = data;
    temp_dialog.body.type_action = this.state.tab_content[pos_content].tag;
    this.setState({ show_delete_confirmation : temp_dialog });
  }

  showDeleteDriver(pos_content, data) {
    let temp_dialog = this.state.show_delete_confirmation;
    temp_dialog.show = !temp_dialog.show;
    temp_dialog.question = 'Apakah anda yakin ingin menghapus "' + data.driver_name + '" dari daftar pengemudi anda?';
    temp_dialog.body = data;
    temp_dialog.body.type_action = this.state.tab_content[pos_content].tag;
    this.setState({ show_delete_confirmation : temp_dialog });
  }

  getCategoryHeader(data) {
    return (
      <tr>
        <th className='whole_padding default_text_normal_gray main_column'>{data.header.description}</th>
        <th className='whole_padding default_text_normal_gray'>{data.header.id}</th>
      </tr>
    );
  }

  getCategoryTable(pos_content, position, data) {
    return (
      <tr key={position}>
        <td className='whole_padding'>
          <div className='product_name_wrapper'>
            <img className='rectangle_background' src={data.image} alt=''/>
            <div className='normal_padding_left_d default_text_normal_gray'>{data.description}</div>
          </div>
        </td>
        <td className='whole_padding default_text_normal_gray'>{data.id}</td>
      </tr>
    );
  }

  getDriverHeader(data) {
    return (
      <tr>
        <th className='whole_padding default_text_normal_gray main_column'>{data.header.driver_name}</th>
        <th className='whole_padding default_text_normal_gray'>{data.header.driver_code}</th>
        <th className='whole_padding default_text_normal_gray'>Action</th>
      </tr>
    );
  }

  getDriverTable(pos_content, position, data) {
    return (
      <tr key={position}>
        <td className='whole_padding default_text_normal_gray'>{data.driver_name}</td>
        <td className='whole_padding default_text_normal_gray'>{data.driver_code}</td>
        <td className='whole_padding_d'>
          <div className='wrapper_product_action'>
            <div onClick={this.showDriverDialog.bind(this, this.state.tab_content[pos_content].tag, data)} style={{width:80}} className='custom_padding_e label_search_container blue_background'>
              <img className='mini_image_d' src={img_edit} alt=''/>
              <div className='normal_padding_left_c default_text_normal_white'>Edit</div>
            </div>
            <div onClick={this.showDeleteDriver.bind(this, pos_content, data)} style={{width:80}} className='normal_margin_left custom_padding_e label_search_container red_background'>
              <img className='mini_image_d' src={icon_delete} alt=''/>
              <div className='normal_padding_left_c default_text_normal_white'>Hapus</div>
            </div>
          </div>
        </td>
      </tr>
    );
  }

  getMeasurementHeader(data) {
    return (
      <tr>
        <th className='whole_padding default_text_normal_gray main_column'>{data.header.description}</th>
        <th className='whole_padding default_text_normal_gray'>{data.header.uom}</th>
      </tr>
    );
  }

  getMeasurementTable(pos_content, position, data) {
    return (
      <tr key={position}>
        <td className='whole_padding default_text_normal_gray'>{data.description}</td>
        <td className='whole_padding default_text_normal_gray'>{data.uom}</td>
      </tr>
    );
  }

  getAdminHeader(data) {
    return (
      <tr>
        <th className='whole_padding default_text_normal_gray main_column'>{data.header.owner}</th>
        <th className='whole_padding default_text_normal_gray'>{data.header.description}</th>
        <th className='whole_padding default_text_normal_gray'>Action</th>
      </tr>
    );
  }

  getAdminTable(pos_content, position, data) {
    return (
      <tr key={position}>
        <td className='whole_padding default_text_normal_gray'>{data.owner + ' (' + data.retail_id + ')'}</td>
        <td className='whole_padding default_text_normal_gray'>{data.description}</td>
        <td className='whole_padding_d'>
          <div className='wrapper_product_action'>
            <div onClick={this.showAdminDialog.bind(this, this.state.tab_content[pos_content], data)} style={{width:80}} className='custom_padding_e label_search_container blue_background'>
              <img className='mini_image_d' src={img_edit} alt=''/>
              <div className='normal_padding_left_c default_text_normal_white'>Edit</div>
            </div>
            <div onClick={this.showDeleteAdmin.bind(this, pos_content, data)} style={{width:80}} className='normal_margin_left custom_padding_e label_search_container red_background'>
              <img className='mini_image_d' src={icon_delete} alt=''/>
              <div className='normal_padding_left_c default_text_normal_white'>Hapus</div>
            </div>
          </div>
        </td>
      </tr>
    );
  }

  initTabContent(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      if (data[i].is_show) {
        switch (data[i].tag) {
          case 'cat_a':
            view.push(view_util.initTableContent(i, data[i], this.getCategoryHeader(data[i]), (pos_content, position, data) => this.getCategoryTable(pos_content, position, data) ));
            break;
          case 'cat_b':
            view.push(view_util.initTableContent(i, data[i], this.getCategoryHeader(data[i]), (pos_content, position, data) => this.getCategoryTable(pos_content, position, data) ));
            break;
          case 'cat_d':
            view.push(view_util.initTableContent(i, data[i], this.getCategoryHeader(data[i]), (pos_content, position, data) => this.getCategoryTable(pos_content, position, data) ));
            break;
          case 'driver':
            view.push(view_util.initTableContent(i, data[i], this.getDriverHeader(data[i]), (pos_content, position, data) => this.getDriverTable(pos_content, position, data) ));
            break;
          case 'measurement':
            view.push(view_util.initTableContent(i, data[i], this.getMeasurementHeader(data[i]), (pos_content, position, data) => this.getMeasurementTable(pos_content, position, data) ));
            break;
          case 'admin':
            view.push(view_util.initTableContent(i, data[i], this.getAdminHeader(data[i]), (pos_content, position, data) => this.getAdminTable(pos_content, position, data) ));
            break;
          default:
            break;
        }
        break;
      }
    }
    return view;
  }

  onCloseDeleteConfirmation(action) {
    let temp_dialog = this.state.show_delete_confirmation;
    temp_dialog.show = !temp_dialog.show;
    temp_dialog.question = '';
    if (action !== 'next') {
      temp_dialog.body = null;
    }
    this.setState({
      is_loading : action === 'next' ? true : false,
      show_delete_confirmation : temp_dialog
    }, () => {
      if (action === 'next') {
        switch (temp_dialog.body.type_action) {
          case 'driver':
            this.postDeleteDriver();
            break;
          case 'admin':
            this.postDeleteAdmin();
            break;
          default:
            break;
        }
      }
    });
  }

  postDeleteAdmin() {
    let temp_dialog = this.state.show_delete_confirmation;
    temp_dialog.body.user_id = this.state.user_information.user_id;
    request.onRequestData(1, '/delete/admin', temp_dialog.body, res_data => {
      let selected_tab = temp_dialog.body.type_action;
      temp_dialog.body = null;
      if (res_data.code === 1) {
        this.setState({
          is_loading : false,
          show_delete_confirmation : temp_dialog
        }, () => {
          this.refreshData(selected_tab);
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          },
          show_delete_confirmation : temp_dialog
        });
      }
    });
  }

  onCloseDriver(action, data) {
    let temp_dialog = this.state.show_driver_dialog;
    temp_dialog.show = !temp_dialog.show;
    temp_dialog.body = null;
    this.setState({
      is_loading : action === 'next' ? true : false,
      show_driver_dialog : temp_dialog
    }, () => {
      if (action === 'next') {
        if (data.is_update) {
          this.updateDriver(data);
        } else {
          this.postDriver(data);
        }
      }
    });
  }

  postDeleteDriver() {
    let temp_dialog = this.state.show_delete_confirmation;
    request.onRequestData(1, '/delete/driver', temp_dialog.body, res_data => {
      let selected_tab = temp_dialog.body.type_action;
      temp_dialog.body = null;
      if (res_data.code === 1) {
        this.setState({
          is_loading : false,
          show_delete_confirmation : temp_dialog
        }, () => {
          this.refreshData(selected_tab);
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          },
          show_delete_confirmation : temp_dialog
        });
      }
    });
  }

  updateDriver(body) {
    request.onRequestData(1, '/update/driver', body, res_data => {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => {
          this.refreshData(body.type_action);
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    });
  }

  postDriver(body) {
    body.driver_phone = str_util.parseHandphone(body.driver_phone);
    request.onRequestData(1, '/post/driver', body, res_data => {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => {
          this.refreshData(body.type_action);
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    });
  }

  onCloseAdmin(action, data) {
    let temp_dialog = this.state.show_admin_dialog;
    temp_dialog.show = !temp_dialog.show;
    temp_dialog.body = null;
    this.setState({
      is_loading : action === 'next' ? true : false,
      show_admin_dialog : temp_dialog
    }, () => {
      if (action === 'next') {
        data.privilege_id = this.state.user_information.privilege_id;
        data.selected_privilege = data.admin_role.selected.key;
        data.phone = str_util.parseHandphone(data.phone);
        if (data.is_update) {
          this.updateAdmin(data);
        } else {
          this.postAdmin(data);
        }
      }
    });
  }

  postAdmin(body) {
    body.user_id = this.state.user_information.user_id;
    request.onRequestData(1, '/post/admin', body, res_data => {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => {
          this.refreshData(body.type_action);
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    });
  }

  updateAdmin(body) {
    body.user_id = this.state.user_information.user_id;
    request.onRequestData(1, '/update/admin', body, res_data => {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => {
          this.refreshData(body.type_action);
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    });
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className='loading_container'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return(
          <div className='root_category'>
            <ConfirmationDialog data={this.state.show_delete_confirmation} onCloseDialog={(action) => this.onCloseDeleteConfirmation(action)} />
            <LoadingDialog showDialog={this.state.is_loading} />
            { this.showErrorDialog(this.state.body_error) }
            { this.state.show_driver_dialog.show ? <DriverDialog data={this.state.show_driver_dialog.body} onCloseListener={(action, data) => this.onCloseDriver(action, data)} /> : null }
            { this.state.show_admin_dialog.show ? <AdminDialog data={this.state.show_admin_dialog.body} onCloseListener={(action, data) => this.onCloseAdmin(action, data)} /> : null }
            { view_util.initTabView(this, (pos_content) => this.onLeftHeaderActionClicked(pos_content)) }
            { this.initTabContent(this.state.tab_content) }
          </div>
        );
      } else {
        return (
          <div className='loading_container'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

}
