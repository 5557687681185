import React, { Component } from 'react';

import Dropdown from '../native/Dropdown';
import ErrorDialog from './ErrorDialog';

export default class VoucherDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      body_error : {
        is_show : false,
        message : ''
      },
      body : JSON.parse(this.props.data)
    }
  }

  onEditTextChanged(event, action) {
    let temp_data = this.state.body;
    temp_data[action] = event.target.value;
    this.setState({ body : temp_data });
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  onSaveClicked() {
    try {
      if (this.state.body.description.length === 0) { throw Error('Kolom deskripsi tidak boleh kosong..!!'); }
      if (this.state.body.type_voucher.selected.key === null) { throw Error('Silahkan pilih tipe voucher terlebih dahulu..!!'); }
      if (this.state.body.discount.length === 0) { throw Error('Kolom potongan harga tidak boleh kosong..!!'); }
      if (isNaN(this.state.body.discount)) { throw Error('Kolom potongan harga harus berupa angka..!!'); }
      if (this.state.body.minimum_purchase.length === 0) { throw Error('Kolom minimal pembelian tidak boleh kosong..!!'); }
      if (isNaN(this.state.body.minimum_purchase)) { throw Error('Kolom minimal pembelian harus berupa angka..!!'); }
      if (this.state.body.max_claim.length === 0) { throw Error('Kolom maksimal klaim tidak boleh kosong..!!'); }
      if (isNaN(this.state.body.max_claim)) { throw Error('Kolom maksimal klaim harus berupa angka..!!'); }
      if (!this.state.body.is_update && this.state.body.image === null) { throw Error('Belum ada gambar yang akan anda unggah!'); }

      if (this.state.body.type_voucher.selected.key === 'inner_vloyalty') {
        if (this.state.body.list_ws_loyalty.selected.key === null) { throw Error('Silahkan pilih point loyalty terlebih dahulu..!!'); }

        if (this.state.body.claim_value.length === 0) { throw Error('Kolom potongan loyalty tidak boleh kosong..!!'); }
        if (isNaN(this.state.body.claim_value)) { throw Error('Kolom potongan loyalty harus berupa angka..!!'); }
      }

      this.props.onCloseListener('next', this.state.body);
    } catch (e) {
      this.setState({
        body_error : {
          is_show : true,
          message : e.message
        }
      });
    }
  }

  onSelectedImage(event) {
    let temp_data = this.state.body;
    temp_data.image = event.target.files[0];
    this.setState({ body : temp_data });
  }

  getListWsLoyalty() {
    if (this.state.body.type_voucher.selected.key === 'inner_vloyalty') {
      return (
        <div>
          <div className='normal_margin_bottom_b normal_margin_top_e default_text_normal_gray bold_text'>Jenis Loyalty Grosir</div>
          <Dropdown data={this.state.body.list_ws_loyalty}/>
        </div>
      );
    } else { return null; }
  }

  getMaximumClaim() {
    if (this.state.body.type_voucher.selected.key === 'inner_delivery') {
      return (
        <div>
          <div className='normal_margin_top_e default_text_normal_gray bold_text'>Maksimal Klaim</div>
          <input onChange={ (event) => this.onEditTextChanged(event, 'max_claim') } value={this.state.body.max_claim} style={{width:250}} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input' type='text' />
        </div>
      );
    } else { return null; }
  }

  getClaimValue() {
    if (this.state.body.type_voucher.selected.key === 'inner_vloyalty') {
      return (
        <div>
          <div className='normal_margin_top_e default_text_normal_gray bold_text'>Jumlah Loyalty Terpotong</div>
          <input onChange={ (event) => this.onEditTextChanged(event, 'claim_value') } value={this.state.body.claim_value} style={{width:250}} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input' type='text' />
        </div>
      );
    } else { return null; }
  }

  onChangeVoucherType(data) {
    let t_body = this.state.body;
    t_body.list_ws_loyalty.selected = {
      key : null,
      label : 'Pilih Loyalty Grosir'
    }
    if (data.key === 'inner_vloyalty') {
      t_body.max_claim = '999';
    } else {
      t_body.max_claim = '';
    }
    t_body.claim_value = '';
    this.setState({ body : t_body })
  }

  render() {
    return (
      <div onClick={(e) => this.props.onCloseListener('close', null)} className='process_dialog_root layer_a'>
        <div onClick={(e) => e.stopPropagation()} className='add_retail_dialog_container'>
          {this.showErrorDialog(this.state.body_error)}
          <div className='default_text_normal_gray bold_text'>Nama Voucher</div>
          <input onChange={ (event) => this.onEditTextChanged(event, 'description') } value={this.state.body.description} style={{width:250}} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input' type='text' />
          <div className='normal_margin_bottom_b normal_margin_top_e default_text_normal_gray bold_text'>Jenis Voucher</div>
          <Dropdown data={this.state.body.type_voucher} listener_change={this.onChangeVoucherType.bind(this)}/>

          { this.getListWsLoyalty() }

          <div className='normal_margin_top_e default_text_normal_gray bold_text'>Besar Potongan</div>
          <input onChange={ (event) => this.onEditTextChanged(event, 'discount') } value={this.state.body.discount} style={{width:250}} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input' type='text' />
          <div className='normal_margin_top_e default_text_normal_gray bold_text'>Minimal Pembelian</div>
          <input onChange={ (event) => this.onEditTextChanged(event, 'minimum_purchase') } value={this.state.body.minimum_purchase} style={{width:250}} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input' type='text' />
          { this.getMaximumClaim() }
          { this.getClaimValue() }
          <div className='normal_margin_top_e default_text_normal_gray bold_text'>Upload Gambar</div>
          <input className='normal_margin_top default_text_normal_gray' accept='image/x-png,image/jpeg' onChange={(event) => this.onSelectedImage(event) } type='file'/>
          <div className='button_process_wrapper_b'>
            <div onClick={() => this.props.onCloseListener('close', null)} className='default_text_normal_white_b red_background process_button_C'>Batal</div>
            <div style={{width : 20}}/>
            <div onClick={this.onSaveClicked.bind(this)} className='default_text_normal_white_b green_background process_button_C'>Simpan</div>
          </div>
        </div>
      </div>
    );
  }

}
