import React, { Component } from 'react';

import LoadingDialog from './LoadingDialog';
import ConfirmationDialog from './ConfirmationDialog';

import img_error from '../resources/error.png';

var request = require('../../utilities/NetworkRequest');

export default class DeliveryConfirmationDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_loading : false,
      is_error : false,
      error_message : '',
      show_confirm_confirmation : {
        show : false,
        question : 'Apakah anda yakin pesanan ini sudah diterima pembeli?',
        yes_answer : 'Ya',
        no_answer : 'Tidak'
      },
      show_cancel_confirmation : {
        show : false,
        question : 'Apakah anda yakin pesanan yang dikirim telah ditolak pembeli?',
        yes_answer : 'Ya',
        no_answer : 'Tidak'
      }
    }
  }

  onConfirmClicked(action) {
    switch (action) {
      case 'close':
        this.props.onCloseDialog(action);
        break;
      case 'cancel':
        this.onShowCancelConfirmation();
        break;
      case 'next':
        this.onShowDeliveryConfirmation();
        break;
      default:
        break;
    }
  }

  onShowCancelConfirmation() {
    let temp_confirmation = this.state.show_cancel_confirmation;
    temp_confirmation.show = !temp_confirmation.show;
    this.setState({ show_cancel_confirmation : temp_confirmation });
  }

  onShowDeliveryConfirmation() {
    let temp_confirmation = this.state.show_confirm_confirmation;
    temp_confirmation.show = !temp_confirmation.show;
    this.setState({ show_confirm_confirmation : temp_confirmation });
  }

  onConfirmationAction(action) {
    this.onShowDeliveryConfirmation();
    if (action === 'next') {
      this.setState({
        is_loading : true
      }, () => {
        let body = {
          wholesaler_id : this.props.data.body.wholesaler_id,
          order_no : this.props.data.body.order_no,
          retail_id : this.props.data.body.retail_id,
          user_id : this.props.data.body.user_id,
          privilege_id : this.props.data.body.privilege_id
        }
        request.onRequestData(request.OPTION_API_MAIN, '/post/accept_order', body, res_data => {
          if (res_data.code === 1) {
            this.setState({
              is_loading : false,
              is_error : false
            }, () => {
              this.props.onCloseDialog('refresh');
            });
          } else {
            this.setState({
              is_loading : false,
              is_error : true,
              error_message : Array.isArray(res_data.message) ? res_data.message[0].text : res_data.message
            });
          }
        });
      });
    }
  }

  onCancelConfirmation(action) {
    this.onShowCancelConfirmation();
    if (action === 'next') {
      this.setState({
        is_loading : true
      }, () => {
        let body = {
          wholesaler_id : this.props.data.body.wholesaler_id,
          order_no : this.props.data.body.order_no,
          retail_id : this.props.data.body.retail_id,
          user_id : this.props.data.body.user_id,
          status_transaction : 5
        }
        request.onRequestData(request.OPTION_API_MAIN, '/cancel/order', body, res_data => {
          if (res_data.code === 1) {
            this.setState({
              is_loading : false,
              is_error : false
            }, () => {
              this.props.onCloseDialog('refresh');
            });
          } else {
            this.setState({
              is_loading : false,
              is_error : true,
              error_message : res_data.message
            });
          }
        });
      });
    }
  }

  initMainView() {
    if (this.state.is_error) {
      return (
        <div onClick={(e) => e.stopPropagation()} className='pop_up_dialog_container'>
          <img className='normal_margin_top_c big_image_b' src={img_error} alt='' />
          <div className='whole_padding_c default_text_medium_gray'>{this.state.error_message}</div>
        </div>
      );
    } else {
      return (
        <div onClick={(e) => e.stopPropagation()} className='pop_up_dialog_container_b layer_b whole_padding_c'>
          <LoadingDialog showDialog={this.state.is_loading}/>
          <ConfirmationDialog data={this.state.show_confirm_confirmation} onCloseDialog={(action) => this.onConfirmationAction(action)} />
          <ConfirmationDialog data={this.state.show_cancel_confirmation} onCloseDialog={(action) => this.onCancelConfirmation(action)} />
          <div className='normal_padding_bottom_c default_text_medium_gray'>Apakah order <b>({this.props.data.body.order_no})</b> diterima atau ditolak pembeli?</div>
          <div className='button_process_wrapper_c'>
            <div onClick={this.onConfirmClicked.bind(this, 'close')} className='default_text_normal_white_b blue_background process_button_d'>Tutup</div>
            <div style={{width : 15}}/>
            <div onClick={this.onConfirmClicked.bind(this, 'cancel')} className='default_text_normal_white_b red_background process_button_d'>Konfirmasi Tolak</div>
            <div style={{width : 15}}/>
            { this.getConfirmationAction() }
          </div>
        </div>
      );
    }
  }

  getConfirmationAction() {
    if (Number(this.props.data.body.status) === 3) {
      return <div onClick={this.onConfirmClicked.bind(this, 'next')} className='default_text_normal_white_b green_background process_button_d'>Konfirmasi Terima</div>;
    } else {
      return <div className='default_text_normal_white_b custom_padding_d gray_background label_search_container'>Konfirmasi Terima</div>;
    }
  }

  render() {
    return(
      <div onClick={(e) => this.props.onCloseDialog('close')} className='process_dialog_root layer_a'>
        {this.initMainView()}
      </div>
    );
  }

}
