import React, { Component } from "react";
import DatePicker from "react-date-picker";

import Dropdown from "../native/Dropdown";

import ic_cancel from "../resources/cancel_circle.png";
import ic_start_calendar from "../resources/calendar_start.png";
import ic_stop_calendar from "../resources/calendar_stop.png";

export default class AdvSearchDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { body: this.props.body };
  }

  initMainView() {
    return (
      <table className="table_search_container">
        <tbody>{this.initView(this.state.body)}</tbody>
      </table>
    );
  }

  initView(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      switch (data[i].type) {
        case "time_range":
          view.push(this.getTimeRange(data[i]));
          break;
        case "input_text":
          view.push(this.getFreeText(data[i]));
          break;
        case "input_free_text":
          view.push(this.getFreeText(data[i]));
          break;
        case "input_text_phone":
          view.push(this.getFreeText(data[i]));
          break;
        case "dropdown":
        case "dropdown_string":
          view.push(this.getDropdown(data[i]));
          break;
        default:
          // DO NOTHING
          break;
      }
    }
    return view;
  }

  getDropdown(data) {
    return (
      <tr key={data.key}>
        <td className="whole_padding default_text_normal_gray">
          {data.description}
        </td>
        <td className="side_padding_c default_text_normal_gray">:</td>
        <td className="whole_padding">
          <Dropdown data={data} />
        </td>
      </tr>
    );
  }

  getFreeText(data) {
    return (
      <tr key={data.key}>
        <td className="whole_padding default_text_normal_gray">
          {data.description}
        </td>
        <td className="side_padding_c default_text_normal_gray">:</td>
        <td className="whole_padding">
          <input
            onChange={(event) =>
              this.onValueChanged("value", data, event.target.value)
            }
            value={data.value}
            spellCheck="false"
            className="non_transparent_input_c default_text_normal_gray"
            type="text"
          />
        </td>
      </tr>
    );
  }

  getDateString(value) {
    return (
      ("0" + (value.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + value.getDate()).slice(-2) +
      "/" +
      value.getFullYear()
    );
  }

  onValueChanged(TAG, data, value) {
    let temp = this.state.body;
    for (let i = 0; i < Object.keys(temp).length; i++) {
      if (temp[i].key === data.key) {
        temp[i][TAG] = value;
        break;
      }
    }
    this.setState({ body: temp });
  }

  getTimeRange(data) {
    return (
      <tr key={data.key}>
        <td className="whole_padding default_text_normal_gray">
          {data.description}
        </td>
        <td className="side_padding_c default_text_normal_gray">:</td>
        <td className="whole_padding">
          <div className="time_range_container">
            <div className="orange_background side_padding display_flex_center">
              <img className="mini_image_c" src={ic_start_calendar} alt="" />
            </div>
            <DatePicker
              className="default_text_normal_gray"
              value={new Date(data.value_start)}
              locale="en-US"
              onChange={(date) =>
                this.onValueChanged(
                  "value_start",
                  data,
                  this.getDateString(date)
                )
              }
            />
            <div className="orange_background side_padding display_flex_center">
              <img className="mini_image_c" src={ic_stop_calendar} alt="" />
            </div>
            <DatePicker
              className="default_text_normal_gray"
              value={new Date(data.value_end)}
              locale="en-US"
              onChange={(date) =>
                this.onValueChanged("value_end", data, this.getDateString(date))
              }
            />
          </div>
        </td>
      </tr>
    );
  }

  render() {
    return (
      <div
        onClick={(e) => this.props.onCloseListener("close", null)}
        className="process_dialog_root layer_a"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="process_dialog_container_c"
        >
          <div className="title_search_container orange_background">
            <div className="full_flex default_text_medium_white bold_text">
              {this.props.body[0].titleDialog
                ? this.props.body[0].titleDialog
                : "Pencarian"}
            </div>
            <img
              onClick={(e) => this.props.onCloseListener("close", null)}
              className="custom_cursor_a mini_image_c"
              src={ic_cancel}
              alt=""
            />
          </div>
          {this.initMainView()}
          <div className="wrapper_form_search">
            <div
              onClick={(e) =>
                this.props.onCloseListener("next", this.state.body)
              }
              className="default_text_normal_white_b green_background process_button_b"
            >
              {this.props.body[0].titleDialog
                ? this.props.body[0].titleDialog
                : "Telusuri"}
            </div>
            <div style={{ width: 20 }} />
            <div
              onClick={(e) => this.props.onCloseListener("close", null)}
              className="default_text_normal_white_b red_background process_button_b"
            >
              Tutup
            </div>
          </div>
        </div>
      </div>
    );
  }
}
