import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import gif_img from './resources/loading.gif';
import img_error from './resources/error.png';
import img_expand_more from './resources/baseline_expand_more.png';
import img_expand_less from './resources/baseline_expand_less.png';
import img_right from './resources/baseline_chevron_right.png';
import img_tp from './resources/tokopintar.png';

import AdminDashboard from './admin/Dashboard';
import AdminWholesaler from './admin/Wholesaler';

import RetailAdmin from './RetailAdmin';
import Dashboard from './Dashboard';
import Analyze from './Analyze';
import ListOrder from './ListOrder';
import Delivery from './Delivery';
import Retail from './Retail';
import Utility from './Utility';
import Voucher from './Voucher';
import Monitor from './MonitoringOrder';
import MonitorOld from './MonitoringOrderOld';
import Product from './Product';
import RootProducts from './RootProducts';
import RootReqProducts from './RootReqProducts';
import Banner from './Banner';
import Flashsale from './Flashsale';
import DeliveryMonitoring from './DeliveryMonitoring';
import DeliveryReady from './DeliveryReady';
import Log from './Log';
import WsLoyalty from './WsLoyalty';
import WsLoyaltyCustomer from './WsLoyaltyCustomer';
import BundleWholesaler from './BundleWholesaler';

var request = require('../utilities/NetworkRequest');
var cache_util = require('../utilities/CacheUtil');

// REASON TO PREVENT FIRST DASHBOARD SHOW WHEN REFRESH ACTION
var route_list = [
  'profile',
  'pending',
  'delivery',
  'root_product',
  'product',
  'retail',
  'retail_admin',
  'analyze',
  'utility',
  'monitor',
  'old_monitor',
  'banner',
  'flash_sale',
  'delivery_monitor',
  'list_delivery',
  'log_activity',
  'request_product',
  'admin_action',
  'list_wholesaler',
  'voucher',
  'ws_loyalty',
  'ws_loyalty_customer',
  'bundle_product'
];

export default class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_first_load : true,
      is_main_loading : true,
      is_error : false,
      error_message : '',
      user_information : null,
      side_home_data : null,
      header_home_data : null,
      dashboard_data : null
    };
  }

  componentDidMount() {
    let res_data = cache_util.onGetCacheData(cache_util.CACHE_INFO);
    if (res_data !== null) {
      this.reformatView(res_data.result.side_page.action);
      this.setState({
        is_main_loading : false,
        is_error : false,
        user_information : res_data.result.user_information,
        side_home_data : res_data.result.side_page,
        header_home_data : res_data.result.header,
        dashboard_data : res_data.result
      });
    }
  }

  reformatView(data) {
    let is_sub_found = false;
    for (let i = 0; i < Object.keys(data).length; i++) {
      for (let j = 0; j < Object.keys(data[i].sub_action).length; j++) {
        if (data[i].sub_action[j].key === this.props.match.params.page_id) {
          is_sub_found = true;
          data[i].show_sub = true;
          data[i].sub_action[j].selected = true;
          break;
        }
      }
      if (is_sub_found) {
        break;
      }
    }
  }

  // SIDE LEFT PANEL CLICKED ACTION
  onActionSideClicked(position) {
    let temp_view = this.state.side_home_data;
    let temp_header_view = this.state.header_home_data;
    let temp_first_load = this.state.is_first_load;
    if (Object.keys(temp_view.action[position].sub_action).length === 0) {
      temp_header_view.initial = temp_view.action[position].label;
      temp_first_load = false;
    }
    temp_view.action[position].show_sub = !temp_view.action[position].show_sub;
    this.setState({
      is_first_load : temp_first_load,
      side_home_data : temp_view,
      header_home_data : temp_header_view
    });
  }

  initExpandImage(data) {
    if (Object.keys(data.sub_action).length > 0) {
      if (data.show_sub) {
        return <img className='mini_image' src={img_expand_less} alt='' />
      } else {
        return <img className='mini_image' src={img_expand_more} alt='' />
      }
    } else {
      return null;
    }
  }

  getActionSideView(data, position) {
    return (
      <div onClick={this.onActionSideClicked.bind(this,position)} className='text_container custom_padding custom_cursor_a'>
        <img className='mini_image_b' src={require(data.image + '')} alt=''/>
        <div className='normal_padding_left full_flex default_text_normal_white_b'>
          {data.label}
        </div>
        {this.initExpandImage(data)}
      </div>
    );
  }

  getSubActionSideStyle(data) {
    if (data.selected) {
      return {'backgroundColor': '#f5dac9'};
    } else {
      return null;
    }
  }

  // SUB LEFT SIDE CLICKED ACTION
  onSubActionSideClicked(sub_position, action_position) {
    let temp_view = this.state.side_home_data;
    let temp_header_view = this.state.header_home_data;
    for (let i = 0; i < Object.keys(temp_view.action).length; i++) {
      if (Object.keys(temp_view.action[i].sub_action).length > 0) {
        for (let j = 0; j < Object.keys(temp_view.action[i].sub_action).length; j++) {
          if (i === action_position && j === sub_position) {
            temp_view.action[i].sub_action[j].selected = true;
            temp_header_view.initial = temp_view.action[i].label + '%' + temp_view.action[i].sub_action[j].label;
          } else {
            temp_view.action[i].sub_action[j].selected = false;
          }
        }
      }
    }
    this.setState({
      is_first_load : false,
      side_home_data : temp_view
    });
  }

  getSubActionSide(data, action_position) {
    let view = null;
    if (Object.keys(data.sub_action).length > 0 && data.show_sub) {
      view = [];
      for (let j = 0; j < Object.keys(data.sub_action).length; j++) {
        view.push(
          <Link key={data.sub_action[j].key} to={"/gphome/" + data.sub_action[j].key + "/"} style={{ textDecoration: 'none' }}>
            <div onClick={this.onSubActionSideClicked.bind(this, j, action_position)} style={this.getSubActionSideStyle(data.sub_action[j])} className='text_container_sub custom_padding'>
              <div style={{width : 16, height : 16}}/>
              <div className='normal_padding_left full_flex default_text_normal_white'>
                {data.sub_action[j].label}
              </div>
            </div>
          </Link>
        );
      }
    }
    return view;
  }

  initSideHome(side_page) {
    let view = [];
    view.push(
      <div key='home_tp' className='home_tp_container bold_text'><img className='big_image_e' src={img_tp} alt='' /><p>TOKO PINTAR</p></div>
    );
    for (let i = 0; i < Object.keys(side_page.action).length; i++) {
      view.push(
        <div key={i} className='vertical_padding_c'>
          {this.getActionSideView(side_page.action[i], i)}
          {this.getSubActionSide(side_page.action[i], i)}
        </div>
      );
    }
    return view;
  }

  onHeaderIconClicked(data) {
    switch (data) {
      case 'logout' :
        cache_util.onRemoveCache(cache_util.CACHE_INFO);
        window.open(request.IP_PATH + '/wholesaler','_self');
        break;
      default :
        // DO NOTHING
        break;
    }
  }

  initHeaderItem(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      switch (data[i].key) {
        case 'profile' :
          view.push(
            <div key={data[i].key} className='header_profile_container'>
              <img className='circle_background' src={data[i].image} alt='' />
              <div className='normal_padding_left_b default_text_normal_white_b'>{data[i].label}</div>
            </div>
          );
          break;
        case 'icon':
          view.push(
            <img onClick={this.onHeaderIconClicked.bind(this, data[i].key_element)} key={i} className='normal_margin_left_c mini_image_c custom_cursor_a' title={data[i].label} src={require(data[i].image + '')} alt='' />
          );
          break;
        default:
          // DO NOTHING
          break;
      }
    }
    return view;
  }

  initHeaderInitialView(data) {
    let view = [];
    view.push(
      <div key='half_triangle' className='header_half_triangle'></div>
    );
    let information = data.split('%');
    for (let i = 0; i < Object.keys(information).length; i++) {
      if (i === Object.keys(information).length - 1) {
        view.push(
          <div key={i} className='default_text_medium_white'>{information[i]}</div>
        );
      } else {
        view.push(
          <div key={i} className='default_text_medium_white'>{information[i]}</div>
        );
        view.push(
          <img key={'chevron_' + i} className='mini_image normal_padding_right_b normal_padding_left_b' src={img_right} alt='' />
        );
      }
    }
    return view;
  }

  getDashboardView() {
    if (this.state.is_first_load) {
      if (route_list.includes(this.props.match.params.page_id)) {
        return null;
      } else {
        if (this.state.dashboard_data.admin_view === undefined) {
          return <Dashboard data={this.state.dashboard_data} />
        } else {
          return <AdminDashboard data={this.state.dashboard_data} />
        }
      }
    } else {
      return null;
    }
  }

  render() {
    if (this.state.is_main_loading) {
      if (this.state.user_information === null) {
        return null;
      } else {
        return (
          <div className='loading_container_b'><img className='big_image' src={gif_img} alt='' /></div>
        );
      }
    } else {
      if (!this.state.is_error) {
        return (
          <Router id='root'>
            <div className='home_root'>
              <div className='home_side_page'>
                {this.initSideHome(this.state.side_home_data)}
              </div>
              <div className='home_main_page'>
                <div className='header_bar_container'>
                  <div className='header_half_container_start'>{this.initHeaderInitialView(this.state.header_home_data.initial)}</div>
                  <div className='header_half_container_end'>{this.initHeaderItem(this.state.header_home_data.data)}</div>
                </div>
                {this.getDashboardView()}
                <Route path="/gphome/admin_action/" component={AdminDashboard} />
                <Route path="/gphome/list_wholesaler/" component={AdminWholesaler} />
                <Route path="/gphome/profile/" component={Dashboard} />
                <Route path="/gphome/pending/" component={ListOrder} />
                <Route path='/gphome/delivery/' component={Delivery} />
                <Route path='/gphome/root_product/' component={RootProducts} />
                <Route path='/gphome/request_product/' component={RootReqProducts} />
                <Route path='/gphome/product/' component={Product} />
                <Route path='/gphome/retail/' component={Retail} />
                <Route path='/gphome/retail_admin/' component={RetailAdmin} />
                <Route path='/gphome/analyze/' component={Analyze} />
                <Route path='/gphome/utility/' component={Utility} />
                <Route path='/gphome/voucher/' component={Voucher} />
                <Route path='/gphome/monitor/' component={Monitor} />
                <Route path='/gphome/old_monitor/' component={MonitorOld} />
                <Route path='/gphome/banner/' component={Banner} />
                <Route path='/gphome/flash_sale/' component={Flashsale} />
                <Route path='/gphome/delivery_monitor/' component={DeliveryMonitoring} />
                <Route path='/gphome/list_delivery/' component={DeliveryReady} />
                <Route path='/gphome/log_activity/' component={Log} />
                <Route path='/gphome/ws_loyalty/' component={WsLoyalty} />
                <Route path='/gphome/ws_loyalty_customer/' component={WsLoyaltyCustomer} />
                <Route path='/gphome/bundle_product/' component={BundleWholesaler} />
              </div>
            </div>
          </Router>
        );
      } else {
        return (
          <div className='loading_container_b'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

}
