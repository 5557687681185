import React, { Component } from 'react';

import img_warning from '../resources/warning.png';

export default class ErrorDialog extends Component {

  render() {
    return(
      <div onClick={(e) => this.props.onCloseDialog()} className='process_dialog_root layer_a'>
        <div onClick={(e) => e.stopPropagation()} className='pop_up_dialog_container layer_b'>
          <img className='normal_margin_top_c big_image_b' src={img_warning} alt='' />
          <div className='whole_padding_c default_text_medium_gray'>{this.props.body_error.message}</div>
        </div>
      </div>
    );
  }

}

export function showErrorDialog(context) {
  if (context.state.body_error.is_show) {
    return <ErrorDialog body_error={context.state.body_error} onCloseDialog={() => context.setState({ body_error : { is_show : false, message : '' } })}/>
  } else {
    return null;
  }
}
