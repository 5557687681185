import React, { Component } from 'react';
import DatePicker from 'react-date-picker';

const view_util = require('../../utilities/ViewUtil');
const img_utils = require('../../utilities/ImageUtil');
const request = require('../../utilities/NetworkRequest');

class AddLoyaltyDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      body : {
        description : '',
        sub_description : '',
        start_date : null,
        end_date : null,
        image : null
      }
    }
  }

  onSelectedImage(event) {
    let temp_data = this.state.body;
    temp_data.image = event.target.files[0];
    this.setState({ body : temp_data });
  }

  onDateChanged(TAG, date) {
    let temp_body = this.state.body;
    temp_body[TAG] = date;
    this.setState({ body : temp_body });
  }

  onSave() {
    this.props.context.setState({ is_loading : true }, () => {
      try {
        if (this.state.body.description.trim().length === 0) { throw Error('Nama point tidak boleh kosong!'); }
        if (this.state.body.sub_description.trim().length === 0) { throw Error('Deskripsi tidak boleh kosong!'); }
        if (this.state.body.start_date === null) { throw Error('Tanggal awal tidak boleh kosong!'); }
        if (this.state.body.end_date === null) { throw Error('Tanggal akhir tidak boleh kosong!'); }
        if (this.state.body.image === null) { throw Error('Tidak ada gambar yang di-unggah!'); }
        if (!img_utils.accepted_file.includes(this.state.body.image.type)) {
          throw Error('Data gambar yang anda inputkan bukan berjenis jpeg/png, cobalah periksa kembali.');
        }

        let data_form = new FormData();
        data_form.append('wholesaler_id', this.props.context.state.user_information.wholesaler_id);
        data_form.append('description', this.state.body.description);
        data_form.append('sub_description', this.state.body.sub_description);
        data_form.append('start_date', `${this.state.body.start_date.getFullYear()}-${(this.state.body.start_date.getMonth() + 1)}-${this.state.body.start_date.getDate()}`);
        data_form.append('end_date', `${this.state.body.end_date.getFullYear()}-${(this.state.body.end_date.getMonth() + 1)}-${this.state.body.end_date.getDate()}`);
        data_form.append('image', this.state.body.image);
        request.onRequestForm(1, '/post/ws_loyalty_master', data_form, res_data => {
          if (res_data.code === 1) {
            onCloseDialog(this.props.context, true);
          } else {
            this.props.context.setState({
              is_loading : false,
              body_error : {
                is_show : true,
                message : res_data.message
              }
            });
          }
        });
      } catch (e) {
        this.props.context.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : e.message
          }
        });
      }
    });
  }

  render() {
    return (
      <div onClick={(e) => onCloseDialog(this.props.context, false)} className='process_dialog_root layer_a'>
        <div onClick={(e) => e.stopPropagation()} className='default_dialog_container'>
          {view_util.getInputViewMinimal(this, 'description', 'Nama Point')}
          {view_util.getInputViewMinimal(this, 'sub_description', 'Deskripsi')}

          <div className='normal_margin_top_h'>
            <div className='default_text_normal_gray bold_text'>Tanggal Awal</div>
            <div className='normal_margin_top default_text_normal_gray'>
              <DatePicker value={this.state.body.start_date} locale="en-US" onChange={(date) => {this.onDateChanged('start_date', date)}} />
            </div>
          </div>

          <div className='normal_margin_top_h'>
            <div className='default_text_normal_gray bold_text'>Tanggal Akhir</div>
            <div className='normal_margin_top default_text_normal_gray'>
              <DatePicker value={this.state.body.end_date} locale="en-US" onChange={(date) => {this.onDateChanged('end_date', date)}} />
            </div>
          </div>

          <div className='normal_margin_top_h'>
            <div className='default_text_normal_gray bold_text'>Upload Gambar</div>
            <input className='normal_margin_top default_text_normal_gray' accept='image/x-png,image/jpeg' onChange={(event) => this.onSelectedImage(event) } type='file'/>
          </div>

          <div className='button_process_wrapper_b'>
            <div onClick={() => onCloseDialog(this.props.context, false)} className='default_text_normal_white_b red_background process_button_C'>Batal</div>
            <div style={{width : 20}}/>
            <div onClick={() => this.onSave()} className='default_text_normal_white_b green_background process_button_C'>Tambahkan</div>
          </div>
        </div>
      </div>
    );
  }

}

function onCloseDialog(context, reload_data) {
  context.setState({
    is_loading : false,
    add_loyalty : {is_show : false, data : null}
  }, () => {
    if (reload_data) { context.refreshData(true, false); }
  });
}

export function showAddLoyalty(context) {
  if (context.state.add_loyalty === undefined) {
    context.state.add_loyalty = {
      is_show : false,
      data : null
    }
  }
  if (context.state.add_loyalty.is_show) {
    return <AddLoyaltyDialog context={context}/>
  } else {
    return null;
  }
}
