import React, { Component } from 'react';

import img_error from './resources/error.png';

export default class PageNotFound extends Component {

  render() {
    if (this.props.is_null !== undefined) {
      return null;
    } else {
      return (
        <div className='loading_container_b'>
          <img className='big_image_b' src={img_error} alt='' />
          <div className='whole_padding_c default_text_big_gray'>Tidak ada halaman yang bisa ditampilkan!</div>
        </div>
      );
    }
  }

}
