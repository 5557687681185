import React, { Component } from 'react';

import LoadingDialog from './dialog/LoadingDialog';
import AdvSearchDialog from './dialog/AdvSearchDialog';
import ErrorDialog from './dialog/ErrorDialog';
import AdminDialog from './dialog/AdminDialog';
import TableSearch from './native/TableSearch';
import Pagination from './native/Pagination';

import gif_img from './resources/loading.gif';
import img_error from './resources/error.png';
import img_setting from './resources/settings.png';
import img_edit from './resources/baseline_edit.png';

var request = require('../utilities/NetworkRequest');
var str_util = require('../utilities/StringUtil');
var cache_util = require('../utilities/CacheUtil');
var view_util = require('../utilities/ViewUtil');

export default class RetailAdmin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : false,
      is_loading : false,
      is_error : false,
      error_message : '',
      body_error : {
        is_show : false,
        message : ''
      },
      dialog_advance : {
        show : false,
        body : null
      },
      show_admin_dialog : {
        show : false,
        body : null
      },
      admin_role : null,
      user_information : null,
      header_left : [],
      header_search : {},
      table_header : {},
      table_data : [],
      pagination : {},
      pagination_array : []
    };
  }

  componentDidMount() {
    this.refreshData(false, false);
  }

  refreshData(table_only, is_filter) {
    this.setState({
      is_main_loading : true
    }, () => {
      try {
        let decoded_input = this.state.user_information;
        if (decoded_input === null) {
          decoded_input = cache_util.onGetCacheData(cache_util.CACHE_INFO).result.user_information;
          decoded_input.length_data = 50;
        }
        decoded_input.idx = 0;
        if (!is_filter) {
          if (decoded_input.hasOwnProperty('filter')) {
            delete decoded_input.filter;
          }
        }
        request.onRequestData(1, '/show/retail_admin', decoded_input, res_data => {
          let temp_pagination_array = [];
          if (res_data.code === 1) {
            temp_pagination_array.push(res_data.result.res_data.pagination);
            if (table_only) {
              this.setState({
                is_main_loading : false,
                is_error : false,
                table_data : res_data.result.res_data.arrays,
                pagination : res_data.result.res_data.pagination,
                pagination_array : temp_pagination_array
              });
            } else {
              this.setState({
                is_main_loading : false,
                is_error : false,
                dialog_advance : {
                  show : false,
                  body : res_data.result.filterV2
                },
                admin_role : res_data.result.admin_role,
                user_information : decoded_input,
                header_left : res_data.result.header.left,
                header_search : res_data.result.header.search,
                table_header : res_data.result.res_data.header,
                table_data : res_data.result.res_data.arrays,
                pagination : res_data.result.res_data.pagination,
                pagination_array : temp_pagination_array
              });
            }
          } else {
            this.setState({
              is_main_loading : false,
              is_error : true,
              error_message : res_data.message
            });
          }
        });
      } catch (e) {
        this.setState({
          is_main_loading : false,
          is_error : true,
          error_message : e.message
        });
      }
    })
  }

  onCloseAdmin(action, data) {
    let temp_dialog = this.state.show_admin_dialog;
    temp_dialog.show = !temp_dialog.show;
    temp_dialog.body = null;
    this.setState({
      is_loading : action === 'next' ? true : false,
      show_admin_dialog : temp_dialog
    }, () => {
      if (action === 'next') {
        data.privilege_id = 3;
        data.selected_privilege = data.admin_role.selected.key;
        data.phone = str_util.parseHandphone(data.phone);
        if (data.is_update) {
          this.updateAdmin(data);
        } else {
          this.postAdmin(data);
        }
      }
    });
  }

  postAdmin(body) {
    body.user_id = this.state.user_information.user_id;
    body.is_admin = true;
    request.onRequestData(1, '/post/admin', body, res_data => {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => {
          this.refreshData(body.type_action);
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    });
  }

  updateAdmin(body) {
    body.user_id = this.state.user_information.user_id;
    body.is_admin = true;
    request.onRequestData(1, '/update/admin', body, res_data => {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => {
          this.refreshData(body.type_action);
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    });
  }

  showAdminDialog(body_dialog) {
    let data = this.state.admin_role;
    let temp_admin_dialog = this.state.show_admin_dialog;
    temp_admin_dialog.show = !temp_admin_dialog.show;
    if (body_dialog === null) {
      body_dialog = {
        wholesaler_id : '',
        phone : '',
        access_code : '',
        is_update : false
      }
    } else {
      for (let i = 0; i < Object.keys(data.data).length; i++) {
        if (data.data[i].key === body_dialog.privilege_id) {
          data.selected = data.data[i];
          break;
        }
      }
      body_dialog.is_update = true;
    }
    body_dialog.admin_role = data;
    body_dialog.super_admin = true;
    temp_admin_dialog.body = JSON.stringify(body_dialog);
    this.setState({ show_admin_dialog : temp_admin_dialog });
  }

  onLeftHeaderActionClicked(position) {
    switch (this.state.header_left[position].key) {
      case 'refresh' :
        this.refreshData(true, false);
        break;
      case 'add':
        this.showAdminDialog(null);
        break;
      default :
        break;
    }
  }

  initLeftHeader(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <div onClick={this.onLeftHeaderActionClicked.bind(this, i)} key={data[i].key} className='container_image'>
          <img className='mini_image_b' src={require(data[i].image + '')} alt=''/>
        </div>
      );
    }
    return view;
  }

  onCheckboxClicked(is_header, position) {
    let temp_table_header = this.state.table_header;
    let temp_table_data = this.state.table_data;
    if (is_header) {
      temp_table_header.is_selected = !temp_table_header.is_selected;
      for (let i = 0; i < Object.keys(temp_table_data).length; i++) {
        temp_table_data[i].is_selected = temp_table_header.is_selected;
      }
      this.setState({
        table_header : temp_table_header,
        table_data : temp_table_data
      });
    } else {
      temp_table_data[position].is_selected = !temp_table_data[position].is_selected;
      if (!temp_table_data[position].is_selected && temp_table_header.is_selected) {
        temp_table_header.is_selected = !temp_table_header.is_selected;
        this.setState({
          table_header : temp_table_header,
          table_data : temp_table_data
        });
      } else {
        this.setState({
          table_data : temp_table_data
        });
      }
    }
  }

  initDataTable(data) {
    let view = [], i, i_found = 0;
    for (i = 0; i < Object.keys(data).length; i++) {
      if (data[i].show_data) {
        i_found++;
        view.push(
          <tr key={i}>
            <td className='whole_padding default_text_normal_gray'>{data[i].owner}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].retail_id}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].wholesaler_name}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].description}</td>
            <td className='whole_padding_d'>
              <div className='wrapper_product_action'>
                <div onClick={this.showAdminDialog.bind(this, data[i])} style={{width:80}} className='custom_padding_e label_search_container blue_background'>
                  <img className='mini_image_d' src={img_edit} alt=''/>
                  <div className='normal_padding_left_c default_text_normal_white'>Edit</div>
                </div>
              </div>
            </td>
          </tr>
        );
      }
    }
    if (i_found < 1) {
      view.push(view_util.getZeroRow(9));
    }
    return view;
  }

  initTableView(data) {
    return (
      <table className='table_container_retail'>
        <thead>
          <tr>
            <th className='whole_padding default_text_normal_gray main_column'>{data.owner}</th>
            <th className='whole_padding default_text_normal_gray'>{data.retail_id}</th>
            <th className='whole_padding default_text_normal_gray main_column'>{data.wholesaler_name}</th>
            <th className='whole_padding default_text_normal_gray'>{data.privilege_id}</th>
            <th className='whole_padding default_text_normal_gray'>Action</th>
          </tr>
        </thead>
        <tbody>
          {this.initDataTable(this.state.table_data)}
        </tbody>
      </table>
    );
  }

  requestAdminRetail(body, temp_pagination_array) {
    this.setState({
      is_loading : true
    }, () => {
      request.onRequestData(1, '/show/retail_admin', body, res_data => {
        if (res_data.code === 1) {
          if (temp_pagination_array == null) {
            temp_pagination_array = this.state.pagination_array;
            temp_pagination_array.push(res_data.result.res_data.pagination);
          }
          this.setState({
            is_loading : false,
            table_data : res_data.result.res_data.arrays,
            pagination : res_data.result.res_data.pagination,
            pagination_array : temp_pagination_array
          });
        } else {
          this.setState({
            is_loading : false,
            body_error : {
              is_show : true,
              message : res_data.message
            }
          });
        }
      });
    });
  }

  initSearchHeader() {
    return (
      <div className='search_root_container'>
        {/* <TableSearch header={this.state.header_search} table={this.state.table_data} onTableUpdate={(data_table) => this.setState({ table_data : data_table })} /> */}
        <div onClick={() => this.showDialogAdvance('open', this.state.dialog_advance.body)} className='container_image_b'>
          <img className='mini_image_b' src={img_setting} alt=''/>
        </div>
      </div>
    );
  }

  showDialogAdvance(action, body) {
    let temp = this.state.dialog_advance;
    temp.show = !temp.show;
    switch (action) {
      case 'next' :
        let temp_info = this.state.user_information;
        temp_info.filter = JSON.stringify(body);
        this.setState({
          user_information : temp_info,
          dialog_advance : temp
        }, () => { this.refreshData(true, true); });
        break;
      default :
        this.setState({ dialog_advance : temp });
        break;
    }
  }

  onPaginationClicked(index, data) {
    let temp_body = this.state.user_information;
    if (index === 9999 && data === null) {
      temp_body.idx = this.state.pagination.index;
    } else {
      temp_body.idx = this.state.pagination_array[index].index_before;
    }
    this.requestAdminRetail(temp_body, data);
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className='loading_container'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return (
          <div className='delivery_root'>
            <div className='header_container'>
              <div className='flex_left'>
                {this.initLeftHeader(this.state.header_left)}
              </div>
              <div className='flex_right'>
                { this.initSearchHeader() }
              </div>
            </div>
            {this.initTableView(this.state.table_header)}
            <Pagination pagination={this.state.pagination} pagination_array={this.state.pagination_array} onChangePage={(index, page_array) => this.onPaginationClicked(index, page_array)}/>

            { this.state.dialog_advance.show ? <AdvSearchDialog body={this.state.dialog_advance.body} onCloseListener={(action, body) => this.showDialogAdvance(action, body)}/> : null }
            { this.state.show_admin_dialog.show ? <AdminDialog data={this.state.show_admin_dialog.body} onCloseListener={(action, data) => this.onCloseAdmin(action, data)} /> : null }
            { this.showErrorDialog(this.state.body_error) }
            <LoadingDialog showDialog={this.state.is_loading} />
          </div>
        );
      } else {
        return (
          <div className='loading_container'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

}
