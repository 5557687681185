import React, { Component } from 'react';

import ErrorDialog from './ErrorDialog';

var view_util = require('../../utilities/ViewUtil');

export default class AddRetailDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      body_error : {
        is_show : false,
        message : ''
      },
      body : {
        access_code : '',
        phone : ''
      }
    }
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  onUpdateClicked() {
    try {
      if (this.state.body.access_code.length === 0) {
        throw Error('Kode Akses tidak boleh kosong.');
      }
      if (this.state.body.phone.length === 0) {
        throw Error('Nomor Handphone tidak boleh kosong.');
      }
      this.props.onCloseListener('next', this.state.body);
    } catch (e) {
      this.setState({
        body_error : {
          is_show : true,
          message : e.message
        }
      });
    }
  }

  render() {
    return (
      <div onClick={(e) => this.props.onCloseListener('close', null)} className='process_dialog_root layer_a'>
        <div onClick={(e) => e.stopPropagation()} className='default_dialog_container'>
          {this.showErrorDialog(this.state.body_error)}
          {view_util.getInputViewMinimal(this, 'access_code', 'Kode Akses')}
          {view_util.getInputViewMinimal(this, 'phone', 'Nomor Handphone')}
          <div className='button_process_wrapper_b'>
            <div onClick={() => this.props.onCloseListener('close', null)} className='default_text_normal_white_b red_background process_button_C'>Batal</div>
            <div style={{width : 20}}/>
            <div onClick={this.onUpdateClicked.bind(this)} className='default_text_normal_white_b green_background process_button_C'>Tambahkan</div>
          </div>
        </div>
      </div>
    );
  }

}
