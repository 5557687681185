import React, { Component } from 'react';

import gif_img from './resources/loading.gif';
import img_error from './resources/error.png';

var base64 = require('base-64');

var request = require('../utilities/NetworkRequest');
var str_util = require('../utilities/StringUtil');

export default class PrintProduct extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : true,
      is_error : false,
      error_message : '',
      data : {}
    }
  }

  componentDidMount() {
    try {
      let decoded_input = JSON.parse(base64.decode(this.props.match.params.data));
      let body = {
        wholesaler_id : decoded_input.wholesaler_id,
        doc_delivery : decoded_input.doc_delivery,
        driver_name : decoded_input.driver_name
      }
      request.onRequestData(1, '/print/product_delivery', body, res_data => {
        if (res_data.code === 1) {
          this.setState({
            is_main_loading : false,
            is_error : false,
            data : res_data.result
          });
        } else {
          this.setState({
            is_main_loading : false,
            is_error : true,
            error_message : res_data.message
          });
        }
      });
    } catch (e) {
      this.setState({
        is_main_loading : false,
        is_error : true,
        error_message : e.message
      });
    }
  }

  initProducts(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <tr key={i} className='special_tr_product'>
          <td className='whole_padding default_text_normal_gray'>{data[i].pcode_name}</td>
          <td className='whole_padding default_text_normal_gray'>{data[i].product_id}</td>
          <td className='whole_padding default_text_normal_gray'>{data[i].total_order}</td>
          <td/>
          <td className='whole_padding'><span className='default_text_normal_gray'>{data[i].qty_real_a_processed}</span> <i className='default_text_normal_gray_c'>{data[i].uom_a}</i></td>
          <td className='whole_padding'><span className='default_text_normal_gray'>{data[i].qty_real_b_processed}</span> <i className='default_text_normal_gray_c'>{data[i].uom_b}</i></td>
          <td id='special_algn_a' className='whole_padding default_text_normal_gray'>{str_util.formatSeparator(data[i].price.toString())}</td>
        </tr>
      );
    }
    view.push(
      <tr key='total_price' className='normal_top_border_b'>
        <td colSpan='3' className='special_td_data whole_padding default_text_normal_gray'>Total Harga</td>
        <td className='whole_padding default_text_normal_gray'>:</td>
        <td id='special_algn_a' colSpan='3' className='whole_padding default_text_normal_gray'>{str_util.formatSeparator(this.state.data.amount_price.toString())}</td>
        <td/>
      </tr>
    );
    return view;
  }

  initView(data) {
    return (
      <div className='print_root'>
        <div className='receipt_container'>
          <div className='bold_text whole_padding print_header_container'>REKAP PENGIRIMAN PRODUK</div>
          <table className='tbl_print_delivery_container'>
            <tbody>
              <tr>
                <td className='whole_padding default_text_normal_gray'>Nama Supir</td>
                <td className='whole_padding default_text_normal_gray'>:</td>
                <td className='whole_padding default_text_normal_gray'><b>{data.data.driver_name}</b></td>

                <td className='whole_padding default_text_normal_gray'>Dokumen Pengiriman</td>
                <td className='whole_padding default_text_normal_gray'>:</td>
                <td className='whole_padding default_text_normal_gray'><b>{data.data.doc_delivery}</b></td>
              </tr>
              <tr>
                <td className='whole_padding default_text_normal_gray'>Tanggal Kirim</td>
                <td className='whole_padding default_text_normal_gray'>:</td>
                <td colSpan={4} className='whole_padding default_text_normal_gray bold_text'>{data.data.date_delivery}</td>
              </tr>
            </tbody>
          </table>
          <div className='normal_padding_bottom_b side_padding_c default_text_normal_gray'>Halaman ini bertujuan untuk merekap seluruh produk dari setiap <i><b>sales order</b></i> yang akan dikirimkan.</div>
          <div className='normal_top_border vertical_padding_c side_margin default_text_normal_gray_b bold_text'>Daftar Pesanan</div>
          <table className='tbl_print_product_container'>
            <thead>
              <tr>
                <th className='whole_padding default_text_normal_gray'>Nama Produk</th>
                <th className='whole_padding default_text_normal_gray'>Kode Produk</th>
                <th className='whole_padding default_text_normal_gray'>Jumlah Pesanan</th>
                <th/>
                <th className='whole_padding default_text_normal_gray'>Satuan Besar</th>
                <th className='whole_padding default_text_normal_gray'>Satuan Kecil</th>
                <th id='special_algn_a' className='whole_padding default_text_normal_gray'>Harga</th>
              </tr>
            </thead>
            <tbody>
              {this.initProducts(data.products_change)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className='loading_container_b'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return(
          <div className='print_root'>
            {this.initView(this.state.data)}
          </div>
        );
      } else {
        return (
          <div className='loading_container_b'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

}
