import React, { Component } from 'react';

import WholesalerDialog from './dialog/WholesalerDialog';

import gif_img from '../resources/loading.gif';
import img_error from '../resources/error.png';

var request = require('../../utilities/NetworkRequest');
var cache_util = require('../../utilities/CacheUtil');

export default class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : false,
      is_loading : false,
      is_error : false,
      error_message : '',
      body_error : {
        is_show : false,
        message : ''
      },
      wholesaler_dialog : {
        is_show : false,
        data : null
      },
      user_information : {},
      dashboard_data : null
    }
  }

  componentDidMount() {
    if (this.props.data === undefined) {
      this.refreshData();
    } else {
      this.setState({
        user_information : this.props.data.user_information,
        dashboard_data : this.props.data.admin_view
      });
    }
  }

  refreshData() {
    this.setState({ is_main_loading : true }, () => {
      let decoded_input = cache_util.onGetCacheData(cache_util.CACHE_INFO).result.user_information;
      request.onRequestData(1, '/show/home', decoded_input, res_data => {
        if (res_data.code === 1) {
          this.setState({
            is_main_loading : false,
            user_information : decoded_input,
            dashboard_data : res_data.result.admin_view
          });
        } else {
          this.setState({
            is_main_loading : false,
            is_error : true,
            error_message : res_data.message
          });
        }
      });
    });
  }

  onCloseAdminWholesaler() {
    let temp = this.state.wholesaler_dialog;
    temp.is_show = false;
    this.setState({ wholesaler_dialog : temp });
  }

  showAdminWholesalerDialog() {
    if (this.state.wholesaler_dialog.is_show) {
      return( <WholesalerDialog body={null} onCloseListener={() => this.onCloseAdminWholesaler()} /> );
    } else { return null; }
  }

  onAdminActionClicked(data) {
    let temp;
    switch (data.key) {
      case 'wholesaler':
        temp = this.state.wholesaler_dialog;
        temp.is_show = !temp.is_show;
        this.setState({ wholesaler_dialog : temp });
        break;
      default:
        alert('Menu ini masih dalam tahap pengembangan.');
        break;
    }
  }

  initAdminAction(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <div key={i} onClick={this.onAdminActionClicked.bind(this, data[i])} className='wrapper_admin_act'>
          <img className='big_image_c' src={require('' + data[0].image)} alt=''/>
          <div className='default_text_big_white normal_margin_top_e bold_text'>{data[0].label}</div>
        </div>
      );
    }
    return view;
  }

  initViewDashboard(data) {
    if (data !== undefined) {
      if (data === null) {
        return null;
      } else {
        return (
          <div className='admin_root'>
            { this.showAdminWholesalerDialog() }
            <div className='dashboard_header default_text_medium_gray normal_border_bottom'>Selamat Datang Admin, <i><b>{data.sub_home.wholesaler_name} ({this.state.user_information.user_id})</b></i></div>
            <div className='admin_wrapper'>
              { this.initAdminAction(data.admin_action) }
            </div>
          </div>
        );
      }
    } else { return null; }
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className='loading_container'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return this.initViewDashboard(this.state.dashboard_data);
      } else {
        return (
          <div className='loading_container'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

}
