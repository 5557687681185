import React, { Component } from 'react';

// import Dropdown from './native/Dropdown';
import DropdownSearch from './native/DropdownSearch';

import gif_img from './resources/loading.gif';
import img_error from './resources/error.png';

var base64 = require('base-64');
var request = require('../utilities/NetworkRequest');
var str_util = require('../utilities/StringUtil');
var cache_util = require('../utilities/CacheUtil');

function initPrintMargin() {
  let data = [];
  for (let i = 0; i <= 20; i+= 0.5) {
    data.push({
      key : `margin_${i}mm`,
      value : {margin: `0mm 0mm 0mm ${i}mm`},
      label : `Margin ${i} MM`
    });
  }
  return data;
}

function initFontSize() {
  let data = [];
  for (let i = 5; i <= 20; i++) {
    data.push({
      key : `${i}pt`,
      value : {fontSize:`${i}pt`},
      label : `Ukuran Text ${i} Pt`
    });
  }
  return data;
}

function initPrintPaper() {
  let data = [{
    key : 'auto',
    value : {width:'auto'},
    label : 'Lebar Auto'
  }];
  for (let i = 20; i <= 200; i++) {
    data.push({
      key : `${i}mm`,
      value : {width:`${i}mm`},
      label : `Lebar Kertas ${i} MM`
    });
  }
  return data;
}

function initPrintWord() {
  let data = [];
  for (let i = 10; i <= 200; i++) {
    data.push({
      key : i,
      label : `Panjang ${i} Kata`
    });
  }
  return data;
}

export default class PrintMiniOrderOld extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data : [],
      stt_str_obj : '',
      selected_print_margin : {
        key : 'margin_5mm',
        value : {margin: '0mm 5mm 0mm 5mm'},
        label : 'Margin 5 MM'
      },
      stt_print_margin : {
        selected : {
          key : 'margin_5mm',
          value : {margin: '0mm 5mm 0mm 5mm'},
          label : 'Margin 5 MM'
        },
        text : 'category_margin',
        is_expand : false,
        data : initPrintMargin()
      },
      selected_print_fsize : {
        key : '6pt',
        value : {fontSize: '6.1pt'},
        label : 'Ukuran Text 6 Pt'
      },
      stt_print_fsize : {
        selected : {
          key : '6pt',
          value : {fontSize: '6.1pt'},
          label : 'Ukuran Text 6 Pt'
        },
        text : 'category_fsize',
        is_expand : false,
        data : initFontSize()
      },
      selected_print_paper : {
        key : 'auto',
        value : {width:'auto'},
        label : 'Lebar Auto'
      },
      stt_print_paper : {
        selected : {
          key : 'auto',
          value : {width:'auto'},
          label : 'Lebar Auto'
        },
        text : 'category_paper',
        is_expand : false,
        data : initPrintPaper()
      },
      stt_print_word : {
        selected : {
          key : 37,
          label : 'Panjang 37 Kata'
        },
        text : 'category_word',
        is_expand : false,
        data : initPrintWord()
      },
      is_main_loading : true,
      is_error : false,
      error_message : ''
    }
  }

  componentDidMount() {
    try {
      // GET PREVIOUS SETTING
      let prev_setting = cache_util.onGetCacheData(cache_util.CACHE_PRINT_SETTING);
      let _selected_print_margin = this.state.selected_print_margin;
      let _stt_print_margin = this.state.stt_print_margin;
      let _selected_print_fsize = this.state.selected_print_fsize;
      let _stt_print_fsize = this.state.stt_print_fsize;
      let _selected_print_paper = this.state.selected_print_paper;
      let _stt_print_paper = this.state.stt_print_paper;
      let _stt_print_word = this.state.stt_print_word;
      if (prev_setting !== null) {
        _selected_print_margin = prev_setting.selected_print_margin;
        _stt_print_margin.selected = prev_setting.selected_print_margin;
        _selected_print_fsize = prev_setting.selected_print_fsize;
        _stt_print_fsize.selected = prev_setting.selected_print_fsize;
        _selected_print_paper = prev_setting.selected_print_paper;
        _stt_print_paper.selected = prev_setting.selected_print_paper;
        _stt_print_word.selected = prev_setting.selected_print_word;
      }
      // GET ORDER
      let decoded_input = JSON.parse(base64.decode(this.props.match.params.data));
      let body = {
        wholesaler_id : decoded_input.user_information.wholesaler_id,
        order_number : JSON.stringify(decoded_input.set_id.split('#')),
        page_id : decoded_input.view_id
      }
      request.onRequestData(request.OPTION_API_MAIN, '/print/order_old', body, res_data => {
        if (res_data.code === 1) {
          let temp_stt = this.initReceiptOrder(res_data.result);
          this.setState({
            is_main_loading : false,
            is_error : false,
            data : res_data.result,
            stt_str_obj : temp_stt,
            selected_print_margin : _selected_print_margin,
            stt_print_margin : _stt_print_margin,
            selected_print_fsize : _selected_print_fsize,
            stt_print_fsize : _stt_print_fsize,
            selected_print_paper : _selected_print_paper,
            stt_print_paper : _stt_print_paper,
            stt_print_word : _stt_print_word
          });
        } else {
          this.setState({
            is_main_loading : false,
            is_error : true,
            error_message : res_data.message
          });
        }
      });
    } catch (e) {
      this.setState({
        is_main_loading : false,
        is_error : true,
        error_message : e.message
      });
    }
  }

  getUnitValue(data) {
    if (Number(data.qty_real_order) > 0) {
      return Math.trunc(Number(data.amount_sales_order)/Number(data.qty_real_order));
    } else {
      return 0;
    }
  }

  printTextDivider(data) {
    let temp_arr = [];
    for (let i = 0; i < data.length; i++) {
      temp_arr.push(data.text);
    }
    return temp_arr.join('');
  }

  printTextContinue(data) {
    // PREPARE TEXT
    let i, j;
    let txt_arr = [];
    let idx_spr = 0;
    let txt_split = data.text.split('');
    for (i = 0; i < txt_split.length; i++) {
      if (i !== 0 && i % data.length === 0) {
        idx_spr++;
      }
      if (txt_arr[idx_spr] === undefined) {
        // INITIATE NEW ARRAY
        txt_arr[idx_spr] = [];
      }
      txt_arr[idx_spr].push(txt_split[i]);
    }
    // SET FINAL ARRAY
    let final = [], start_txt, stopper;
    for (i = 0; i < txt_arr.length; i++) {

      // SET ALLIGNMENT
      if (data.allignment === 'center') {
        // CENTER ALLIGNMENT
        start_txt = (Math.floor(data.length / 2)) - (Math.floor(txt_arr[i].length / 2));
      } else if (data.allignment === 'left') {
        // LEFT ALLIGNMENT
        start_txt = 0;
      }

      stopper = 0;
      if (final[i] === undefined) {
        // INITIATE NEW ARRAY FINAL
        final[i] = [];
      }
      // WRITE ARRAY TEXT
      for (j = 0; j < data.length; j++) {
        if (j >= start_txt && txt_arr[i][stopper] !== undefined) {
          final[i].push(txt_arr[i][stopper]);
          stopper++;
        } else {
          final[i].push(' ');
        }
      }
    }
    // FINAL EXECUTION
    let temp_arr = [];
    for (i = 0; i < final.length; i++) {
      temp_arr.push(final[i].join(''));
    }
    return temp_arr.join('\n');
  }

  printTextDivision(data) {
    // INITIAL ARRAY
    let temp_arr = [], i;
    for (i = 0; i < data.length; i++) {
      temp_arr.push(' ');
    }
    let txt_split_r = data.text_r.split('');
    let txt_split_l = data.text_l.split('');
    // UPDATE RIGHT ALLIGNTMENT
    let start_r = data.length - txt_split_r.length;
    let stopper = 0;
    for (i = start_r; i < temp_arr.length; i++) {
      temp_arr[i] = txt_split_r[stopper];
      stopper++;
    }
    // UPDATE LEFT ALLIGNMENT (Give space 1 character on front for distinct)
    stopper = 0;
    let end_l = txt_split_l.length + data.skip;
    if (end_l >= start_r) {
      end_l = start_r-1;
    }
    for (i = data.skip; i < end_l; i++) {
      temp_arr[i] = txt_split_l[stopper];
      stopper++;
    }
    return temp_arr.join('');
  }

  getUnitMeasurement(data, j) {
    if (data.items[j].uom_a === data.items[j].uom_b) {
      return data.items[j].qty_real_a_processed + ' ' + data.items[j].uom_a;
    } else {
      return data.items[j].qty_real_a_processed + ' ' + data.items[j].uom_a + ' ' + data.items[j].qty_real_b_processed + ' ' + data.items[j].uom_b;
    }
  }

  initMiniReceipt(idx, data) {
    let m_arr_txt = [];
    m_arr_txt.push(
      this.printTextDivider({
        text : '-',
        length : this.state.stt_print_word.selected.key
      }),
      this.printTextContinue({
        text : 'SALES ORDER',
        allignment : 'center',
        length : this.state.stt_print_word.selected.key
      }),
      this.printTextContinue({
        text : data.wholesaler_name,
        allignment : 'center',
        length : this.state.stt_print_word.selected.key
      }),
      this.printTextContinue({
        text : data.order_no,
        allignment : 'center',
        length : this.state.stt_print_word.selected.key
      }),
      this.printTextContinue({
        text : data.retail_name,
        allignment : 'center',
        length : this.state.stt_print_word.selected.key
      }),
      this.printTextDivider({
        text : ' ',
        length : this.state.stt_print_word.selected.key
      })
    );
    let product_price = 0, total_payments = 0, j;
    for (j = 0; j < Object.keys(data.items).length; j++) {
      product_price += Number(data.items[j].amount_sales_order);
      m_arr_txt.push(
        this.printTextContinue({
          text : `${data.items[j].pcode_name} ~ ${this.getUnitMeasurement(data, j)}`,
          allignment : 'left',
          length : this.state.stt_print_word.selected.key
        }),
        this.printTextDivision({
          text_r : str_util.formatSeparator(data.items[j].amount_sales_order),
          text_l : `${data.items[j].qty_real_order.toString()} X ${str_util.formatSeparator( this.getUnitValue(data.items[j]).toString() )}`,
          skip : 1,
          length : this.state.stt_print_word.selected.key
        })
      );
    }
    m_arr_txt.push(
      this.printTextDivider({
        text : ' ',
        length : this.state.stt_print_word.selected.key
      }),
      this.printTextDivision({
        text_r : str_util.formatSeparator(product_price.toString()),
        text_l : 'Harga Produk',
        skip : 0,
        length : this.state.stt_print_word.selected.key
      }),
      this.printTextDivision({
        text_r : str_util.formatSeparator(data.delivery_price.toString()),
        text_l : 'Biaya Layanan Aplikasi',
        skip : 0,
        length : this.state.stt_print_word.selected.key
      })
    )
    total_payments += product_price + Number(data.delivery_price);
    // ADMIN FEE
    if (Number(data.vendor_admin) > 0) {
      m_arr_txt.push(this.printTextDivision({
        text_r : str_util.formatSeparator(data.vendor_admin.toString()),
        text_l : 'Biaya Admin',
        skip : 0,
        length : this.state.stt_print_word.selected.key
      }));
      total_payments += Number(data.vendor_admin);
    }
    // VOUCHER
    if (Number(data.voucher_price) > 0) {
      m_arr_txt.push(this.printTextDivision({
        text_r : `-${str_util.formatSeparator(data.voucher_price.toString())}`,
        text_l : data.voucher_title,
        skip : 0,
        length : this.state.stt_print_word.selected.key
      }));
      total_payments -= Number(data.voucher_price);
    }
    // PAYMENTS
    for (j = 0; j < Object.keys(data.payments).length; j++) {
      if (data.payments[j].is_minus) {
        total_payments -= Number(data.payments[j].amount_sales);
        m_arr_txt.push(this.printTextDivision({
          text_r : `-${str_util.formatSeparator(data.payments[j].amount_sales.toString())}`,
          text_l : data.payments[j].description,
          skip : 0,
          length : this.state.stt_print_word.selected.key
        }));
      } else {
        m_arr_txt.push(this.printTextDivision({
          text_r : str_util.formatSeparator(data.payments[j].amount_sales.toString()),
          text_l : data.payments[j].description,
          skip : 0,
          length : this.state.stt_print_word.selected.key
        }));
      }
    }
    // TOTAL PURCHASE
    m_arr_txt.push(
      this.printTextDivision({
        text_r : str_util.formatSeparator(total_payments.toString()),
        text_l : 'Total Bayar',
        skip : 0,
        length : this.state.stt_print_word.selected.key
      }),
      this.printTextDivider({
        text : ' ',
        length : this.state.stt_print_word.selected.key
      })
    );
    // POINT
    if (Object.keys(data.points).length > 0) {
      for (j = 0; j < Object.keys(data.points).length; j++) {
        m_arr_txt.push(this.printTextContinue({
          text : `*${data.points[j].description} (${data.print_date}) = ${str_util.formatSeparator(data.points[j].point.toString())}`,
          allignment : 'left',
          length : this.state.stt_print_word.selected.key
        }));
      }
    }
    // LAST INFORMATION
    m_arr_txt.push(
      this.printTextDivider({
        text : ' ',
        length : this.state.stt_print_word.selected.key
      }),
      this.printTextContinue({
        text : '*Dokumen ini merupakan pencatatan pesanan, bukan dokumen faktur pesanan.',
        allignment : 'left',
        length : this.state.stt_print_word.selected.key
      }),
      this.printTextDivider({
        text : '-',
        length : this.state.stt_print_word.selected.key
      })
    );
    return m_arr_txt.join('\n');
  }

  initReceiptOrder(data) {
    let temp_stt_str_obj = '';
    for (let i = 0; i < Object.keys(data).length; i++) {
      temp_stt_str_obj += `${this.initMiniReceipt(i, data[i])}\n`;
    }
    return temp_stt_str_obj;
  }

  onRefreshPage() {
    let cache_obj = {
      selected_print_margin : this.state.stt_print_margin.selected,
      selected_print_fsize : this.state.stt_print_fsize.selected,
      selected_print_paper : this.state.stt_print_paper.selected,
      selected_print_word : this.state.stt_print_word.selected,
    }
    cache_util.onSaveCache(cache_util.CACHE_PRINT_SETTING, JSON.stringify(cache_obj));
    let temp = this.initReceiptOrder(this.state.data);
    this.setState({
      stt_str_obj : temp,
      selected_print_fsize : this.state.stt_print_fsize.selected,
      selected_print_paper : this.state.stt_print_paper.selected,
      selected_print_margin : this.state.stt_print_margin.selected
    });
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className='loading_container_b'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return (
          <div style={this.state.selected_print_paper.value} className='print_mini_container' >
            <div className='print_action hide'>
              <div className='wrapper_product_action'>
                <DropdownSearch data={this.state.stt_print_word}/>
                <div className='normal_margin_left'>
                  <DropdownSearch className='normal_margin_left' data={this.state.stt_print_paper}/>
                </div>
              </div>
              <div className='wrapper_product_action normal_margin_top_e'>
                <DropdownSearch data={this.state.stt_print_fsize}/>
                <div className='normal_margin_left'>
                  <DropdownSearch data={this.state.stt_print_margin}/>
                </div>
              </div>
              <div className='normal_margin_top_h wrapper_product_action full_flex'>
                <div className='process_button_f'/>
                <div onClick={this.onRefreshPage.bind(this)} className='action_button process_button_b normal_margin_left orange_background default_text_normal_white'>Perbaharui Halaman</div>
              </div>
            </div>
            <div style={{...this.state.selected_print_margin.value, ...this.state.selected_print_fsize.value}} className='print_mini_root'><pre>{this.state.stt_str_obj}</pre></div>
          </div>
        );
      } else {
        return (
          <div className='loading_container_b'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

}
