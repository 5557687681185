import React, { Component } from 'react';

import LoadingDialog from './LoadingDialog';
import ErrorDialog from './ErrorDialog';

var view_util = require('../../utilities/ViewUtil');
var request = require('../../utilities/NetworkRequest');

export default class EditTicketDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_loading : false,
      body_error : {
        is_show : false,
        message : ''
      },
      root_data : this.props.root_data,
      data : this.props.body
    }
  }

  onCloseDialog(is_updated) {
    this.props.onCloseListener(is_updated);
  }

  initHeaderView(data) {
    return (
      <table className='table_header_process_container'>
        <tbody>
          <tr>
            <td className='whole_padding default_text_normal_gray'>Nomor Dokumen</td>
            <td className='whole_padding default_text_normal_gray'>:</td>
            <td className='whole_padding default_text_normal_gray bold_text'>{data.doc_delivery}</td>
          </tr>
          <tr>
            <td className='whole_padding default_text_normal_gray'>Nama Supir</td>
            <td className='whole_padding default_text_normal_gray'>:</td>
            <td className='whole_padding default_text_normal_gray bold_text'>{data.driver_name}</td>
          </tr>
          <tr>
            <td className='whole_padding default_text_normal_gray'>Waktu Jalan</td>
            <td className='whole_padding default_text_normal_gray'>:</td>
            <td className='whole_padding default_text_normal_gray bold_text'>{data.time_delivery}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  getSelectedRowStyle(is_selected) {
    if (is_selected) {
      return 'custom_cursor_a gray_background';
    } else {
      return 'custom_cursor_a';
    }
  }

  onOrderClicked(TAG, position) {
    let temp_root = this.state.data;
    let temp_data = temp_root[TAG];
    for (let i = 0; i < Object.keys(temp_data).length; i++) {
      if (i === position) {
        temp_data[i].is_selected = true;
      } else {
        temp_data[i].is_selected = false;
      }
    }
    this.setState({ data : temp_root });
  }

  populateOrder(TAG, data) {
    let view = [], i;
    for (i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <tr className={this.getSelectedRowStyle(data[i].is_selected)} onClick={this.onOrderClicked.bind(this, TAG, i)} key={i}>
          <td className='whole_padding default_text_normal_gray'>{data[i].retail_name}</td>
          <td className='whole_padding default_text_normal_gray'>-</td>
          <td className='whole_padding default_text_normal_gray'>{data[i].order_no} <b>({data[i].c_item} Item)</b></td>
        </tr>
      );
    }
    if (i < 1) {
      view.push(view_util.getZeroRow(1));
    }
    return view;
  }

  onChangeAssignment(TAG, TAG_TARGET, is_all) {
    let temp_data = this.state.data, i;
    for (i = 0; i < Object.keys(temp_data[TAG]).length; i++) {
      if (temp_data[TAG][i].is_selected || is_all) {
        temp_data[TAG][i].is_selected = false;
        temp_data[TAG_TARGET].push(temp_data[TAG][i]);
        if (!is_all) {
          temp_data[TAG].splice(i, 1);
          break;
        }
      }
    }
    if (is_all) {
      temp_data[TAG] = [];
    }
    this.setState({ data : temp_data });
  }

  initTableSelector(data) {
    return (
      <div className='table_selector_wrapper'>
        <div className='normal_margin_left_b table_selector_container'>
          <table className='table_selector'>
            <thead>
              <tr>
                <th colSpan={'3'} className='whole_padding default_text_normal_white'>Pesanan Tersedia</th>
              </tr>
            </thead>
            <tbody>
              {this.populateOrder('available', data.available)}
            </tbody>
          </table>
        </div>
        <div className='selector_action side_margin_b'>
          <div onClick={this.onChangeAssignment.bind(this, 'available', 'selected', true)} className='custom_cursor_a add_all_selector'>
            <div className='arrow_right_b'/>
            <div className='arrow_right_b'/>
          </div>
          <div onClick={this.onChangeAssignment.bind(this, 'selected', 'available', true)} className='normal_margin_top_f custom_cursor_a add_all_selector'>
            <div className='arrow_left_b'/>
            <div className='arrow_left_b'/>
          </div>
          <div onClick={this.onChangeAssignment.bind(this, 'available', 'selected', false)} className='custom_cursor_a arrow_right normal_margin_top_g'/>
          <div onClick={this.onChangeAssignment.bind(this, 'selected', 'available', false)} className='custom_cursor_a arrow_left normal_margin_top_f'/>
        </div>
        <div className='normal_margin_right table_selector_container'>
          <table className='table_selector'>
            <thead>
              <tr>
                <th colSpan={'3'} className='whole_padding default_text_normal_white'>Pesanan Dikirimkan</th>
              </tr>
            </thead>
            <tbody>
              {this.populateOrder('selected', data.selected)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  onSaveClicked() {
    this.setState({ is_loading : true }, () => {
      let body = {
        wholesaler_id : this.state.root_data.wholesaler_id,
        doc_delivery : this.state.root_data.doc_delivery,
        set_available : JSON.stringify(this.state.data.available),
        set_selected : JSON.stringify(this.state.data.selected)
      }
      request.onRequestData(request.OPTION_API_MAIN, '/post/ticket_editor', body, res_data => {
        if (res_data.code === 1) {
          this.setState({ is_loading : false }, () => {
            this.onCloseDialog(true);
          });
        } else {
          this.setState({
            is_loading : false,
            body_error : {
              is_show : true,
              message : res_data.message
            }
          });
        }
      });
    });
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  render() {
    return (
      <div onClick={this.onCloseDialog.bind(this, false)} className='process_dialog_root layer_a'>
        <div onClick={(e) => e.stopPropagation()} className='process_dialog_container'>
          <LoadingDialog showDialog={this.state.is_loading}/>
          {this.showErrorDialog(this.state.body_error)}
          <div className='process_dialog_title default_text_medium_white orange_background bold_text'>DETAIL PENGIRIMAN</div>
          <div className='process_dialog_page'>
            {this.initHeaderView(this.state.data.data)}
            {this.initTableSelector(this.state.data)}
            <div className='whole_padding default_text_normal_red'>* Supir yang bersangkutan akan memperoleh tugas pengantaran pesanannya masing-masing, sesuai pada kolom "Pesanan Dikirimkan"</div>
            <div className='button_process_wrapper'>
              <div onClick={this.onSaveClicked.bind(this)} className='default_text_normal_white_b green_background process_button'>Simpan</div>
              <div style={{width : 20}}/>
              <div onClick={this.onCloseDialog.bind(this, false)} className='default_text_normal_white_b red_background process_button'>Tutup</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}
