import React, { Component } from 'react';

import ErrorDialog from './ErrorDialog';

export default class CustomerMigrationDialog extends Component {

  constructor(props) {
    super(props);
    let is_admin = this.props.is_admin;
    if (is_admin === undefined) { is_admin = false; }
    this.state = {
      is_admin : is_admin,
      body_error : {
        is_show : false,
        message : ''
      },
      body : {
        access_code : '',
        new_wholesaler : '',
        old_wholesaler : ''
      }
    }
  }

  onEditTextChanged(event, action) {
    let temp_data = this.state.body;
    temp_data[action] = event.target.value;
    this.setState({ body : temp_data });
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  onUpdateClicked() {
    try {
      if (this.state.body.access_code.length === 0) { throw Error('Kode Referensi tidak boleh kosong.'); }
      if (this.state.body.new_wholesaler.length === 0) { throw Error('ID Grosir tidak boleh kosong.'); }
      this.props.onCloseListener('next', this.state.body);
    } catch (e) {
      this.setState({
        body_error : {
          is_show : true,
          message : e.message
        }
      });
    }
  }

  render() {
    return (
      <div onClick={(e) => this.props.onCloseListener('close', null)} className='process_dialog_root layer_a'>
        <div onClick={(e) => e.stopPropagation()} className='add_retail_dialog_container'>
          {this.showErrorDialog(this.state.body_error)}
          <div className='default_text_normal_gray bold_text'>Kode Grosir Lama</div>
          <input onChange={ (event) => this.onEditTextChanged(event, 'old_wholesaler') } value={this.state.body.old_wholesaler} style={{width:250, marginBottom : 10}} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input' type='text' />
          <div className='default_text_normal_gray bold_text'>Kode Grosir Baru</div>
          <input onChange={ (event) => this.onEditTextChanged(event, 'new_wholesaler') } value={this.state.body.new_wholesaler} style={{width:250}} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input' type='text' />
          <div className='normal_margin_top_e default_text_normal_gray bold_text'>Kode Referensi Grosir Baru</div>
          <input onChange={ (event) => this.onEditTextChanged(event, 'access_code') } value={this.state.body.access_code} style={{width:250}} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input' type='text' />
          <div className='button_process_wrapper_b'>
            <div onClick={() => this.props.onCloseListener('close', null)} className='default_text_normal_white_b red_background process_button_C'>Batal</div>
            <div style={{width : 20}}/>
            <div onClick={this.onUpdateClicked.bind(this)} className='default_text_normal_white_b green_background process_button_C'>Pindahkan</div>
          </div>
        </div>
      </div>
    );
  }

}
