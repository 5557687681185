import React, { Component } from 'react';

import gif_img from './resources/loading.gif';
import img_error from './resources/error.png';

var base64 = require('base-64');

var request = require('../utilities/NetworkRequest');
var str_util = require('../utilities/StringUtil');

export default class PrintDelivery extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : true,
      is_error : false,
      error_message : '',
      data : {}
    }
  }

  componentDidMount() {
    try {
      let decoded_input = JSON.parse(base64.decode(this.props.match.params.data));
      let body = {
        wholesaler_id : decoded_input.wholesaler_id,
        doc_delivery : decoded_input.doc_delivery,
        driver_name : decoded_input.driver_name
      }
      request.onRequestData(1, '/print/doc_delivery', body, res_data => {
        if (res_data.code === 1) {
          this.setState({
            is_main_loading : false,
            is_error : false,
            data : res_data.result
          });
        } else {
          this.setState({
            is_main_loading : false,
            is_error : true,
            error_message : res_data.message
          });
        }
      });
    } catch (e) {
      this.setState({
        is_main_loading : false,
        is_error : true,
        error_message : e.message
      });
    }
  }

  initOrders(data) {
    let view = [], temp_payments = {}, total = 0, i;
    for (i = 0; i < Object.keys(data).length; i++) {
      total += Number(data[i].price_total);
      // payment_1 -> for key id ; description_1 -> for description key
      if (!temp_payments.hasOwnProperty('payment_' + data[i].payment_method)) {
        temp_payments['payment_' + data[i].payment_method] = Number(data[i].price_total);
        temp_payments['description_' + data[i].payment_method] = data[i].description;
      } else {
        temp_payments['payment_' + data[i].payment_method] += Number(data[i].price_total);
      }
      view.push(
        <tr key={i} className='special_tr_product'>
          <td className='whole_padding default_text_normal_gray'>{data[i].retail_name}</td>
          <td className='whole_padding default_text_normal_gray'>{data[i].order_no}</td>
          <td className='whole_padding default_text_normal_gray'>{data[i].inv_ref}</td>
          <td className='whole_padding default_text_normal_gray'>{data[i].description}</td>
          <td/>
          <td className='whole_padding default_text_normal_gray'>{data[i].c_order}</td>
          <td id='special_algn_a' className='whole_padding default_text_normal_gray'>{str_util.formatSeparator(data[i].price_total)}</td>
          <td className='side_padding_b default_text_normal_gray'><div className='print_signed_border'/></td>
        </tr>
      );
    }
    view.push(
      <tr key='total_price' className='normal_border_bottom'>
        <td colSpan='4' className='special_td_data whole_padding default_text_normal_gray'>Total Harga</td>
        <td className='whole_padding default_text_normal_gray'>:</td>
        <td id='special_algn_a' colSpan='2' className='whole_padding default_text_normal_gray'>{str_util.formatSeparator(total.toString())}</td>
        <td/>
      </tr>
    );
    let key_payments = Object.keys(temp_payments), key_id;
    for (i = 0; i < key_payments.length; i++) {
      if (key_payments[i].substring(0,8) === 'payment_') {
        key_id = key_payments[i].substring(8, key_payments[i].length);
        view.push(
          <tr key={'key_' + i}>
            <td colSpan='4' className='special_td_data whole_padding default_text_normal_gray'>{temp_payments['description_' + key_id]}</td>
            <td className='whole_padding default_text_normal_gray'>:</td>
            <td id='special_algn_a' colSpan='2' className='whole_padding default_text_normal_gray'>{str_util.formatSeparator(temp_payments['payment_' + key_id].toString())}</td>
            <td/>
          </tr>
        );
      }
    }
    return view;
  }

  initView(data) {
    return (
      <div className='receipt_container'>
        <div className='bold_text whole_padding print_header_container'>REKAP FAKTUR PENGIRIMAN</div>
        <table className='tbl_print_delivery_container'>
          <tbody>
            <tr>
              <td className='whole_padding default_text_normal_gray'>Nama Supir</td>
              <td className='whole_padding default_text_normal_gray'>:</td>
              <td className='whole_padding default_text_normal_gray'><b>{data.data.driver_name}</b></td>

              <td className='whole_padding default_text_normal_gray'>Dokumen Pengiriman</td>
              <td className='whole_padding default_text_normal_gray'>:</td>
              <td className='whole_padding default_text_normal_gray'><b>{data.data.doc_delivery}</b></td>
            </tr>
            <tr>
              <td className='whole_padding default_text_normal_gray'>Tanggal Kirim</td>
              <td className='whole_padding default_text_normal_gray'>:</td>
              <td colSpan={4} className='whole_padding default_text_normal_gray bold_text'>{data.data.date_delivery}</td>
            </tr>
          </tbody>
        </table>
        <div className='normal_padding_bottom_b side_padding_c default_text_normal_gray'>Pastikan pada setiap pengantaran barang, anda harus meminta <b>tanda tangan</b> dari masing-masing pemilik toko sebagai tanda dokumentasi yang sah.</div>
        <div className='normal_top_border vertical_padding_c side_margin default_text_normal_gray_b bold_text'>Daftar Pesanan</div>
        <table className='tbl_print_data_container'>
          <thead>
            <tr>
              <th className='whole_padding default_text_normal_gray'>Nama Toko</th>
              <th className='whole_padding default_text_normal_gray'>Nomor Pesanan</th>
              <th className='whole_padding default_text_normal_gray'>Nomor Referensi</th>
              <th className='whole_padding default_text_normal_gray'>Pembayaran</th>
              <th/>
              <th className='whole_padding default_text_normal_gray'>Jumlah Item</th>
              <th id='special_algn_a' className='whole_padding default_text_normal_gray'>Harga</th>
              <th className='whole_padding default_text_normal_gray'>TTD</th>
            </tr>
          </thead>
          <tbody>
            {this.initOrders(data.set_orders)}

            <tr style={{height:30}}/>
            <tr>
              <td colSpan='2'/>
              <td colSpan='6' className='whole_padding default_text_normal_gray'>Yang menyetujui, {data.data.date_delivery}</td>
            </tr>
            <tr style={{height:60}}/>

            <tr>
              <td colSpan='2'/>
              <td colSpan='2' className='normal_top_border_b whole_padding default_text_normal_gray'>Penanggung Jawab Grosir</td>
              <td/>
              <td id='special_algn_b' colSpan='3' className='normal_top_border_b whole_padding default_text_normal_gray'>Pengantar</td>
            </tr>

          </tbody>
        </table>
      </div>
    );
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className='loading_container_b'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return(
          <div className='print_root'>
            {this.initView(this.state.data)}
          </div>
        );
      } else {
        return (
          <div className='loading_container_b'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

}
