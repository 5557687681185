import React, { Component } from 'react';

import img_expand_more from '../resources/baseline_expand_more.png';

export default class Dropdown extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_active : this.props.is_active === undefined ? true : this.props.is_active,
      data : this.props.data
    }
  }

  onDropdownSelected(position) {
    let temp = this.state.data;
    for (let i = 0; i < Object.keys(temp).length; i++) {
      temp.selected = temp.data[position];
    }
    this.setState({ data : temp }, () => {
      if (this.props.listener_change !== undefined) {
        if (this.props.listener_change !== null) {
          this.props.listener_change(temp.data[position]);
        }
      }
    });
  }

  populateDropdownItem() {
    let temp = this.state.data.data;
    let view = [];
    for (let i = 0; i < Object.keys(temp).length; i++) {
      view.push(
        <div onClick={this.onDropdownSelected.bind(this, i)} key={temp[i].key} className='side_padding vertical_padding_b default_text_normal_gray'>{temp[i].label}</div>
      );
    }
    return view;
  }

  showChooserDropdown() {
    if (this.state.data.is_expand && this.state.is_active) {
      return(
        <div className='dropdown_wrapper layer_a normal_margin_top_b'>
          <div className='arrow-up'/>
          <div className='dropdown_content_b white_background'>
            {this.populateDropdownItem()}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  onDropdownClicked(data) {
    data.is_expand = !data.is_expand;
    this.setState({ data : data });
  }

  getAdditionalClass() {
    let class_name = 'dropdown_container_category';
    if (this.props.className !== undefined) {
      class_name += ' ' + this.props.className;
    }
    return class_name;
  }

  render() {
    return (
      <div onClick={this.onDropdownClicked.bind(this, this.state.data)} className={this.getAdditionalClass()}>
        <div className='dropdown_chooser_category'>
          <div className='full_flex default_text_normal_gray'>{this.state.data.selected.label}</div>
          <div className='center_content circle_background_b'>
            <img className='mini_image_b' src={img_expand_more} alt=''/>
          </div>
        </div>
        { this.state.data.is_expand && this.state.is_active ? <div className='dropdown_mask' /> : null }
        {this.showChooserDropdown()}
      </div>
    );
  }

}
