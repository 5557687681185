import React, { Component } from 'react';

import LoadingDialog from '../dialog/LoadingDialog';
import AdvSearchDialog from '../dialog/AdvSearchDialog';
import WholesalerDialog from './dialog/WholesalerDialog';

import gif_img from '../resources/loading.gif';
import img_error from '../resources/error.png';
import img_edit from '../resources/baseline_edit.png';

var request = require('../../utilities/NetworkRequest');
var cache_util = require('../../utilities/CacheUtil');
var view_util = require('../../utilities/ViewUtil');

export default class wholesaler extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : false,
      is_loading : false,
      is_error : false,
      error_message : '',
      user_information : null,
      body_error : {
        is_show : false,
        message : ''
      },
      dialog_advance : {
        show : false,
        body : null
      },
      wholesaler_dialog : {
        is_show : false,
        data : null
      },
      search_header : {},
      toolbar : {},
      data_table : [],
      header_table : {},
      pagination : {},
      pagination_array : []
    }
  }

  componentDidMount() { this.requestData(false, false); }

  requestData(table_only, is_filter) {
    this.setState({ is_main_loading : true }, () => {
      try {
        let decoded_input = this.state.user_information;
        if (decoded_input === null) {
          decoded_input = cache_util.onGetCacheData(cache_util.CACHE_INFO).result.user_information;
          decoded_input.length_data = 50;
        }
        if (!is_filter) {
          if (decoded_input.hasOwnProperty('filter')) {
            delete decoded_input.filter;
          }
        }
        request.onRequestData(1, '/show/list_wholesaler', decoded_input, res_data => {
          let temp_pagination_array = [];
          if (res_data.code === 1) {
            temp_pagination_array.push(res_data.result.res_data.pagination);
            if (table_only) {
              this.setState({
                is_main_loading : false,
                is_error : false,
                data_table : res_data.result.res_data.arrays,
                pagination : res_data.result.res_data.pagination,
                pagination_array : temp_pagination_array
              });
            } else {
              this.setState({
                is_main_loading : false,
                is_error : false,
                dialog_advance : {
                  show : false,
                  body : res_data.result.filterV2
                },
                user_information : decoded_input,
                search_header : res_data.result.search_header,
                toolbar : res_data.result.toolbar,
                data_table : res_data.result.res_data.arrays,
                header_table : res_data.result.res_data.header,
                pagination : res_data.result.res_data.pagination,
                pagination_array : temp_pagination_array
              });
            }
          } else {
            this.setState({
              is_main_loading : false,
              is_error : true,
              error_message : res_data.message
            });
          }
        });
      } catch (e) {
        this.setState({
          is_main_loading : false,
          is_error : true,
          error_message : e.message
        });
      }
    });
  }

  onEditClicked(data) {
    let temp = this.state.wholesaler_dialog;
    temp.is_show = !temp.is_show;
    temp.data = data;
    this.setState({ wholesaler_dialog : temp });
  }

  onLeftToolbarClicked(data) {
    switch (data.key) {
      case 'refresh':
        this.requestData(true, false);
        break;
      default:
        alert('Menu ini masih dalam tahap pengembangan.');
        break;
    }
  }

  onCloseAdminWholesaler() {
    let temp = this.state.wholesaler_dialog;
    temp.is_show = false;
    this.setState({ wholesaler_dialog : temp });
  }

  showAdminWholesalerDialog() {
    if (this.state.wholesaler_dialog.is_show) {
      return( <WholesalerDialog body={this.state.wholesaler_dialog.data} onCloseListener={() => this.onCloseAdminWholesaler()} /> );
    } else { return null; }
  }

  requestNextData(body, temp_pagination_array) {
    this.setState({
      is_loading : true
    }, () => {
      request.onRequestData(1, '/show/list_wholesaler', body, res_data => {
        if (res_data.code === 1) {
          if (temp_pagination_array == null) {
            temp_pagination_array = this.state.pagination_array;
            temp_pagination_array.push(res_data.result.res_data.pagination);
          }
          this.setState({
            is_loading : false,
            is_error : false,
            pagination : res_data.result.res_data.pagination,
            data_table : res_data.result.res_data.arrays,
            pagination_array : temp_pagination_array
          });
        } else {
          this.setState({
            is_loading : false,
            is_error : true,
            error_message : res_data.message
          });
        }
      });
    });
  }

  initTableHeader(data) {
    return (
      <tr>
        <th className='main_column whole_padding default_text_normal_gray'>{data.wholesaler_name}</th>
        <th className='whole_padding default_text_normal_gray'>{data.wholesaler_id}</th>
        <th className='whole_padding default_text_normal_gray'>{data.region}</th>
        <th className='whole_padding default_text_normal_gray'>{data.rsm}</th>
        <th className='whole_padding default_text_normal_gray'>{data.asm}</th>
        <th className='whole_padding default_text_normal_gray'>{data.tp_cust_no}</th>
        <th className='whole_padding default_text_normal_gray'>{data.owner}</th>
        <th className='whole_padding default_text_normal_gray'>{data.address}</th>
        <th className='whole_padding default_text_normal_gray'>Action</th>
      </tr>
    );
  }

  initTableBody(pos, data) {
    return (
      <tr key={pos}>
        <td className='whole_padding default_text_normal_gray'>{data.wholesaler_name}</td>
        <td className='whole_padding default_text_normal_gray'>{data.wholesaler_id}</td>
        <td className='whole_padding default_text_normal_gray'>{data.region}</td>
        <td className='whole_padding default_text_normal_gray'>{data.rsm}</td>
        <td className='whole_padding default_text_normal_gray'>{data.asm}</td>
        <td className='whole_padding default_text_normal_gray'>{data.tp_cust_no}</td>
        <td className='whole_padding default_text_normal_gray'>{data.owner}</td>
        <td className='whole_padding default_text_normal_gray'>{view_util.limitValue(80, data.address)}</td>
        <td className='custom_padding_h'>
          <div onClick={this.onEditClicked.bind(this, data)} className='default_button label_search_container green_background'>
            <img className='mini_image_d' src={img_edit} alt=''/>
            <div className='normal_padding_left_c default_text_normal_white'>Edit</div>
          </div>
        </td>
      </tr>
    );
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className='loading_container_b'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return (
          <div className='product_root'>
            <LoadingDialog showDialog={this.state.is_loading} />
            { this.showAdminWholesalerDialog() }
            {
              this.state.dialog_advance.show ? <AdvSearchDialog
                body={this.state.dialog_advance.body}
                onCloseListener={ (action, body) => view_util.showDialogAdvance(this, action, () => this.requestData(true, true)) }
              /> : null
            }
            <div className='product_wrapper'>
              { view_util.initViewToolbar(
                this, 'data_table',
                (data) => this.onLeftToolbarClicked(data),
                () => this.requestData(true, true)
              ) }
              { view_util.initViewTable(this.state.data_table,
                this.initTableHeader(this.state.header_table),
                (pos, data) => this.initTableBody(pos, data)
              ) }
              { view_util.initPaginationTable(
                this.state,
                (body, pagination) => this.requestNextData(body, pagination),
                (body) => this.requestNextData(body, null)
              ) }
            </div>
          </div>
        );
      } else {
        return (
          <div className='loading_container_b'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

}
