import React, { Component } from 'react';

import Dropdown from '../native/Dropdown';

import img_back from '../resources/back_a.png';
import img_delete from '../resources/delete.png';
import gif_img from '../resources/loading.gif';
import img_error from '../resources/error.png';
import img_add from '../resources/baseline_add_white.png';
import img_cancel from '../resources/cancel_circle.png';

const request = require('../../utilities/NetworkRequest');
const str_util = require('../../utilities/StringUtil');
var view_util = require('../../utilities/ViewUtil');

class DetailLoyaltyDialog extends Component {

  constructor(props) {
    super(props);
    this._verifyTimeout = null;
    this.state = {
      is_main_loading : true,
      is_error : false,
      error_message : '',
      main_data : null,
      is_verify : false,

      expand_add : false,
      ls_exclude : [],
      dt_point : '',
      dt_min_val : '',
      dt_max_val : '',
      dt_exclude : '',
      dt_filter : '',
      dt_filter_message : '',
      filter_include : {
        selected : {
          key : 'all_products',
          label : 'Semua Produk'
        },
        text : 'filter_include',
        is_expand : false,
        data : [
          {
            key : 'all_products',
            label : 'Semua Produk'
          },
          {
            key : 'category_id',
            label : 'Kategori Produk'
          },
          {
            key : 'principal_id',
            label : 'Principal Produk'
          }
        ]
      },
      filter_exclude : {
        selected : {
          key : 'category_id',
          label : 'Kategori Produk'
        },
        text : 'filter_exclude',
        is_expand : false,
        data : [
          {
            key : 'category_id',
            label : 'Kategori Produk'
          },
          {
            key : 'principal_id',
            label : 'Principal Produk'
          }
        ]
      },
      type_gain : {
        selected : {
          key : 'range',
          label : 'Rentang Nilai'
        },
        text : 'type_gain',
        is_expand : false,
        data : [
          {
            key : 'range',
            label : 'Rentang Nilai'
          },
          {
            key : 'multiple',
            label : 'Kelipatan'
          }
        ]
      }
    }
  }

  componentDidMount() {
    this.refreshData();
  }

  componentWillUnmount() {
    if (this._verifyTimeout !== null) { clearTimeout(this._verifyTimeout); }
  }

  refreshData() {
    this.setState({is_main_loading : true}, () => {
      let body = { id_ws_loyalty : this.props.context.state.ws_loyalty.data.id_ws_loyalty };
      request.onRequestData(request.OPTION_API_MAIN, '/show/ws_loyalty_rule', body, res_data => {
        if (res_data.code === 1) {
          this.setState({
            dt_min_val : '',
            dt_max_val : '',
            dt_point : '',
            dt_exclude : '',
            dt_filter : '',
            ls_exclude : [],
            expand_add : false,
            is_main_loading : false,
            is_error : false,
            main_data : res_data.result
          });
        } else {
          this.setState({
            is_main_loading : false,
            is_error : true,
            error_message : res_data.message
          });
        }
      });
    });
  }

  getExclude(data) {
    let view = [], n_data = Object.keys(data).length;
    if (n_data === 0) { return null; }
    for (let i = 0; i < n_data; i++) {
      view.push(
        <tr key={i}>
          <td style={{padding : '5px 0'}}>{data[i].type_exclude_desc}</td>
          <td>{data[i].data_exclude}</td>
        </tr>
      );
    }
    return (
      <div style={{paddingTop : 10}}>
        <table className='default_text_normal_gray' style={{borderCollapse : 'collapse', width : '100%', textAlign : 'left'}}>
          <thead>
            <tr>
              <th style={{borderBottom : '1px solid #e3e6ea', padding : '5px 0'}}>Jenis Pengecualian</th>
              <th style={{borderBottom : '1px solid #e3e6ea'}}>Data Pengecualian</th>
            </tr>
          </thead>
          <tbody>{view}</tbody>
        </table>
      </div>
    );
  }

  deleteRule(data) {
    this.props.context.setState({ is_loading : true }, () => {
      let body = { id_ws_loyalty_gain : data.id_ws_loyalty_gain }
      request.onRequestData(request.OPTION_API_MAIN, '/post/delete_rule', body, res_data => {
        if (res_data.code === 1) {
          this.props.context.setState({is_loading : false}, () => {
            this.refreshData();
          });
        } else {
          this.props.context.setState({
            is_loading : false,
            body_error : {
              is_show : true,
              message : res_data.message
            }
          });
        }
      });
    });
  }

  getRule() {
    let view = [];
    for (let i = 0; i < Object.keys(this.state.main_data).length; i++) {
      view.push(
        <div key={i} style={{marginTop : 10, padding : 10, border : '1px solid #e3e6ea', borderRadius : 5, position : 'relative'}}>
          <div onClick={() => this.deleteRule(this.state.main_data[i])} className='custom_cursor_a display_flex_center' style={{height : 40, width : 40, right : 0, top : 0, position : 'absolute'}}>
            <img alt='' src={img_delete} style={{width : 16, height : 16}}/>
          </div>

          <div style={{display : 'flex', flexDirection : 'row'}}>
            <div style={{flex : 1}}>
              <div className='default_text_normal_gray bold_text'>Tipe Rule</div>
              <div className='default_text_normal_gray'>{this.state.main_data[i].type_gain_desc}</div>
            </div>

            <div style={{flex : 1}}>
              <div className='default_text_normal_gray bold_text'>Jumlah Point</div>
              <div className='default_text_normal_gray'>{str_util.formatSeparator(this.state.main_data[i].point + '')}</div>
            </div>
          </div>

          <div style={{display : 'flex', flexDirection : 'row', paddingTop : 5}}>
            <div style={{flex : 1}}>
              <div className='default_text_normal_gray bold_text'>{this.state.main_data[i].type_gain === 'range' ? 'Nilai Minimum' : 'Nilai Per-Kelipatan'}</div>
              <div className='default_text_normal_gray'>{'Rp ' + str_util.formatSeparator(this.state.main_data[i].min_value)}</div>
            </div>

            <div style={{flex : 1}}>
              <div className='default_text_normal_gray bold_text'>Nilai Maksimum</div>
              <div className='default_text_normal_gray'>{(this.state.main_data[i].max_value === null ? '-' : 'Rp ' + str_util.formatSeparator(this.state.main_data[i].max_value))}</div>
            </div>
          </div>

          <div style={{display : 'flex', flexDirection : 'row', paddingTop : 5}}>
            <div style={{flex : 1}}>
              <div className='default_text_normal_gray bold_text'>Jenis Filter</div>
              <div className='default_text_normal_gray'>{this.state.main_data[i].type_include_desc}</div>
            </div>

            <div style={{flex : 1}}>
              <div className='default_text_normal_gray bold_text'>Data Filter</div>
              <div className='default_text_normal_gray'>{this.state.main_data[i].data_include === null ? 'Kosong' : this.state.main_data[i].data_include}</div>
            </div>
          </div>

          {this.getExclude(this.state.main_data[i].excludes)}
        </div>
      );
    }
    return view;
  }

  getVerifyLoading() {
    if (this.state.is_verify) {
      return (
        <div style={{paddingTop : 10, display : 'flex', justify : 'center', alignItems : 'center', flexDirection : 'column'}}>
          <img className='mini_image_c' src={gif_img} alt='' />
          <div className='default_text_normal_gray'>Memverifikasi kode data filter...</div>
        </div>
      );
    } else {
      return null;
    }
  }

  onChangeDataFilter(text) {
    if (this._verifyTimeout !== null) { clearTimeout(this._verifyTimeout); }
    if (text.trim().length > 0) {
      this._verifyTimeout = setTimeout(this.requestVerification.bind(this, text), 1000);
    }
    this.setState({ dt_filter : text });
  }

  requestVerification(text) {
    if (this.state.dt_filter === text) {
      this.setState({ is_verify : true }, () => {
        let body = {
          variable_data : this.state.dt_filter,
          variable_type : this.state.filter_include.selected.key,
          wholesaler_id : this.props.context.state.user_information.wholesaler_id
        };
        request.onRequestData(request.OPTION_API_MAIN, '/verify/variable_loyalty', body, res_data => {
          if (res_data.code === 1) {
            this.setState({
              is_verify : false,
              dt_filter_message : `Filter '${res_data.result.desc}' berhasil ditemukan.`
            });
          } else {
            this.setState({
              is_verify : false,
              dt_filter_message : `Data '${text}' tidak ditemukan pada ${this.state.filter_include.selected.label}.`
            });
          }
        });
      });
    }
  }

  getAddView() {
    if (!this.state.expand_add) { return null; }
    return (
      <div style={{marginTop : 20, padding : 10, borderRadius : 5, border : '3px solid #e3e6ea'}}>
        <div style={{flexDirection : 'row', display : 'flex'}}>
          <div style={{flex : 1, flexDirection : 'column', display : 'flex'}}>
            <div className='default_text_normal_gray bold_text'>Jenis Filter</div>
            <div className='normal_margin_top' style={{height : 35}}>
              <Dropdown data={this.state.filter_include}/>
            </div>
          </div>
          <div style={{width : 20}}/>

          <div style={{flex : 1, flexDirection : 'column', display : 'flex'}}>
            <div className='default_text_normal_gray bold_text'>Kode Data Filter</div>
            <input onChange={(e) => this.onChangeDataFilter(e.target.value)} value={this.state.dt_filter} spellCheck='false' className='normal_margin_top default_text_normal_gray transparent_input_c normal_border_bottom' type='text' style={{height : 35}} />
          </div>
        </div>

        {this.getVerifyLoading()}
        <div style={{paddingTop : 5}} className='default_text_normal_blue bold_text'>{this.state.dt_filter_message}</div>

        <div style={{flexDirection : 'row', display : 'flex', paddingTop : 10}}>
          <div style={{flex : 1, flexDirection : 'column', display : 'flex'}}>
            <div className='default_text_normal_gray bold_text'>Tipe Perolehan Point</div>
            <div className='normal_margin_top' style={{height : 35}}>
              <Dropdown data={this.state.type_gain} listener_change={(data) => this.setState({ dt_point : this.state.dt_point })}/>
            </div>
          </div>
          <div style={{width : 20}}/>

          <div style={{flex : 1, flexDirection : 'column', display : 'flex'}}>
            <div className='default_text_normal_gray bold_text'>Jumlah Point Didapat</div>
            <input onChange={(e) => this.setState({ dt_point : e.target.value })} value={this.state.dt_point} spellCheck='false' className='normal_margin_top default_text_normal_gray transparent_input_c normal_border_bottom' type='text' style={{height : 35}} />
          </div>
        </div>

        <div style={{flexDirection : 'row', display : 'flex', paddingTop : 10}}>
          <div style={{flex : 1, flexDirection : 'column', display : 'flex'}}>
            <div className='default_text_normal_gray bold_text'>{this.state.type_gain.selected.key === 'range' ? 'Nilai Minimum' : 'Nilai Per-Kelipatan'}</div>
            <input onChange={(e) => this.setState({ dt_min_val : e.target.value })} value={this.state.dt_min_val} spellCheck='false' className='normal_margin_top default_text_normal_gray transparent_input_c normal_border_bottom' type='text' style={{height : 35}} />
          </div>
          <div style={{width : 20}}/>
          <div style={{flex : 1, flexDirection : 'column', display : 'flex'}}>
            <div className='default_text_normal_gray bold_text'>Nilai Maksimum</div>
            <input onChange={(e) => this.setState({ dt_max_val : e.target.value })} value={this.state.dt_max_val} spellCheck='false' className='normal_margin_top default_text_normal_gray transparent_input_c normal_border_bottom' type='text' style={{height : 35}} />
          </div>
        </div>

        <div style={{paddingTop : 10}} className='default_text_normal_gray bold_text'>Aturan Pengecualian</div>
        <div style={{flexDirection : 'row', display : 'flex', paddingTop : 10}}>
          <div style={{flex : 1, flexDirection : 'column', display : 'flex'}}>
            <div className='default_text_normal_gray bold_text'>Jenis Pengecualian</div>
            <div className='normal_margin_top' style={{height : 35}}>
              <Dropdown data={this.state.filter_exclude}/>
            </div>
          </div>
          <div style={{width : 10, flexShrink : 0}}/>

          <div style={{flex : 1, flexDirection : 'column', display : 'flex'}}>
            <div className='default_text_normal_gray bold_text'>Kode Data</div>
            <div style={{display : 'flex', flexDirection : 'row'}}>
              <input onChange={(e) => this.setState({ dt_exclude : e.target.value })} value={this.state.dt_exclude} spellCheck='false' className='normal_margin_top default_text_normal_gray transparent_input_c normal_border_bottom' type='text' style={{height : 35, width : '100%'}} />
              <div style={{width : 10, flexShrink : 0}}/>
              <div className='wrapper_product_action'>
                <div onClick={() => this.onAddListExclude()} className='normal_margin_top custom_padding_e label_search_container blue_background'>
                  <img className='mini_image_d' src={img_add} alt=''/>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.getListExclude(this.state.ls_exclude)}
      </div>
    );
  }

  onSaveRule() {
    this.props.context.setState({ is_loading : true }, () => {
      try {
        if (this.state.filter_include.selected.key !== 'all_products' && this.state.dt_filter.trim().length === 0) { throw Error('Kode data filter tidak boleh kosong jika spesifik pada kategori tertentu!'); }

        if (this.state.dt_point.trim().length === 0) { throw Error('Jumlah point tidak boleh kosong!'); }
        if (!str_util.isNumeric(this.state.dt_point)) { throw Error('Jumlah point harus berupa angka!'); }
        if (Number(this.state.dt_point) < 0) { throw Error('Jumlah point harus lebih dari 0!'); }

        if (this.state.dt_min_val.trim().length === 0) { throw Error('Nilai minimum tidak boleh kosong!'); }
        if (!str_util.isNumeric(this.state.dt_min_val)) { throw Error('Nilai minimum harus berupa angka!'); }
        if (Number(this.state.dt_min_val) < 0) { throw Error('Nilai minimum tidak boleh kurang dari 0!'); }

        if (this.state.dt_max_val.trim().length === 0) { throw Error('Nilai maksimum tidak boleh kosong!'); }
        if (this.state.dt_max_val !== '-') {
          if (!str_util.isNumeric(this.state.dt_max_val)) { throw Error('Nilai maksimum harus berupa angka!'); }
          if (Number(this.state.dt_max_val) <= Number(this.state.dt_min_val)) { throw Error('Nilai maksimum tidak boleh kurang dari nilai minimum!'); }
        }
        let body = {
          id_ws_loyalty : this.props.context.state.ws_loyalty.data.id_ws_loyalty,
          type_gain : this.state.type_gain.selected.key,
          min_value : this.state.dt_min_val,
          max_value : this.state.dt_max_val === '-' ? null : this.state.dt_max_val,
          point : this.state.dt_point,
          type_include : this.state.filter_include.selected.key,
          data_include : this.state.dt_filter.trim().length === 0 ? null : this.state.dt_filter,
          list_excludes : this.state.ls_exclude
        }
        request.onRequestData(request.OPTION_API_MAIN, '/post/ws_loyalty_rule', body, res_data => {
          if (res_data.code === 1) {
            this.props.context.setState({is_loading : false}, () => {
              this.refreshData();
            });
          } else {
            this.props.context.setState({
              is_loading : false,
              body_error : {
                is_show : true,
                message : res_data.message
              }
            });
          }
        });
      } catch (e) {
        this.props.context.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : e.message
          }
        });
      }
    });
  }

  onAddListExclude() {
    this.props.context.setState({ is_loading : true }, () => {
      try {
        if (this.state.dt_exclude.trim().length === 0) { throw Error('Data Exclude tidak boleh kosong!'); }
        let body = {
          variable_data : this.state.dt_exclude,
          variable_type : this.state.filter_exclude.selected.key,
          wholesaler_id : this.props.context.state.user_information.wholesaler_id
        };
        request.onRequestData(request.OPTION_API_MAIN, '/verify/variable_loyalty', body, res_data => {
          if (res_data.code === 1) {
            let temp = this.state.ls_exclude, is_found = false;
            for (let i = 0; i < Object.keys(temp).length; i++) {
              if (temp[i].type_exclude === body.variable_type
                && temp[i].data_exclude === body.variable_data) {
                is_found = true;
                break;
              }
            }
            if (!is_found) {
              temp.push({
                type_exclude : this.state.filter_exclude.selected.key,
                type_exclude_desc : this.state.filter_exclude.selected.label,
                data_exclude : this.state.dt_exclude,
                desc : res_data.result.desc
              });
            }
            this.setState({
              ls_exclude : temp
            }, () => this.props.context.setState({ is_loading : false }) );
          } else {
            this.props.context.setState({
              is_loading : false,
              body_error : {
                is_show : true,
                message : res_data.message
              }
            });
          }
        });
      } catch (e) {
        this.props.context.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : e.message
          }
        });
      }
    });
  }

  deleteExclude(pos) {
    let temp = this.state.ls_exclude;
    temp.splice(pos, 1);
    this.setState({ ls_exclude : temp });
  }

  getListExclude(data) {
    let view = [], n_data = Object.keys(data).length;
    if (n_data === 0) {
      return null;
    }
    for (let i = 0; i < n_data; i++) {
      view.push(
        <tr key={i}>
          <td style={{padding : '5px 0'}}>{data[i].type_exclude_desc}</td>
          <td>{data[i].data_exclude}</td>
          <td>{view_util.limitValue(20, data[i].desc)}</td>
          <td>
            <div onClick={() => this.deleteExclude(i)} className='center_content'><img className='custom_cursor_a mini_image_d' src={img_back} alt=''/></div>
          </td>
        </tr>
      );
    }
    return (
      <div style={{paddingTop : 10}}>
        <table className='default_text_normal_gray' style={{borderCollapse : 'collapse', width : '100%', textAlign : 'left'}}>
          <thead>
            <tr>
              <th style={{borderBottom : '1px solid #e3e6ea', padding : '5px 0'}}>Jenis Pengecualian</th>
              <th style={{borderBottom : '1px solid #e3e6ea'}}>Data Pengecualian</th>
              <th style={{borderBottom : '1px solid #e3e6ea'}}>Keterangan</th>
              <th style={{borderBottom : '1px solid #e3e6ea'}}/>
            </tr>
          </thead>
          <tbody>{view}</tbody>
        </table>
      </div>
    );
  }

  getButtonAction() {
    if (this.state.expand_add) {
      return (
        <div className='wrapper_product_action' style={{paddingTop : 20}}>
          <div onClick={() => this.onSaveRule()} style={{width:80}} className='custom_padding_e label_search_container green_background'>
            <img className='mini_image_d' src={img_add} alt=''/>
            <div className='normal_padding_left_c default_text_normal_white'>Simpan</div>
          </div>

          <div onClick={() => this.setState({ expand_add : false })} style={{width:80, marginLeft : 20}} className='custom_padding_e label_search_container red_background'>
            <img className='mini_image_d' src={img_cancel} alt=''/>
            <div className='normal_padding_left_c default_text_normal_white'>Batal</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className='wrapper_product_action' style={{paddingTop : 20}}>
          <div onClick={() => this.setState({ expand_add : true })} style={{width:80}} className='custom_padding_e label_search_container blue_background'>
            <img className='mini_image_d' src={img_add} alt=''/>
            <div className='normal_padding_left_c default_text_normal_white'>Tambah Rule</div>
          </div>
        </div>
      );
    }
  }

  getView() {
    return (
      <div onClick={(e) => e.stopPropagation()} style={{width : '90%', maxWidth : 500, maxHeight : 400, overflow : 'auto',padding : 10, background : 'white', borderRadius : 5, position : 'relative'}}>
        <div onClick={(e) => onCloseDialog(this.state.is_main_loading, this.props.context)} className='custom_cursor_a display_flex_center' style={{height : 40, width : 40, right : 0, top : 0, position : 'absolute'}}>
          <img alt='' src={img_back} style={{width : 16, height : 16}}/>
        </div>
        <div className='wrapper_form_product normal_padding_bottom default_text_medium_gray bold_text normal_border_bottom'>EDITOR RULE LOYALTY</div>

        {this.getAddView()}

        {this.getButtonAction()}
        <div>{this.getRule()}</div>
      </div>
    );
  }

  initMainView() {
    if (this.state.is_main_loading) {
      return (
        <div onClick={(e) => e.stopPropagation()} className='pop_up_dialog_container'>
          <img className='big_image' src={gif_img} alt='' />
          <div className='custom_padding_c default_text_big_gray'>Memuat Data</div>
        </div>
      );
    } else {
      if (this.state.is_error) {
        return (
          <div onClick={(e) => e.stopPropagation()} className='pop_up_dialog_container'>
            <img className='normal_margin_top_c big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_medium_gray'>{this.state.error_message}</div>
          </div>
        );
      } else {
        return this.getView();
      }
    }
  }

  render() {
    return(
      <div onClick={(e) => onCloseDialog(this.state.is_main_loading, this.props.context)} className='process_dialog_root layer_a'>
        {this.initMainView()}
      </div>
    );
  }

}

function onCloseDialog(is_loading, context) {
  if (!is_loading) {
    context.setState({ ws_loyalty : {is_show : false, data : null} });
  }
}

export function showDetailLoyalty(context) {
  if (context.state.ws_loyalty === undefined) {
    context.state.ws_loyalty = {
      is_show : false,
      data : null
    }
  }
  if (context.state.ws_loyalty.is_show) {
    return <DetailLoyaltyDialog context={context}/>
  } else {
    return null;
  }
}
