import React from 'react';

import gif_img from '../resources/loading.gif';

var request = require('../../utilities/NetworkRequest');

export function showFormInput(id, is_active, value, icon, placeholder, input_type, max_length, add_style, listener) {
  let v_style = {...m_style.input_wrapper_a, ...add_style};
  let input_style = { outline : 'none', borderWidth : 0, flex : 1 }
  if (!is_active) {
    input_style.pointerEvents = 'none';
    input_style.color = 'rgba(55, 73, 87, 0.5)';
  }
  return (
    <div id={id} style={v_style}>
      { (icon !== null ? <img style={{width : 16, height : 16, marginRight : 10}} alt='' src={icon}/> : null ) }
      <input maxLength={max_length} value={value} onChange={(e) => listener(e.target.value)} type={input_type} placeholder={placeholder} className='v2_text_a' style={input_style} />
    </div>
  );
}

function onSelectedProduct(context, TAG, position, listener_selected) {
  let selected = JSON.stringify(context.state[TAG + '_list'][position]);
  context.setState({
    [TAG] : context.state[TAG + '_list'][position].text,
    [TAG + '_list'] : []
  }, () => {
    listener_selected(JSON.parse(selected));
  });
}

function getInputList(context, TAG, listener_selected) {
  let view = []
  for (let i = 0; i < Object.keys(context.state[TAG + '_list']).length; i++) {
    view.push(<div onClick={() => onSelectedProduct(context, TAG, i, listener_selected)} className='v2_text_a v2_cursor_pointer' style={{padding : 8}} key={i}>{context.state[TAG + '_list'][i].text}</div>);
  }
  return view;
}

function getInputLoading(context, TAG) {
  if (context.state[TAG + '_loading']) {
    return (
      <div style={{width : '100%', height : 35, display : 'flex', justifyContent : 'center', alignItems : 'center'}}>
        <img style={{width : 30, height : 30}} src={gif_img} alt=''/>
      </div>
    );
  } else { return null; }
}

function showInputSuggestion(context, TAG, listener_selected) {
  if (context.state[TAG].length > 0 && (context.state[TAG + '_loading'] || Object.keys(context.state[TAG + '_list']).length > 0)) {
    return (
      <div style={m_style.input_wrapper_suggestion_a}>
        { getInputLoading(context, TAG) }
        { getInputList(context, TAG, listener_selected) }
      </div>
    );
  } else {
    return null;
  }
}

function onChangeSearchInput(context, TAG, text) {
  context.setState({ [TAG] : text }, () => {
    if (text.length > 0) {
      let timer = setInterval(function() {
        if (text === context.state[TAG]) {
          requestSuggestions(context, TAG, text);
        }
        clearInterval(timer);
      }, 2000);
    }
  });
}

function requestSuggestions(context, TAG, text) {
  if (context.state.user_information !== null) {
    context.setState({
      [TAG + '_list'] : [],
      [TAG + '_loading'] : true
    }, () => {
      let body = {
        wholesaler_id : context.state.user_information.wholesaler_id,
        pcode_name : text
      }
      request.onRequestData(1, '/show/suggestions', body, res_data => {
        if (res_data.code === 1) {
          context.setState({
            [TAG + '_list'] : res_data.result,
            [TAG + '_loading'] : false
          });
        } else {
          context.setState({ [TAG + '_loading'] : false });
        }
      });
    });
  }
}

export function showSearchInput(context, TAG, id, is_active, icon, placeholder, input_type, max_length, add_style, listener_selected) {
  if (context.state[TAG] === undefined) { context.state[TAG] = ''; }
  if (context.state[TAG + '_list'] === undefined) { context.state[TAG + '_list'] = []; }
  if (context.state[TAG + '_loading'] === undefined) { context.state[TAG + '_loading'] = true; }
  let v_style = {...m_style.input_wrapper_a, ...add_style};
  let input_style = { outline : 'none', borderWidth : 0, flex : 1 }
  if (!is_active) {
    input_style.pointerEvents = 'none';
    input_style.color = 'rgba(55, 73, 87, 0.5)';
  }
  return (
    <div id={id} style={v_style}>
      { showInputSuggestion(context, TAG, listener_selected) }
      { (icon !== null ? <img style={{marginRight : 10}} alt='' src={icon}/> : null ) }
      <input maxLength={max_length} value={context.state[TAG]} onChange={(e) => onChangeSearchInput(context, TAG, e.target.value)} type={input_type} placeholder={placeholder} className='v2_text_a' style={input_style} />
    </div>
  );
}

const m_style = {
  input_wrapper_a : {
    display : 'flex',
    height : 39,
    position : 'relative',
    borderBottom : '1px solid red',
    flexDirection : 'row',
    alignItems : 'center',
    paddingLeft : 10,
    paddingRight : 10
  },
  input_wrapper_suggestion_a : {
    top : 50,
    left : 0,
    zIndex : 2,
    display : 'flex',
    flexDirection : 'column',
    position : 'absolute',
    maxHeight : 200,
    width : 'calc(100% - 2px)',
    border : '1px solid red',
    backgroundColor : 'white',
    overflow : 'auto'
  }
}
