import React, { Component } from 'react';

import flashsale_data from '../local/flashsale.json';

import img_expand_more from './resources/baseline_expand_more.png';
import img_search from './resources/search.png';
import img_left_chevron from './resources/baseline_chevron_left_2x.png';
import img_right_chevron from './resources/baseline_chevron_right_2x.png';

export default class Flashsale extends Component {

  constructor(props) {
    super(props);
    this.state = {
      header_left : flashsale_data.result.header.left,
      header_right : flashsale_data.result.header.right,
      header_search : flashsale_data.result.header.search,
      table_header : flashsale_data.result.res_data.header,
      table_data : flashsale_data.result.res_data.arrays,
      pagination : flashsale_data.result.res_data.pagination,
      pagination_array : []
    }
  }

  initLeftHeader(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <div key={data[i].key} className='container_image'>
          <img className='mini_image_b' src={require(data[i].image + '')} alt=''/>
        </div>
      );
    }
    return view;
  }

  initDropdownData(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <div key={data[i].key} className='side_padding vertical_padding_b default_text_normal_gray'>{data[i].label}</div>
      );
    }
    return view;
  }

  initStatusDropdownData(position, data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <div key={data[i].key} className='side_padding vertical_padding_b default_text_normal_gray'>{data[i].label}</div>
      );
    }
    return view;
  }

  showDropdown(position, data) {
    if (data.show) {
      if (data.key === 'main_search') {
        return (
          <div className='dropdown_wrapper layer_b normal_margin_top_b normal_position_right_b'>
            <div className='arrow-up'/>
            <div className='dropdown_content gray_background'>{this.initDropdownData(data.data)}</div>
          </div>
        );
      } else {
        return (
          <div className='dropdown_wrapper layer_a normal_margin_top'>
            <div className='arrow-up'/>
            <div className='dropdown_content white_background'>{this.initStatusDropdownData(position, data.data)}</div>
          </div>
        );
      }
    } else {
      return null;
    }
  }

  onSearchFilterClicked() {
    let temp_header_search = this.state.header_search;
    temp_header_search.show = !temp_header_search.show;
    this.setState({
      header_search : temp_header_search
    })
  }

  initSearchHeader(data) {
    return (
      <div className='search_root_container'>
        <div className='search_container'>
          <input spellCheck='false' className='transparent_input' type='text' />
          <div onClick={this.onSearchFilterClicked.bind(this)} className='dropdown_display normal_margin_left'>
            <div className='vertical_padding normal_padding_left_b label_search_container blue_background'>
              <div className='default_text_normal_white'>{data.active.label}</div>
              <img className='mini_image_b side_padding_b' src={img_expand_more} alt=''/>
            </div>
            {this.showDropdown(null, data)}
          </div>
          <img className='mini_image_b side_padding' src={img_search} alt=''/>
        </div>
      </div>
    );
  }

  onFilterDropdownClicked(position) {
    let temp_header_right = this.state.header_right;
    temp_header_right[position].show = !temp_header_right[position].show;
    this.setState({
      header_right : temp_header_right
    })
  }

  initFilterHeader(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      switch (data[i].type) {
        case 'dropdown':
          view.push(
            <div onClick={this.onFilterDropdownClicked.bind(this,i)} key={data[i].key} className='dropdown_display dropdown_container'>
              <div className='side_padding dropdown_chooser'>
                <div className='full_flex default_text_normal_gray'>{data[i].selected.label}</div>
                <div className='center_content circle_background_b'>
                  <img className='mini_image_b' src={img_expand_more} alt=''/>
                </div>
              </div>
              {this.showDropdown(i, data[i])}
            </div>
          );
          break;
        case "icon":
          view.push(
            <div key={data[i].key} className='container_image_b'>
              <img className='mini_image_b' src={require(data[i].image + '')} alt=''/>
            </div>
          );
          break;
        default :
          // DO NOTHING
          break;
      }
    }
    return view;
  }

  initDataTable(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <tr key={i}>
          <td className='whole_padding default_text_normal_gray'>{data[i].access_code}</td>
        </tr>
      );
    }
    return view;
  }

  initTableView(data) {
    return (
      <table className='table_container_acess'>
        <thead>
          <tr>
            <th className='whole_padding default_text_normal_gray'>{data.access_code}</th>
          </tr>
        </thead>
        <tbody>
          {this.initDataTable(this.state.table_data)}
        </tbody>
      </table>
    );
  }

  getClassPagination(data, is_left) {
    if (is_left) {
      if (Object.keys(this.state.pagination_array).length === 1) {
        return 'btn_previous_dead gray_background'
      } else {
        return 'btn_previous pink_background'
      }
    } else {
      if (data.is_more) {
        return 'btn_next pink_background';
      } else {
        return 'btn_next_dead gray_background';
      }
    }
  }

  initPaginationTable(data) {
    return (
      <div className='pagination_root'>
        <div className={this.getClassPagination(data, true)}>
          <img className='mini_image_c' src={img_left_chevron} alt=''/>
        </div>
        <div style={{backgroundColor:'white', width:1, height:30}}/>
        <div className={this.getClassPagination(data, false)}>
          <img className='mini_image_c' src={img_right_chevron} alt=''/>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className='delivery_root'>
        <div className='header_container'>
          <div className='flex_left'>
            {this.initLeftHeader(this.state.header_left)}
          </div>
          <div className='flex_right'>
            {this.state.header_search.hasOwnProperty('active') ? this.initSearchHeader(this.state.header_search) : null}
            <div className='filter_container'>
              {this.initFilterHeader(this.state.header_right)}
            </div>
          </div>
        </div>
        {this.initTableView(this.state.table_header)}
        {this.initPaginationTable(this.state.pagination)}
      </div>
    );
  }

}
