import React from 'react';

export function showButton(id, label, text_style, add_style, listener) {
  let v_style = {...m_style.button_a, ...{backgroundColor : 'red'}, ...add_style}
  let t_style = text_style + ' v2_text_bold';
  return (
    <div id={id} className='v2_cursor_pointer' onClick={() => { if(listener !== null) {listener();} }} style={v_style}>
      <div className={t_style} style={{whiteSpace : 'nowrap', paddingLeft : 10, paddingRight : 10}}>{label}</div>
    </div>
  );
}

const m_style = {
  button_a : {
    display : 'flex',
    justifyContent : 'center',
    alignItems : 'center',
    height : 35,
    borderRadius : 10
  }
}
