// PROJECT DISCONTINUE

import React, { Component } from 'react';

import ic_cancel from '../resources/cancel_circle.png';

export default class BundleProductDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <div onClick={(e) => this.props.onCloseListener('close', null)} className='process_dialog_root layer_a'>
        <div onClick={(e) => e.stopPropagation()} className='process_dialog_container'>
          <div className='title_search_container orange_background'>
            <div className='full_flex default_text_medium_white bold_text'>PRODUK BUNDLE</div>
            <img onClick={(e) => this.props.onCloseListener('close', null)} className='custom_cursor_a mini_image_c' src={ic_cancel} alt='' />
          </div>

          <div style={{flexDirection : 'row', flex : 1, display : 'flex'}}>
            <div style={{flex : 1, background : 'blue'}}></div>
            <div style={{flex : 1, background : 'red'}}></div>
          </div>
        </div>
      </div>
    );
  }

}
