import React, { Component } from 'react';

import img_expand_more from '../resources/baseline_expand_more.png';

export default class DropdownSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_active : this.props.is_active === undefined ? true : this.props.is_active,
      data : this.props.data
    }
  }

  onDropdownSelected(position) {
    let temp = this.state.data;
    temp.selected = temp.data[position];
    if (this.props.onChangeSelected !== undefined) { this.props.onChangeSelected(temp.selected); }
  }

  populateDropdownItem() {
    let temp = this.state.data.data;
    let view = [];
    for (let i = 0; i < Object.keys(temp).length; i++) {
      if (temp[i].is_show) {
        view.push(
          <div onClick={this.onDropdownSelected.bind(this, i)} key={temp[i].key} className='side_padding vertical_padding_b default_text_normal_gray'>{temp[i].label}</div>
        );
      }
    }
    return view;
  }

  onChangeText(event) {
    let temp = this.state.data;
    for (let i = 0; i < Object.keys(temp.data).length; i++) {
      if (temp.data[i].label.toLowerCase().indexOf(event.target.value) !== -1) {
        temp.data[i].is_show = true;
      } else {
        temp.data[i].is_show = false;
      }
    }
    this.setState({ data : temp });
  }

  showChooserDropdown() {
    if (this.state.data.is_expand && this.state.is_active) {
      return(
        <div className='dropdown_wrapper layer_a normal_margin_top_b'>
          <div className='arrow-up'/>
          <div className='dropdown_content_e white_background'>
            <div className='dropdown_filter normal_border_bottom'>
              <input onChange={(event) => this.onChangeText(event)} placeholder='Pencarian...' onClick={(e) => e.stopPropagation()} className='normal_border_bottom transparent_input_c default_text_normal_gray'/>
            </div>
            <div className='dropdown_content_f'>
              {this.populateDropdownItem()}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  onDropdownClicked() {
    let temp = this.state.data;
    temp.is_expand = !temp.is_expand;
    for (let i = 0; i < temp.data.length; i++) {
      temp.data[i].is_show = true;
    }
    this.setState({ data : temp });
  }

  render() {
    return (
      <div onClick={this.onDropdownClicked.bind(this)} className='dropdown_container_category'>
        <div className='dropdown_chooser_category'>
          <div className='full_flex default_text_normal_gray'>{this.state.data.selected.label}</div>
          <div className='center_content circle_background_b'>
            <img className='mini_image_b' src={img_expand_more} alt=''/>
          </div>
        </div>
        { this.state.data.is_expand && this.state.is_active ? <div className='dropdown_mask' /> : null }
        {this.showChooserDropdown()}
      </div>
    );
  }

}
