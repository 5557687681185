import React, { Component } from 'react';

import LoadingDialog from './dialog/LoadingDialog';
import BannerDialog from './dialog/BannerDialog';
import ConfirmationDialog from './dialog/ConfirmationDialog';

import gif_img from './resources/loading.gif';
import img_error from './resources/error.png';
import img_edit from './resources/baseline_edit.png';
import icon_delete from './resources/baseline_delete.png';

var request = require('../utilities/NetworkRequest');
var cache_util = require('../utilities/CacheUtil');

export default class Banner extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : false,
      is_loading : false,
      is_error : false,
      error_message : '',
      user_information : {},
      show_banner_dialog : {
        show : false,
        body : null
      },
      show_delete_confirmation : {
        show : false,
        question : 'Apakah anda yakin ingin menghapus banner ini?',
        body : null,
        yes_answer : 'Hapus',
        no_answer : 'Batal'
      },
      left_icon : [],
      arrays : []
    };
  }

  componentDidMount() {
    this.requestData(false);
  }

  requestData(data_only) {
    this.setState({
      is_main_loading : true
    }, () => {
      try {
        let decoded_input = cache_util.onGetCacheData(cache_util.CACHE_INFO).result.user_information;
        let body = {
          wholesaler_id : decoded_input.wholesaler_id
        }
        request.onRequestData(request.OPTION_API_MAIN, '/show/banner', body, res_data => {
          if (res_data.code === 1) {
            if (data_only) {
              this.setState({
                is_main_loading : false,
                is_error : false,
                arrays : res_data.result.res_data
              });
            } else {
              let temp_dialog = this.state.show_banner_dialog;
              this.setState({
                is_main_loading : false,
                is_error : false,
                user_information : decoded_input,
                show_banner_dialog : temp_dialog,
                left_icon : res_data.result.left,
                arrays : res_data.result.res_data
              });
            }
          } else {
            this.setState({
              is_main_loading : false,
              is_error : true,
              error_message : res_data.message
            });
          }
        });
      } catch (e) {
        this.setState({
          is_main_loading : false,
          is_error : true,
          error_message : e.message
        });
      }
    });
  }

  onLeftHeaderActionClicked(data) {
    switch (data.key) {
      case 'add':
        let temp_dialog = this.state.show_banner_dialog;
        temp_dialog.show = !temp_dialog.show;
        let temp_body = {
          wholesaler_id : this.state.user_information.wholesaler_id,
          pcode : '',
          pcode_name : '',
          image : null,
          description : '',
          is_update : false
        }
        temp_dialog.body = JSON.stringify(temp_body);
        this.setState({ show_banner_dialog : temp_dialog });
        break;
      case 'refresh':
        this.requestData(true);
        break;
      default:
        break;
    }
  }

  initLeftHeader(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <div onClick={this.onLeftHeaderActionClicked.bind(this, data[i])} key={data[i].key} className='container_image'>
          <img className='mini_image_b' src={require(data[i].image + '')} alt=''/>
        </div>
      );
    }
    return view;
  }

  onEditClicked(data) {
    let temp_dialog = this.state.show_banner_dialog;
    temp_dialog.show = !temp_dialog.show;
    let temp_body = data;
    temp_body.wholesaler_id = this.state.user_information.wholesaler_id;
    temp_body.pcode_name = '';
    temp_body.is_update = true;
    temp_dialog.body = JSON.stringify(temp_body);
    this.setState({ show_banner_dialog : temp_dialog });
  }

  onDeleteClicked(data) {
    let temp_dialog = this.state.show_delete_confirmation;
    temp_dialog.show = !temp_dialog.show;
    temp_dialog.body = {
      wholesaler_id : this.state.user_information.wholesaler_id,
      idx : data.idx
    }
    this.setState({ show_delete_confirmation : temp_dialog });
  }

  initBannerData(data) {
    let view = [], view_row, length_data = Object.keys(data).length;
    for (let i = 0; i < length_data; i++) {
      if ((i%2) === 0) {
        view_row = [];
        view_row.push(
          <div key={i} className='banner_image_container'>
            <img className='default_text_normal_gray' style={{height:'100%', width:'100%'}} src={data[i].img_banner + '?' + new Date().getTime()} alt='Not Found'/>
            <div className='banner_action_wrapper'>
              <div onClick={this.onEditClicked.bind(this, data[i])} className='circle_background_d blue_background display_flex_center'>
                <img className='mini_image' src={img_edit} alt=''/>
              </div>
              <div onClick={this.onDeleteClicked.bind(this, data[i])} className='normal_margin_left circle_background_d red_background display_flex_center'>
                <img className='mini_image' src={icon_delete} alt=''/>
              </div>
            </div>
          </div>
        );
        view_row.push(
          <div key={'div' + i} style={{width:20}}/>
        );
        if ((length_data-1) === i) {
          view_row.push(
            <div key={'space' + length_data} style={{flex:0.5, padding:10}} />
          );
          view.push(
            <div key={'row' + length_data} className='banner_row_wrapper'>
              {view_row}
            </div>
          );
        }
      } else {
        view_row.push(
          <div key={i} className='banner_image_container'>
            <img className='default_text_normal_gray' style={{height:'100%', width:'100%'}} src={data[i].img_banner + '?' + new Date().getTime()} alt='Not Found'/>
            <div className='banner_action_wrapper'>
              <div onClick={this.onEditClicked.bind(this, data[i])} className='circle_background_d blue_background display_flex_center'>
                <img className='mini_image' src={img_edit} alt=''/>
              </div>
              <div onClick={this.onDeleteClicked.bind(this, data[i])} className='normal_margin_left circle_background_d red_background display_flex_center'>
                <img className='mini_image' src={icon_delete} alt=''/>
              </div>
            </div>
          </div>
        );
        view.push(
          <div key={'row' + i} className='banner_row_wrapper'>
            {view_row}
          </div>
        );
      }
    }
    return view;
  }

  onCloseBanner(action) {
    let temp_dialog = this.state.show_banner_dialog;
    temp_dialog.show = !temp_dialog.show;
    temp_dialog.body = null;
    this.setState({
      show_banner_dialog : temp_dialog
    }, () => {
      if (action === 'next') {
        this.requestData(true);
      }
    });
  }

  onCloseDeleteConfirmation(action) {
    let temp_dialog = this.state.show_delete_confirmation;
    temp_dialog.show = !temp_dialog.show;
    this.setState({
      is_loading : action === 'next' ? true : false,
      show_delete_confirmation : temp_dialog
    }, () => {
      if (action === 'next') {
        this.postDeleteBanner();
      }
    });
  }

  postDeleteBanner() {
    let temp_dialog = this.state.show_delete_confirmation;
    request.onRequestData(1, '/delete/banner', temp_dialog.body, res_data => {
      temp_dialog.body = null;
      if (res_data.code === 1) {
        this.setState({
          is_loading : false,
          is_error : false,
          show_delete_confirmation : temp_dialog
        }, () => {
          this.requestData(true);
        });
      } else {
        this.setState({
          is_loading : false,
          is_error : true,
          error_message : res_data.message,
          show_delete_confirmation : temp_dialog
        });
      }
    });
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className='loading_container'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return(
          <div className='root_category'>
            <LoadingDialog showDialog={this.state.is_loading} />
            <ConfirmationDialog data={this.state.show_delete_confirmation} onCloseDialog={(action) => this.onCloseDeleteConfirmation(action)} />
            { this.state.show_banner_dialog.show ? <BannerDialog data={this.state.show_banner_dialog.body} onCloseListener={(action) => this.onCloseBanner(action)} /> : null }
            <div className='header_container'>
              {this.initLeftHeader(this.state.left_icon)}
            </div>
            <div className='banner_container'>
              {this.initBannerData(this.state.arrays)}
            </div>
          </div>
        );
      } else {
        return (
          <div className='loading_container'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

}
