import React, { Component } from 'react';
import DatePicker from 'react-date-picker';
import TimePicker from 'react-time-picker';

import LoadingDialog from './LoadingDialog';
import ConfirmationDialog from './ConfirmationDialog';
import ErrorDialog from './ErrorDialog';

import ic_cancel from '../resources/cancel_circle.png';
import gif_img from '../resources/loading.gif';
import img_error from '../resources/error.png';
import img_edit from '../resources/baseline_edit.png';
import img_edit_black from '../resources/baseline_edit_black.png';

var request = require('../../utilities/NetworkRequest');

export default class ProcessDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : true,
      is_loading : false,
      is_error : false,
      body_error : {
        is_show : false,
        message : ''
      },
      error_message : '',
      show_confirm_confirmation : {
        show : false,
        question : 'Apakah anda yakin ingin membatalkan pesanan ini?',
        yes_answer : 'Lanjutkan',
        no_answer : 'Tutup'
      },
      header : {},
      data_table : [],
      input : {
        now_date : null,
        now_time : null
      }
    }
  }

  componentDidMount() {
    request.onRequestData(request.OPTION_API_MAIN, '/show/process', this.props.body, res_data => {
      if (res_data.code === 1) {
        let temp_input = this.state.input;
        temp_input.now_date = new Date(res_data.result.input.now_date);
        temp_input.now_time = res_data.result.input.now_time;
        this.setState({
          is_main_loading : false,
          is_error : false,
          header : res_data.result.information,
          data_table : res_data.result.data,
          input : temp_input
        });
      } else {
        this.setState({
          is_main_loading : false,
          is_error : true,
          error_message : res_data.message
        });
      }
    });
  }

  setQuantity(event, isCarton, position) {
    let temp_data_table = this.state.data_table, val;
    if (event.target.value.length !== 0) {
      val = event.target.value;
    } else {
      val = 0;
    }
    if (isCarton) {
      if (val >= 0) {
        temp_data_table[position].qty_real_a_processed = val;
      } else {
        temp_data_table[position].qty_real_a_processed = 0;
      }
    } else {
      if (val >= 0) {
        temp_data_table[position].qty_real_b_processed = val;
      } else {
        temp_data_table[position].qty_real_b_processed = 0;
      }
    }
    this.setState({
      data_table : temp_data_table
    });
  }

  onEditClicked(position) {
    if (this.state.header.flag_required === 0) {
      let temp_data_table = this.state.data_table;
      temp_data_table[position].is_edit = !temp_data_table[position].is_edit;
      this.setState({
        data_table : temp_data_table
      });
    }
  }

  initViewFlashsale(data) {
    if (data.fs_code !== null) {
      return <div className='tag_flashsale blue_background default_text_normal_white'>Flashsale</div>;
    } else {
      return null;
    }
  }

  initViewChanged(data) {
    if (data.is_changed) {
      return <div className='tag_flashsale red_background default_text_normal_white'>Harga Berubah</div>;
    } else {
      return null;
    }
  }

  getButtonClass() {
    if (this.state.header.flag_required === 1) {
      return 'side_margin_b custom_padding_b label_search_container gray_background';
    } else {
      return 'side_margin_b custom_padding_b label_search_container green_background';
    }
  }

  initItemProductView(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      if (data[i].is_edit) {
        view.push(
          <tr key={i}>
            <td>
              <div className='product_wrapper_dialog'>
                <div className='whole_padding default_text_normal_gray'>{data[i].pcode_name} - <b>{data[i].pcode}</b></div>
                {this.initViewFlashsale(data[i])}
                {this.initViewChanged(data[i])}
              </div>
            </td>
            <td>
              <div className='quantity_edit_wrapper'>
                <input onChange={(event) => this.setQuantity(event, true,i)} spellCheck='false' className='default_text_normal_gray transparent_input_b' type='number' value={data[i].qty_real_a_processed} />
                <img className='normal_padding_left_c mini_image_d' src={img_edit_black} alt=''/>
              </div>
            </td>
            <td>
              <div className='quantity_edit_wrapper'>
                <input onChange={(event) => this.setQuantity(event, false,i)} spellCheck='false' className='default_text_normal_gray transparent_input_b' type='number' value={data[i].qty_real_b_processed} />
                <img className='normal_padding_left_c mini_image_d' src={img_edit_black} alt=''/>
              </div>
            </td>
            <td>
              <div onClick={this.onEditClicked.bind(this,i)} className='side_margin_b custom_padding_b label_search_container green_background'>
                <img className='mini_image_d' src={img_edit} alt=''/>
                <div className='normal_padding_left_c default_text_normal_white process_button_h'>Simpan</div>
              </div>
            </td>
          </tr>
        );
      } else {
        view.push(
          <tr key={i}>
            <td>
              <div className='product_wrapper_dialog'>
                <div className='whole_padding default_text_normal_gray'>{data[i].pcode_name} - <b>{data[i].pcode}</b></div>
                {this.initViewFlashsale(data[i])}
                {this.initViewChanged(data[i])}
              </div>
            </td>
            <td className='whole_padding default_text_normal_gray'>{data[i].qty_real_a_processed}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].qty_real_b_processed}</td>
            <td>
              <div onClick={this.onEditClicked.bind(this,i)} className={this.getButtonClass()}>
                <img className='mini_image_d' src={img_edit} alt=''/>
                <div className='normal_padding_left_c default_text_normal_white process_button_h'>Ubah</div>
              </div>
            </td>
          </tr>
        );
      }
    }
    return view;
  }

  initTableView() {
    return (
      <table className='table_data_process_container'>
        <thead>
          <tr>
            <th className='whole_padding default_text_normal_gray'>Nama Barang</th>
            <th className='whole_padding default_text_normal_gray'>Jumlah Besar</th>
            <th className='whole_padding default_text_normal_gray'>Jumlah Kecil</th>
            <th className='whole_padding default_text_normal_gray'>Action</th>
          </tr>
        </thead>
        <tbody>
          {this.initItemProductView(this.state.data_table)}
        </tbody>
      </table>
    );
  }

  initHeaderView(data) {
    return (
      <table className='table_header_process_container'>
        <tbody>
          <tr>
            <td className='whole_padding default_text_normal_gray'>ID Ritel</td>
            <td className='whole_padding default_text_normal_gray'>:</td>
            <td className='whole_padding default_text_normal_gray bold_text'>{data.retail_id}</td>
          </tr>
          <tr>
            <td className='whole_padding default_text_normal_gray'>Nama Ritel</td>
            <td className='whole_padding default_text_normal_gray'>:</td>
            <td className='whole_padding default_text_normal_gray bold_text'>{data.retail_name}</td>
          </tr>
          <tr>
            <td className='whole_padding default_text_normal_gray'>Alamat</td>
            <td className='whole_padding default_text_normal_gray'>:</td>
            <td className='whole_padding default_text_normal_gray bold_text'>{data.address}</td>
          </tr>
          <tr>
            <td className='whole_padding default_text_normal_gray'>Waktu Pemesanan</td>
            <td className='whole_padding default_text_normal_gray'>:</td>
            <td className='whole_padding default_text_normal_gray'>{data.order_date + ' - ' + data.order_timestamp}</td>
          </tr>
          <tr>
            <td className='whole_padding default_text_normal_gray'>Catatan</td>
            <td className='whole_padding default_text_normal_gray'>:</td>
            <td className='whole_padding default_text_normal_gray'>{data.note}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  onDateChanged(date) {
    let temp_input = this.state.input;
    temp_input.now_date = date;
    this.setState({ input : temp_input });
  }

  onTimeChanged(time) {
    let temp_input = this.state.input;
    temp_input.now_time = time;
    this.setState({ input : temp_input });
  }

  initInputView(data) {
    return (
      <table className='table_header_process_container'>
        <tbody>
          <tr>
            <td className='whole_padding default_text_normal_gray'>Estimasi Tanggal Pengiriman</td>
            <td className='whole_padding default_text_normal_gray'>:</td>
            <td className='whole_padding default_text_normal_gray'><DatePicker value={data.now_date} locale="en-US" onChange={(date) => {this.onDateChanged(date)}} /></td>
          </tr>
          <tr>
            <td className='whole_padding default_text_normal_gray'>Estimasi Waktu Pengiriman</td>
            <td className='whole_padding default_text_normal_gray'>:</td>
            <td id='time_picker_format' className='whole_padding default_text_normal_gray'><TimePicker value={data.now_time} locale="bg-BG" onChange={(time) => {this.onTimeChanged(time)}} /></td>
          </tr>
        </tbody>
      </table>
    );
  }

  onCloseDialog(is_processed) {
    if (!this.state.is_loading) {
      this.props.onCloseListener(is_processed);
    }
  }

  onActionCancel() {
    this.setState({
      is_loading : true
    }, () => {
      let body = {
        wholesaler_id : this.props.body.wholesaler_id,
        user_id : this.props.body.user_id,
        order_no : this.props.body.order_no,
        retail_id : this.props.body.retail_id,
        status_transaction : 2
      }
      request.onRequestData(request.OPTION_API_MAIN, '/cancel/order', body, res_data => {
        if (res_data.code === 1) {
          this.setState({
            is_loading : false,
            is_error : false
          }, () => {
            this.onCloseDialog(true);
          });
        } else {
          this.setState({
            is_loading : false,
            is_error : true,
            error_message : res_data.message
          });
        }
      });
    });
  }

  onActionProcess() {
    this.setState({
      is_loading : true
    }, () => {
      try {
        let date_processed = this.state.input.now_date;
        let body = {
          processed_products : JSON.stringify(this.state.data_table),
          wholesaler_id : this.props.body.wholesaler_id,
          privilege_id : this.props.body.privilege_id,
          user_id : this.props.body.user_id,
          order_no : this.props.body.order_no,
          retail_id : this.props.body.retail_id,
          estimate_date_time : (date_processed.getMonth() + 1) + '/' + date_processed.getDate() + '/' + date_processed.getFullYear() + ' ' + this.state.input.now_time
        }
        for (let i = 0; i < Object.keys(this.state.data_table).length; i++) {
          if (this.state.data_table[i].is_changed
            && (Number(this.state.data_table[i].qty_real_a_processed) !== 0
            || Number(this.state.data_table[i].qty_real_b_processed) !== 0)) {
              throw Error('Untuk melanjutkan proses, khusus produk yang berubah harga silahkan ubah kuantitasnya menjadi 0 terlebih dahulu.');
          }
        }
        request.onRequestData(request.OPTION_API_MAIN, '/update/process', body, res_data => {
          if (res_data.code === 1) {
            this.setState({
              is_loading : false,
              is_error : false
            }, () => {
              this.onCloseDialog(true);
            });
          } else {
            this.setState({
              is_loading : false,
              body_error : {
                is_show : true,
                message : res_data.message
              }
            });
          }
        });
      } catch (e) {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : e.message
          }
        });
      }
    });
  }

  initMainView() {
    if (this.state.is_main_loading) {
      return (
        <div onClick={(e) => e.stopPropagation()} className='pop_up_dialog_container'>
          <img className='big_image' src={gif_img} alt='' />
          <div className='custom_padding_c default_text_big_gray'>Memuat Data</div>
        </div>
      );
    } else {
      if (this.state.is_error) {
        return (
          <div onClick={(e) => e.stopPropagation()} className='pop_up_dialog_container'>
            <img className='normal_margin_top_c big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_medium_gray'>{this.state.error_message}</div>
          </div>
        );
      } else {
        return (
          <div onClick={(e) => e.stopPropagation()} className='process_dialog_container'>
            <LoadingDialog showDialog={this.state.is_loading}/>
            <ConfirmationDialog data={this.state.show_confirm_confirmation} onCloseDialog={(action) => this.onCloseCancelConfirmation(action)} />
            {this.showErrorDialog(this.state.body_error)}
            <div className='title_search_container orange_background'>
              <div className='full_flex default_text_medium_white bold_text'>DETAIL TRANSAKSI</div>
              <img onClick={this.onCloseDialog.bind(this, false)} className='custom_cursor_a mini_image_c' src={ic_cancel} alt='' />
            </div>
            <div className='process_dialog_page'>
              {this.initHeaderView(this.state.header)}
              {this.initTableView()}
              {this.initInputView(this.state.input)}
              <div className='button_process_wrapper'>
                <div onClick={this.onActionProcess.bind(this)} className='default_text_normal_white_b green_background process_button'>Proses Pesanan</div>
                <div style={{width : 20}}/>
                <div onClick={this.onCancelOrder.bind(this)} className='default_text_normal_white_b red_background process_button'>Batal Pesanan</div>
              </div>
            </div>
          </div>
        );
      }
    }
  }

  onCancelOrder() {
    let temp_confirmation = this.state.show_confirm_confirmation;
    temp_confirmation.show = !temp_confirmation.show;
    this.setState({ show_confirm_confirmation : temp_confirmation });
  }

  onCloseCancelConfirmation(action) {
    switch (action) {
      case 'cancel' :
        this.onCancelOrder();
        break;
      case 'next' :
        this.onCancelOrder();
        this.onActionCancel();
        break;
      default :
        break;
    }
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  render() {
    return (
      <div onClick={this.onCloseDialog.bind(this, false)} className='process_dialog_root'>
        {this.initMainView()}
      </div>
    );
  }

}
