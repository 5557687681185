import React, { Component } from 'react';

import LoadingDialog from './dialog/LoadingDialog';
import ErrorDialog from './dialog/ErrorDialog';

import img_key from './resources/key.png';
import img_telephone from './resources/telephone.png';
import img_tp_icon from './resources/tp_icon.ico';

var request = require('../utilities/NetworkRequest');
var cache_util = require('../utilities/CacheUtil');
var view_util = require('../utilities/ViewUtil');
var str_util = require('../utilities/StringUtil');

export default class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_loading : false,
      body_error : {
        is_show : false,
        message : ''
      },
      form : {
        handphone : '',
        password : ''
      }
    }
  }

  componentDidMount() {
    if (view_util.isValidVersion()) {
      window.open(request.IP_PATH + '/gphome/dashboard/','_self');
    }
  }

  onChangeText(key, event) {
    let temp = this.state.form;
    temp[key] = event.target.value;
    this.setState({ form : temp });
  }

  onLoginClicked() {
    this.setState({
      is_loading : true
    }, () => {
      let temp_body = {
        handphone : str_util.parseHandphone(this.state.form.handphone),
        password : this.state.form.password
      }
      request.onRequestData(1, '/post/login', temp_body, res_data => {
        if (res_data.code === 1) {
          cache_util.onSaveCache(cache_util.CACHE_INFO, JSON.stringify(res_data));
          window.open(request.IP_PATH + '/gphome/dashboard/','_self');
        } else {
          this.setState({
            is_loading : false,
            body_error : {
              is_show : true,
              message : res_data.message
            }
          });
        }
      });
    });
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className='root_login'>
        <LoadingDialog showDialog={this.state.is_loading}/>
        {this.showErrorDialog(this.state.body_error)}
        <div className='login_form_container'>
          <div className='main_icon_container display_flex_center'>
            <div className='display_flex_center circle_background_g white_background'>
              <img alt='' style={{width:50,height:47}} src={img_tp_icon}/>
            </div>
          </div>
          <div className='normal_margin_top_g default_text_normal_white'>Nomor Handphone</div>
          <div className='normal_margin_top login_editor_container'>
            <input onChange={(event) => this.onChangeText('handphone', event)} value={this.state.form.handphone} spellCheck='false' className='default_text_normal_gray transparent_input_c full_flex' type='text' />
            <div className='vertical_divider' />
            <div className='side_margin_b'>
              <img className='mini_image_d' src={img_telephone} alt=''/>
            </div>
          </div>
          <div className='normal_margin_top_c default_text_normal_white'>Password</div>
          <div className='normal_margin_top login_editor_container'>
            <input onChange={(event) => this.onChangeText('password', event)} value={this.state.form.password} spellCheck='false' className='default_text_normal_gray transparent_input_c full_flex' type='password' />
            <div className='vertical_divider' />
            <div className='side_margin_b'>
              <img className='mini_image_d' src={img_key} alt=''/>
            </div>
          </div>
          <div onClick={this.onLoginClicked.bind(this)} className='default_text_normal_white normal_margin_top_g process_button_g red_background'>MASUK</div>
        </div>
      </div>
    );
  }

}
