import React, { Component } from 'react';

export default class ConfirmationDialog extends Component {

  onConfirmClicked(action) {
    this.props.onCloseDialog(action);
  }

  render() {
    if (this.props.data.show) {
      return(
        <div onClick={(e) => this.props.onCloseDialog('cancel')} className='process_dialog_root layer_a'>
          <div onClick={(e) => e.stopPropagation()} className='pop_up_dialog_container_b layer_b whole_padding_c'>
            <div className='normal_padding_bottom_c default_text_medium_gray'>{this.props.data.question}</div>
            <div className='button_process_wrapper_c'>
              <div onClick={this.onConfirmClicked.bind(this, 'cancel')} className='default_text_normal_white_b red_background process_button_b'>{this.props.data.no_answer}</div>
              <div style={{width : 15}}/>
              <div onClick={this.onConfirmClicked.bind(this, 'next')} className='default_text_normal_white_b green_background process_button_b'>{this.props.data.yes_answer}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

}
