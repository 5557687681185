import React from 'react';
import Switch from "react-switch";

import TableSearch from '../component/native/TableSearch';
import DropdownSearch from '../component/native/DropdownSearch';

import img_setting from '../component/resources/settings.png';
import img_left_chevron from '../component/resources/baseline_chevron_left_2x.png';
import img_right_chevron from '../component/resources/baseline_chevron_right_2x.png';
import gif_img from '../component/resources/loading.gif';
import img_error from '../component/resources/error.png';

export function getPaymentType(data) {
  if (4 <= data && data <= 12) {
    return <div className='custom_padding_b label_search_container pink_background default_text_normal_white'>Damcorp</div>
  } else {
    switch (data) {
      case 1:
        return <div className='custom_padding_b label_search_container red_background default_text_normal_white'>Cash</div>
      case 2:
        return <div className='custom_padding_b label_search_container orange_background default_text_normal_white'>Saldo TP</div>
      case 3:
        return <div className='custom_padding_b label_search_container green_background default_text_normal_white'>Kredit</div>
      case 31:
        return <div className='custom_padding_b label_search_container yellow_background default_text_normal_white'>Kombinasi</div>
      default:
        return <div className='custom_padding_b label_search_container red_background default_text_normal_white'>Not Found</div>
    }
  }
}

export function getMainViewDialog(context, main_view) {
  if (context.state.is_main_loading) {
    return (
      <div onClick={(e) => e.stopPropagation()} className='pop_up_dialog_container'>
        <img className='big_image' src={gif_img} alt='' />
        <div className='custom_padding_c default_text_big_gray'>Memuat Data</div>
      </div>
    );
  } else {
    if (context.state.is_error) {
      return (
        <div onClick={(e) => e.stopPropagation()} className='pop_up_dialog_container'>
          <img className='normal_margin_top_c big_image_b' src={img_error} alt='' />
          <div className='whole_padding_c default_text_medium_gray'>{context.state.error_message}</div>
        </div>
      );
    } else {
      return main_view;
    }
  }
}

export function onEditTextChanged(context, event, action) {
  let temp_data = context.state.body;
  temp_data[action] = event.target.value;
  context.setState({ body : temp_data });
}

function getInputMinimalStyle(is_readOnly) {
  if (is_readOnly) {
    return 'normal_margin_top default_text_normal_gray non_transparent_input_inactive'
  } else {
    return 'normal_margin_top default_text_normal_gray non_transparent_input';
  }
}

export function getInputViewMinimal(context, TAG, label, placeholder, is_readOnly) {
  if (is_readOnly === undefined) { is_readOnly = false; }
  if (placeholder === undefined) { placeholder = 'Isi kolom ini...'; }
  return (
    <div className='normal_margin_top_h'>
      <div className='default_text_normal_gray bold_text'>{label}</div>
      <input placeholder={placeholder} readOnly={is_readOnly} onChange={ (event) => onEditTextChanged(context, event, TAG) } value={context.state.body[TAG]} style={{minWidth:400}} spellCheck='false' className={getInputMinimalStyle(is_readOnly)} type='text' />
    </div>
  );
}

export function getInputAreaViewMinimal(context, TAG, label) {
  return (
    <div className='normal_margin_top_h'>
      <div className='default_text_normal_gray bold_text'>{label}</div>
      <textarea onChange={ (event) => onEditTextChanged(context, event, TAG) } value={context.state.body[TAG]} spellCheck='false' className='normal_margin_top default_text_normal_gray text_area_input_b' type='text' />
    </div>
  );
}

export function limitValue(limit, data) {
  if (data.length > limit) {
    return data.substring(0,limit) + '...';
  } else {
    return data;
  }
}

export function getAdminInformation(context, data) {
  let view = [];
  if (context.state.user_information !== null) {
    if (context.state.user_information.wholesaler_id === 'TP9999') {
      view.push(<td key='wholesaler_name' className='whole_padding default_text_normal_gray'>{data.wholesaler_name}</td>);
      view.push(<td key='region' className='whole_padding default_text_normal_gray'>{data.region}</td>);
      view.push(<td key='rsm' className='whole_padding default_text_normal_gray'>{data.rsm}</td>);
      view.push(<td key='asm' className='whole_padding default_text_normal_gray'>{data.asm}</td>);
    }
  }
  return view;
}

export function getAdminHeader(context, data) {
  let view = [];
  if (context.state.user_information !== null) {
    if (context.state.user_information.wholesaler_id === 'TP9999') {
      view.push(<th key='wholesaler_name' className='whole_padding default_text_normal_gray'>{data.wholesaler_name}</th>)
      view.push(<th key='region' className='whole_padding default_text_normal_gray'>{data.region}</th>)
      view.push(<th key='rsm' className='whole_padding default_text_normal_gray'>{data.rsm}</th>)
      view.push(<th key='asm' className='whole_padding default_text_normal_gray'>{data.asm}</th>)
    }
  }
  return view;
}

function onActionSwitched(context, checked, action) {
  let temp_data = context.state.information;
  temp_data[action] = checked;
  context.setState({ information : temp_data });
}

export function getSwitchView(context, TAG, label, color_code) {
  return (
    <div className='wrapper_status_product normal_padding_bottom'>
      <Switch
        onChange={(checked) => { onActionSwitched(context, checked, TAG) }}
        checked={context.state.information[TAG]}
        className="react-switch"
        height={20}
        width={40}
        onColor={color_code}
      />
      <div className='default_text_normal_gray normal_padding_left'>{label}</div>
    </div>
  );
}

export function getSwitchWrapper(icon, label, is_bottom, switch_view) {
  return (
    <div className={getDropdownBottomStyle(is_bottom)}>
      <div className='wrapper_left_form_product'>
        <img className='normal_margin_top_d mini_image_d' src={icon} alt=''/>
        <div className='normal_margin_left default_text_normal_gray'>{label}</div>
      </div>
      { switch_view }
    </div>
  );
}

function onSwicthBundle(context, TAG, position, checked) {
  let data = context.state[TAG];
  data[position].is_active = checked;
  context.setState({ [TAG] : data });
}

export function getSwitchBundle(context, TAG, icon, label, is_bottom) {
  let view = [];
  let bundle_data = context.state[TAG], n_data = Object.keys(bundle_data).length, m_class;
  for (let i = 0; i < n_data; i++) {
    m_class = 'wrapper_status_product';
    if (i !== n_data-1) {
      m_class += ' normal_padding_bottom';
    }
    view.push(
      <div key={i} className={m_class}>
        <Switch
          onChange={ (checked) => onSwicthBundle(context, TAG, i, checked) }
          checked={bundle_data[i].is_active}
          className="react-switch"
          height={20}
          width={40}
          onColor='#43987b'
        />
        <div className='default_text_normal_gray normal_padding_left'>{bundle_data[i].description}</div>
      </div>
    );
  }
  return getSwitchWrapper(icon, label, is_bottom, (
    <div className={getRightDropdownBottomStyle(is_bottom)}>
      { view }
    </div>
  ));
}

function getDropdownBottomStyle(is_bottom) {
  if (is_bottom) {
    return 'wrapper_form_product normal_border_bottom gray_background_b';
  } else {
    return 'wrapper_form_product gray_background_b';
  }
}

function getRightDropdownBottomStyle(is_bottom) {
  if (is_bottom) {
    return 'wrapper_right_form_product normal_padding_bottom';
  } else {
    return 'wrapper_right_form_product normal_padding_bottom normal_border_bottom';
  }
}

export function getDropdownView(context, TAG, icon, label, is_bottom, is_active, is_show, onChangeSelected) {
  if (is_show) {
    return (
      <div className={getDropdownBottomStyle(is_bottom)}>
        <div className='wrapper_left_form_product'>
          <img className='normal_margin_top_d mini_image_d' src={icon} alt=''/>
          <div className='normal_margin_left default_text_normal_gray'>{label}</div>
        </div>
        <div className={getRightDropdownBottomStyle(is_bottom)}>
          <DropdownSearch is_active={is_active} data={context.state[TAG]} onChangeSelected={onChangeSelected}/>
        </div>
      </div>
    );
  } else { return null; }
}

function onChangeValueInput(context, e, action) {
  let temp_data = context.state.information;
  temp_data[action] = e.target.value;
  context.setState({ information : temp_data });
}

function getBottomStyle(is_bottom) {
  if (is_bottom) {
    return 'wrapper_form_product normal_border_bottom normal_padding_bottom';
  } else {
    return 'wrapper_form_product';
  }
}

export function getInputView(context, TAG, icon, label, placeholder, is_non_active, is_show, is_bottom) {
  if (is_show) {
    return (
      <div className={getBottomStyle(is_bottom)}>
        <div className='wrapper_left_form_product'>
          <img className='normal_margin_top_d mini_image_d' src={icon} alt=''/>
          <div className='normal_margin_left default_text_normal_gray'>{label}</div>
        </div>
        <div className='wrapper_right_form_product normal_border_bottom'>
          <div className='default_text_normal_gray'>{label}</div>
          <input placeholder={placeholder} readOnly={is_non_active} onChange={(e) => onChangeValueInput(context, e, TAG)} value={context.state.information[TAG]} spellCheck='false' className='normal_margin_top_d bold_text default_text_normal_gray transparent_input_c' type='text' />
        </div>
      </div>
    );
  } else { return null; }
}

export function getZeroRow(length_span) {
  return (
    <tr key='zero_row'>
      <td colSpan={length_span} className='whole_padding default_text_normal_gray'>Tidak ada data yang bisa ditampilkan . . . .</td>
    </tr>
  );
}

function onPreviousClicked(state, listener) {
  if (Object.keys(state.pagination_array).length > 1) {
    // IF PAGINATION INDEX BEFORE IS NOT 0, THAT MEAN PAGINATION ARRAY HAVE MORE THAN 1 DATA
    let temp_pagination_array = [], index = 0;
    while (index < Object.keys(state.pagination_array).length - 1) {
      temp_pagination_array.push(state.pagination_array[index]);
      index++;
    }
    index--;
    let body = state.user_information;
    if (state.pagination_array[index].hasOwnProperty('index_before')) {
      body.idx = state.pagination_array[index].index_before;
    } else {
      body.last_time_stamp = state.pagination_array[index].time_stamp_before;
    }
    listener(body, temp_pagination_array);
  }
}

function onNextClicked(state, listener) {
  if (state.pagination.is_more) {
    let body = state.user_information;
    if (state.pagination.hasOwnProperty('index')) {
      body.idx = state.pagination.index;
    } else {
      body.last_time_stamp = state.pagination.time_stamp;
    }
    listener(body);
  }
}

function getClassPagination(data, is_left, state_pagination) {
  if (is_left) {
    if (Object.keys(state_pagination).length <= 1) {
      return 'btn_previous_dead gray_background'
    } else {
      return 'btn_previous pink_background'
    }
  } else {
    if (data.is_more) {
      return 'btn_next pink_background';
    } else {
      return 'btn_next_dead gray_background';
    }
  }
}

export function initPaginationTable(state, listener_previous, listener_next) {
  return (
    <div key='default_pagination' className='normal_padding_bottom product_pagination'>
      <div onClick={onPreviousClicked.bind(this, state, listener_previous)} className={getClassPagination(state.pagination, true, state.pagination_array)}>
        <img className='mini_image_c' src={img_left_chevron} alt=''/>
      </div>
      <div style={{backgroundColor:'white', width:1, height:30}}/>
      <div onClick={onNextClicked.bind(this, state, listener_next)} className={getClassPagination(state.pagination, false, state.pagination_array)}>
        <img className='mini_image_c' src={img_right_chevron} alt=''/>
      </div>
    </div>
  );
}

function initLeftToolbar(data, listener) {
  let view = [];
  for (let i = 0; i < Object.keys(data).length; i++) {
    view.push(
      <div onClick={listener.bind(this, data[i])} key={data[i].key} className='container_image'>
        <img className='mini_image_b' src={require(data[i].image + '')} alt=''/>
      </div>
    );
  }
  return view;
}

export function showDialogAdvance(context, action, listener_next) {
  let temp = context.state.dialog_advance;
  temp.show = !temp.show;
  switch (action) {
    case 'next' :
      let temp_info = context.state.user_information;
      temp_info.filter = JSON.stringify(context.state.dialog_advance.body);
      context.setState({
        user_information : temp_info,
        dialog_advance : temp
      }, () => listener_next());
      break;
    default :
      context.setState({ dialog_advance : temp });
      break;
  }
}

function initSearchHeader(context, TABLE_KEY, listener_adv_next) {
  return (
    <div className='search_root_container'>
      {/* { context.state.search_header !== null ? <TableSearch header={context.state.search_header} table={context.state[TABLE_KEY]} onTableUpdate={(data_table) => context.setState({ [TABLE_KEY] : data_table })} /> : null } */}
      {
        context.state.dialog_advance.body !== null ? (
          <div onClick={showDialogAdvance.bind(this, context, 'open', listener_adv_next)} className='container_image_b'>
            <img className='mini_image_b' src={img_setting} alt=''/>
          </div>
        ) : null
      }
    </div>
  );
}

export function initViewToolbar(context, TABLE_KEY, listener_left_tbr, listener_adv_next) {
  return (
    <div key='default_toolbar' className='product_toolbar_wrapper'>
      <div className='product_toolbar_left'>
        { context.state.toolbar.hasOwnProperty('left') ? initLeftToolbar(context.state.toolbar.left, listener_left_tbr) : null }
      </div>
      <div className='product_toolbar_right'>
        { initSearchHeader(context, TABLE_KEY, listener_adv_next) }
      </div>
    </div>
  );
}

export function viewQuickButton(context, listener) {
  if (context.state.quick_button === undefined) { context.state.quick_button = null; }
  if (context.state.quick_button === null) { context.state.quick_button = []; }
  let view = [];
  for (let i = 0; i < Object.keys(context.state.quick_button).length; i++) {
    view.push(
      <div onClick={listener.bind(this, context.state.quick_button[i])} key={context.state.quick_button[i].key} className='label_search_container custom_cursor_a' style={{float : 'left', padding : '5px 10px', margin : '10px 10px 0 0', background : context.state.quick_button[i].background}}>
        <span className='default_text_normal_white'>{context.state.quick_button[i].description}</span>
      </div>
    );
  }
  if (view.length > 0) {
    return (
      <div style={{display : 'inline-block'}}>{view}</div>
    );
  } else { return null; }
}

function initDataTable(data, body_listener) {
  let view = [], c_row = 0;
  for (let i = 0; i < Object.keys(data).length; i++) {
    if (data[i].show_data) {
      c_row++;
      view.push(body_listener(i, data[i]));
    }
  }
  if (c_row === 0) { view.push(getZeroRow(99)); }
  return view;
}

function getTableStyle(is_scrollable) {
  let m_style = 'table_default_container vertical_margin';
  if (is_scrollable === undefined) { is_scrollable = true; }
  if (is_scrollable) {
    m_style += ' scrollable';
  }
  return m_style;
}

export function initViewTable(data_table, header_view, body_listener, is_scrollable) {
  return (
    <table key='default_table' className={getTableStyle(is_scrollable)}>
      <thead>
        {header_view}
      </thead>
      <tbody>
        {initDataTable(data_table, body_listener)}
      </tbody>
    </table>
  );
}

function onTabClicked(context, position) {
  let temp_data_tab = context.state.tab_view, temp_content = context.state.tab_content;
  for (let i = 0; i < Object.keys(temp_data_tab).length; i++) {
    if (i === position) {
      temp_data_tab[i].is_selected = true;
      temp_content[i].is_show = true;
    } else {
      temp_data_tab[i].is_selected = false;
      temp_content[i].is_show = false;
    }
  }
  context.setState({
    tab_view : temp_data_tab,
    tab_content : temp_content
  })
}

function populateTabPane(context) {
  let data = context.state.tab_view;
  let view = [], length_data = Object.keys(data).length;
  view.push( <div key='first_tab' className='first_tab_divider'/> )
  for (let i = 0; i < length_data; i++) {
    if (data[i].is_selected) {
      view.push( <div key={data[i].key} onClick={onTabClicked.bind(this, context, i)} className='tab_pane normal_border_bottom_white white_background default_text_normal_gray bold_text'>{data[i].label}</div> );
    } else {
      view.push( <div key={data[i].key} onClick={onTabClicked.bind(this, context, i)} className='tab_pane gray_background_c default_text_normal_gray normal_border_bottom_b'>{data[i].label}</div> );
    }
    if (i !== length_data - 1) {
      view.push( <div key={'divider_' + i} className='tab_divider'/> );
    }
  }
  view.push( <div key='last_tab' className='last_tab_divider'/> );
  return view;
}

function initLeftHeader(data, listener) {
  let view = [];
  for (let i = 0; i < Object.keys(data).length; i++) {
    if (data[i].is_show) {
      for (let j = 0; j < Object.keys(data[i].left).length; j++) {
        view.push(
          <div onClick={listener.bind(this, i)} key={data[i].left[j].key} className='container_image'>
            <img className='mini_image_b' src={require(data[i].left[j].image + '')} alt=''/>
          </div>
        );
      }
      break;
    }
  }
  return view;
}

export function initTabView(context, left_header_listener) {
  let view = [];
  view.push(
    <div key='key_container' className='tab_container'>
      {populateTabPane(context)}
    </div>
  );
  view.push(
    <div key='key_header_container' className='header_container'>
      {initLeftHeader(context.state.tab_content, left_header_listener)}
    </div>
  );
  return view;
}

function getContentTable(pos_content, data, listener) {
  let view = [], i;
  for (i = 0; i < Object.keys(data.arrays).length; i++) {
    view.push(listener(pos_content, i, data.arrays[i]));
  }
  if (i < 1) { view.push(getZeroRow(99)); }
  return view;
}

export function initTableContent(pos_content, data, header_view, body_listener) {
  return (
    <div key={pos_content} className='table_root_container'>
      <table className='table_container_category'>
        <thead>
          {header_view}
        </thead>
        <tbody>
          {getContentTable(pos_content, data, body_listener)}
        </tbody>
      </table>
    </div>
  );
}
