import React, { Component } from 'react';

const view_util = require('../../utilities/ViewUtil');
var request = require('../../utilities/NetworkRequest');

class MirrorRetailDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      body : {
        wholesaler_id_a : '',
        access_code_a : '',
        wholesaler_id_b : '',
        access_code_b : ''
      }
    }
  }

  onClose() {
    this.props.context.setState({ show_mirror_dialog : false });
  }

  onMirrorRequest() {
    try {
      if (this.state.body.wholesaler_id_a.length === 0) { throw Error('Kode grosir A tidak boleh kosong!'); }
      if (this.state.body.access_code_a.length === 0) { throw Error('Kode akses grosir A tidak boleh kosong!'); }
      if (this.state.body.wholesaler_id_b.length === 0) { throw Error('Kode grosir B tidak boleh kosong!'); }
      if (this.state.body.access_code_b.length === 0) { throw Error('Kode akses grosir B tidak boleh kosong!'); }
      this.props.context.setState({ is_loading : true });
      request.onRequestData(request.OPTION_API_MAIN, '/update/mirror_retail', this.state.body, res_data => {
        if (res_data.code === 1) {
          this.props.context.setState({
            is_loading : false
          }, () => {
            this.onClose();
          });
        } else {
          this.props.context.setState({
            is_loading : false,
            body_error : {
              is_show : true,
              message : res_data.message
            }
          });
        }
      });
    } catch (e) {
      this.props.context.setState({
        body_error : {
          is_show : true,
          message : e.message
        }
      });
    }
  }

  render() {
    return (
      <div onClick={(e) => this.onClose()} className='process_dialog_root layer_a'>
        <div onClick={(e) => e.stopPropagation()} className='default_dialog_container'>
          {view_util.getInputViewMinimal(this, 'wholesaler_id_a', 'Kode Grosir A')}
          {view_util.getInputViewMinimal(this, 'access_code_a', 'Kode Akses Grosir A')}
          {view_util.getInputViewMinimal(this, 'wholesaler_id_b', 'Kode Grosir B')}
          {view_util.getInputViewMinimal(this, 'access_code_b', 'Kode Akses Grosir B')}
          <div className='button_process_wrapper_b'>
            <div onClick={(e) => this.onClose()} className='default_text_normal_white_b red_background process_button_C'>Batal</div>
            <div style={{width : 20}}/>
            <div onClick={() => this.onMirrorRequest()} className='default_text_normal_white_b green_background process_button_C'>Lanjutkan</div>
          </div>
        </div>
      </div>
    );
  }

}

export function showMirrorRetailDialog(context) {
  if (context.state.show_mirror_dialog === undefined) { context.state.show_mirror_dialog = false; }
  if (context.state.show_mirror_dialog) {
    return <MirrorRetailDialog context={context}/>;
  } else {
    return null;
  }
}
