import React, { Component } from 'react';

import { formatSeparator } from '../utilities/StringUtil';
import { showCatalogDialog } from './dialog/CatalogDialog';
import { showError, showErrorDialog, showErrorPage } from './native/V2_Error';
import { showLoadingPage, showLoadingDialog } from './native/V2_Loading';

import ic_back from './resources_v2/back_a.png';
import ic_delete from './resources/delete.png';

var cache_util = require('../utilities/CacheUtil');
var request = require('../utilities/NetworkRequest');

export default class CatalogEditor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user_information : null,
      p_setting : null
    }
  }

  componentDidMount() { this.onRefresh(); }

  onRefresh() {
    try {
      let decoded_input = this.state.user_information;
      if (decoded_input === null) {
        decoded_input = cache_util.onGetCacheData(cache_util.CACHE_INFO);
        if (decoded_input === null) { throw Error('Session akun tidak ditemukan! Silahkan login kembali!'); }
        decoded_input = decoded_input.result.user_information;
      }
      request.onRequestData(1, '/show/catalog_editor', decoded_input, res_data => {
        if (res_data.code === 1) {
          let cache_setting = cache_util.onGetCacheData(cache_util.CACHE_CATALOG_EDITOR);
          if (cache_setting !== null) {
            if (cache_setting.version !== res_data.result.version) { cache_setting = res_data.result; }
          } else {
            cache_setting = res_data.result;
          }
          this.setState({
            user_information : decoded_input,
            loading_page : false,
            p_setting : cache_setting
          });
        } else {
          this.setState({
            loading_page : false,
            error_page : showError(res_data)
          });
        }
      });
    } catch (e) {
      this.setState({ error_page : showError({ message : e.message, result : null }) });
    }
  }

  showPaper() {
    let view = [], n_data = Object.keys(this.state.p_setting.paper.data).length;
    for (let i = 0; i < n_data; i++) {
      if (this.state.p_setting.paper.selected.id === this.state.p_setting.paper.data[i].id) {
        view.push( <div key={i} className='custom_cursor_a default_text_big_white bold_text' style={{marginLeft : 10, background : 'red', borderRadius : 5, width : 120, height : 60, display : 'flex', justifyContent : 'center', alignItems : 'center'}}>{this.state.p_setting.paper.data[i].description}</div> );
      } else {
        view.push( <div key={i} className='custom_cursor_a default_text_big_red bold_text' style={{marginLeft : 10, border : '1px solid red', borderRadius : 5, width : 120, height : 60, display : 'flex', justifyContent : 'center', alignItems : 'center'}}>{this.state.p_setting.paper.data[i].description}</div> );
      }
    }
    return view;
  }

  onDeleteProduct(pos_i, pos_j, pos_k) {
    this.setState({ is_loading : true }, () => {
      let m_page = this.state.p_setting;
      m_page.page.data[pos_i].catalog[pos_j].splice(pos_k, 1);
      cache_util.onSaveCache(cache_util.CACHE_CATALOG_EDITOR, JSON.stringify(m_page));
      this.setState({ p_setting : m_page, is_loading : false });
    });
  }

  getCatalog(data) {
    let view = [], p_width, p_height, i, j, k;
    for (i = 0; i < Object.keys(data.data).length; i++) {
      if (data.data[i].id === data.selected) {
        for (j = 0; j < Object.keys(data.data[i].catalog).length; j++) {
          for (k = 0; k < Object.keys(data.data[i].catalog[j]).length; k++) {
            p_width = this.state.p_setting.paper.selected.style_value.h_init * data.data[i].catalog[j][k].h_value;
            p_height = this.state.p_setting.paper.selected.style_value.v_init;
            let pos_i = i, pos_j = j, pos_k = k;
            if (data.data[i].catalog[j][k].p_type === 'banner') {
              view.push(
                <div id='catalog_root' key={j+''+k} style={{float : 'left', width : p_width, height : p_height, display : 'flex', flexDirection : 'row', position : 'relative'}}>
                  <div onClick={() => this.onDeleteProduct(pos_i, pos_j, pos_k)} id='catalog_delete' className='v2_custom_pointer_a' style={{position : 'absolute', zIndex : 1, right : 10, top : 10, width : 40, height : 40, borderRadius : 20, justifyContent : 'center', alignItems : 'center'}}><img alt='' src={ic_delete} /></div>
                  <div style={{display : 'flex', flexDirection : 'column', flex : 1}}>


                    <div style={{position : 'relative', display : 'flex', flex : 1, flexDirection : 'row', padding : 10}}>
                      <div className='default_text_normal_gray_b' style={{whiteSpace : 'nowrap', overflow : 'hidden', textOverflow : 'ellipsis', position : 'absolute', width : 300, background : 'yellow', padding : '5px 10px 5px 10px', border : '2px solid orange', borderTopLeftRadius : 10, borderBottomRightRadius : 10}}>{data.data[i].catalog[j][k].name}</div>
                      <div style={{position : 'absolute', left: 300, display : 'flex', flexDirection : 'column', bottom : 10}}>
                        <div className='strike_through_text bold_text' style={{fontSize : 30, color : 'grey'}}>{'Rp. ' + formatSeparator(data.data[i].catalog[j][k].price_comparison)}</div>
                        <div className='bold_text' style={{fontSize : 30, color : 'black'}}>{'Rp. ' + formatSeparator(data.data[i].catalog[j][k].price) + ' /' + data.data[i].catalog[j][k].uom}</div>
                      </div>
                      <img alt='' style={{objectFit : 'cover', width : 280, height : (p_height - 21)}} src={data.data[i].catalog[j][k].image}/>
                      <div style={{flex : 1}}/>
                      <div style={{display : 'flex', flexDirection : 'column', paddingLeft : 10, width : 400}}>
                        <div className='bold_text' style={{fontSize : 30, color : '#ea343c', textAlign : 'right'}}>{data.data[i].catalog[j][k].header_1}</div>
                        <div className='bold_text' style={{fontSize : 40, color : 'red', textAlign : 'right'}}>{data.data[i].catalog[j][k].header_2}</div>
                      </div>
                    </div>





                    <div style={{height : 1, background : 'red', flexShrink : 0}}/>
                  </div>
                  <div style={{width : 1, background : 'red', flexShrink : 0}}/>
                </div>
              );
            } else if (data.data[i].catalog[j][k].p_type === 'product') {
              view.push(
                <div id='catalog_root' key={j+''+k} style={{position : 'relative', float : 'left', width : p_width, height : p_height, display : 'flex', flexDirection : 'row'}}>
                  <div onClick={() => this.onDeleteProduct(pos_i, pos_j, pos_k)} id='catalog_delete' className='v2_custom_pointer_a' style={{position : 'absolute', zIndex : 1, right : 10, top : 10, width : 40, height : 40, borderRadius : 20, justifyContent : 'center', alignItems : 'center'}}><img alt='' src={ic_delete} /></div>
                  <div style={{display : 'flex', flexDirection : 'column', flex : 1}}>


                    <div style={{flex : 1, display : 'flex', flexDirection : 'column', padding : 10}}>
                      <div className='default_text_normal_gray_b' style={{width : p_width-44, whiteSpace : 'nowrap', overflow : 'hidden', textOverflow : 'ellipsis', background : 'yellow', padding : '5px 10px 5px 10px', border : '2px solid orange', borderTopLeftRadius : 10, borderBottomRightRadius : 10}}>{data.data[i].catalog[j][k].name}</div>
                      <div style={{display : 'flex', flexDirection : 'row', flex : 1, alignItems : 'center'}}>
                        <img alt='' style={{objectFit : 'cover', width : 160, height : 160}} src={data.data[i].catalog[j][k].image}/>
                        <div style={{display : 'flex', flexDirection : 'column', paddingLeft : 10, justifyContent : 'center'}}>
                          <div className='default_text_normal_gray bold_text'>CEK TOKO SEBELAH</div>
                          <div className='strike_through_text bold_text' style={{fontSize : 14, color : 'grey'}}>{'Rp. ' + formatSeparator(data.data[i].catalog[j][k].price_comparison)}</div>
                        </div>
                      </div>
                      <div style={{display : 'flex', justifyContent : 'flex-end', alignItems : 'flex-end'}}>
                        <div className='bold_text' style={{fontSize : 14, color : 'white', padding : 10, background : 'red', borderRadius : 10}}>{'Rp. ' + formatSeparator(data.data[i].catalog[j][k].price) + ' /' + data.data[i].catalog[j][k].uom}</div>
                      </div>
                    </div>



                    <div style={{height : 1, background : 'red', flexShrink : 0}}/>
                  </div>
                  <div style={{width : 1, background : 'red', flexShrink : 0}}/>
                </div>
              )
            }
          }
        }
        break;
      }
    }
    return view;
  }

  showRootView() {
    if (this.state.p_setting !== null) {
      return (
        <div style={m_style.con_root}>
          <div id='catalog_navigation' style={{flexDirection : 'column', margin : 10, border : '1px solid red'}}>
            <div className='bold_text default_text_normal_red' style={{display : 'flex', alignItems : 'center', height : 30, margin : '10px 10px 0 10px', borderBottom : '1px solid red'}}>JENIS KERTAS</div>
            <div style={{display : 'flex', flexDirection : 'row', padding : '10px 0 10px 0'}}>{this.showPaper()}</div>
            <div className='bold_text default_text_normal_red' style={{display : 'flex', alignItems : 'center', height : 30, margin : '0 10px 0 10px', borderBottom : '1px solid red'}}>ATTRIBUT PRODUCT</div>
            <div style={{display : 'flex', flexDirection : 'row', padding : '10px 0 10px 0'}}>
              <div onClick={() => this.setState({ d_show_catalog : true, d_big_catalog : true })} className='custom_cursor_a default_text_normal_red bold_text' style={{marginLeft : 10, border : '1px solid red', borderRadius : 5, width : 120, height : 60, display : 'flex', justifyContent : 'center', alignItems : 'center'}}>KATALOG BESAR</div>
              <div onClick={() => this.setState({ d_show_catalog : true, d_big_catalog : false })} className='custom_cursor_a default_text_normal_red bold_text' style={{marginLeft : 10, border : '1px solid red', borderRadius : 5, width : 120, height : 60, display : 'flex', justifyContent : 'center', alignItems : 'center'}}>KATALOG KECIL</div>
            </div>
          </div>
          <div style={{...this.state.p_setting.paper.selected.style, ...{flexShrink : 0, display : 'flex', flexDirection : 'row'}}}>
            <div style={{width : 5, background : 'red'}}/>
            <div style={{display : 'flex', flexDirection : 'column', flex : 1}}>
              <div style={{height : 5, background : 'red', flexShrink : 0}}/>
              <div style={{flex : 1}}>{this.getCatalog(this.state.p_setting.page)}</div>
              <div style={{height : 5, background : 'red', flexShrink : 0}}/>
            </div>
            <div style={{width : 5, background : 'red'}}/>
          </div>
        </div>
      );
    } else { return null; }
  }

  showMainView() {
    return showErrorPage(this, this.showRootView(), () => this.onRefresh());
  }

  render() {
    return (
      <div style={m_style.win_root}>
        { showLoadingDialog(this) }
        { showErrorDialog(this) }
        { showCatalogDialog(this) }
        <div id='header_navigation' style={m_style.back_header}>
          <img alt='' className='custom_cursor_a' style={{width : 18, height : 18}} src={ic_back}/>
          <div style={{paddingLeft : 20}} className='default_text_medium_white bold_text'>KATALOG EDITOR</div>
        </div>
        { showLoadingPage(this) }
      </div>
    );
  }

}

const m_style = {
  win_root : {
    display : 'flex',
    flexDirection : 'column',
    width : '100%',
    height : '100vh'
  },
  back_header : {
    padding : '0 10px 0 10px',
    flexDirection : 'row',
    alignItems : 'center',
    height : 45,
    background : 'red'
  },
  con_root : {
    display : 'flex',
    flexDirection : 'column',
    height : '100%',
    overflow : 'auto'
  }
}
