import React, { Component } from 'react';
import Switch from "react-switch";

import LoadingDialog from './dialog/LoadingDialog';
import ErrorDialog from './dialog/ErrorDialog';
import ConfirmationDialog from './dialog/ConfirmationDialog';
import CustomerMigrationDialog from './dialog/CustomerMigrationDialog';

import gif_img from './resources/loading.gif';
import img_error from './resources/error.png';
import icon_delete from './resources/delete.png';
import icon_media from './resources/media.png';
import icon_font from './resources/font.png';
import icon_key from './resources/key.png';
import icon_chat from './resources/chat.png';
import icon_credit from './resources/credit_card.png';
import icon_map from './resources/map.png';
import icon_heart_rate from './resources/heart_rate.png';
import icon_additional_action from './resources/additional_action.png';
import icon_additional_notification from './resources/additional_notification.png';
import icon_radius from './resources/radius.png';
import icon_telephone from './resources/telephone.png';

var QRCode = require('qrcode.react');

var request = require('../utilities/NetworkRequest');
var cache_util = require('../utilities/CacheUtil');
var img_utils = require('../utilities/ImageUtil');

export default class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : false,
      is_loading : false,
      is_error : false,
      error_message : '',
      body_error : {
        is_show : false,
        message : ''
      },
      show_migration_dialog : { show : false },
      show_confirmation : {
        show : false,
        question : '',
        body : null,
        yes_answer : 'Lanjut',
        no_answer : 'Batal'
      },
      user_information : {},
      auth_presence : null,
      dashboard_data : null
    }
  }

  componentDidMount() {
    // if (this.props.data === undefined) {
    //   this.refreshData();
    // } else {
    //   this.setState({
    //     user_information : this.props.data.user_information,
    //     dashboard_data : this.props.data.dashboard_view,
    //     auth_presence : this.props.data.auth_presence
    //   });
    // }

    if (this.props.data) {
      if (this.props.data.auth_presence && this.props.data.user_information && this.props.data.dashboard_view) {
        this.setState({
          user_information : this.props.data.user_information,
          dashboard_data : this.props.data.dashboard_view,
          auth_presence : this.props.data.auth_presence
        });
      } else {
        this.refreshData();
      }
    } else {
      this.refreshData();
    }
  }

  refreshData() {
    this.setState({ is_main_loading : true }, () => {
      let decoded_input = cache_util.onGetCacheData(cache_util.CACHE_INFO).result.user_information;
      request.onRequestData(request.OPTION_API_MAIN, '/show/home', decoded_input, res_data => {
        if (res_data.code === 1) {
          this.setState({
            is_main_loading : false,
            user_information : decoded_input,
            auth_presence : res_data.result.auth_presence,
            dashboard_data : res_data.result.dashboard_view
          });
        } else {
          this.setState({
            is_main_loading : false,
            is_error : true,
            error_message : res_data.message
          });
        }
      });
    });
  }

  initMainAction(data) {
    let view = [];
    for (let j = 0; j < Object.keys(data).length; j++) {
      if (data[j].key !== 'qr_code') {
        view.push(
          <div key={j} className='main_action_container'>
            <div className='circle_background center_content'>
              <img className='mini_image' src={require(data[j].image + '')} alt=''/>
            </div>
            <div className='normal_padding_left_b' >
              <div className="bold_text default_text_big_white">{data[j].count}</div>
              <div className='default_text_normal_white'>{data[j].label}</div>
            </div>
          </div>
        );
      } else {
        view.push(
          <QRCode key='qr_code' style={{'marginTop':10, 'marginBottom':30}} size={200} value={data[j].label} />
        );
      }
    }
    return view;
  }

  onChangeText(event, key, data) {
    if (data.is_edit) {
      let temp = this.state.dashboard_data;
      temp.sub_home[key] = event.target.value;
      this.setState({ dashboard_data : temp });
    }
  }

  getClassForm(class_type) {
    switch (class_type) {
      case 1:
        return 'wrapper_form_product';
      case 2:
        return 'wrapper_form_product normal_padding_bottom normal_border_bottom';
      default:
        return '';
    }
  }

  getEditorStyle(data) {
    if (!data.is_edit) {
      return 'wrapper_right_form_dashboard normal_border_bottom normal_margin_top_d bold_text default_text_normal_gray transparent_input_c';
    } else {
      return 'wrapper_right_form_dashboard normal_margin_top_d bold_text default_text_normal_gray non_transparent_input_b'
    }
  }

  onSelectedFile(event) {
    let temp = this.state.dashboard_data;
    temp.sub_home.image_edit = event.target.files[0];
    this.setState({ dashboard_data : temp });
  }

  getInputType(type_input, key, data) {
    switch (type_input) {
      case 1:
        return <input onChange={(event) => this.onChangeText(event, key, data)} value={data[key]} spellCheck='false' readOnly={!data.is_edit} className={this.getEditorStyle(data)} type='text' />;
      case 2:
        return <div className='wrapper_right_form_dashboard normal_margin_top_d'><input accept='image/x-png,image/jpeg' onChange={(event) => this.onSelectedFile(event)} className='default_text_normal_gray' spellCheck='false' type='file' /></div>;
      default:
        return null;
    }
  }

  initFormInput(type_input, class_type, icon, title, key, data) {
    return (
      <div className={this.getClassForm(class_type)}>
        <div className='wrapper_left_form_dashboard'>
          <img className='mini_image_d' src={icon} alt=''/>
          <div className='normal_margin_left default_text_normal_gray'>{title}</div>
        </div>
        {this.getInputType(type_input, key, data)}
      </div>
    );
  }

  onPopupDelete(position, data_array) {
    let temp = this.state.dashboard_data;
    data_array.splice(position, 1);
    if (data_array.length > 0) {
      temp.sub_home.message_popup = data_array.join('%');
    } else {
      temp.sub_home.message_popup = '';
    }
    this.setState({ dashboard_data : temp });
  }

  initDataTable(data) {
    let view = [], popup_array = [];
    if (data.message_popup.length > 0) {
      popup_array = data.message_popup.split('%');
    }
    for (let i = 0; i < popup_array.length; i++) {
      view.push(
        <tr key={i}>
          <td><div className='normal_margin_right circle_background_f green_background'/></td>
          <td className='default_text_normal_gray'>{popup_array[i]}</td>
          <td>{data.is_edit ? <img onClick={this.onPopupDelete.bind(this, i, popup_array)} className='custom_cursor_a normal_margin_left mini_image_d' src={icon_delete} alt=''/> : null}</td>
        </tr>
      );
    }
    return view;
  }

  onActionSwitched(TAG, checked, data) {
    if (data.is_edit) {
      let temp = this.state.dashboard_data;
      temp.sub_home[TAG] = checked;
      this.setState({ dashboard_data : temp });
    }
  }

  getTextAreaStyle(data) {
    if (!data.is_edit) {
      return 'wrapper_right_area_dashboard default_text_normal_gray text_area_input_c';
    } else {
      return 'wrapper_right_area_dashboard default_text_normal_gray non_transparent_text_area';
    }
  }

  initImageEditor(data) {
    if (data.is_edit) {
      return this.initFormInput(2, 2, icon_media, 'Foto Grosir', '', data);
    }
  }

  getImageViewStyle(data) {
    if (!data.is_edit) {
      return 'wrapper_form_product normal_border_bottom normal_top_border_b';
    } else {
      return 'wrapper_form_product normal_top_border_b';
    }
  }

  getPresence() {
    if (this.state.auth_presence !== null) {
      if (this.state.auth_presence.code === 1) {
        return (
          <div>
            <div className='dashboard_header default_text_medium_gray center_content' style={{paddingBottom : 20}}><b>QR ABSEN GROSIR</b></div>
            <div className='center_content' style={{paddingBottom : 20}}>
              <QRCode key='qr_code_auth' size={200} value={this.state.auth_presence.result} />
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className='dashboard_header default_text_medium_gray center_content' style={{paddingBottom : 10}}><b>QR Code Invalid</b></div>
            <div className='default_text_normal_gray_b center_content' style={{paddingBottom : 20}}>{this.state.auth_presence.message}</div>
          </div>
        );
      }
    } else {
      return null;
    }
  }

  initDashboardContent(data) {
    return (
      <div className='dashboard_content'>
        <div className='dashboard_header default_text_medium_gray' style={{paddingBottom : 0}}>Selamat Datang, <i><b>{data.wholesaler_name} ({data.wholesaler_id})</b></i></div>
        <div style={{padding : '0 20px 20px 20px'}} className='default_text_normal_gray_b text'><u>Saat ini anda login sebagai <i><b>({this.state.user_information.user_id})</b></i></u></div>

        <div className={this.getImageViewStyle(data)}>
          <div className='wrapper_image_form_dashboard'>
            <img className='big_image_d' src={data.profile_img_url} alt='GAMBAR TIDAK DITEMUKAN' />
          </div>
        </div>
        {this.initImageEditor(data)}

        {this.initFormInput(1, 1, icon_font, 'Nama Grosir', 'wholesaler_name', data)}
        {data.is_edit ? null : this.initFormInput(1, 1, icon_key, 'MID Grosir', 'tp_cust_no', data)}
        {this.initFormInput(1, 1, icon_telephone, 'Nomor Telephone', 'phone', data)}
        {this.initFormInput(1, 2, icon_map, 'Alamat Grosir', 'address', data)}

        {this.initFormInput(1, 1, icon_credit, 'Limit Pemesanan', 'limit_order', data)}
        {this.initFormInput(1, 1, icon_radius, 'Radius Pelanggan', 'radius', data)}
        <div className='wrapper_form_product'>
          <div className='wrapper_left_form_dashboard'>
            <img className='mini_image_d' src={icon_key} alt=''/>
            <div className='normal_margin_left default_text_normal_gray'>Kode Akses</div>
          </div>
          <Switch
            onChange={(checked) => { this.onActionSwitched('need_acc_code', checked, data) }}
            checked={data.need_acc_code}
            className="react-switch wrapper_right_area_dashboard"
            height={20}
            width={40}
            onColor='#ee3c32'
          />
        </div>

        <div className='wrapper_form_product normal_padding_bottom normal_border_bottom'>
          <div className='wrapper_left_form_dashboard'>
            <img className='mini_image_d' src={icon_credit} alt=''/>
            <div className='normal_margin_left default_text_normal_gray'>Terima Pesanan Sebagian</div>
          </div>
          <Switch
            onChange={(checked) => { this.onActionSwitched('accept_half', checked, data) }}
            checked={data.accept_half}
            className="react-switch wrapper_right_area_dashboard"
            height={20}
            width={40}
            onColor='#ee3c32'
          />
        </div>

        <div className='wrapper_form_product'>
          <div className='wrapper_left_form_dashboard_b'>
            <img className='mini_image_d' src={icon_additional_notification} alt=''/>
            <div className='normal_margin_left default_text_normal_gray'>Pengumuman Tambahan</div>
          </div>
          <textarea onChange={(event) => this.onChangeText(event, 'notification_msg', data)} value={data.notification_msg} spellCheck='false' readOnly={!data.is_edit} className={this.getTextAreaStyle(data)} type='text' />
        </div>
        <div className='wrapper_form_product normal_padding_bottom normal_border_bottom'>
          <div className='wrapper_left_form_dashboard_b'>
            <img className='mini_image_d' src={icon_chat} alt=''/>
            <div className='normal_margin_left default_text_normal_gray'>Pesan Pop-up</div>
          </div>
          <div className='wrapper_right_form_dashboard'>
            {this.initPopUp(data)}
          </div>
        </div>

        {this.getStatusView(data)}

        {this.getAdditionalAction(data)}

        {this.getActionEditor(data)}
      </div>
    );
  }

  getStatusView(data) {
    if (data.is_edit) {
      return null;
    } else {
      return (
        <div className='wrapper_form_product'>
          <div className='wrapper_left_form_dashboard'>
            <img className='mini_image_d' src={icon_heart_rate} alt=''/>
            <div className='normal_margin_left default_text_normal_gray'>Status Grosir</div>
          </div>
          <div className='wrapper_right_form_dashboard'>
            <div className='green_background_2 bold_text non_process_button_a default_text_normal_white_b'>{data.wholesaler_status}</div>
          </div>
        </div>
      );
    }
  }

  getAdditionalAction(data) {
    if (data.is_edit) {
      return null;
    } else {
      return (
        <div className='wrapper_form_product normal_padding_bottom normal_border_bottom'>
          <div className='wrapper_left_form_dashboard_b'>
            <img className='mini_image_d' src={icon_additional_action} alt=''/>
            <div className='normal_margin_left default_text_normal_gray'>Aksi Tambahan</div>
          </div>
          <div className='wrapper_right_form_dashboard'>
            <div className='wrapper_dashboard_button_b'>
              <div onClick={this.confirmAliveWholesaler.bind(this, data)} className='default_text_normal_white_b orange_background process_button_f custom_cursor_a'>Aktifkan Grosir</div>
              <div onClick={this.confirmCBWholesaler.bind(this, data)} className='normal_margin_left_c default_text_normal_white_b orange_background process_button_f custom_cursor_a'>Pencarian Pelanggan</div>
            </div>
            <div className='normal_padding_top wrapper_dashboard_button_b'>
              <div onClick={this.showMigrationDialog.bind(this)} className='default_text_normal_white_b orange_background process_button_f custom_cursor_a'>Migrasi Pelanggan</div>
              <div onClick={this.postMonthlyReport.bind(this)} className='normal_margin_left_c default_text_normal_white_b red_background process_button_f custom_cursor_a'>Laporan Bulanan</div>
            </div>
          </div>
        </div>
      );
    }
  }

  initPopUp(data) {
    let view = [];
    view.push(
      <table key={'dashboard_popup_table'} className='table_form_dashboard'>
        <tbody>
          {this.initDataTable(data)}
        </tbody>
      </table>
    );
    if (data.is_edit) {
      view.push(
        <div key={'dashboard_popup_editor'} className='wrapper_popup_editor'>
          <input onChange={(event) => this.onChangeText(event, 'popup_editor', data)} value={data.popup_editor} spellCheck='false' className='normal_border_bottom bold_text default_text_normal_gray non_transparent_input_b' type='text' />
          <div onClick={this.onPopupEditorClicked.bind(this)} className='normal_margin_left default_text_normal_white_b blue_background process_button_i custom_cursor_a'>Tambah</div>
        </div>
      );
    }
    return view;
  }

  onPopupEditorClicked() {
    let temp = this.state.dashboard_data;
    if (temp.sub_home.popup_editor.length > 0) {
      if (temp.sub_home.message_popup.length > 0) {
        temp.sub_home.message_popup += '%' + temp.sub_home.popup_editor;
      } else {
        temp.sub_home.message_popup += temp.sub_home.popup_editor;
      }
      temp.sub_home.popup_editor = '';
      this.setState({ dashboard_data : temp });
    }
  }

  onEditorClicked() {
    let temp = this.state.dashboard_data;
    temp.sub_home.is_edit = !temp.sub_home.is_edit;
    this.setState({ dashboard_data : temp });
  }

  getActionEditor(data) {
    if (!data.is_edit) {
      return (
        <div className='wrapper_dashboard_button'>
          <div className='default_text_normal_white_b gray_background process_button_f'>Batal</div>
          <div onClick={this.onEditorClicked.bind(this)} className='normal_margin_left_c default_text_normal_white_b green_background process_button_f custom_cursor_a'>Edit</div>
        </div>
      );
    } else {
      return (
        <div className='wrapper_dashboard_button'>
          <div onClick={this.onEditorClicked.bind(this)} className='default_text_normal_white_b red_background process_button_f custom_cursor_a'>Batal</div>
          <div onClick={this.onSaveChange.bind(this)} className='normal_margin_left_c default_text_normal_white_b green_background process_button_f custom_cursor_a'>Simpan</div>
        </div>
      );
    }
  }

  onSaveChange() {
    this.setState({ is_loading : true }, () => {
      let temp_dashboard = this.state.dashboard_data;
      let temp = temp_dashboard.sub_home;
      try {
        if (temp.image_edit === undefined) {
          temp.image_edit = null;
        }
        // INIT FORM DATA
        let data_form = new FormData();
        if (temp.image_edit !== null) {
          if (!img_utils.accepted_file.includes(temp.image_edit.type)) {
            throw Error('Data gambar yang anda inputkan bukan berjenis jpeg/png, cobalah periksa kembali.');
          }
          data_form.append('image', temp.image_edit);
          data_form.append('image_type', temp.image_edit.type.split('/')[1]);
        }
        if (isNaN(temp.limit_order)) {
          throw Error('Nilai limit order yang anda inputkan mengandung karakter yang bukan merupakan angka.');
        }
        if (isNaN(temp.radius)) {
          throw Error('Nilai radius yang anda inputkan mengandung karakter yang bukan merupakan angka.');
        }
        data_form.append('wholesaler_id', temp.wholesaler_id);
        data_form.append('wholesaler_name', temp.wholesaler_name);
        data_form.append('address', temp.address);
        data_form.append('phone', temp.phone);
        data_form.append('notification_msg', temp.notification_msg);
        data_form.append('limit_order', temp.limit_order);
        data_form.append('radius', temp.radius);
        data_form.append('message_popup', temp.message_popup);
        data_form.append('need_acc_code', temp.need_acc_code);
        data_form.append('accept_half', temp.accept_half);
        if (temp.user_id !== undefined) {
          data_form.append('user_id', temp.user_id);
        }
        request.onRequestForm(1, '/update/master_wholesaler', data_form, function(res_data) {
          if (res_data.code === 1) {
            this.setState({
              is_loading : false
            }, () => { this.refreshData(); });
          } else {
            this.setState({
              is_loading : false,
              body_error : {
                is_show : true,
                message : res_data.message
              }
            });
          }
        }.bind(this));
      } catch (e) {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : e.message
          }
        });
      }
    });
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  showMigrationDialog() {
    let temp_migration_dialog = this.state.show_migration_dialog;
    temp_migration_dialog.show = !temp_migration_dialog.show;
    this.setState({ show_migration_dialog : temp_migration_dialog });
  }

  confirmCBWholesaler(data) {
    if (data.cb_confirmation !== null) {
      let temp_dialog = this.state.show_confirmation;
      temp_dialog.show = !temp_dialog.show;
      temp_dialog.question = data.cb_confirmation;
      data.privilege_id = this.state.user_information.privilege_id;
      data.user_id = this.state.user_information.user_id;
      temp_dialog.body = data;
      temp_dialog.body.type_action = 'connect_customer';
      this.setState({ show_confirmation : temp_dialog });
    } else {
      this.setState({
        body_error : {
          is_show : true,
          message : 'Maaf fitur ini tidak bisa digunakan, karena kecamatan akun induk grosir tidak sesuai dengan yang telah didaftarkan sebelumnya. Silahkan hubungi Tim Tokopintar untuk menyesuaikan kecamatan yang benar..!!'
        }
      });
    }
  }

  confirmAliveWholesaler(data) {
    let temp_dialog = this.state.show_confirmation;
    temp_dialog.show = !temp_dialog.show;
    temp_dialog.question = 'Dengan aksi ini, maka status grosir akan berubah menjadi \'LIVE\' dan beberapa fitur pendukung akan diaktifkan ataupun di RESET dari Tokopintar. Apakah anda ingin melanjutkannya?';
    data.privilege_id = this.state.user_information.privilege_id;
    data.user_id = this.state.user_information.user_id;
    temp_dialog.body = data;
    temp_dialog.body.type_action = 'alive';
    this.setState({ show_confirmation : temp_dialog });
  }

  confirmResignWholesaler(data) {
    let temp_dialog = this.state.show_confirmation;
    temp_dialog.show = !temp_dialog.show;
    temp_dialog.question = 'Dengan aksi ini, maka status grosir akan berubah menjadi \'RESIGN\' dan semua fitur akan di non-aktifkan dari Tokopintar. Apakah anda ingin melanjutkannya?';
    data.privilege_id = this.state.user_information.privilege_id;
    data.user_id = this.state.user_information.user_id;
    temp_dialog.body = data;
    temp_dialog.body.type_action = 'resign';
    this.setState({ show_confirmation : temp_dialog });
  }

  confirmNonActiveWholesaler(data) {
    let temp_dialog = this.state.show_confirmation;
    temp_dialog.show = !temp_dialog.show;
    temp_dialog.question = 'Dengan aksi ini, maka status grosir akan berubah menjadi \'NON-ACTIVE\' dan grosir tidak akan ditampilkan di pencarian Tokopintar, serta fitur belanja akan dihentikan. Apakah anda ingin melanjutkannya?';
    data.privilege_id = this.state.user_information.privilege_id;
    data.user_id = this.state.user_information.user_id;
    temp_dialog.body = data;
    temp_dialog.body.type_action = 'non_active';
    this.setState({ show_confirmation : temp_dialog });
  }

  postMonthlyReport() {
    this.setState({ is_loading : true }, () => {
      let body = { wholesaler_id : this.state.dashboard_data.sub_home.wholesaler_id }
      request.onRequestData(1, '/post/monthly_transaction', body, function(res_data) {
        if (res_data.code === 1) {
          this.setState({
            is_loading : false
          }, () => { window.open(res_data.result, '_self'); });
        } else {
          this.setState({
            is_loading : false,
            body_error : {
              is_show : true,
              message : res_data.message
            }
          });
        }
      }.bind(this));
    });
  }

  onCloseConfirmation(action) {
    let temp_dialog = this.state.show_confirmation;
    temp_dialog.show = !temp_dialog.show;
    temp_dialog.question = '';
    if (action !== 'next') {
      temp_dialog.body = null;
    }
    this.setState({
      is_loading : action === 'next' ? true : false,
      show_confirmation : temp_dialog
    }, () => {
      if (action === 'next') {
        switch (temp_dialog.body.type_action) {
          case 'alive':
            this.postAliveWholesaler(temp_dialog.body);
            break;
          case 'non_active':
            this.postNonActiveWholesaler(temp_dialog.body);
            break;
          case 'resign':
            this.postResignWholesaler(temp_dialog.body);
            break;
          case 'connect_customer':
            this.postConnectCustomer(temp_dialog.body);
            break;
          default:
            break;
        }
      }
    });
  }

  postConnectCustomer(body) {
    request.onRequestData(1, '/post/connect_customer', body, function(res_data) {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => { this.refreshData(); });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    }.bind(this));
  }

  postResignWholesaler(body) {
    request.onRequestData(1, '/post/resign_wholesaler', body, function(res_data) {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => { this.refreshData(); });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    }.bind(this));
  }

  postNonActiveWholesaler(body) {
    request.onRequestData(1, '/post/non_active_wholesaler', body, function(res_data) {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => { this.refreshData(); });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    }.bind(this));
  }

  postAliveWholesaler(body) {
    request.onRequestData(1, '/post/live_wholesaler', body, function(res_data) {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => { this.refreshData(); });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    }.bind(this));
  }

  onCloseMigration(action, data) {
    let temp_migration_dialog = this.state.show_migration_dialog;
    temp_migration_dialog.show = !temp_migration_dialog.show;
    if (action === 'next') {
      this.setState({
        is_loading : true,
        show_migration_dialog : temp_migration_dialog
      }, () => { this.postCustomerMigration(data); });
    } else {
      this.setState({ show_migration_dialog : temp_migration_dialog });
    }
  }

  postCustomerMigration(data) {
    data.wholesaler_id = this.state.dashboard_data.sub_home.wholesaler_id;
    data.privilege_id = this.state.user_information.privilege_id;
    data.user_id = this.state.user_information.user_id;
    request.onRequestData(1, '/post/migration_customer', data, function(res_data) {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => { this.refreshData(); });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    }.bind(this));
  }

  initViewDashboard(data) {
    if (data !== undefined) {
      if (data === null) {
        return null;
      } else {
        return (
          <div className='dashboard_root'>
            <ConfirmationDialog data={this.state.show_confirmation} onCloseDialog={(action) => this.onCloseConfirmation(action)} />
            { this.state.show_migration_dialog.show ? <CustomerMigrationDialog onCloseListener={(action, data) => this.onCloseMigration(action, data)} /> : null }
            <LoadingDialog showDialog={this.state.is_loading}/>
            {this.showErrorDialog(this.state.body_error)}
            {this.initDashboardContent(data.sub_home)}
            <div className='main_dashboard_grid' style={{paddingRight : 10}}>
              {this.initMainAction(data.status_wholesaler)}
              { this.getPresence() }
            </div>
          </div>
        );
      }
    } else {
      return null;
    }
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className='loading_container'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return this.initViewDashboard(this.state.dashboard_data);
      } else {
        return (
          <div className='loading_container'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

}
