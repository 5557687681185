import React from 'react';

import RingLoader from 'react-spinners/RingLoader';
import PuffLoader from 'react-spinners/PuffLoader';

/* THIS FUNCTION WILL CREATE NEW STATE CALL = loading_floating */
export function showLoadingFloating(context) {
  if (context.state.loading_floating === undefined) { context.state.loading_floating = false; }
  if (context.state.loading_floating) {
    return (
      <div className='v2_app' style={{right : 20, marginTop : 55, height : 'auto', width : 'auto', zIndex : 1, position : 'absolute'}}>
        <PuffLoader
          size={30}
          color='red'
          loading={true}
        />
      </div>
    );
  } else { return null; }
}

/* THIS FUNCTION WILL CREATE NEW STATE CALL = loading_dialog */
export function showLoadingDialog(context) {
  if (context.state.loading_dialog === undefined) { context.state.loading_dialog = false; }
  if (context.state.loading_dialog) {
    return (
      <div className='v2_app' style={{zIndex : 3, position : 'absolute', justifyContent : 'center', alignItems : 'center', backgroundColor : 'rgba(0, 0, 0, 0.8)'}}>
        <RingLoader
          size={60}
          color='red'
          loading={true}
        />
      </div>
    );
  } else { return null; }
}

/* THIS FUNCTION WILL CREATE NEW STATE CALL = loading_page */
export function showLoadingPage(context) {
  if (context.state.loading_page === undefined) { context.state.loading_page = true; }
  if (context.state.loading_page) {
    return (
      <div className='v2_app' style={{justifyContent : 'center', alignItems : 'center'}}>
        <RingLoader
          size={60}
          color='red'
          loading={true}
        />
      </div>
    );
  } else {
    return context.showMainView();
  }
}
