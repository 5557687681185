import React, { Component } from 'react';

import LoadingDialog from './LoadingDialog';
import ErrorDialog from './ErrorDialog';

import img_shuffle from '../resources/baseline_shuffle.png';
import img_expand_more from '../resources/baseline_expand_more.png';

var request = require('../../utilities/NetworkRequest');

export default class DeliveryDialog extends Component {

  constructor(props) {
    super(props);
    let temp_props = JSON.stringify(this.props.body);
    temp_props = JSON.parse(temp_props);
    this.state = {
      is_loading : false,
      body_error : {
        is_show : false,
        message : ''
      },
      driver : temp_props.driver,
      body : temp_props.body_request
    }
  }

  onDropdownChoosed(data) {
    let temp_data = this.state.body;
    temp_data.driver = data.key;
    let temp_driver = this.state.driver;
    temp_driver.selected = data;
    temp_driver.is_show = !temp_driver.is_show;
    this.setState({
      driver : temp_driver,
      body : temp_data
    });
  }

  initDropdownData(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <div onClick={this.onDropdownChoosed.bind(this, data[i])} key={i} className='side_padding vertical_padding_b default_text_normal_gray'>{data[i].description}</div>
      );
    }
    return view;
  }

  showDropdown(data) {
    if (data.is_show) {
      return (
        <div className='dropdown_wrapper layer_a normal_margin_top_b'>
          <div className='arrow-up'/>
          <div className='dropdown_content_b white_background'>
            {this.initDropdownData(data.data)}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  onDropdownClicked() {
    let temp_data = this.state.driver;
    temp_data.is_show = !temp_data.is_show;
    this.setState({
      driver : temp_data
    });
  }

  onInvoiceChange(event) {
    let temp_body = this.state.body;
    temp_body.inv_ref = event.target.value;
    this.setState({
      body : temp_body
    });
  }

  onRandomClicked() {
    let temp_body = this.state.body;
    temp_body.inv_ref = 'INV-' + Date.now();
    this.setState({
      body : temp_body
    })
  }

  requestPostDelivery() {
    let body = this.state.body;
    request.onRequestData(request.OPTION_API_MAIN, '/post/delivery', body, res_data => {
      if (res_data.code === 1) {
        this.setState({
          is_loading : false
        }, () => {
          this.onCloseDialog(true);
        });
      } else {
        this.setState({
          is_loading : false,
          body_error : {
            is_show : true,
            message : res_data.message
          }
        });
      }
    });
  }

  onActionDeliver() {
    this.setState({
      is_loading : true
    }, () => {
      switch (this.state.body.view_type) {
        case 1:
          this.requestPostDelivery();
          break;
        case 2:
          this.requestPostTicket();
          break;
        default:
          // DO NOTHING
          break;
      }
    });
  }

  requestPostTicket() {
    try {
      let body = this.state.body;
      if (this.state.driver.selected.key === null) {
        throw Error('Anda belum memilih driver yang bersangkutan.');
      }
      body.driver = this.state.driver.selected.key;
      request.onRequestData(request.OPTION_API_MAIN, '/post/delivery_ticket', body, res_data => {
        if (res_data.code === 1) {
          this.setState({
            is_loading : false
          }, () => {
            this.onCloseDialog(true);
          });
        } else {
          this.setState({
            is_loading : false,
            body_error : {
              is_show : true,
              message : res_data.message
            }
          });
        }
      });
    } catch (e) {
      this.setState({
        is_loading : false,
        body_error : {
          is_show : true,
          message : e.message
        }
      });
    }
  }

  initViewType() {
    switch (this.state.body.view_type) {
      case 1:
        return (
          <div className='delivery_form_container'>
            <div id='form_label' className='default_text_normal_gray'>Kode Invoice</div>
            <div className='center_content side_padding_c default_text_normal_gray'>:</div>
            <div id='form_input' className='normal_border_bottom'>
              <input value={this.state.body.inv_ref} onChange={(e) => this.onInvoiceChange(e)} style={{width:250}} spellCheck='false' className='bold_text default_text_normal_gray transparent_input_c full_flex' type='text' />
              <div onClick={this.onRandomClicked.bind(this)} className='custom_padding_b label_search_container green_background normal_margin_bottom'>
                <img className='mini_image_d' src={img_shuffle} alt=''/>
                <div className='normal_padding_left_c default_text_normal_white'>Random</div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className='delivery_form_container'>
            <div id='form_label' className='default_text_normal_gray'>Pilih Supir</div>
            <div className='center_content side_padding_c default_text_normal_gray'>:</div>
            <div className='dropdown_container_category'>
              <div onClick={this.onDropdownClicked.bind(this)} className='dropdown_chooser_category'>
                <div className='full_flex default_text_normal_gray'>{this.state.driver.selected.description}</div>
                <div className='center_content circle_background_b'>
                  <img className='mini_image_b' src={img_expand_more} alt=''/>
                </div>
              </div>
              {this.showDropdown(this.state.driver)}
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  initMainView() {
    return (
      <div onClick={(e) => e.stopPropagation()} className='delivery_dialog_container'>
        {this.initViewType()}
        <div className='button_process_wrapper_b'>
          <div onClick={this.onCloseDialog.bind(this, false)} className='default_text_normal_white_b red_background process_button_C'>Batal</div>
          <div style={{width : 20}}/>
          <div onClick={this.onActionDeliver.bind(this)} className='default_text_normal_white_b green_background process_button_C'>Lanjutkan</div>
        </div>
      </div>
    );
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  onCloseDialog(is_updated) {
    if (!this.state.is_loading && !this.state.body_error.is_show) {
      this.props.onCloseListener(is_updated);
    }
  }

  render() {
    return(
      <div onClick={this.onCloseDialog.bind(this, false)} className='process_dialog_root layer_a'>
        <LoadingDialog showDialog={this.state.is_loading}/>
        {this.showErrorDialog(this.state.body_error)}
        {this.initMainView()}
      </div>
    );
  }

}
