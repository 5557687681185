import React, { Component } from 'react';
import DatePicker from 'react-date-picker';
import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";

import AdvSearchDialog from './dialog/AdvSearchDialog';
import LoadingDialog from './dialog/LoadingDialog';
import ErrorDialog from './dialog/ErrorDialog';
import DropdownSearch from './native/DropdownSearch';

import gif_img from './resources/loading.gif';
import img_error from './resources/error.png';
import ic_start_calendar from './resources/calendar_start.png';
import ic_stop_calendar from './resources/calendar_stop.png';
import icon_growth from './resources/growth.png';
import icon_calendar from './resources/calendar.png';

var base64 = require('base-64');

var view_util = require('../utilities/ViewUtil');
var request = require('../utilities/NetworkRequest');
var cache_util = require('../utilities/CacheUtil');
var str_util = require('../utilities/StringUtil');

const Plot = createPlotlyComponent(Plotly);

export default class Analyze extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : false,
      is_loading : false,
      is_error : false,
      error_message : '',
      body_error : {
        is_show : false,
        message : ''
      },
      user_information : null,
      base_analyze : [],
      graph_header : null,
      dialog_advance : {
        show : false,
        body : null
      },
      search_header : null,
      toolbar : {},
      table_header : null,
      table_data : [],
      pagination : {},
      pagination_array : []
    }
  }

  componentDidMount() {
    this.setState({
      is_main_loading : true
    }, () => {
      try {
        let decoded_input = this.state.user_information;
        if (decoded_input === null) {
          decoded_input = cache_util.onGetCacheData(cache_util.CACHE_INFO).result.user_information;
          decoded_input.length_data = 50;
        }
        request.onRequestData(1, '/show/base_analyze', decoded_input, res_data => {
          if (res_data.code === 1) {
            this.setState({
              user_information : decoded_input,
              is_main_loading : false,
              is_error : false,
              base_analyze : res_data.result
            });
          } else {
            this.setState({
              is_main_loading : false,
              is_error : true,
              error_message : res_data.message
            });
          }
        });
      } catch (e) {
        this.setState({
          is_main_loading : false,
          is_error : true,
          error_message : e.message
        });
      }
    });
  }

  refreshGraphData() {
    this.setState({ is_loading : true, table_data : [], search_header : null }, () => {
      let body = {
        wholesaler_id : this.state.user_information.wholesaler_id,
        base_analyze : JSON.stringify(this.state.base_analyze)
      }
      let decoded_input = this.state.user_information;
      if (decoded_input.hasOwnProperty('m_point')) { delete decoded_input.m_point; }
      request.onRequestData(1, '/show/start_analyze', body, res_data => {
        if (res_data.code === 1) {
          this.setState({
            is_loading : false,
            graph_header : res_data.result.graph_header,
            dialog_advance : {
              show : false,
              body : res_data.result.filterV2
            },
            user_information : decoded_input,
            search_header : res_data.result.search_header,
            toolbar : res_data.result.toolbar,
            table_header : res_data.result.res_data.header,
            table_data : res_data.result.res_data.data,
            pagination : {},
            pagination_array : []
          });
        } else {
          this.setState({
            is_loading : false,
            body_error : {
              is_show : true,
              message : res_data.message
            }
          });
        }
      });
    });
  }

  getPoint(data, table_only, is_filter) {
    let key = this.getBaseAnalyzeKey();
    if (key !== 'rsm_performance_analyze') {
      this.setState({ is_loading : true, table_data : [], search_header : null }, () => {
        let decoded_input = this.state.user_information;
        if (!is_filter) {
          if (decoded_input.hasOwnProperty('filter')) {
            delete decoded_input.filter;
          }
        }
        let m_point = null;
        if (data !== null) {
          m_point = {
            x : data.points[0].x,
            y : data.points[0].y,
            key_header : data.points[0].data.key_header
          }
          decoded_input.m_point = m_point;
          decoded_input.point = JSON.stringify(m_point);
        }
        if (decoded_input.hasOwnProperty('last_time_stamp')) { delete decoded_input.last_time_stamp; }
        request.onRequestData(1, '/show/point_analyze', decoded_input, res_data => {
          if (res_data.code === 1) {
            let temp_pagination_array = [];
            temp_pagination_array.push(res_data.result.res_data.pagination);
            if (table_only) {
              this.setState({
                is_loading : false,
                user_information : decoded_input,
                search_header : res_data.result.search_header,
                table_data : res_data.result.res_data.data,
                pagination : res_data.result.res_data.pagination,
                pagination_array : temp_pagination_array
              });
            } else {
              this.setState({
                is_loading : false,
                dialog_advance : {
                  show : false,
                  body : res_data.result.filterV2
                },
                user_information : decoded_input,
                search_header : res_data.result.search_header,
                toolbar : res_data.result.toolbar,
                table_header : res_data.result.res_data.header,
                table_data : res_data.result.res_data.data,
                pagination : res_data.result.res_data.pagination,
                pagination_array : temp_pagination_array
              });
            }
          } else {
            this.setState({
              is_loading : false,
              body_error : {
                is_show : true,
                message : res_data.message
              }
            });
          }
        });
      });
    }
  }

  getPaddingTopStyle(need_top_padding) {
    let style = 'wrapper_form_analyze';
    if (need_top_padding) {
      style += ' normal_padding_top';
    }
    return style;
  }

  getAnalyzeDropdown(data, need_top_padding, listener) {
    return (
      <div key={data.key} className={this.getPaddingTopStyle(need_top_padding)}>
        <div className='wrapper_left_form_product'>
          <img className='mini_image_d' src={icon_growth} alt=''/>
          <div className='normal_margin_left default_text_normal_gray'>{data.description}</div>
        </div>
        <div className='wrapper_right_form_product'>
          <DropdownSearch onChangeSelected={listener} is_active={true} data={data}/>
        </div>
      </div>
    );
  }

  getDateString(value) {
    return ('0' + (value.getMonth() + 1)).slice(-2) + '/' + ('0' + value.getDate()).slice(-2) + '/' + value.getFullYear();
  }

  onValueChanged(position, TAG, value) {
    let temp = this.state.base_analyze;
    temp[position][TAG] = value;
    this.setState({ base_analyze : temp });
  }

  getTimeRange(position, data, need_top_padding) {
    return (
      <div key={data.key} className={this.getPaddingTopStyle(need_top_padding)}>
        <div className='wrapper_left_form_product'>
          <img className='mini_image_d' src={icon_calendar} alt=''/>
          <div className='normal_margin_left default_text_normal_gray'>{data.description}</div>
        </div>
        <div className='wrapper_right_form_product'>
          <div className='time_range_container'>
            <div className='orange_background side_padding display_flex_center'><img className='mini_image_c' src={ic_start_calendar} alt='' /></div>
            <DatePicker className='default_text_normal_gray' value={new Date(data.value_start)} locale="en-US" onChange={(date) => this.onValueChanged(position, 'value_start', this.getDateString(date))} />
            <div className='orange_background side_padding display_flex_center'><img className='mini_image_c' src={ic_stop_calendar} alt='' /></div>
            <DatePicker className='default_text_normal_gray' value={new Date(data.value_end)} locale="en-US" onChange={(date) => this.onValueChanged(position, 'value_end', this.getDateString(date))} />
          </div>
        </div>
      </div>
    );
  }

  initTableBody(pos, data) {
    let view = null;
    let key = this.getBaseAnalyzeKey();
    if (this.state.user_information.hasOwnProperty('m_point')) { key = this.state.user_information.m_point.key_header; }
    switch (key) {
      case 'count_all':
      case 'count_done':
      case 'count_pending':
      case 'count_cancel':
      case 'sum_all':
      case 'sum_done':
      case 'sum_pending':
      case 'sum_cancel':
        view = (
          <tr key={pos}>
            <td className='whole_padding'><input type="checkbox" checked={data.is_selected} onChange={this.onCheckboxClicked.bind(this,false,pos)} name={data.order_no} value={data.order_no}/></td>
            <td className='whole_padding default_text_normal_gray'>{data.order_no}</td>
            <td className='whole_padding default_text_normal_gray'>{data.order_date_formatted}</td>
            <td className='whole_padding default_text_normal_gray'>{data.retail_id}</td>
            <td className='whole_padding default_text_normal_gray'>{data.retail_name}</td>
            <td className='whole_padding default_text_normal_gray'>{data.total_items}</td>
            <td className='whole_padding default_text_normal_gray'>{str_util.formatSeparator(data.amount)}</td>
          </tr>
        );
        break;
      case 'order_analyze':
      case 'rsm_performance_analyze':
        view = (
          <tr key={pos}>
            <td className='whole_padding default_text_normal_gray'>{data.label}</td>
            <td className='whole_padding default_text_normal_gray'>{str_util.formatSeparator(data.sum_all) + ' (' + data.count_all + ')'}</td>
            <td className='whole_padding default_text_normal_gray'>{str_util.formatSeparator(data.sum_done) + ' (' + data.count_done + ')'}</td>
            <td className='whole_padding default_text_normal_gray'>{str_util.formatSeparator(data.sum_pending) + ' (' + data.count_pending + ')'}</td>
            <td className='whole_padding default_text_normal_gray'>{str_util.formatSeparator(data.sum_cancel) + ' (' + data.count_cancel + ')'}</td>
          </tr>
        );
        break;
      default:
        break;
    }
    return view;
  }

  getBaseAnalyzeKey() {
    let key = '';
    for (let i = 0; i < Object.keys(this.state.base_analyze).length; i++) {
      if (this.state.base_analyze[i].key === 'type_analyze') {
        key = this.state.base_analyze[i].selected.key;
        break;
      }
    }
    return key;
  }

  onCheckboxClicked(is_header, position) {
    let temp_table_header = this.state.table_header;
    let temp_table_data = this.state.table_data;
    if (is_header) {
      temp_table_header.is_selected = !temp_table_header.is_selected;
      for (let i = 0; i < Object.keys(temp_table_data).length; i++) {
        temp_table_data[i].is_selected = temp_table_header.is_selected;
      }
      this.setState({
        table_header : temp_table_header,
        table_data : temp_table_data
      });
    } else {
      temp_table_data[position].is_selected = !temp_table_data[position].is_selected;
      if (!temp_table_data[position].is_selected && temp_table_header.is_selected) {
        temp_table_header.is_selected = !temp_table_header.is_selected;
        this.setState({
          table_header : temp_table_header,
          table_data : temp_table_data
        });
      } else {
        this.setState({
          table_data : temp_table_data
        });
      }
    }
  }

  initTableHeader(data) {
    let view = null;
    let key = this.getBaseAnalyzeKey();
    if (this.state.user_information.hasOwnProperty('m_point')) { key = this.state.user_information.m_point.key_header; }
    switch (key) {
      case 'count_all':
      case 'count_done':
      case 'count_pending':
      case 'count_cancel':
      case 'sum_all':
      case 'sum_done':
      case 'sum_pending':
      case 'sum_cancel':
        view = (
          <tr>
            <th className='whole_padding'><input type="checkbox" checked={data.is_selected} onChange={this.onCheckboxClicked.bind(this,true,0)} name="select_all" value="all"/></th>
            <th className='main_column whole_padding default_text_normal_gray'>{data.order_no}</th>
            <th className='whole_padding default_text_normal_gray'>{data.order_date_formatted}</th>
            <th className='whole_padding default_text_normal_gray'>{data.retail_id}</th>
            <th className='whole_padding default_text_normal_gray'>{data.retail_name}</th>
            <th className='whole_padding default_text_normal_gray'>{data.total_items}</th>
            <th className='whole_padding default_text_normal_gray'>{data.amount}</th>
          </tr>
        );
        break;
      case 'order_analyze':
      case 'rsm_performance_analyze':
        view = (
          <tr>
            <th className='main_column whole_padding default_text_normal_gray'>{data.order_date}</th>
            <th className='whole_padding default_text_normal_gray'>{data.count_all}</th>
            <th className='whole_padding default_text_normal_gray'>{data.count_done}</th>
            <th className='whole_padding default_text_normal_gray'>{data.count_pending}</th>
            <th className='whole_padding default_text_normal_gray'>{data.count_cancel}</th>
          </tr>
        );
        break;
      default:
        break;
    }
    return view;
  }

  onChangeTypeBase(position, data) {
    // THIS WILL TRIGGER BASE VIEW
    let temp = this.state.base_analyze;
    this.setState({ table_header : null, table_data : [], base_analyze : temp });
  }

  getBaseAnalyze() {
    let view = [];
    let key = '';
    for (let i = 0; i < Object.keys(this.state.base_analyze).length; i++) {
      if (this.state.base_analyze[i].key === 'type_analyze') {
        key = this.state.base_analyze[i].selected.key;
        view.push(this.getAnalyzeDropdown(this.state.base_analyze[i], false, (data) => this.onChangeTypeBase(i, data)));
      } else {
        if (this.state.base_analyze[i].type_analyze.includes(key)) {
          switch (this.state.base_analyze[i].type) {
            case 'time_range_base':
              view.push(this.getTimeRange(i, this.state.base_analyze[i], true));
              break;
            case 'dropdown':
              view.push(this.getAnalyzeDropdown(this.state.base_analyze[i], true, null));
              break;
            default:
              break;
          }
        }
      }
    }
    return (
      <div className='analyze_search_container'>
        { view }
        <div className='wrapper_form_search_b'>
          <div className='process_button_f'/>
          <div style={{width : 20}}/>
          <div onClick={this.refreshGraphData.bind(this)} className='default_text_normal_white_b orange_background process_button_b'>Mulai Analisa</div>
        </div>
      </div>
    );
  }

  onLeftToolbarClicked(data) {
    switch (data.key) {
      case 'print':
        let temp_table_data = this.state.table_data, set_order_id = '';
        for (let i = 0; i < Object.keys(temp_table_data).length; i++) {
          if (temp_table_data[i].is_selected) {
            set_order_id += temp_table_data[i].order_no + '#';
          }
        }
        if (set_order_id.length > 0) {
          let print_params = {
            user_information : this.state.user_information,
            set_id : set_order_id.substr(0,set_order_id.length-1),
            view_id : 3
          }
          window.open(request.IP_PATH + '/print/' + base64.encode(JSON.stringify(print_params)), '_blank');
        }
        break;
      default:
        alert('Menu ini masih dalam tahap pengembangan.');
        break;
    }
  }

  refreshPagination(body, temp_pagination_array) {
    this.setState({
      is_loading : true
    }, () => {
      request.onRequestData(1, '/show/point_analyze', body, res_data => {
        if (res_data.code === 1) {
          if (temp_pagination_array == null) {
            temp_pagination_array = this.state.pagination_array;
            temp_pagination_array.push(res_data.result.res_data.pagination);
          }
          this.setState({
            is_loading : false,
            table_data : res_data.result.res_data.data,
            pagination : res_data.result.res_data.pagination,
            pagination_array : temp_pagination_array
          });
        } else {
          this.setState({
            is_loading : false,
            body_error : {
              is_show : true,
              message : res_data.message
            }
          });
        }
      });
    });
  }

  getAnalyzeView() {
    let view = [];
    if (this.state.graph_header !== null && this.state.table_header !== null) {
      view.push(
        <div key='graph' className='display_flex_center'>
          <Plot
            data={this.state.graph_header}
            layout={ {title: 'Grafik Analisa Pesanan', showlegend: true, hovermode:'closest'} }
            onClick={(data) => this.getPoint(data, false, false)}
          />
        </div>
      )
      view.push(
        view_util.initViewToolbar(
          this, 'table_data',
          (data) => this.onLeftToolbarClicked(data),
          () => console.log('WAIT')
        )
      );
      view.push(
        view_util.initViewTable(this.state.table_data,
          this.initTableHeader(this.state.table_header),
          (pos, data) => this.initTableBody(pos, data), false
        )
      );
      view.push(
        view_util.initPaginationTable(
          this.state,
          (body, pagination) => this.refreshPagination(body, pagination),
          (body) => this.refreshPagination(body, null)
        )
      );
    }
    return view;
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className='loading_container_b'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return (
          <div className='root_analyze'>
            <LoadingDialog showDialog={this.state.is_loading} />
            { this.showErrorDialog(this.state.body_error) }
            {
              this.state.dialog_advance.show ? <AdvSearchDialog
                body={this.state.dialog_advance.body}
                onCloseListener={ (action, body) => view_util.showDialogAdvance(this, action, () => this.getPoint(null, true, true) ) }
              /> : null
            }
            <div className='product_wrapper'>
              { this.getBaseAnalyze() }
              { this.getAnalyzeView() }
            </div>
          </div>
        );
      } else {
        return (
          <div className='loading_container_b'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }
}
