import React, { Component } from 'react';

import ErrorDialog from './ErrorDialog';

export default class DriverDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      body_error : {
        is_show : false,
        message : ''
      },
      body : JSON.parse(this.props.data)
    }
  }

  onEditTextChanged(event, action) {
    let temp_data = this.state.body;
    temp_data[action] = event.target.value;
    this.setState({ body : temp_data });
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return <ErrorDialog body_error={body} onCloseDialog={() => this.setState({ body_error : { is_show : false, message : '' } })}/>
    } else {
      return null;
    }
  }

  onSaveClicked() {
    try {
      if (this.state.body.driver_code.length === 0) {
        throw Error('Kolom ID pengemudi tidak boleh kosong.');
      }
      if (this.state.body.driver_name.length === 0) {
        throw Error('Kolom nama pengemudi tidak boleh kosong.');
      }
      if (this.state.body.driver_phone !== undefined) {
        if (this.state.body.driver_phone.length === 0) { throw Error('Kolom nomor tokopintar tidak boleh kosong.'); }
      }
      this.props.onCloseListener('next', this.state.body);
    } catch (e) {
      this.setState({
        body_error : {
          is_show : true,
          message : e.message
        }
      });
    }
  }

  getInputType() {
    if (this.state.body.is_update) {
      return <input readOnly='readonly' value={this.state.body.driver_code} style={{width:250}} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input' type='text' />;
    } else {
      return <input onChange={ (event) => this.onEditTextChanged(event, 'driver_code') } value={this.state.body.driver_code} style={{width:250}} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input' type='text' />;
    }
  }

  getPhoneForm() {
    if (!this.state.body.is_update) {
      return (
        <div className='normal_margin_top_e'>
          <div className='default_text_normal_gray bold_text'>Nomor Tokopintar</div>
          <input onChange={ (event) => this.onEditTextChanged(event, 'driver_phone') } value={this.state.body.driver_phone} style={{width:250}} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input' type='text' />
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div onClick={(e) => this.props.onCloseListener('close', null)} className='process_dialog_root layer_a'>
        <div onClick={(e) => e.stopPropagation()} className='add_retail_dialog_container'>
          {this.showErrorDialog(this.state.body_error)}
          <div className='default_text_normal_gray bold_text'>ID Pengemudi</div>
          {this.getInputType()}
          <div className='normal_margin_top_e default_text_normal_gray bold_text'>Nama Pengemudi</div>
          <input onChange={ (event) => this.onEditTextChanged(event, 'driver_name') } value={this.state.body.driver_name} style={{width:250}} spellCheck='false' className='normal_margin_top default_text_normal_gray non_transparent_input' type='text' />
          {this.getPhoneForm()}
          <div className='button_process_wrapper_b'>
            <div onClick={() => this.props.onCloseListener('close', null)} className='default_text_normal_white_b red_background process_button_C'>Batal</div>
            <div style={{width : 20}}/>
            <div onClick={this.onSaveClicked.bind(this)} className='default_text_normal_white_b green_background process_button_C'>Simpan</div>
          </div>
        </div>
      </div>
    );
  }

}
